import axios from "axios";

export async function getRequest(url) {
  const auth_key = document.cookie
    .split("; ")
    .find((row) => row.startsWith("auth_key="))
    ?.split("=")[1];

  try {
    const response = await axios.get(
      `${import.meta.env.VITE_APP_BASE_URL}${url}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${auth_key}`,
        },
      },
    );
    return response;
  } catch (error) {
    // console.log(error)
    // handleRedirect(error?.response?.status)
    throw error?.response;
  }
}
export async function getRequest_image(url) {
  const auth_key = document.cookie
    .split("; ")
    .find((row) => row.startsWith("auth_key="))
    ?.split("=")[1];

  try {
    const response = await axios.get(
      `${import.meta.env.VITE_APP_BASE_URL}${url}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${auth_key}`,
          Accept: "*/*",
        },
        responseType: "blob", // Ensure the response is treated as a blob
      },
    );

    console.log("GET REQUEST IMAGE RESPONSE", response);
    console.log("GET REQUEST IMAGE RESPONSE DATA", response.data);
    const blob = response.data;
    const blobUrl = URL.createObjectURL(blob);

    console.log("GET REQUEST IMAGE RESPONSE BLOB IMAGE1 ", blob);
    console.log("GET REQUEST IMAGE RESPONSE BLOB IMAGE 2 ", blobUrl);

    // Infer the file extension from the MIME type
    const mimeType = blob.type;
    let extension;
    switch (mimeType) {
      case "image/jpeg":
        extension = "jpg";
        break;
      case "image/jpeg":
        extension = "jpeg";
        break;
      case "image/png":
        extension = "png";
        break;
      case "application/pdf":
        extension = "pdf";
        break;
      // Add more cases as needed
      default:
        extension = "";
    }

    console.log("GET REQUEST IMAGE RESPONSE BLOB IMAGE1 ", mimeType);

    return { blobUrl, extension };
  } catch (error) {
    console.error("Error in GET REQUEST IMAGES:");
    throw error;
  }
}

export async function postRequest(url, data) {
  const auth_key = document.cookie
    .split("; ")
    .find((row) => row.startsWith("auth_key="))
    ?.split("=")[1];

  try {
    const response = await axios.post(
      process.env.NEXT_PUBLIC_BASE_API_URL + url,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${auth_key}`,
        },
      },
    );
    return response;
  } catch (error) {
    // console.log(error)
    // handleRedirect(error?.response?.status)
    throw error?.response;
  }
}

export async function putRequest(url, data) {
  const auth_key = document.cookie
    .split("; ")
    .find((row) => row.startsWith("auth_key="))
    ?.split("=")[1];

  try {
    const response = await axios.put(
      `${import.meta.env.VITE_APP_BASE_URL}${url}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${auth_key}`,
        },
      },
    );
    return response;
  } catch (error) {
    // console.log(error)
    // handleRedirect(error?.response?.status)
    throw error?.response;
  }
}

export async function patchRequest(url, data) {
  const auth_key = document.cookie
    .split("; ")
    .find((row) => row.startsWith("auth_key="))
    ?.split("=")[1];
  try {
    const response = await axios.patch(
      `${import.meta.env.VITE_APP_BASE_URL}${url}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${auth_key}`,
        },
      },
    );
    return response;
  } catch (error) {
    // console.log(error)
    // handleRedirect(error?.response?.status)
    throw error?.response;
  }
}

export async function deleteRequest(url) {
  const auth_key = document.cookie
    .split("; ")
    .find((row) => row.startsWith("auth_key="))
    ?.split("=")[1];

  try {
    const response = await axios.delete(
      `${import.meta.env.VITE_APP_BASE_URL}${url}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${auth_key}`,
        },
      },
    );
    return response;
  } catch (error) {
    // console.log(error)
    // handleRedirect(error?.response?.status)
    throw error?.response;
  }
}

export const multiPartPostRequest = async (url, formData) => {
  const auth_key = document.cookie
    .split("; ")
    .find((row) => row.startsWith("auth_key="))
    ?.split("=")[1];

  try {
    const response = await axios.post(
      `${import.meta.env.VITE_APP_BASE_URL}${url}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${auth_key}`,
        },
      },
    );
    return response;
  } catch (error) {
    throw error?.response;
  }
};

export const multiPartPatchRequest = async (url, formData) => {
  const auth_key = document.cookie
    .split("; ")
    .find((row) => row.startsWith("auth_key="))
    ?.split("=")[1];
  try {
    const response = await axios.patch(
      `${import.meta.env.VITE_APP_BASE_URL}${url}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${auth_key}`,
        },
      },
    );
    return response;
  } catch (error) {
    throw error?.response;
  }
};

export const handleRedirect = (status) => {
  const errorPages = {
    404: "404",
    403: "login",
    401: "login",
    500: "500",
  };
  const page = errorPages[status];

  if (page) {
    window.location.assign(`${import.meta.env.VITE_APP_BASE_URL}/${page}`);
  } else {
    return;
  }
};
