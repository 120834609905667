import axios from "axios";

export const uploadImage = async (blob, moodboard_id, selectedUserCompany=null) => {
  const token = document.cookie
    .split("; ")
    .find((row) => row.startsWith("auth_key="))
    ?.split("=")[1];

  const formData = new FormData();
  formData.append("file", blob);
  formData.append("name", "new image");
  formData.append("tracker", "moodboard")
  if (selectedUserCompany){
    formData.append("company", selectedUserCompany)
  } else {
    formData.append("project", "1");
  }
  if (moodboard_id) {
    formData.append("moodboard_id", moodboard_id);
  }

  const response = await axios.post(
    `${import.meta.env.VITE_APP_BASE_URL}/dc/api/usecproject_files/`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    },
  );
  const modifiedUrl = response.data.file.replace("cus_data", "unsecprojfiles");

  return { link: modifiedUrl, linkId: response?.data?.id };
};
