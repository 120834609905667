import React, { useState } from "react";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

import axios from "axios";

// ** MUI Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";

import MuiCard from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import MuiFormControlLabel from "@mui/material/FormControlLabel";

// ** Icons Imports
import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";

const LoginPage = () => {
  const navigate = useNavigate();
  // ** State
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [error, setError] = useState({});

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to the auth/login endpoint
      const response = await axios.post(
        import.meta.env.VITE_APP_BASE_URL + "/auth/login/",
        {
          email: values.email,
          password: values.password,
        },
      );
      if (response.data && response.data.key) {
        // Save the key to local storage or a cookie
        function setCookie(cookieName, cookieValue, expirationDays) {
          const d = new Date();
          d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
          const expires = "expires=" + d.toUTCString();
          document.cookie =
            cookieName + "=" + cookieValue + ";" + expires + ";path=/";
        }
        // localStorage.setItem("auth_key", response.data.key);
        setCookie("auth_key", response.data.key, 30);
        navigate("/");
        // const previousPage = localStorage.getItem("previousPage");
        // if (previousPage) {
        //   window.location.assign(
        //     "https://moodboard.dezinexpert.com?moodboardID=" + previousPage,
        //   );
        //   // navigate("?moodboardID=" + previousPage);
        // } else {
        //   navigate("/");
        // }
        // Redirect to the dashboard
        // router.push("/projects/");
      } else {
        // Handle the scenario when key is not present (you can customize this)
        alert(
          "Login response is missing the authentication key. Please try again.",
        );
      }
      // router.push('/dashboard')
    } catch (error) {
      if (error.response && error.response.data) {
        // You can customize this based on the error response from your server
        // console.log(error.response.data);
        setError(error.response.data);
        // console.log("set error");

        // alert(error.response.data.message || 'An error occurred during login.')
      } else {
        setError({
          non_field_errors:
            "Unexpected error. Please try again later. Contact support if error persists",
        });
        // General error handling
        alert("An unexpected error occurred. Please try again.");
      }

      // Optionally, you can reset the form or perform other actions
      setValues({
        ...values,
        password: "",
      });
    }
  };

  return (
    <>
      <Box
        style={{
          display: "grid",
          width: "full",
          //   margin: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MuiCard sx={{ zIndex: 1, width: "28rem" }}>
          <CardContent
          // sx={{ padding: (theme) => `${theme.spacing(12, 9, 7)} !important` }}
          >
            <Box
              sx={{
                mb: 8,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Logo />
              <Typography
                variant="h6"
                sx={{
                  ml: 3,
                  lineHeight: 1,
                  fontWeight: 600,
                  textTransform: "uppercase",
                  fontSize: "1.5rem !important",
                }}
              >
                Dev Login
              </Typography>
            </Box>
            <Box sx={{ mb: 6 }}>
              <Typography variant="body2">Please sign-in to your </Typography>
            </Box>
            <form
              noValidate
              autoComplete="off"
              onSubmit={(e) => e.preventDefault()}
            >
              <TextField
                autoFocus
                // helperText={error.email && error.email[0]}
                fullWidth
                // onChange={handleChange("email")}
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
                value={values.email}
                id="email"
                label="Email"
                sx={{ marginBottom: 4 }}
              />
              <FormControl fullWidth>
                <InputLabel htmlFor="auth-login-password">Password</InputLabel>
                <OutlinedInput
                  label="Password"
                  value={values.password}
                  id="auth-login-password"
                  // onChange={handleChange("password")}
                  onChange={(e) =>
                    setValues({ ...values, password: e.target.value })
                  }
                  type={values.showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        aria-label="toggle password visibility"
                      >
                        {values.showPassword ? (
                          <EyeOutline />
                        ) : (
                          <EyeOffOutline />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Box
                sx={{
                  mb: 4,
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <MuiFormControlLabel
                  control={<Checkbox />}
                  label="Remember Me"
                />
                <Link to="/">Forgot Password?</Link>
              </Box>

              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{ marginBottom: 7 }}
                onClick={handleLogin}
              >
                Login
              </Button>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" sx={{ marginRight: 2 }}>
                  New on our platform?
                </Typography>
                <Typography variant="body2">
                  <Link to="/register">Create an account</Link>
                </Typography>
              </Box>

              <Divider sx={{ my: 5 }}>or</Divider>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <Link href='/' passHref> */}
                {/* <IconButton component='a' onClick={e => e.preventDefault()}>
              <Google sx={{ color: '#db4437' }} />
            </IconButton> */}
                {/* <GoogleLogin
            clientId="774407158869-l8478qeor2uop802hu4ba6dvcto9f8as.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
          /> */}
                {/* </Link> */}
              </Box>
            </form>
          </CardContent>
        </MuiCard>
      </Box>
    </>
  );
};

export default LoginPage;

export const Logo = () => {
  return <img src="/logo.svg" style={{ width: "28px" }} alt="logo" />;
};
