import {
  AppBar,
  Toolbar,
  //  Typography
} from "@mui/material";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Logo } from "./auth/loginPage";
import { useLayoutEffect, useState } from "react";
// import { debounce } from "../../src/utils";
import axios from "axios";
import Button from "@mui/material/Button";

const NavBar = ({
  disable,
  token,
  moodboardID,
  handleSave,
  fetchedTitle,
  setDrawerOpen,
  showResponse,
}) => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(fetchedTitle);
  // console.log(fetchedTitle);
  useLayoutEffect(() => {
    setTitle(fetchedTitle);
  }, [fetchedTitle]);

  const handleTitleChange = (event) => {
    event.preventDefault();
    // console.log("function run");
    if (moodboardID && token && title !== "") {
      // console.log("axios run");
      const selectedUserCompany = document.cookie
      .split("; ")
      .find((row) => row.startsWith("selected_company="))
      ?.split("=")[1];
      axios
        .patch(
          `${
            import.meta.env.VITE_APP_BASE_URL
          }/dc/api/${selectedUserCompany}/moodboard_api/${moodboardID}/`,
          { title },
          {
            headers: {
              // "Content-Type": "multipart/form-data",
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          },
        )
        .then((response) => {
          setLoading(false);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <AppBar
        elevation={10}
        position="static"
        className="layout-navbar"
        // pl="100"
        style={{
          backgroundColor: "white",
          // paddingLeft: "12px",
          fontWeight: "800",
          borderBottom: "1px solid #cbd5e1",
        }}
      >
        <Toolbar>
          <Link
            href={`${import.meta.env.VITE_APP_BASE_URL}/moodboard`}
            sx={{
              display: "none",
              gap: "2px",
              // alignItems: "center",
              textDecoration: "none",
              "@media (min-width: 640px)": {
                display: "flex",
              },
            }}
          >
            <Logo />
          </Link>
          <form onSubmit={(event) => handleTitleChange(event)}>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onFocus={(event) => {
                event.target.select();
              }}
              onBlur={(event) => {
                if (title === "") {
                  setTitle(fetchedTitle ? fetchedTitle : "Untitled");
                }
                handleTitleChange(event);
              }}
              style={{
                border: "none",
                outline: "none",
                fontWeight: "500",
                marginLeft: "10px",
                lineHeight: "normal",
                color: "#374151",
                fontSize: "20px",
                maxWidth: "200px",
                // borderBottom: "1px solid rgba(0,0,0,0.4)",
              }}
            />
          </form>

          <div style={{ marginLeft: "auto", display: "flex", gap: 10 }}>
            {showResponse && (
              <Button
                sx={{
                  "@media (max-width: 640px)": {
                    display: "none",
                  },
                }}
                onClick={() => {
                  window.open(
                    `${
                      import.meta.env.VITE_APP_BASE_URL
                    }/projects/requirement/response/?kycpID=${showResponse}`,
                    "_blank",
                    "noopener, noreferrer",
                  );
                }}
              >
                Client Requirement
              </Button>
            )}
            {/* @ts-ignore */}
            <Button
              sx={{
                "@media (max-width: 640px)": {
                  display: "none",
                },
              }}
              onClick={() => setDrawerOpen(true)}
              variant="outlined"
            >
              AI Tools
            </Button>
            <LoadingButton
              // disabled
              // style={{ backgroundColor: "#9155FD" }}
              color="primary"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              loadingposition="start"
              loading={loading}
              onClick={() => handleSave(setLoading, title)}
              disabled={disable}
            >
              Save
            </LoadingButton>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
