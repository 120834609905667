import React from "react";
import { BackIcon } from "../../src/components/icons";
import { MainMenu } from "../../src/packages/excalidraw/index";
import { LanguageList } from "./LanguageList";

export const AppMainMenu: React.FC<{
  // setCollabDialogShown: (toggle: boolean) => any;
  // isCollaborating: boolean;
  // isCollabEnabled: boolean;
}> = React.memo((props) => {
  return (
    <MainMenu>
      {/* <MainMenu.DefaultItems.LoadScene /> */}

      <MainMenu.ItemLink
        icon={BackIcon}
        href={`https://dezinexpert.com`}
        // className="ExcalidrawPlus"
      >
        Back to DezineXpert
      </MainMenu.ItemLink>
      <MainMenu.Separator />
      {/* <MainMenu.DefaultItems.SaveToActiveFile />
      <MainMenu.DefaultItems.Export /> */}
      <MainMenu.DefaultItems.SaveAsImage />
      {/* {props.isCollabEnabled && (
        <MainMenu.DefaultItems.LiveCollaborationTrigger
          isCollaborating={props.isCollaborating}
          onSelect={() => props.setCollabDialogShown(true)}
        />
      )} */}

      <MainMenu.DefaultItems.Help />
      <MainMenu.DefaultItems.ClearCanvas />

      {/* <MainMenu.Separator /> */}
      {/* <MainMenu.DefaultItems.ToggleTheme /> */}
      <MainMenu.ItemCustom>
        <LanguageList style={{ width: "100%" }} />
      </MainMenu.ItemCustom>
      {/* <MainMenu.DefaultItems.ChangeCanvasBackground /> */}
    </MainMenu>
  );
});
