import polyfill from "../src/polyfill";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  useEffect,
  useLayoutEffect,
  // useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { trackEvent } from "../src/analytics";
// import { getDefaultAppState } from "../src/appState";
import { ErrorDialog } from "../src/components/ErrorDialog";
import { TopErrorBoundary } from "../src/components/TopErrorBoundary";
import {
  APP_NAME,
  EVENT,
  TITLE_TIMEOUT,
  VERSION_TIMEOUT,
} from "../src/constants";
import { loadFromBlob } from "../src/data/blob";
import {
  ExcalidrawElement,
  FileId,
  // NonDeletedExcalidrawElement,
} from "../src/element/types";
import { useCallbackRefState } from "../src/hooks/useCallbackRefState";
import { t } from "../src/i18n";
import { Excalidraw, defaultLang } from "../src/packages/excalidraw/index";
// import { serializeAsJSON } from "../src/data/json";
import {
  AppState,
  BinaryFileData,
  ExcalidrawImperativeAPI,
  BinaryFiles,
  ExcalidrawInitialDataState,
} from "../src/types";
import {
  debounce,
  getVersion,
  getFrame,
  isTestEnv,
  preventUnload,
  ResolvablePromise,
  resolvablePromise,
  isRunningInIframe,
} from "../src/utils";
import {
  FIREBASE_STORAGE_PREFIXES,
  STORAGE_KEYS,
  SYNC_BROWSER_TABS_TIMEOUT,
} from "./app_constants";
import Collab, {
  CollabAPI,
  collabAPIAtom,
  collabDialogShownAtom,
  // isCollaboratingAtom,
  // isOfflineAtom,
} from "./collab/Collab";
import {
  // exportToBackend,
  getCollaborationLinkData,
  isCollaborationLink,
  loadScene,
} from "./data";
import {
  getLibraryItemsFromStorage,
  importFromLocalStorage,
  importUsernameFromLocalStorage,
} from "./data/localStorage";
import {
  restore,
  restoreAppState,
  RestoredDataState,
} from "../src/data/restore";
import {
  // ExportToExcalidrawPlus,
  exportToExcalidrawPlus,
} from "./components/ExportToExcalidrawPlus";
import { updateStaleImageStatuses } from "./data/FileManager";
import { newElementWith } from "../src/element/mutateElement";
import { isInitializedImageElement } from "../src/element/typeChecks";
import { loadFilesFromFirebase } from "./data/firebase";
import { LocalData } from "./data/LocalData";
import { isBrowserStorageStateNewer } from "./data/tabSync";
import clsx from "clsx";
import { reconcileElements } from "./collab/reconciliation";
import {
  parseLibraryTokensFromUrl,
  useHandleLibrary,
} from "../src/data/library";
import { AppMainMenu } from "./components/AppMainMenu";
import { AppWelcomeScreen } from "./components/AppWelcomeScreen";
import {
  atom,
  Provider,
  useAtom,
  // useAtomValue
} from "jotai";
import { appJotaiStore } from "./app-jotai";

import "./index.scss";
import { ResolutionType } from "../src/utility-types";
import { ShareableLinkDialog } from "../src/components/ShareableLinkDialog";
import { openConfirmModal } from "../src/components/OverwriteConfirm/OverwriteConfirmState";
import { OverwriteConfirmDialog } from "../src/components/OverwriteConfirm/OverwriteConfirm";
import Trans from "../src/components/Trans";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

// import Link from "@mui/material/Link";
import initialData from "./library/defaultLibrary";
import NavBar from "./components/NavBar";
// import { exportToBlob } from "../src/packages/utils";
// import { MIME_TYPES } from "../src/constants";
// import { useExcalidrawSetAppState } from "../src/components/App";
// const { exportToBlob } = window.ExcalidrawLib;
// import { compressData } from "../src/data/encode";
// import { generateEncryptionKey } from "../src/data/encryption";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import MagicRemoverComponent from "./components/magicRemoverComponent";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { getRequest, handleRedirect } from "./utils/fetch";
// import { uploadImage } from "./utils";
// import { base64toBlob } from "./utils/magicRemover";
import { saveMoodboard } from "./utils/saveMoodboard";
import { AddImageToExcalidraw } from "./utils/addImageToExcalidraw";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ImageSearchDialog from "./components/ImageSearchDialog";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";

polyfill();
window.EXCALIDRAW_THROTTLE_RENDER = true;

let isSelfEmbedding = false;

if (window.self !== window.top) {
  try {
    const parentUrl = new URL(document.referrer);
    const currentUrl = new URL(window.location.href);
    if (parentUrl.origin === currentUrl.origin) {
      isSelfEmbedding = true;
    }
  } catch (error) {
    // ignore
  }
}

const languageDetector = new LanguageDetector();
languageDetector.init({
  languageUtils: {},
});

const shareableLinkConfirmDialog = {
  title: t("overwriteConfirm.modal.shareableLink.title"),
  description: (
    <Trans
      i18nKey="overwriteConfirm.modal.shareableLink.description"
      bold={(text) => <strong>{text}</strong>}
      br={() => <br />}
    />
  ),
  actionLabel: t("overwriteConfirm.modal.shareableLink.button"),
  color: "danger",
} as const;

const initializeScene = async (opts: {
  collabAPI: CollabAPI | null;
  excalidrawAPI: ExcalidrawImperativeAPI;
}): Promise<
  { scene: ExcalidrawInitialDataState | null } & (
    | { isExternalScene: true; id: string; key: string }
    | { isExternalScene: false; id?: null; key?: null }
  )
> => {
  console.log("initializeScene");
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const jsonBackendMatch = window.location.hash.match(
    /^#json=([a-zA-Z0-9_-]+),([a-zA-Z0-9_-]+)$/,
  );
  const externalUrlMatch = window.location.hash.match(/^#url=(.*)$/);

  const localDataState = importFromLocalStorage();

  let scene: RestoredDataState & {
    scrollToContent?: boolean;
  } = await loadScene(null, null, localDataState);

  let roomLinkData = getCollaborationLinkData(window.location.href);
  const isExternalScene = !!(id || jsonBackendMatch || roomLinkData);
  if (isExternalScene) {
    if (
      // don't prompt if scene is empty
      !scene.elements.length ||
      // don't prompt for collab scenes because we don't override local storage
      roomLinkData ||
      // otherwise, prompt whether user wants to override current scene
      (await openConfirmModal(shareableLinkConfirmDialog))
    ) {
      if (jsonBackendMatch) {
        scene = await loadScene(
          jsonBackendMatch[1],
          jsonBackendMatch[2],
          localDataState,
        );
      }
      scene.scrollToContent = true;
      if (!roomLinkData) {
        window.history.replaceState({}, APP_NAME, window.location.origin);
      }
    } else {
      // https://github.com/excalidraw/excalidraw/issues/1919
      if (document.hidden) {
        return new Promise((resolve, reject) => {
          window.addEventListener(
            "focus",
            () => initializeScene(opts).then(resolve).catch(reject),
            {
              once: true,
            },
          );
        });
      }

      roomLinkData = null;
      window.history.replaceState({}, APP_NAME, window.location.origin);
    }
  } else if (externalUrlMatch) {
    window.history.replaceState({}, APP_NAME, window.location.origin);

    const url = externalUrlMatch[1];
    try {
      const request = await fetch(window.decodeURIComponent(url));
      const data = await loadFromBlob(await request.blob(), null, null);
      if (
        !scene.elements.length ||
        (await openConfirmModal(shareableLinkConfirmDialog))
      ) {
        return { scene: data, isExternalScene };
      }
    } catch (error: any) {
      return {
        scene: {
          appState: {
            errorMessage: t("alerts.invalidSceneUrl"),
          },
        },
        isExternalScene,
      };
    }
  }

  if (roomLinkData && opts.collabAPI) {
    const { excalidrawAPI } = opts;

    const scene = await opts.collabAPI.startCollaboration(roomLinkData);

    return {
      // when collaborating, the state may have already been updated at this
      // point (we may have received updates from other clients), so reconcile
      // elements and appState with existing state
      scene: {
        ...scene,
        appState: {
          ...restoreAppState(
            {
              ...scene?.appState,
              theme: localDataState?.appState?.theme || scene?.appState?.theme,
            },
            excalidrawAPI.getAppState(),
          ),
          // necessary if we're invoking from a hashchange handler which doesn't
          // go through App.initializeScene() that resets this flag
          isLoading: false,
        },
        elements: reconcileElements(
          scene?.elements || [],
          excalidrawAPI.getSceneElementsIncludingDeleted(),
          excalidrawAPI.getAppState(),
        ),
      },
      isExternalScene: true,
      id: roomLinkData.roomId,
      key: roomLinkData.roomKey,
    };
  } else if (scene) {
    return isExternalScene && jsonBackendMatch
      ? {
          scene,
          isExternalScene,
          id: jsonBackendMatch[1],
          key: jsonBackendMatch[2],
        }
      : { scene, isExternalScene: false };
  }
  return { scene: null, isExternalScene: false };
};

const detectedLangCode = languageDetector.detect() || defaultLang.code;
export const appLangCodeAtom = atom(
  Array.isArray(detectedLangCode) ? detectedLangCode[0] : detectedLangCode,
);
// @ts-ignore
const ExcalidrawWrapper = forwardRef(({ okToSave }, ref) => {
  const [openFileSizeWarning, setOpenFileSizeWarning] = useState(false);
  const [openImageSearch, setOpenImageSearch] = useState(false);
  const [token, setToken] = useState(null);
  const [isTabActive, setIsTabActive] = useState(true);
  const [fetchedTitle, setFetchedTitle] = useState("Untitled");
  const [count, setCount] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showResponse, setShowResponse] = useState(false);

  const [initialState, setInitialState] = useState({});
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [updateLibrary, setUpdateLibrary] = useState([]);

  // Get a specific query parameter
  const moodboardID = searchParams.get("moodboardID");

  // const setAppState = useExcalidrawSetAppState();
  // check for auth
  useImperativeHandle(ref, () => ({
    getAlert() {
      handleSave();
    },
  }));
  useLayoutEffect(() => {
    let auth_key: string | null | undefined = null;
    const selectedUserCompany = document.cookie
    .split("; ")
    .find((row) => row.startsWith("selected_company="))
    ?.split("=")[1];

    if (typeof window !== "undefined") {
      const cookies = document.cookie
        .split("; ")
        .find((row) => row.startsWith("auth_key="))
        ?.split("=")[1];


      auth_key =
        cookies === undefined || cookies === null || cookies === ""
          ? null
          : cookies;
      // @ts-ignore
      setToken(auth_key);
      // @ts-ignore
      console.log("auth_key" + " " + auth_key);
      console.log("moodboardID" + moodboardID);
    }

    if (moodboardID !== null && auth_key === null) {
      window.location.assign(
        `${
          import.meta.env.VITE_APP_BASE_URL
        }/login?previousPage=${encodeURIComponent(
          `${
            import.meta.env.VITE_APP_BASE_MOODBOARD_URL
          }?moodboardID=${moodboardID}`,
        )}`,
      );
    } else if (
      moodboardID !== null &&
      auth_key !== null &&
      Object.entries(initialState).length === 0
    ) {
      localStorage.removeItem("excalidraw-state");
      localStorage.removeItem("excalidraw");
      const func = async () => {
        try {
          const response = await getRequest(
            `/dc/api/${selectedUserCompany}/moodboard_api/${moodboardID}`,
          );

          setFetchedTitle(response.data.title);

          const responsefile = await getRequest(
            `/dc/moodboardfile/${response.data.uuid}`,
          );

          setInitialState(responsefile.data);

          // check if project is linked or not
          if (response.data.project) {
            console.log("fetch kycp with projectID");
            const kycp = await getRequest(
              `/dc/api/kycp?project=${response.data.project}`,
            );

            if (kycp?.data.length !== 0) {
              if (kycp?.data?.[0].submitted === true) {
                // setLoading('See Response')
                console.log(kycp?.data?.[0]?.id);
                setShowResponse(kycp?.data?.[0]?.id);
              }
            }
          }
        } catch (error: any) {
          console.log(error.status);
          handleRedirect(error.status);
        }
      };
      func();
    }
  }, [moodboardID]);

  useEffect(() => {
    // add library default elements
    const updateLibrary = async () => {
      const response = await getRequest("/dc/api/company/");
      const data = response?.data?.[0];
      if (data?.designLibrary !== null) {
        const response = await axios.get(
          data?.designLibrary.replace("http://", "https://"),
        );
        const finallibrary = [...response?.data, ...initialData];
        // @ts-ignore
        setUpdateLibrary(finallibrary);

        // const serializedItems = JSON.stringify(finallibrary);
        // localStorage.setItem(STORAGE_KEYS.LOCAL_STORAGE_THEME, "light");
        // localStorage.setItem(
        //   STORAGE_KEYS.LOCAL_STORAGE_LIBRARY,
        //   serializedItems as any,
        // );
        // return;
      }
      const serializedItems = JSON.stringify(initialData);
      localStorage.setItem(STORAGE_KEYS.LOCAL_STORAGE_THEME, "light");
      localStorage.setItem(
        STORAGE_KEYS.LOCAL_STORAGE_LIBRARY,
        serializedItems as any,
      );
      // setUpdateLibrary(true);
    };
    updateLibrary();
  }, []);

  const [errorMessage, setErrorMessage] = useState("");
  const [langCode, setLangCode] = useAtom(appLangCodeAtom);
  const isCollabDisabled = isRunningInIframe();

  // initial state
  // ---------------------------------------------------------------------------

  const initialStatePromiseRef = useRef<{
    promise: ResolvablePromise<ExcalidrawInitialDataState | null>;
  }>({ promise: null! });
  if (!initialStatePromiseRef.current.promise) {
    initialStatePromiseRef.current.promise =
      resolvablePromise<ExcalidrawInitialDataState | null>();
  }

  useEffect(() => {
    trackEvent("load", "frame", getFrame());
    // Delayed so that the app has a time to load the latest SW
    setTimeout(() => {
      trackEvent("load", "version", getVersion());
    }, VERSION_TIMEOUT);
  }, []);

  const [excalidrawAPI, excalidrawRefCallback] =
    useCallbackRefState<ExcalidrawImperativeAPI>();

  const [collabAPI] = useAtom(collabAPIAtom);
  const [, setCollabDialogShown] = useAtom(collabDialogShownAtom);

  useHandleLibrary({
    excalidrawAPI,
    getInitialLibraryItems: getLibraryItemsFromStorage,
  });

  useEffect(() => {
    if (!excalidrawAPI || (!isCollabDisabled && !collabAPI)) {
      return;
    }

    const loadImages = (
      data: ResolutionType<typeof initializeScene>,
      isInitialLoad = false,
    ) => {
      if (!data.scene) {
        return;
      }
      if (collabAPI?.isCollaborating()) {
        if (data.scene.elements) {
          collabAPI
            .fetchImageFilesFromFirebase({
              elements: data.scene.elements,
              forceFetchFiles: true,
            })
            .then(({ loadedFiles, erroredFiles }) => {
              excalidrawAPI.addFiles(loadedFiles);
              updateStaleImageStatuses({
                excalidrawAPI,
                erroredFiles,
                elements: excalidrawAPI.getSceneElementsIncludingDeleted(),
              });
            });
        }
      } else {
        const fileIds =
          data.scene.elements?.reduce((acc, element) => {
            if (isInitializedImageElement(element)) {
              return acc.concat(element.fileId);
            }
            return acc;
          }, [] as FileId[]) || [];

        if (data.isExternalScene) {
          loadFilesFromFirebase(
            `${FIREBASE_STORAGE_PREFIXES.shareLinkFiles}/${data.id}`,
            data.key,
            fileIds,
          ).then(({ loadedFiles, erroredFiles }) => {
            excalidrawAPI.addFiles(loadedFiles);
            updateStaleImageStatuses({
              excalidrawAPI,
              erroredFiles,
              elements: excalidrawAPI.getSceneElementsIncludingDeleted(),
            });
          });
        } else if (isInitialLoad) {
          if (fileIds.length) {
            LocalData.fileStorage
              .getFiles(fileIds)
              .then(({ loadedFiles, erroredFiles }) => {
                if (loadedFiles.length) {
                  excalidrawAPI.addFiles(loadedFiles);
                }
                updateStaleImageStatuses({
                  excalidrawAPI,
                  erroredFiles,
                  elements: excalidrawAPI.getSceneElementsIncludingDeleted(),
                });
              });
          }
          // on fresh load, clear unused files from IDB (from previous
          // session)
          LocalData.fileStorage.clearObsoleteFiles({ currentFileIds: fileIds });
        }
      }
    };

    initializeScene({ collabAPI, excalidrawAPI }).then(async (data) => {
      loadImages(data, /* isInitialLoad */ true);
      initialStatePromiseRef.current.promise.resolve(data.scene);
    });

    const onHashChange = async (event: HashChangeEvent) => {
      event.preventDefault();
      const libraryUrlTokens = parseLibraryTokensFromUrl();
      if (!libraryUrlTokens) {
        if (
          collabAPI?.isCollaborating() &&
          !isCollaborationLink(window.location.href)
        ) {
          collabAPI.stopCollaboration(false);
        }
        excalidrawAPI.updateScene({ appState: { isLoading: true } });

        initializeScene({ collabAPI, excalidrawAPI }).then((data) => {
          loadImages(data);
          if (data.scene) {
            excalidrawAPI.updateScene({
              ...data.scene,
              ...restore(data.scene, null, null, { repairBindings: true }),
              commitToHistory: true,
            });
          }
        });
      }
    };

    const titleTimeout = setTimeout(
      () => (document.title = APP_NAME),
      TITLE_TIMEOUT,
    );

    const syncData = debounce(() => {
      if (isTestEnv()) {
        return;
      }
      if (
        !document.hidden &&
        ((collabAPI && !collabAPI.isCollaborating()) || isCollabDisabled)
      ) {
        // don't sync if local state is newer or identical to browser state
        if (
          isBrowserStorageStateNewer(STORAGE_KEYS.VERSION_DATA_STATE) &&
          moodboardID === null
        ) {
          const localDataState = importFromLocalStorage();
          const username = importUsernameFromLocalStorage();
          let langCode = languageDetector.detect() || defaultLang.code;
          if (Array.isArray(langCode)) {
            langCode = langCode[0];
          }
          setLangCode(langCode);
          excalidrawAPI.updateScene({
            ...localDataState,
          });
          excalidrawAPI.updateLibrary({
            libraryItems: getLibraryItemsFromStorage(),
          });
          collabAPI?.setUsername(username || "");
        }

        if (isBrowserStorageStateNewer(STORAGE_KEYS.VERSION_FILES)) {
          const elements = excalidrawAPI.getSceneElementsIncludingDeleted();
          const currFiles = excalidrawAPI.getFiles();
          const fileIds =
            elements?.reduce((acc, element) => {
              if (
                isInitializedImageElement(element) &&
                // only load and update images that aren't already loaded
                !currFiles[element.fileId]
              ) {
                return acc.concat(element.fileId);
              }
              return acc;
            }, [] as FileId[]) || [];
          if (fileIds.length) {
            LocalData.fileStorage
              .getFiles(fileIds)
              .then(({ loadedFiles, erroredFiles }) => {
                if (loadedFiles.length) {
                  excalidrawAPI.addFiles(loadedFiles);
                }
                updateStaleImageStatuses({
                  excalidrawAPI,
                  erroredFiles,
                  elements: excalidrawAPI.getSceneElementsIncludingDeleted(),
                });
              });
          }
        }
      }
    }, SYNC_BROWSER_TABS_TIMEOUT);

    const onUnload = () => {
      LocalData.flushSave();
    };

    const visibilityChange = (event: FocusEvent | Event) => {
      if (event.type === EVENT.BLUR || document.hidden) {
        LocalData.flushSave();
      }
      if (
        event.type === EVENT.VISIBILITY_CHANGE ||
        event.type === EVENT.FOCUS
      ) {
        syncData();
      }
    };

    window.addEventListener(EVENT.HASHCHANGE, onHashChange, false);
    window.addEventListener(EVENT.UNLOAD, onUnload, false);
    window.addEventListener(EVENT.BLUR, visibilityChange, false);
    document.addEventListener(EVENT.VISIBILITY_CHANGE, visibilityChange, false);
    window.addEventListener(EVENT.FOCUS, visibilityChange, false);
    return () => {
      window.removeEventListener(EVENT.HASHCHANGE, onHashChange, false);
      window.removeEventListener(EVENT.UNLOAD, onUnload, false);
      window.removeEventListener(EVENT.BLUR, visibilityChange, false);
      window.removeEventListener(EVENT.FOCUS, visibilityChange, false);
      document.removeEventListener(
        EVENT.VISIBILITY_CHANGE,
        visibilityChange,
        false,
      );
      clearTimeout(titleTimeout);
    };
  }, [isCollabDisabled, collabAPI, excalidrawAPI, setLangCode]);

  useEffect(() => {
    const unloadHandler = (event: BeforeUnloadEvent) => {
      LocalData.flushSave();

      if (
        excalidrawAPI &&
        LocalData.fileStorage.shouldPreventUnload(
          excalidrawAPI.getSceneElements(),
        )
      ) {
        preventUnload(event);
      }
    };
    window.addEventListener(EVENT.BEFORE_UNLOAD, unloadHandler);
    return () => {
      window.removeEventListener(EVENT.BEFORE_UNLOAD, unloadHandler);
    };
  }, [excalidrawAPI]);

  useEffect(() => {
    languageDetector.cacheUserLanguage(langCode);
  }, [langCode]);

  // @ts-ignore
  const onChange = (
    elements: readonly ExcalidrawElement[],
    appState: AppState,
    files: BinaryFiles,
  ) => {
    // setHasUnsavedChanges(true);
    // if (collabAPI?.isCollaborating()) {
    //   collabAPI.syncElements(elements);
    // }

    // @ts-ignore
    // if (timeoutId) {
    //   clearTimeout(timeoutId);
    //   timeoutId = null;
    // }

    // if (moodboardID !== null) {
    //   LocalData.pauseSave("collaboration");
    // }

    // this check is redundant, but since this is a hot path, it's best
    // not to evaludate the nested expression every time
    if (!LocalData.isSavePaused()) {
      LocalData.save(elements, appState, files, () => {
        if (excalidrawAPI) {
          let didChange = false;

          const elements = excalidrawAPI
            .getSceneElementsIncludingDeleted()
            .map((element) => {
              if (
                LocalData.fileStorage.shouldUpdateImageElementStatus(element)
              ) {
                const newElement = newElementWith(element, { status: "saved" });
                // console.log(newElement);
                if (newElement !== element) {
                  didChange = true;
                }
                return newElement;
              }
              return element;
            });

          if (didChange) {
            excalidrawAPI.updateScene({
              elements,
            });
          }
        }
      });
    }
  };

  const [latestShareableLink, setLatestShareableLink] = useState<string | null>(
    null,
  );

  useEffect(() => {
    // update UI logic
    setCount(count + 1);
    if (excalidrawAPI && count > 1) {
      const sceneData = {
        // @ts-ignore
        appState: initialState?.appState,
        // @ts-ignore
        elements: initialState?.elements,
        // files: initialState?.files,
        // commitToHistory: true,
      };
      console.log(sceneData);

      const filesMap = new Map<FileId, BinaryFileData>();
      // @ts-ignore
      for (const element of initialState?.elements) {
        if (
          isInitializedImageElement(element) &&
          // @ts-ignore
          initialState?.files[element.fileId]
        ) {
          // @ts-ignore
          filesMap.set(element.fileId, initialState?.files[element.fileId]);
        }
      }
      console.log(filesMap);
      // const finalFiles = Object.values(filesMap);
      const arr = [];
      for (const [id, fileData] of filesMap) {
        arr.push({
          ...fileData,
        });
      }
      // console.log(arr);
      excalidrawAPI.history.clear();
      // @ts-ignore
      excalidrawAPI.addFiles(arr);
      excalidrawAPI.updateScene(sceneData);
    }
  }, [excalidrawAPI, initialState]);

  useEffect(() => {
    if (excalidrawAPI && updateLibrary) {
      // setIsLibraryUpdated(true);
      excalidrawAPI.updateLibrary({
        // ts@-ignore
        libraryItems: updateLibrary,
      });
    }
  }, [updateLibrary, excalidrawAPI]);

  // autosave logic
  useEffect(() => {
    let autosaveInterval: NodeJS.Timeout | null = null;
    console.log("running useefect autosave");
    console.log(initialState);

    if (autosaveInterval) {
      clearInterval(autosaveInterval);
    }
    const handleVisibilityChange = () => {
      console.log(document.visibilityState);
      setIsTabActive(document.visibilityState === "visible");
    };

    const handleFocus = () => {
      console.log("running focus autosave");
      if (autosaveInterval) {
        clearInterval(autosaveInterval);
      }
      setIsTabActive(true);
      autosaveInterval = setInterval(handleSave, 1000 * 60 * 5);
    };

    const handleBlur = () => {
      console.log("running blur autosave");

      // handleSave();
      setIsTabActive(false);
      if (autosaveInterval) {
        clearInterval(autosaveInterval);
      }
    };

    if (
      excalidrawAPI &&
      Object.entries(initialState).length !== 0 &&
      moodboardID !== null
    ) {
      console.log("running useefect set auttosave interval");
      autosaveInterval = setInterval(handleSave, 1000 * 60 * 5);
      document.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("focus", handleFocus);
      window.addEventListener("blur", handleBlur);
    }
    return () => {
      // setIsTabActive(false);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
      if (autosaveInterval) {
        clearInterval(autosaveInterval);
      }
    };
  }, [excalidrawAPI, initialState]);

  // browsers generally prevent infinite self-embedding, there are
  // cases where it still happens, and while we disallow self-embedding
  // by not whitelisting our own origin, this serves as an additional guard
  if (isSelfEmbedding) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          height: "100%",
        }}
      >
        <h1>I'm not a pretzel!</h1>
      </div>
    );
  }

  const handleSave = (
    setLoading: React.Dispatch<React.SetStateAction<boolean>> = () => {},
    title: string = fetchedTitle ? fetchedTitle : "",
  ) => {
    saveMoodboard({
      setLoading,
      title,
      okToSave,
      isTabActive,
      moodboardID,
      initialState,
      excalidrawAPI,
      token,
      setOpenFileSizeWarning,
      navigate,
      setInitialState,
    });
  };

  const handleImageClick = (selectedImage: any) => {
    AddImageToExcalidraw({ image: selectedImage, excalidrawAPI });
    setOpenImageSearch(false);
  };
  const onLibraryChange = async (libraryItems: any) => {
    const personalLibrary = libraryItems.filter(
      // @ts-ignore
      (items) => items.status === "unpublished",
    );
    if (
      personalLibrary.length > 100 ||
      token === null ||
      token === undefined ||
      personalLibrary.length === 0 ||
      // updateLibrary.length === libraryItems.length
      JSON.stringify(updateLibrary) == JSON.stringify(libraryItems)
    ) {
      // toast.warning("You have reached the limit of 100 items in your personal library. Please delete some items to add more.");
      return;
    }
    const data = personalLibrary.slice(0, 100);
    const serializedItems = JSON.stringify(data);
    const blob = new Blob([serializedItems], { type: "application/json" });
    const formData = new FormData();
    formData.append("designLibrary", blob, "designLibrary.json");
    try {
      const responseCompany = await getRequest("/dc/api/company/");

      const response = await axios.patch(
        `${import.meta.env.VITE_APP_BASE_URL}/dc/api/company/${
          responseCompany?.data?.[0]?.id
        }/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        },
      );
      toast.success("Library saved successfully");
    } catch (error: any) {
      console.log(error);
      toast.error("Failed to save library");
    }
  };

  return (
    <>
      <Dialog
        open={openFileSizeWarning}
        onClose={() => setOpenFileSizeWarning(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          {"Failed to save the file!!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`File size (including sketches and text) exceeds the 8 MB limit.
            Upgrade to premium for unlimited sketching and creative freedom.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFileSizeWarning(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ImageSearchDialog
        openImageSearch={openImageSearch}
        setOpenImageSearch={setOpenImageSearch}
        // @ts-ignore
        handleImageClick={handleImageClick}
      />
      <MagicRemoverComponent
        setDrawerOpen={setDrawerOpen}
        drawerOpen={drawerOpen}
        token={token}
        showResponse={showResponse}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // style={customStyle}
        // theme={isDarkMode ? 'dark' : 'light'}
      />
      <NavBar
        disable={
          !!(moodboardID !== null && Object.entries(initialState).length === 0)
        }
        showResponse={showResponse}
        token={token}
        moodboardID={moodboardID}
        handleSave={handleSave}
        fetchedTitle={fetchedTitle}
        setDrawerOpen={setDrawerOpen}
      />
      <Box
        sx={{
          width: "100%",
          display:
            moodboardID !== null && Object.entries(initialState).length === 0
              ? "block"
              : "none",
        }}
      >
        <LinearProgress />
      </Box>
      <div
        style={{ height: "calc(100vh - 64px)" }}
        className={clsx("excalidraw-app", "custom-styles")}
      >
        <Excalidraw
          ref={excalidrawRefCallback}
          onChange={onChange}
          initialData={initialStatePromiseRef.current.promise}
          onPointerUpdate={collabAPI?.onPointerUpdate}
          onLibraryChange={onLibraryChange}
          langCode={langCode}
          detectScroll={false}
          handleKeyboardGlobally={true}
          autoFocus={true}
          renderTopRightUI={() => {
            return (
              <Tooltip title="Add Images" placement="bottom">
                <IconButton
                  onClick={() => setOpenImageSearch(true)}
                  color="primary"
                >
                  <ImageSearchIcon />
                </IconButton>
              </Tooltip>
            );
          }}
        >
          <AppMainMenu
          // setCollabDialogShown={setCollabDialogShown}
          // isCollaborating={false}
          // isCollabEnabled={!false}
          />
          <AppWelcomeScreen
            setCollabDialogShown={setCollabDialogShown}
            isCollabEnabled={!isCollabDisabled}
          />
          <OverwriteConfirmDialog>
            <OverwriteConfirmDialog.Actions.ExportToImage />
            <OverwriteConfirmDialog.Actions.SaveToDisk />
            {excalidrawAPI && (
              <OverwriteConfirmDialog.Action
                title={t("overwriteConfirm.action.excalidrawPlus.title")}
                actionLabel={t("overwriteConfirm.action.excalidrawPlus.button")}
                onClick={() => {
                  exportToExcalidrawPlus(
                    excalidrawAPI.getSceneElements(),
                    excalidrawAPI.getAppState(),
                    excalidrawAPI.getFiles(),
                  );
                }}
              >
                {t("overwriteConfirm.action.excalidrawPlus.description")}
              </OverwriteConfirmDialog.Action>
            )}
          </OverwriteConfirmDialog>

          {latestShareableLink && (
            <ShareableLinkDialog
              link={latestShareableLink}
              onCloseRequest={() => setLatestShareableLink(null)}
              setErrorMessage={setErrorMessage}
            />
          )}
          {excalidrawAPI && !isCollabDisabled && (
            <Collab excalidrawAPI={excalidrawAPI} />
          )}
          {errorMessage && (
            <ErrorDialog onClose={() => setErrorMessage("")}>
              {errorMessage}
            </ErrorDialog>
          )}
        </Excalidraw>
      </div>
    </>
  );
});

const ExcalidrawApp = () => {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const childRef = useRef();
  // @ts-ignore
  const handleBeforeUnload = (event) => {
    event.preventDefault();

    event.returnValue = "";
  };

  useEffect(() => {
    const bc = new BroadcastChannel("checkTab");
    const date = new Date().getTime().toString();
    const tabId = date + Math.floor(Math.random() * 1000000000).toString();
    window.addEventListener("beforeunload", (event) => {
      handleBeforeUnload(event);
    });
    localStorage.setItem("currentTabId", tabId);

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "currentTabId" && event.newValue !== tabId) {
        setOpen(true);
        bc.postMessage("Tab already opened");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    bc.addEventListener("message", (event) => {
      if (event.data === "Tab already opened") {
        window.removeEventListener("storage", handleStorageChange);

        console.log(event);
        if (localStorage.getItem("currentTabId") === tabId) {
          window.removeEventListener("beforeunload", handleBeforeUnload);
          setOpenDialog(true);
        }
      }
    });
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      // localStorage.removeItem("currentTabId");
    };
  }, []);

  return (
    <TopErrorBoundary>
      {open ? (
        <>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"MoodBoard is Open on another window!"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Moodboard can only be opened on a single window. Click "Use
                Here" to use MoodBoard in this window
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() =>
                  window.location.assign("https://dezinexpert.com/")
                }
              >
                Discard Changes
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // @ts-ignore
                  childRef.current.getAlert();
                  setOpen(false);
                }}
              >
                Save & Use Here
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
      {openDialog ? (
        <Dialog
          open={openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"MoodBoard is Open on another window!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Save and close all other tabs to use this moodboard.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              // variant="outlined"
              onClick={() => window.location.assign("https://dezinexpert.com/")}
            >
              Go to Home
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Provider unstable_createStore={() => appJotaiStore}>
          <ExcalidrawWrapper
            ref={childRef}
            // @ts-ignore
            okToSave={!openDialog}
          />
        </Provider>
      )}
    </TopErrorBoundary>
  );
};

export default ExcalidrawApp;
