export function base64toBlob(base64Data, contentType) {
  contentType = contentType || "";
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }

    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }

  return new Blob(byteArrays, { type: contentType });
}

export const onDragFileData = (event) => {
  const blob = base64toBlob(event.target.src.split(",")[1], "image/png");
  // const file = new File([blob], "name.png", { type: blob.type });
  const file = new File([blob], "name.png", {
    type: "image/png",
  });

  // const dataTransfer = new DataTransfer();
  // dataTransfer.items.add(file);
  // console.log(dataTransfer);
  // event.dataTransfer.setData("Files", dataTransfer);
  // console.log(dataTransfer?.files);
  event.dataTransfer.items.add(file);
  // event.dataTransfer.setData("image/png", file);
};

export function compressBase64Image(
  base64Image,
  quality = 0.1,
  type = "image/webp",
) {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = base64Image;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            resolve(reader.result);
            canvas.remove();
          };
        },
        type,
        quality,
      );
    };
  });
}
