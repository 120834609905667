import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import ExcalidrawApp from "../excalidraw-app";
import LoginPage from "../excalidraw-app/components/auth/loginPage";
import { registerSW } from "virtual:pwa-register";
import {
  createTheme,
  ThemeProvider,
  // styled,
  Theme,
} from "@mui/material/styles";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import "../excalidraw-app/sentry";
window.__EXCALIDRAW_SHA__ = import.meta.env.VITE_APP_GIT_SHA;

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: "#161834", // Change this to your preferred primary color
    },
    secondary: {
      main: "#ff4081", // Change this to your preferred secondary color
    },
  },
});
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="">
      <Route path="" element={<ExcalidrawApp />} />

      {/* <Route path="/" element={<ExcalidrawApp />}>
        <Route path="/:moodboardID" element={<ExcalidrawApp />} />
      </Route> */}
      <Route path="/login" element={<LoginPage />} />
    </Route>,
  ),
);

const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);
registerSW();

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </StrictMode>,
);
