import { exportToBlob } from "../../src/packages/utils";
import { toast } from "react-toastify";
import { serializeAsJSON } from "../../src/data/json";
import { uploadImage } from "../utils";
import { isInitializedImageElement } from "../../src/element/typeChecks";
import { base64toBlob } from "./magicRemover";
import axios from "axios";
import {
  deleteRequest,
  handleRedirect,
  multiPartPatchRequest,
  multiPartPostRequest,
} from "./fetch";

export const saveMoodboard = async ({
  setLoading,
  title,
  okToSave,
  isTabActive,
  moodboardID,
  initialState,
  excalidrawAPI,
  token,
  setOpenFileSizeWarning,
  navigate,
  setInitialState,
}) => {
  console.log("Saving...");
  const selectedUserCompany = document.cookie
  .split("; ")
  .find((row) => row.startsWith("selected_company="))
  ?.split("=")[1];

  // if not loggedin
  if (moodboardID === null && !token) {
    setLoading(false);
    console.log("user not logged in...");
    redirectUrl(import.meta.env.VITE_APP_BASE_MOODBOARD_URL);
    return;
  }
  // if loading
  if (moodboardID !== null && Object.entries(initialState).length === 0) {
    toast.error("Save rejected");
    return;
  }
  const elements = excalidrawAPI?.getSceneElements();
  const appState = excalidrawAPI?.getAppState();
  let files = excalidrawAPI?.getFiles();
  const img = await exportToBlob({
    elements: excalidrawAPI?.getSceneElements(),
    appState: excalidrawAPI?.getAppState(),
    files: excalidrawAPI?.getFiles(),
    type: "png",
  });

  if (okToSave && isTabActive) {
    setLoading(true);

    const filesArray = elements
      .map((element) => {
        if (
          isInitializedImageElement(element) &&
          files?.[element.fileId]?.dataURL.startsWith("data:image")
        ) {
          return element.fileId;
        }
        return undefined;
      })
      .filter((element) => element !== undefined);

    const imageLink = await Promise.all(
      filesArray.map(async (imageID) => {
        try {
          const blobImage = base64toBlob(
            files[imageID]?.dataURL.split(",")[1],
            files[imageID]?.mimeType,
          );

          const { link, linkId } = await uploadImage(blobImage, moodboardID, selectedUserCompany);

          files[imageID] = {
            ...files?.[imageID],
            dataURL: link.startsWith("http://")
              ? link.replace("http://", "https://")
              : link,
            linkId,
          };
          return { img: link, id: imageID, linkId };
        } catch (error) {
          console.error("Error downloading image:", error);
          // toast.error("Failed to download image");
          return { img: files[imageID]?.dataURL, id: imageID, linkId: null };
        }
      }),
    );
    if (moodboardID !== null) {
      // delete the deleted images
      const deletedImage = await checkAndDeleteMissingIds(
        initialState?.elements
          .map((element) => {
            if (
              isInitializedImageElement(element) &&
              files?.[element.fileId]?.dataURL
            ) {
              return element.fileId;
            }
            return undefined;
          })
          .filter((element) => element !== undefined),
        elements.map((element) => {
          if (
            isInitializedImageElement(element) &&
            files?.[element.fileId]?.dataURL
          ) {
            return element.fileId;
          }
          return undefined;
        }),
        files,
      );
      console.log("deleted images");
      console.log(deletedImage);
    }

    const jsondata = serializeAsJSON(elements, appState, files, "local");
    // blob
    const blob = new Blob([jsondata], { type: "application/json" });

    if (blob.size + img.size > 8 * 1024 * 1024) {
      setLoading(false);
      setOpenFileSizeWarning(true);
      toast.error("File Size too big!!");
      return;
    } else {
      setOpenFileSizeWarning(false);
    }

    if (moodboardID === null && token) {
      const data = {
        user: 2,
        title,
        description: "",
        file: blob,
        comments: "",
      };

      let formData = new FormData();

      for (const key in data) {
        formData.append(key, data[key]);
      }
      const d = new Date();
      formData.append("thumbnail", img, `${d.toString()}.png`);

      multiPartPostRequest(`/dc/api/${selectedUserCompany}/moodboard_api/`, formData)
        .then((response) => {
          setLoading(false);
          toast.success("Saved successfully");
          setInitialState({ appState, elements, files });
          navigate(`?moodboardID=${response.data.id}`);
          // setInitialState(jsondata);
          setInitialState({ appState, elements, files });
        })
        .catch((error) => {
          toast.error("Failed to save moodboard");
          setLoading(false);
          handleRedirect(error?.status);
        });
    } else if (moodboardID !== null) {
      const data = {
        // title,
        file: blob,
      };

      let formData = new FormData();

      for (const key in data) {
        // @ts-ignore
        formData.append(key, data[key]);
      }

      const d = new Date();
      formData.append("thumbnail", img, `${d.toString()}.png`);

      multiPartPatchRequest(`/dc/api/${selectedUserCompany}/moodboard_api/${moodboardID}/`, formData)
        .then((response) => {
          setLoading(false);
          // setHasUnsavedChanges(false);
          toast.success("Saved successfully");
          console.log("value Updated");
          console.log(jsondata);
          setInitialState({ appState, elements, files });
        })
        .catch((error) => {
          toast.error("Failed to save moodboard");
          setLoading(false);
        });
    } else {
      toast.error("someting went wrong");
      setLoading(false);
    }
  }
};

const redirectUrl = (url) => {
  window.location.assign(
    `${
      import.meta.env.VITE_APP_BASE_URL
    }/login?previousPage=${encodeURIComponent(url)}`,
  );
};

const checkAndDeleteMissingIds = async (arr1, arr2, files) => {
  let missingIds = arr1.filter((id) => !arr2.includes(id));
  if (missingIds.length !== 0) {
    await Promise.all(
      missingIds.map((id) => {
        if (files?.[id]?.linkId) {
          deleteMissingId(files?.[id]?.linkId);
          return "deleted";
        }
        return null;
      }),
    );
  }
  return missingIds;
};

const deleteMissingId = (id) => {
  deleteRequest(`/dc/api/usecproject_files/${id}/`)
    .then((response) => {
      console.log("Deleted: " + id);
    })
    .catch((error) => {
      console.log(error);
    });
};
