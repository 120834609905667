import { ExcalidrawProps, UIAppState } from "../types";
// import LibraryMenuBrowseButton from "./LibraryMenuBrowseButton";
// import clsx from "clsx";

export const LibraryMenuControlButtons = ({
  libraryReturnUrl,
  theme,
  id,
  style,
  children,
  className,
}: {
  libraryReturnUrl: ExcalidrawProps["libraryReturnUrl"];
  theme: UIAppState["theme"];
  id: string;
  style: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    // <div
    //   className={clsx("library-menu-control-buttons", className)}
    //   style={style}
    // >
    //   <LibraryMenuBrowseButton
    //     id={id}
    //     libraryReturnUrl={libraryReturnUrl}
    //     theme={theme}
    //   />
    //   {children}
    // </div>
    <></>
  );
};
