import { convertToExcalidrawElements } from "../../src/data/transform";
import { viewportCoordsToSceneCoords } from "../../src/utils";

export const AddImageToExcalidraw = ({ image, excalidrawAPI }) => {
  const elements = excalidrawAPI?.getSceneElements();
  const appState = excalidrawAPI?.getAppState();
  const { x, y } = viewportCoordsToSceneCoords(
    {
      clientX: event.clientX,
      clientY: event.clientY,
    },
    appState,
  );

  const finalElements = [
    ...elements,
    ...convertToExcalidrawElements([
      {
        type: "image",
        x,
        y,
        width: image?.width / 4,
        height: image?.height / 4,
        fileId: image?.id,
      },
    ]),
  ];
  const imagesArray = [
    {
      id: image?.id,
      dataURL: image?.src?.large,
      mimeType: "image/jpeg",
      created: 1644915140367,
      lastRetrieved: 1644915140367,
    },
  ];
  excalidrawAPI.addFiles(imagesArray);
  excalidrawAPI.updateScene({ elements: finalElements, appState });
};
