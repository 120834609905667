import { useCallback, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { useDropzone } from "react-dropzone";
import Button from "@mui/material/Button";
import { Paper, TextField, Typography } from "@mui/material";
import CropIcon from "@mui/icons-material/Crop";
import IconButton from "@mui/material/IconButton";
import CropDialog from "./CropDialog";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  base64toBlob,
  compressBase64Image,
  onDragFileData,
} from "../utils/magicRemover"; // console.log(newBlobUrls);

import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

import Accordion from "@mui/material/Accordion";
// import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { toast } from "react-toastify";
import { Masonry } from "@mui/lab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageIcon from "@mui/icons-material/Image";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MagicRemoverComponent = ({
  setDrawerOpen,
  drawerOpen,
  token,
  showResponse,
}) => {
  // const [AITool, setAITool] = useState("bgRemover");
  const [value, setValue] = useState(0);
  // state for bg-remover
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isProcessedImage, setisProcessedImage] = useState(null);
  const [cropImage, setCropImage] = useState(null);
  const [processedImages, setProcessedImages] = useState([]);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  // state for image generation
  const [imageGeneratorLoading, setImageGeneratorLoading] = useState(false);
  const [roomsData, setRoomsData] = useState([]);
  const [generatedImageHistory, setGeneratedImageHistory] = useState([]);
  const [base64Images, setbase64Images] = useState([]);
  const [loadingBase64, setloadingBase64] = useState(true);

  useEffect(() => {
    if (showResponse && token) {
      axios
        .get(
          `${import.meta.env.VITE_APP_BASE_URL}/dc/api/kycp/${showResponse}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          },
        )
        .then((response) => {
          setRoomsData(response?.data?.data);
        })
        .catch((error) => {
          // toast.error("Failed to fetch the kycp data");
          console.log(error);
        });
    }

    if (token) {
      axios
        .get(`${import.meta.env.VITE_APP_BASE_URL}/dc/aitools/image_history/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        })
        .then((respones) => {
          setGeneratedImageHistory(
            respones?.data
              .sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateB - dateA;
              })
              .map((value) => {
                return `${import.meta.env.VITE_APP_BASE_URL}/static${
                  value?.output?.image_url
                }`;
              }),
          );
        })
        .catch((error) => {
          console.log(error);
          // toast.error("Something went wrong");
        });
    }
  }, [showResponse, token]);

  const downloadGeneratedImages = async () => {
    if (generatedImageHistory.length === 0) {
      setloadingBase64(false);
    } else if (base64Images.length === 0) {
      setloadingBase64(true);
      const newBlobUrls = await Promise.all(
        generatedImageHistory.map(async (imageUrl) => {
          try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const base64Image = await blobToBase64(blob);
            const compressedImage = await compressBase64Image(base64Image, 0.5);
            return compressedImage;
          } catch (error) {
            console.error("Error downloading image:", error);
            // toast.error("Failed to download image");
            return [];
          }
        }),
      );
      setloadingBase64(false);
      setbase64Images(newBlobUrls);
    } else {
      setloadingBase64(false);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Drawer
        PaperProps={{
          style: { width: 450 },
        }}
        // anchor={anchor}
        transitionDuration={{ enter: 200, exit: 10 }}
        open={drawerOpen}
        // hideBackdrop={true}
        elevation={2}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setDrawerOpen(false)}>
              {/* <ListItemIcon> */}
              <ArrowBackIosIcon />
              {/* </ListItemIcon> */}
              <ListItemText primary={"AI Tools"} />
            </ListItemButton>
          </ListItem>
        </List>
        {/* <Divider /> */}

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Magic Remover" {...a11yProps(0)} />
            <Tab
              disabled={token ? false : true}
              onClick={() => downloadGeneratedImages()}
              label="Image Generator"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box role="presentation">
            <BackgroundRemoverComponent
              setDrawerOpen={setDrawerOpen}
              drawerOpen={drawerOpen}
              token={token}
              state={{
                uploadedImage,
                isProcessedImage,
                cropImage,
                processedImages,
                cropModalOpen,
                loading,
                error,
                setError,
                setLoading,
                setCropModalOpen,
                setProcessedImages,
                setCropImage,
                setisProcessedImage,
                setUploadedImage,
              }}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box
            sx={{
              width: "full",
            }}
            role="presentation"
          >
            <ImageGenerator
              loadingBase64={loadingBase64}
              token={token}
              roomsData={roomsData}
              setProcessedImages={setbase64Images}
              processedImages={base64Images}
              loading={loading}
              uploadedImage={uploadedImage}
              setUploadedImage={setUploadedImage}
              setisProcessedImage={setisProcessedImage}
              setCropImage={setCropImage}
              setDrawerOpen={setDrawerOpen}
              setCropModalOpen={setCropModalOpen}
              isProcessedImage={isProcessedImage}
              cropModalOpen={cropModalOpen}
              cropImage={cropImage}
              imageGeneratorLoading={imageGeneratorLoading}
              setImageGeneratorLoading={setImageGeneratorLoading}
              showResponse={showResponse}
            />
          </Box>
        </CustomTabPanel>
      </Drawer>
    </>
  );
};

export default MagicRemoverComponent;

const ImageGenerator = ({
  loadingBase64,
  token,
  roomsData,
  setProcessedImages,
  processedImages,
  loading,
  uploadedImage,
  setUploadedImage,
  setisProcessedImage,
  setCropImage,
  setDrawerOpen,
  setCropModalOpen,
  isProcessedImage,
  cropModalOpen,
  cropImage,
  imageGeneratorLoading,
  setImageGeneratorLoading,
  showResponse,
}) => {
  const [prompt, setPrompt] = useState("");
  const [roomDataForImage, setRoomDataForImage] = useState(null);
  // const [open, setOpen] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const roomName = [
    "livingRoom",
    "dining",
    "kitchen",
    "bedRoom",
    "balcony",
    // 'bathRoom',
    "officeArea",
    "reception",
  ];
  const fetchImage = async (link) => {
    const response = await axios.get(link, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    });
    return response?.data;
  };
  function extractUrl(response) {
    return `${import.meta.env.VITE_APP_BASE_URL}/static${response?.image_url}`;
  }
  const generateImage = async () => {
    let finalPromptData;
    if (roomDataForImage !== null) {
      if (roomDataForImage?.room === "") {
        toast.info("Room is a required field");
        return;
      }
      if (roomDataForImage?.accessory === "") {
        toast.info("Accessories is a required field");
        return;
      }
      finalPromptData = {
        kycpId: roomDataForImage?.kycpId,
        room: roomDataForImage?.room,
        roomAccessories: roomDataForImage?.accessory,
        primaryUsage: roomDataForImage?.for,
        style: roomDataForImage?.style === "" ? null : roomDataForImage?.style,
        colorPreference:
          roomDataForImage?.favColor === "" ? null : roomDataForImage?.favColor,
        customQuery:
          roomDataForImage?.customQuery === ""
            ? null
            : roomDataForImage?.customQuery,
      };
    } else {
      finalPromptData = { prompt };
    }

    try {
      const image = await axios.post(
        import.meta.env.VITE_APP_BASE_URL + "/dc/aitools/generate-image/",
        finalPromptData,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        },
      );
      const responseURL = extractUrl(image.data);
      const generatedImage = await fetchImage(responseURL);
      const base64Image = await blobToBase64(generatedImage);
      const compressedImage = await compressBase64Image(base64Image, 0.5);

      setProcessedImages([compressedImage, ...processedImages]);
      setImageGeneratorLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
      setImageGeneratorLoading(false);
    }
  };
  return (
    <>
      <CropDialog
        setProcessedImages={setProcessedImages}
        processedImages={processedImages}
        isProcessedImage={isProcessedImage}
        cropModalOpen={cropModalOpen}
        setCropModalOpen={setCropModalOpen}
        uploadedImage={cropImage}
        setUploadedImage={setUploadedImage}
      />
      <Box
        sx={{
          width: "full",
          height: "calc(100vh - 162px)",
          overflow: "scroll",
          scrollbarWidth: "none",
        }}
      >
        {roomsData.length !== 0 && (
          <>
            <Accordion
              expanded={isAccordionOpen}
              onChange={() => setIsAccordionOpen(!isAccordionOpen)}
              sx={{ boxShadow: "none" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography variant="body1">
                  Select a Room to generate Image
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "grid", gap: 2 }}>
                {roomName.map((value) => {
                  const arr = roomsData?.[value];
                  const rooms = arr.map((val, index) => {
                    return (
                      <Paper
                        onClick={() => {
                          setRoomDataForImage({
                            room: value,
                            kycpId: showResponse,
                            favColor: roomsData?.favColor,
                            style: roomsData?.styles.join(", "),
                            for: val?.for,
                            accessory: val?.accessory.join(", "),
                            customQuery: "",
                          });
                          setIsAccordionOpen(!isAccordionOpen);
                        }}
                        key={index}
                        sx={{
                          p: 1,
                          border: "solid 1px lightgray",
                          "&:hover": {
                            border: "solid 1px black",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Typography variant="h6">
                          {value.toUpperCase()} {index + 1}
                        </Typography>
                        <Typography variant="body2">
                          For:{" "}
                          {val?.for === "Others"
                            ? `${val?.for} (${val?.others})`
                            : val?.for.toString()}
                        </Typography>
                        <Typography variant="overline">
                          {val?.accessory.join(", ")}
                        </Typography>
                      </Paper>
                    );
                  });
                  return rooms;
                })}
              </AccordionDetails>
            </Accordion>
            <Divider />
          </>
        )}
        <Box sx={{ p: 1 }}>
          {roomDataForImage === null ? (
            <>
              <Typography sx={{ fontWeight: 600 }} variant="body2">
                Start with a detailed description of the Image
              </Typography>
              <TextField
                disabled={imageGeneratorLoading}
                sx={{ mt: 2 }}
                placeholder="Design me a beautiful modern Indian style living room for my parents..."
                multiline
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                rows={6}
                // maxRows={4}
                fullWidth
              />
            </>
          ) : (
            <>
              {roomDataForImage !== null && (
                <Paper
                  elevation={1}
                  sx={{
                    mb: 2,
                    p: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "solid 1px lightgray",
                    "&:hover": {
                      border: "solid 1px black",
                    },
                  }}
                >
                  <Typography variant="body1">
                    {roomDataForImage?.room.toUpperCase() +
                      " " +
                      "for " +
                      roomDataForImage?.for}
                  </Typography>
                  <IconButton onClick={() => setRoomDataForImage(null)}>
                    <DeleteIcon />
                  </IconButton>
                </Paper>
              )}
              <Box sx={{ display: "flex", gap: 1, my: 1 }}>
                <TextField
                  disabled={imageGeneratorLoading}
                  onChange={(event) =>
                    setRoomDataForImage({
                      ...roomDataForImage,
                      room: event.target.value,
                    })
                  }
                  value={roomDataForImage?.room || ""}
                  id="outlined-basic"
                  label="Room"
                  variant="outlined"
                />

                <TextField
                  disabled={imageGeneratorLoading}
                  onChange={(event) =>
                    setRoomDataForImage({
                      ...roomDataForImage,
                      favColor: event.target.value,
                    })
                  }
                  value={roomDataForImage?.favColor || ""}
                  id="outlined-basic"
                  label="Color Preference"
                  variant="outlined"
                />
              </Box>
              <Box sx={{ display: "flex", gap: 1, my: 1 }}>
                <TextField
                  disabled={imageGeneratorLoading}
                  onChange={(event) =>
                    setRoomDataForImage({
                      ...roomDataForImage,
                      for: event.target.value,
                    })
                  }
                  value={roomDataForImage?.for || ""}
                  id="outlined-basic"
                  label="Used for"
                  variant="outlined"
                />
                <TextField
                  disabled={imageGeneratorLoading}
                  onChange={(event) =>
                    setRoomDataForImage({
                      ...roomDataForImage,
                      style: event.target.value,
                    })
                  }
                  value={roomDataForImage?.style || ""}
                  id="outlined-basic"
                  label="Style"
                  variant="outlined"
                />
              </Box>
              <TextField
                disabled={imageGeneratorLoading}
                onChange={(event) =>
                  setRoomDataForImage({
                    ...roomDataForImage,
                    accessory: event.target.value,
                  })
                }
                value={roomDataForImage?.accessory || ""}
                sx={{ mb: 1 }}
                fullWidth
                id="outlined-basic"
                label="Accessories"
                variant="outlined"
              />

              <TextField
                disabled={imageGeneratorLoading}
                onChange={(event) =>
                  setRoomDataForImage({
                    ...roomDataForImage,
                    customQuery: event.target.value,
                  })
                }
                value={roomDataForImage?.customQuery || ""}
                id="outlined-basic"
                label="Custom prompt"
                placeholder="Make the color of walls to red..."
                variant="outlined"
                multiline
                fullWidth
                rows={4}
              />
            </>
          )}

          <LoadingButton
            sx={{ mt: 2 }}
            size="large"
            loading={imageGeneratorLoading}
            disabled={
              roomDataForImage === null ? (prompt === "" ? true : false) : false
            }
            variant="contained"
            startIcon={<AutoAwesomeIcon />}
            fullWidth
            loadingIndicator={
              <span>
                {`Generateing(`}
                <ProgressBar seconds={40} />
                {`)...`}
              </span>
            }
            onClick={() => {
              setImageGeneratorLoading(true);
              generateImage();
            }}
          >
            Generate
          </LoadingButton>
        </Box>
        {processedImages.length !== 0 && loadingBase64 === false && (
          <Box sx={{ mt: 2, mx: 1 }}>
            <Grid container spacing={0.6}>
              {processedImages.map((value, index) => {
                // console.log(value);
                return (
                  <Grid item xs={6}>
                    <ImageComponent
                      styles={{ aspectRatio: 1 }}
                      loading={loading}
                      uploadedImage={uploadedImage}
                      setUploadedImage={setUploadedImage}
                      setisProcessedImage={setisProcessedImage}
                      setCropImage={setCropImage}
                      index={index}
                      processedImages={processedImages}
                      setProcessedImages={setProcessedImages}
                      setDrawerOpen={setDrawerOpen}
                      key={index}
                      image={value}
                      setCropModalOpen={setCropModalOpen}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
        {loadingBase64 === true && (
          <Box
            sx={{ display: "flex", flexWrap: "wrap", gap: 0.6, mt: 2, mx: 1 }}
          >
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={210}
              height={200}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={210}
              height={200}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={210}
              height={200}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={210}
              height={200}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={210}
              height={200}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={210}
              height={200}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

const BackgroundRemoverComponent = ({ setDrawerOpen, state }) => {
  const {
    uploadedImage,
    isProcessedImage,
    cropImage,
    processedImages,
    cropModalOpen,
    loading,
    error,
    setError,
    setLoading,
    setCropModalOpen,
    setProcessedImages,
    setCropImage,
    setisProcessedImage,
    setUploadedImage,
  } = state;
  const [isDragActive, setIsDragActive] = useState(false);

  const handleBackgroundRemove = () => {
    const img = { image: uploadedImage?.previewURL };
    fetch(
      "https://2cysu3y7pgroyrttlt2omfwr4y0cdrty.lambda-url.ap-south-1.on.aws/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Token ${token}`,
        },
        body: JSON.stringify(img),
      },
    )
      .then((response) => {
        if (!response?.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        setLoading(false);
        return response.blob();
      })
      .then(async (data) => {
        setLoading(false);
        const base64 = await blobToBase64(data);
        const compressedImage = await compressBase64Image(
          base64,
          0.5,
          "image/png",
        );
        const processImagesCopy = [...processedImages];
        processImagesCopy[processedImages.indexOf(uploadedImage?.previewURL)] =
          compressedImage;
        setProcessedImages([...processImagesCopy]);
        setUploadedImage(null);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        toast.error("File size exceeds 2MB limit.");
      } else if (!file.type.startsWith("image/")) {
        toast.error("Only image files are allowed.");
      } else {
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setProcessedImages([reader.result, ...processedImages]);
          };
          reader.readAsDataURL(file);
        }
      }
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragActive(true);
  };
  const handleDragLeave = (e) => {
    // Check if the relatedTarget is a child element
    if (e.relatedTarget && e.currentTarget.contains(e.relatedTarget)) {
      return;
    }
    setIsDragActive(false);
  };

  return (
    <>
      {/* CROP COMPONENT */}
      <CropDialog
        setProcessedImages={setProcessedImages}
        processedImages={processedImages}
        isProcessedImage={isProcessedImage}
        cropModalOpen={cropModalOpen}
        setCropModalOpen={setCropModalOpen}
        uploadedImage={cropImage}
        setUploadedImage={setUploadedImage}
      />
      <Box
        sx={{
          mx: 1,
          width: "full",
          height: "calc(100vh - 162px)",
          scrollbarWidth: "none",
          overflow: "scroll",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <Button
          fullWidth
          size="large"
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Upload Image
          <VisuallyHiddenInput
            accept="image/*"
            onChange={(event) => {
              const maxSize = 2 * 1024 * 1024; // 2 MB

              const file = event?.target?.files[0];
              if (file) {
                if (file.size > maxSize) {
                  setError(`Error: File Size is more than 2MB`);
                } else {
                  const reader = new FileReader();
                  setError("");

                  reader.onload = () => {
                    // setUploadedImage({
                    //   file,
                    //   previewURL: reader.result,
                    // });
                    setProcessedImages([reader.result, ...processedImages]);
                  };
                  reader.readAsDataURL(file);
                }
              }
            }}
            type="file"
          />
        </Button>
        {isDragActive && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              height: "100%",
              backgroundColor: "rgba(30, 30, 30, 0.8)",
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 200,
            }}
          >
            <Box
              sx={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
                color: "white",
              }}
            >
              <CloudUploadIcon
                sx={{ justifySelf: "center", fontSize: "6rem" }}
              />
              <Typography variant="h6">Drop images to upload</Typography>
              <Box
                sx={{
                  gap: 1,
                  mt: 2,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImageIcon />
                <Typography variant="h6">Images</Typography>
              </Box>
              <Typography sx={{ mt: 1 }} variant="body1">
                JPG, PNG, SVG, WEBP
              </Typography>
            </Box>
          </Box>
        )}
        {processedImages.length !== 0 && (
          <Box
            sx={{
              p: 1,
              zIndex: 100,
              position: "absolute",
              backgroundColor: "white",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <LoadingButton
              size="large"
              loading={loading}
              disabled={uploadedImage === null ? true : false}
              variant="contained"
              startIcon={<AutoAwesomeIcon />}
              fullWidth
              // loadingIndicator="Loading…"
              onClick={() => {
                setLoading(true);
                handleBackgroundRemove();
              }}
            >
              Remove Background
            </LoadingButton>
          </Box>
        )}
        {processedImages.length === 0 && (
          <Box
            onDragEnter={handleDragEnter}
            sx={{
              mt: 2,
              borderRadius: 5,
              textAlign: "center",
              padding: "80px 10px",
              minHeight: "80px",
              border: "3px dashed #eeeeee",
            }}
          >
            <span style={{ fontSize: "5rem", color: "#777" }}>
              {isDragActive ? "📂" : "📁"}
            </span>
            <Typography variant="body2">
              Drag'n'drop images, or click to select files
            </Typography>
          </Box>
        )}
        {processedImages.length !== 0 && (
          <Box sx={{ mt: 2 }}>
            <Masonry onDragEnter={handleDragEnter} columns={2} spacing={0.4}>
              {processedImages.map((value, index) => {
                // console.log(value);
                return (
                  <ImageComponent
                    loading={loading}
                    uploadedImage={uploadedImage}
                    setUploadedImage={setUploadedImage}
                    setisProcessedImage={setisProcessedImage}
                    setCropImage={setCropImage}
                    index={index}
                    processedImages={processedImages}
                    setProcessedImages={setProcessedImages}
                    setDrawerOpen={setDrawerOpen}
                    key={index}
                    image={value}
                    setCropModalOpen={setCropModalOpen}
                  />
                );
              })}
            </Masonry>
          </Box>
        )}
      </Box>
    </>
  );
};

const ImageComponent = ({
  styles,
  loading,
  uploadedImage,
  image,
  setCropModalOpen,
  setUploadedImage,
  setDrawerOpen,
  index,
  setCropImage,
  processedImages,
  setProcessedImages,
  setisProcessedImage,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleDownload = () => {
    const blobImage = base64toBlob(image.split(",")[1], "image/png");
    const blob = new Blob([blobImage]);
    const link = document.createElement("a");
    const blobUrl = URL.createObjectURL(blob);
    link.href = blobUrl;
    link.download = "designExpertAI.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isSelected =
    processedImages.indexOf(uploadedImage?.previewURL) === index;
  const style = isSelected
    ? {
        border: "1px solid blue",
        boxShadow: "0 0 0 4px rgba(0, 0, 255, 0.2)",
      }
    : {};

  return (
    <>
      <Box
        sx={{
          border: "solid lightgray 1px",
          borderRadius: "0.3rem",
          overflow: "hidden",
          cursor: "pointer",
          position: "relative",
          ...style,
          ...styles,
        }}
        onClick={() => {
          if (!loading) {
            setUploadedImage({
              // file,
              previewURL: image,
            });
          }
        }}
        onDragStart={(event) => {
          onDragFileData(event);
          setDrawerOpen(false);
        }}
      >
        {image !== null && (
          <>
            {loading && isSelected ? (
              <Box
                sx={{
                  display: "grid",
                  justifyContent: "center",
                  position: "absolute",
                  top: "55%",
                  left: "45%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 10,
                }}
              >
                <CircularProgress />
                <ProgressBar seconds={35} />
              </Box>
            ) : null}
            <div>
              <IconButton
                sx={{
                  border: "solid lightgray 1px",
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                  padding: "4px",
                  position: "absolute",
                  top: "0",
                  right: "0",
                  margin: 1,
                  "&:hover": {
                    backgroundColor: "lightgray",
                  },
                }}
                onClick={(event) => {
                  handleClick(event);
                }}
              >
                <MoreHorizIcon fontSize="medium" />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableAutoFocusItem
                PaperProps={{
                  style: {
                    // maxHeight: ITEM_HEIGHT * 4.5,
                    width: 320,
                    maxWidth: "100%",
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setCropModalOpen(true);
                    setisProcessedImage(index);
                    setCropImage(processedImages[index]);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <CropIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Crop Image</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleDownload();
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <CloudDownloadIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Download Image</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setProcessedImages((prev) => {
                      const arr = [...prev];
                      arr.splice(index, 1);
                      return arr;
                    });
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </Menu>
            </div>
            <img
              style={{
                opacity: loading && isSelected ? 0.65 : 1,
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
              src={image}
              alt="uploaded image"
            />
          </>
        )}
      </Box>
    </>
  );
};

const ProgressBar = ({ seconds = 10 }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = Date.now();
    const time = seconds * 1000;
    const endTime = startTime + time;

    const updateProgress = () => {
      const now = Date.now();
      const elapsedTime = now - startTime;
      const percentage = Math.min((elapsedTime / time) * 100, 100);
      setProgress(percentage);
      if (now < endTime) {
        setTimeout(updateProgress, 1000);
      }
    };

    updateProgress();

    // Clean up timeout on component unmount
    return () => clearTimeout(updateProgress);
  }, []);

  return <span>{progress.toFixed(1)}%</span>;
};
