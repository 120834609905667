import React, { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const CropDialog = ({
  cropModalOpen,
  setCropModalOpen,
  uploadedImage,
  setUploadedImage,
  isProcessedImage,
  processedImages,
  setProcessedImages,
}) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState();

  const [crop, setCrop] = useState();

  // const onImageLoad = (event) => {
  //   const crop = makeAspectCrop({
  //     unit: "px",
  //   });
  // };

  return (
    <Dialog
      open={cropModalOpen}
      // onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">{"Crop Image"}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setCropModalOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          // color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {!!uploadedImage && (
        <Box sx={{ display: "grid", justifyContent: "center" }}>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={0}
            minWidth={100}
            minHeight={100}
            // circularCrop
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={uploadedImage}
              // style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}

              style={{
                objectFit: "contain",
                overflow: "hidden",
                maxWidth: 1000,
                maxHeight: 600,
              }}
              // onLoad={onImageLoad}
            />
          </ReactCrop>
        </Box>
      )}
      {completedCrop && (
        <canvas
          ref={previewCanvasRef}
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: completedCrop.width,
            height: completedCrop.height,
          }}
        />
      )}

      <DialogActions>
        <Button
          onClick={() => {
            setCropModalOpen(false);
          }}
          variant="outlined"
        >
          Close
        </Button>
        <Button
          onClick={() => {
            setCropModalOpen(false);
            canvasPreview(
              imgRef.current,
              previewCanvasRef.current,
              completedCrop,
            );
            const croppedDataURL = previewCanvasRef.current.toDataURL(
              "image/png",
              0.1,
            );
            if (completedCrop?.width !== 0 && completedCrop?.height !== 0) {
              if (isProcessedImage === null) {
                setUploadedImage({
                  ...uploadedImage,
                  previewURL: croppedDataURL,
                });
              } else {
                const arr = [...processedImages];
                arr[isProcessedImage] = croppedDataURL;
                setProcessedImages([...arr]);
              }
            }
          }}
          autoFocus
          variant="contained"
        >
          Crop Image
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CropDialog;

const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  rotate = 0,
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio / 1.2;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  ctx.restore();
}
