export default [
  {
    status: "published",
    elements: [
      {
        type: "ellipse",
        version: 154,
        versionNonce: 1345765581,
        isDeleted: false,
        id: "z4grJo-9sD7201QDkDDzE",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 259.00832643484193,
        y: 227.14715793975012,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 100.59635687161841,
        height: 100.59635687161841,
        seed: 955510477,
        groupIds: ["1DccOvuafSo7pWmVlrSVe"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1702037295519,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 462,
        versionNonce: 70008451,
        isDeleted: false,
        id: "L7qlLKzP_17-eeMPeErex",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 261.44213297188014,
        y: 304.2169142851913,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 193.89129413212214,
        height: 129.8016948975852,
        seed: 1200000301,
        groupIds: ["1DccOvuafSo7pWmVlrSVe"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1702037295519,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-21.904042203394624, 15.413963647942467],
          [-52.73193855214396, 12.980188058039914],
          [-49.48688380085008, -31.639144563005004],
          [-32.45042372439627, -86.8048741786194],
          [4.056302965549548, -110.33144375766096],
          [68.95715041434215, -111.95395565974013],
          [109.52018006983747, -103.84134972864103],
          [137.9143008286843, -50.298162962241406],
          [141.15935557997818, -4.056302965549548],
          [134.6692460773903, 17.847739237845076],
          [96.54002295893326, -7.301326769707771],
        ],
      },
    ],
    id: "F0ueJuDxjuWwsTZciEfmR",
    created: 1702037299974,
  },
  {
    status: "published",
    elements: [
      {
        type: "freedraw",
        version: 279,
        versionNonce: 748101943,
        isDeleted: false,
        id: "iqD91KgeJHVZKMID9SUKk",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -1041.5444388767432,
        y: -2121.2189751463334,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 179.00001525878918,
        height: 72.00000762939453,
        seed: 116884535,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [-1.0000228881835938, 1.0000038146972656],
          [-1.0000228881835938, 1.9999885559082031],
          [-1.0000228881835938, 3.000030517578125],
          [-1.0000228881835938, 3.9999961853027344],
          [-1.0000228881835938, 5],
          [-1.0000228881835938, 6.999988555908203],
          [0, 10],
          [1.999969482421875, 13.000030517578125],
          [3.9999771118164062, 15],
          [6.000022888183594, 18.999996185302734],
          [7.999992370605469, 21.999988555908203],
          [9.999961853027344, 25],
          [12.000007629394531, 28.999996185302734],
          [13.999977111816406, 31.999988555908203],
          [17.99999237060547, 36.9999885559082],
          [18.999977111816406, 38.000030517578125],
          [21.000022888183594, 40],
          [21.000022888183594, 40.99998474121094],
          [22.00000762939453, 40.99998474121094],
          [22.99999237060547, 43.000030517578125],
          [24.999961853027344, 43.999996185302734],
          [24.999961853027344, 45],
          [26.000022888183594, 45],
          [27.00000762939453, 45],
          [27.99999237060547, 45],
          [28.999977111816406, 45],
          [29.999961853027344, 45],
          [31.000022888183594, 45],
          [32.00000762939453, 45],
          [32.99999237060547, 45],
          [33.999977111816406, 45],
          [34.999961853027344, 43.999996185302734],
          [36.000022888183594, 41.9999885559082],
          [36.000022888183594, 40],
          [37.00000762939453, 38.000030517578125],
          [37.99999237060547, 35.99998474121094],
          [37.99999237060547, 33.999996185302734],
          [37.99999237060547, 33.000030517578125],
          [37.99999237060547, 31.999988555908203],
          [37.99999237060547, 30.999984741210938],
          [37.99999237060547, 30],
          [38.999977111816406, 28.000030517578125],
          [38.999977111816406, 26.999988555908203],
          [38.999977111816406, 25.999984741210938],
          [38.999977111816406, 25],
          [38.999977111816406, 23.999996185302734],
          [38.999977111816406, 23.000030517578125],
          [38.999977111816406, 21.999988555908203],
          [38.999977111816406, 20.999984741210938],
          [38.999977111816406, 18.999996185302734],
          [38.999977111816406, 18.000030517578125],
          [38.999977111816406, 16.999988555908203],
          [39.999961853027344, 16.999988555908203],
          [39.999961853027344, 16.000003814697266],
          [39.999961853027344, 13.999996185302734],
          [41.000022888183594, 13.999996185302734],
          [41.000022888183594, 11.999988555908203],
          [41.000022888183594, 11.000003814697266],
          [41.000022888183594, 10],
          [41.000022888183594, 8.999996185302734],
          [41.000022888183594, 8.000030517578125],
          [41.000022888183594, 6.999988555908203],
          [41.000022888183594, 5],
          [41.000022888183594, 3.9999961853027344],
          [39.999961853027344, 1.9999885559082031],
          [39.999961853027344, 1.0000038146972656],
          [39.999961853027344, 0],
          [39.999961853027344, -1.0000038146972656],
          [38.999977111816406, -1.999969482421875],
          [37.99999237060547, -3.000011444091797],
          [37.99999237060547, -3.9999961853027344],
          [37.99999237060547, -5],
          [37.00000762939453, -6.000003814697266],
          [37.00000762939453, -6.999969482421875],
          [36.000022888183594, -8.000011444091797],
          [36.000022888183594, -8.999996185302734],
          [34.999961853027344, -10],
          [34.999961853027344, -11.000003814697266],
          [34.999961853027344, -11.999969482421875],
          [34.999961853027344, -13.000011444091797],
          [34.999961853027344, -14.000015258789062],
          [34.999961853027344, -16.000003814697266],
          [34.999961853027344, -18.000011444091797],
          [34.999961853027344, -19.000015258789062],
          [34.999961853027344, -20],
          [34.999961853027344, -21.000003814697266],
          [34.999961853027344, -22.00000762939453],
          [34.999961853027344, -23.000011444091797],
          [36.000022888183594, -24.000015258789062],
          [37.00000762939453, -24.000015258789062],
          [37.00000762939453, -25],
          [37.99999237060547, -25],
          [38.999977111816406, -26.000003814697266],
          [39.999961853027344, -26.000003814697266],
          [41.000022888183594, -26.000003814697266],
          [42.00000762939453, -26.000003814697266],
          [42.99999237060547, -26.000003814697266],
          [43.999977111816406, -27.00000762939453],
          [44.999961853027344, -27.00000762939453],
          [47.000007629394645, -27.00000762939453],
          [47.99999237060558, -27.00000762939453],
          [48.99997711181652, -27.00000762939453],
          [49.99996185302746, -27.00000762939453],
          [51.00002288818371, -27.00000762939453],
          [52.000007629394645, -27.00000762939453],
          [52.000007629394645, -26.000003814697266],
          [52.000007629394645, -25],
          [52.99999237060558, -24.000015258789062],
          [53.99997711181652, -23.000011444091797],
          [53.99997711181652, -22.00000762939453],
          [54.99996185302746, -20],
          [54.99996185302746, -19.000015258789062],
          [54.99996185302746, -17.00000762939453],
          [54.99996185302746, -15],
          [56.00002288818371, -14.000015258789062],
          [57.000007629394645, -14.000015258789062],
          [57.99999237060558, -13.000011444091797],
          [57.99999237060558, -11.999969482421875],
          [58.99997711181652, -11.999969482421875],
          [59.99996185302746, -11.999969482421875],
          [61.00002288818371, -11.999969482421875],
          [62.99999237060558, -11.999969482421875],
          [64.99996185302746, -11.999969482421875],
          [67.99999237060558, -11.999969482421875],
          [72.00000762939464, -11.999969482421875],
          [77.00000762939464, -11.999969482421875],
          [81.00002288818371, -13.000011444091797],
          [84.99996185302746, -13.000011444091797],
          [87.00000762939464, -14.000015258789062],
          [89.99996185302746, -15],
          [92.00000762939464, -16.000003814697266],
          [92.00000762939464, -17.00000762939453],
          [92.00000762939464, -18.000011444091797],
          [92.00000762939464, -19.000015258789062],
          [92.00000762939464, -20],
          [92.99999237060558, -21.000003814697266],
          [93.99997711181652, -21.000003814697266],
          [94.99996185302746, -21.000003814697266],
          [96.00002288818371, -21.000003814697266],
          [97.00000762939464, -21.000003814697266],
          [97.00000762939464, -20],
          [97.00000762939464, -19.000015258789062],
          [97.00000762939464, -18.000011444091797],
          [97.00000762939464, -17.00000762939453],
          [97.00000762939464, -16.000003814697266],
          [97.00000762939464, -15],
          [97.00000762939464, -14.000015258789062],
          [97.00000762939464, -13.000011444091797],
          [97.00000762939464, -11.999969482421875],
          [97.99999237060558, -11.000003814697266],
          [98.99997711181652, -11.000003814697266],
          [99.99996185302746, -11.000003814697266],
          [101.00002288818371, -11.000003814697266],
          [102.99999237060558, -11.000003814697266],
          [104.99996185302746, -11.000003814697266],
          [107.00000762939464, -11.000003814697266],
          [109.99996185302746, -11.000003814697266],
          [112.00000762939464, -11.999969482421875],
          [114.99996185302746, -11.999969482421875],
          [117.00000762939464, -11.999969482421875],
          [117.99999237060558, -13.000011444091797],
          [118.99997711181652, -13.000011444091797],
          [119.99996185302746, -14.000015258789062],
          [119.99996185302746, -15],
          [121.00002288818371, -16.000003814697266],
          [121.00002288818371, -15],
          [121.00002288818371, -13.000011444091797],
          [121.00002288818371, -10],
          [121.00002288818371, -8.999996185302734],
          [121.00002288818371, -6.999969482421875],
          [121.00002288818371, -6.000003814697266],
          [121.00002288818371, -5],
          [121.00002288818371, -3.9999961853027344],
          [119.99996185302746, -3.000011444091797],
          [119.99996185302746, -1.0000038146972656],
          [119.99996185302746, 0],
          [119.99996185302746, 1.0000038146972656],
          [121.00002288818371, 1.9999885559082031],
          [122.00000762939464, 1.9999885559082031],
          [122.99999237060558, 1.9999885559082031],
          [123.99997711181652, 1.9999885559082031],
          [124.99996185302746, 1.9999885559082031],
          [126.00002288818371, 1.9999885559082031],
          [127.00000762939464, 1.0000038146972656],
          [127.99999237060558, 1.0000038146972656],
          [127.99999237060558, 0],
          [127.99999237060558, -1.0000038146972656],
          [127.99999237060558, -1.999969482421875],
          [128.99997711181652, -1.999969482421875],
          [128.99997711181652, -3.000011444091797],
          [128.99997711181652, -3.9999961853027344],
          [129.99996185302746, -3.9999961853027344],
          [131.0000228881837, -3.9999961853027344],
          [132.00000762939464, -3.9999961853027344],
          [132.99999237060558, -3.9999961853027344],
          [133.99997711181652, -3.9999961853027344],
          [134.99996185302746, -3.9999961853027344],
          [134.99996185302746, -3.000011444091797],
          [134.99996185302746, -1.999969482421875],
          [136.0000228881837, 0],
          [137.00000762939464, 0],
          [137.00000762939464, 1.9999885559082031],
          [137.00000762939464, 3.9999961853027344],
          [137.99999237060558, 5],
          [137.99999237060558, 6.999988555908203],
          [137.99999237060558, 8.000030517578125],
          [137.99999237060558, 8.999996185302734],
          [137.99999237060558, 10],
          [138.99997711181652, 10],
          [139.99996185302746, 10],
          [141.0000228881837, 10],
          [142.99999237060558, 10],
          [143.99997711181652, 10],
          [144.99996185302746, 10],
          [146.0000228881837, 8.999996185302734],
          [146.0000228881837, 8.000030517578125],
          [147.00000762939464, 6.000003814697266],
          [147.00000762939464, 5],
          [147.00000762939464, 3.9999961853027344],
          [147.00000762939464, 1.9999885559082031],
          [147.00000762939464, 1.0000038146972656],
          [147.00000762939464, 0],
          [147.00000762939464, -1.999969482421875],
          [147.00000762939464, -3.000011444091797],
          [147.00000762939464, -3.9999961853027344],
          [147.00000762939464, -5],
          [147.00000762939464, -6.000003814697266],
          [147.00000762939464, -8.000011444091797],
          [147.99999237060558, -8.000011444091797],
          [148.99997711181652, -8.000011444091797],
          [149.99996185302746, -8.000011444091797],
          [152.00000762939464, -8.000011444091797],
          [152.99999237060558, -6.000003814697266],
          [153.99997711181652, -3.9999961853027344],
          [157.99999237060558, -1.0000038146972656],
          [161.0000228881837, 1.0000038146972656],
          [167.00000762939464, 3.9999961853027344],
          [168.99997711181652, 6.000003814697266],
          [173.99997711181652, 8.999996185302734],
          [176.0000228881837, 10],
          [177.00000762939464, 11.000003814697266],
          [177.99999237060558, 11.000003814697266],
          [177.99999237060558, 11.999988555908203],
          [177.99999237060558, 13.000030517578125],
          [177.00000762939464, 13.999996185302734],
          [176.0000228881837, 15],
          [176.0000228881837, 16.000003814697266],
          [174.99996185302746, 16.000003814697266],
          [174.99996185302746, 16.999988555908203],
          [174.99996185302746, 18.000030517578125],
          [174.99996185302746, 16.999988555908203],
          [174.99996185302746, 16.000003814697266],
          [174.99996185302746, 15],
          [173.99997711181652, 15],
          [173.99997711181652, 13.999996185302734],
          [173.99997711181652, 13.000030517578125],
          [172.99999237060558, 13.000030517578125],
          [172.00000762939464, 11.999988555908203],
          [171.0000228881837, 11.999988555908203],
          [169.99996185302746, 11.999988555908203],
          [168.99997711181652, 15],
          [168.99997711181652, 16.000003814697266],
          [168.99997711181652, 18.000030517578125],
          [168.99997711181652, 18.999996185302734],
          [168.99997711181652, 20.999984741210938],
          [168.99997711181652, 23.000030517578125],
          [168.99997711181652, 25],
          [168.99997711181652, 26.999988555908203],
          [168.99997711181652, 28.000030517578125],
          [168.99997711181652, 28.999996185302734],
          [168.99997711181652, 30],
          [168.99997711181652, 30],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 370,
        versionNonce: 1691349433,
        isDeleted: false,
        id: "5DIAA5ZIiNeKMbPQCD9JL",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -871.5444770237158,
        y: -2092.2189789610306,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 127.00004577636719,
        height: 172.00000762939453,
        seed: 1850406007,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [0, -0.9999656677246094],
          [1.00006103515625, -2.0000076293945312],
          [3.000030517578125, -3.000011444091797],
          [5, -5],
          [7.0000457763671875, -7.000007629394531],
          [9.000015258789062, -8.000011444091797],
          [11.00006103515625, -10],
          [12.000045776367188, -10],
          [13.000030517578125, -10],
          [14.000015258789062, -10],
          [15, -10],
          [16.00006103515625, -10],
          [17.000045776367188, -10],
          [18.000030517578125, -8.999996185302734],
          [19.000015258789062, -8.999996185302734],
          [20, -8.999996185302734],
          [21.00006103515625, -8.999996185302734],
          [21.00006103515625, -8.000011444091797],
          [21.00006103515625, -7.000007629394531],
          [21.00006103515625, -5.999965667724609],
          [21.00006103515625, -5],
          [20, -3.9999961853027344],
          [20, -3.000011444091797],
          [20, -0.9999656677246094],
          [20, 0],
          [20, 1.0000038146972656],
          [20, 1.9999885559082031],
          [20, 2.9999923706054688],
          [22.000045776367188, 4.000034332275391],
          [25, 5],
          [28.000030517578125, 6.000003814697266],
          [33.000030517578125, 6.999988555908203],
          [39.00001525878906, 7.999992370605469],
          [42.00004577636719, 7.999992370605469],
          [43.000030517578125, 7.999992370605469],
          [43.000030517578125, 6.999988555908203],
          [43.000030517578125, 6.000003814697266],
          [43.000030517578125, 4.000034332275391],
          [43.000030517578125, 1.0000038146972656],
          [43.000030517578125, 0],
          [42.00004577636719, -2.0000076293945312],
          [41.00006103515625, -3.9999961853027344],
          [40, -3.9999961853027344],
          [39.00001525878906, -5],
          [38.000030517578125, -5],
          [37.00004577636719, -5],
          [36.00006103515625, -5],
          [35, -5],
          [35, -3.000011444091797],
          [35, -2.0000076293945312],
          [33.000030517578125, 0],
          [32.00004577636719, 2.9999923706054688],
          [31.00006103515625, 6.000003814697266],
          [30, 10],
          [30, 11.999988555908203],
          [30, 14.00003433227539],
          [30, 15],
          [30, 16.000003814697266],
          [30, 16.999988555908203],
          [31.00006103515625, 16.999988555908203],
          [32.00004577636719, 17.99999237060547],
          [33.000030517578125, 17.99999237060547],
          [34.00001525878906, 17.99999237060547],
          [35, 17.99999237060547],
          [36.00006103515625, 17.99999237060547],
          [37.00004577636719, 16.999988555908203],
          [38.000030517578125, 16.999988555908203],
          [38.000030517578125, 16.000003814697266],
          [39.00001525878906, 16.000003814697266],
          [40, 15],
          [41.00006103515625, 15],
          [43.000030517578125, 15],
          [44.00001525878906, 12.999992370605469],
          [45, 12.999992370605469],
          [46.00006103515625, 12.999992370605469],
          [47.00004577636719, 12.999992370605469],
          [48.000030517578125, 12.999992370605469],
          [49.00001525878906, 12.999992370605469],
          [50, 12.999992370605469],
          [51.00006103515625, 12.999992370605469],
          [52.00004577636719, 12.999992370605469],
          [53.000030517578125, 12.999992370605469],
          [56.00006103515625, 15],
          [56.00006103515625, 16.000003814697266],
          [56.00006103515625, 16.999988555908203],
          [56.00006103515625, 17.99999237060547],
          [56.00006103515625, 19.00003433227539],
          [55, 19.00003433227539],
          [54.00001525878906, 19.00003433227539],
          [52.00004577636719, 20],
          [51.00006103515625, 20],
          [50, 20],
          [48.000030517578125, 20],
          [46.00006103515625, 20],
          [45, 21.000003814697266],
          [43.000030517578125, 21.000003814697266],
          [41.00006103515625, 21.999988555908203],
          [40, 21.999988555908203],
          [38.000030517578125, 21.999988555908203],
          [39.00001525878906, 21.999988555908203],
          [40, 21.999988555908203],
          [41.00006103515625, 21.999988555908203],
          [42.00004577636719, 21.999988555908203],
          [43.000030517578125, 21.999988555908203],
          [44.00001525878906, 21.999988555908203],
          [45, 21.999988555908203],
          [46.00006103515625, 21.999988555908203],
          [47.00004577636719, 21.999988555908203],
          [48.000030517578125, 21.999988555908203],
          [49.00001525878906, 21.999988555908203],
          [50, 21.999988555908203],
          [51.00006103515625, 21.999988555908203],
          [51.00006103515625, 22.99999237060547],
          [51.00006103515625, 24.00003433227539],
          [51.00006103515625, 25],
          [51.00006103515625, 26.000003814697266],
          [50, 26.999988555908203],
          [50, 27.99999237060547],
          [50, 29.00003433227539],
          [49.00001525878906, 29.00003433227539],
          [48.000030517578125, 30],
          [48.000030517578125, 31.000003814697266],
          [48.000030517578125, 31.999988555908203],
          [48.000030517578125, 32.99999237060547],
          [47.00004577636719, 32.99999237060547],
          [46.00006103515625, 34.00003433227539],
          [45, 35],
          [45, 36.9999885559082],
          [45, 37.99999237060547],
          [44.00001525878906, 39.00003433227539],
          [43.000030517578125, 40],
          [43.000030517578125, 41.000003814697266],
          [42.00004577636719, 41.9999885559082],
          [42.00004577636719, 42.99999237060547],
          [42.00004577636719, 44.00003433227539],
          [42.00004577636719, 45],
          [42.00004577636719, 46.000003814697266],
          [42.00004577636719, 46.9999885559082],
          [42.00004577636719, 47.99999237060547],
          [43.000030517578125, 49.00003433227539],
          [44.00001525878906, 49.00003433227539],
          [45, 49.00003433227539],
          [47.00004577636719, 49.00003433227539],
          [49.00001525878906, 49.00003433227539],
          [55, 49.00003433227539],
          [57.00004577636719, 49.00003433227539],
          [60, 49.00003433227539],
          [62.00004577636719, 47.99999237060547],
          [64.00001525878906, 46.9999885559082],
          [65, 46.000003814697266],
          [66.00006103515625, 45],
          [67.00004577636719, 44.00003433227539],
          [68.00003051757812, 42.99999237060547],
          [69.00001525878906, 42.99999237060547],
          [69.00001525878906, 44.00003433227539],
          [69.00001525878906, 45],
          [70, 46.000003814697266],
          [70, 46.9999885559082],
          [70, 47.99999237060547],
          [68.00003051757812, 46.9999885559082],
          [67.00004577636719, 46.000003814697266],
          [68.00003051757812, 46.000003814697266],
          [68.00003051757812, 46.9999885559082],
          [69.00001525878906, 47.99999237060547],
          [69.00001525878906, 49.00003433227539],
          [69.00001525878906, 51.000003814697266],
          [70, 52.99999237060547],
          [70, 54.00003433227539],
          [71.00006103515625, 55.99998474121094],
          [71.00006103515625, 57.00000762939453],
          [71.00006103515625, 57.99999237060547],
          [71.00006103515625, 59.00001525878906],
          [72.00004577636719, 60],
          [72.00004577636719, 60.99998474121094],
          [72.00004577636719, 62.00000762939453],
          [73.00003051757812, 62.00000762939453],
          [73.00003051757812, 64.00001525878906],
          [74.00001525878906, 65.99998474121094],
          [74.00001525878906, 67.00000762939453],
          [75, 69.00001525878906],
          [75, 70.99998474121094],
          [75, 74.00001525878906],
          [75, 75],
          [75, 77.00000762939453],
          [75, 79.00001525878906],
          [76.00006103515625, 79.00001525878906],
          [77.00004577636719, 80],
          [77.00004577636719, 80.99998474121094],
          [77.00004577636719, 82.00000762939453],
          [78.00003051757812, 82.00000762939453],
          [79.00001525878906, 82.00000762939453],
          [80, 80.99998474121094],
          [80, 79.00001525878906],
          [80, 77.99999237060547],
          [80, 77.00000762939453],
          [80, 75.99998474121094],
          [80, 75],
          [80, 72.99999237060547],
          [80, 72.00000762939453],
          [80, 70],
          [80, 69.00001525878906],
          [81.00006103515625, 69.00001525878906],
          [82.00004577636719, 69.00001525878906],
          [83.00003051757812, 69.00001525878906],
          [84.00001525878906, 69.00001525878906],
          [86.00006103515625, 69.00001525878906],
          [87.00004577636719, 70],
          [89.00001525878906, 70.99998474121094],
          [91.00006103515625, 72.00000762939453],
          [93.00003051757812, 74.00001525878906],
          [94.00001525878906, 75.99998474121094],
          [96.00006103515625, 75.99998474121094],
          [97.00004577636719, 77.00000762939453],
          [98.00003051757812, 77.99999237060547],
          [98.00003051757812, 79.00001525878906],
          [98.00003051757812, 80],
          [98.00003051757812, 80.99998474121094],
          [98.00003051757812, 82.00000762939453],
          [98.00003051757812, 82.99999237060547],
          [98.00003051757812, 84.00001525878906],
          [97.00004577636719, 85.99998474121094],
          [93.00003051757812, 87.99999237060547],
          [91.00006103515625, 90],
          [90, 90.99998474121094],
          [88.00003051757812, 90.99998474121094],
          [86.00006103515625, 92.00000762939453],
          [86.00006103515625, 92.99999237060547],
          [85, 94.00001525878906],
          [84.00001525878906, 94.00001525878906],
          [83.00003051757812, 94.00001525878906],
          [82.00004577636719, 94.00001525878906],
          [81.00006103515625, 94.00001525878906],
          [80, 94.00001525878906],
          [79.00001525878906, 94.00001525878906],
          [78.00003051757812, 94.00001525878906],
          [76.00006103515625, 94.00001525878906],
          [75, 94.00001525878906],
          [73.00003051757812, 94.00001525878906],
          [71.00006103515625, 94.00001525878906],
          [68.00003051757812, 95],
          [66.00006103515625, 95],
          [65, 95],
          [64.00001525878906, 95],
          [64.00001525878906, 95.99998474121094],
          [64.00001525878906, 97.00000762939453],
          [64.00001525878906, 97.99999237060547],
          [65, 97.99999237060547],
          [66.00006103515625, 99.00001525878906],
          [69.00001525878906, 100],
          [73.00003051757812, 100.99998474121094],
          [77.00004577636719, 102.00000762939453],
          [81.00006103515625, 102.99999237060547],
          [83.00003051757812, 102.99999237060547],
          [85, 104.00001525878906],
          [87.00004577636719, 104.00001525878906],
          [87.00004577636719, 105],
          [87.00004577636719, 105.99998474121094],
          [87.00004577636719, 107.00000762939453],
          [87.00004577636719, 107.99999237060547],
          [86.00006103515625, 109.00001525878906],
          [85, 110],
          [83.00003051757812, 112.00000762939453],
          [81.00006103515625, 112.99999237060547],
          [79.00001525878906, 115],
          [78.00003051757812, 115.99998474121094],
          [76.00006103515625, 117.00000762939453],
          [73.00003051757812, 117.99999237060547],
          [71.00006103515625, 119.00001525878906],
          [70, 120],
          [69.00001525878906, 120.99998474121094],
          [68.00003051757812, 120.99998474121094],
          [66.00006103515625, 120.99998474121094],
          [65, 120.99998474121094],
          [62.00004577636719, 122.00000762939453],
          [61.00006103515625, 122.00000762939453],
          [59.00001525878906, 122.00000762939453],
          [58.000030517578125, 122.00000762939453],
          [58.000030517578125, 122.99999237060547],
          [57.00004577636719, 124.00001525878906],
          [57.00004577636719, 125],
          [57.00004577636719, 125.99998474121094],
          [58.000030517578125, 125.99998474121094],
          [61.00006103515625, 125.99998474121094],
          [65, 127.00000762939453],
          [71.00006103515625, 127.00000762939453],
          [78.00003051757812, 127.00000762939453],
          [85, 127.00000762939453],
          [90, 127.99999237060547],
          [97.00004577636719, 127.99999237060547],
          [105, 127.99999237060547],
          [112.00004577636719, 127.99999237060547],
          [116.00006103515625, 127.99999237060547],
          [119.00001525878906, 127.99999237060547],
          [120, 127.99999237060547],
          [121.00006103515625, 127.99999237060547],
          [122.00004577636719, 127.99999237060547],
          [123.00003051757812, 127.99999237060547],
          [124.00001525878906, 127.00000762939453],
          [125, 127.00000762939453],
          [126.00006103515625, 127.00000762939453],
          [127.00004577636719, 127.00000762939453],
          [126.00006103515625, 127.00000762939453],
          [124.00001525878906, 127.00000762939453],
          [123.00003051757812, 127.00000762939453],
          [121.00006103515625, 127.99999237060547],
          [118.00003051757812, 127.99999237060547],
          [115, 127.99999237060547],
          [111.00006103515625, 127.99999237060547],
          [107.00004577636719, 127.99999237060547],
          [103.00003051757812, 127.99999237060547],
          [100, 127.99999237060547],
          [97.00004577636719, 127.99999237060547],
          [94.00001525878906, 127.99999237060547],
          [93.00003051757812, 127.99999237060547],
          [91.00006103515625, 127.99999237060547],
          [90, 127.99999237060547],
          [90, 129.00001525878906],
          [90, 130],
          [90, 130.99998474121094],
          [90, 132.00000762939453],
          [90, 132.99999237060547],
          [90, 134.00001525878906],
          [90, 135],
          [90, 135.99998474121094],
          [90, 137.00000762939453],
          [90, 137.99999237060547],
          [90, 139.00001525878906],
          [90, 140],
          [90, 140.99998474121094],
          [90, 142.00000762939453],
          [91.00006103515625, 142.00000762939453],
          [92.00004577636719, 142.00000762939453],
          [93.00003051757812, 142.99999237060547],
          [96.00006103515625, 144.00001525878906],
          [99.00001525878906, 144.00001525878906],
          [101.00006103515625, 145],
          [105, 145],
          [108.00003051757812, 145],
          [111.00006103515625, 145.99998474121094],
          [112.00004577636719, 145.99998474121094],
          [114.00001525878906, 145.99998474121094],
          [115, 145.99998474121094],
          [116.00006103515625, 145.99998474121094],
          [117.00004577636719, 145.99998474121094],
          [117.00004577636719, 147.00000762939453],
          [117.00004577636719, 147.99999237060547],
          [117.00004577636719, 149.00001525878906],
          [117.00004577636719, 150],
          [117.00004577636719, 150.99998474121094],
          [117.00004577636719, 152.00000762939453],
          [116.00006103515625, 152.99999237060547],
          [116.00006103515625, 154.00001525878906],
          [115, 154.00001525878906],
          [115, 155],
          [115, 155.99998474121094],
          [115, 157.00000762939453],
          [115, 157.99999237060547],
          [115, 159.00001525878906],
          [114.00001525878906, 159.00001525878906],
          [114.00001525878906, 160],
          [113.00003051757812, 160.99998474121094],
          [113.00003051757812, 162.00000762939453],
          [113.00003051757812, 162.00000762939453],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 627,
        versionNonce: 95150679,
        isDeleted: false,
        id: "4I5ApnkfNbWo8rxrJopEh",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -754.5444312473486,
        y: -1929.2189865904252,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 211.99996948242188,
        height: 294.00001525878906,
        seed: 1703104279,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [0.9999847412109375, 0],
          [1.999969482421875, 0],
          [2.9999542236328125, 0],
          [4.0000152587890625, 0],
          [5.9999847412109375, 0],
          [7.9999542236328125, 0],
          [9.000015258789062, 0],
          [10, 0],
          [10.999984741210938, 0],
          [11.999969482421875, 0],
          [12.999954223632812, 0],
          [12.999954223632812, 2.9999923706054688],
          [11.999969482421875, 2.9999923706054688],
          [11.999969482421875, 4.0000152587890625],
          [10.999984741210938, 5],
          [10, 6.000022888183594],
          [9.000015258789062, 6.000022888183594],
          [9.000015258789062, 7.000007629394531],
          [7.9999542236328125, 7.999992370605469],
          [5.9999847412109375, 7.999992370605469],
          [5.9999847412109375, 9.000015258789062],
          [4.0000152587890625, 9.000015258789062],
          [1.999969482421875, 10],
          [0.9999847412109375, 10],
          [0, 11.000022888183594],
          [-0.9999847412109375, 11.000022888183594],
          [-2.0000457763671875, 11.000022888183594],
          [-3.000030517578125, 11.000022888183594],
          [-3.000030517578125, 12.000007629394531],
          [-4.0000152587890625, 12.000007629394531],
          [-4.0000152587890625, 12.999992370605469],
          [-5, 14.000015258789062],
          [-5.9999847412109375, 14.000015258789062],
          [-7.0000457763671875, 14.000015258789062],
          [-8.000030517578125, 14.000015258789062],
          [-9.000015258789062, 14.000015258789062],
          [-10, 14.000015258789062],
          [-10.999984741210938, 15],
          [-12.000045776367188, 16.000022888183594],
          [-13.000030517578125, 16.000022888183594],
          [-13.000030517578125, 17.00000762939453],
          [-14.000015258789062, 17.00000762939453],
          [-15, 17.99999237060547],
          [-15, 19.000015258789062],
          [-15.999984741210938, 19.000015258789062],
          [-18.000030517578125, 21.000022888183594],
          [-19.000015258789062, 22.00000762939453],
          [-20, 22.99999237060547],
          [-20, 24.000015258789062],
          [-20.999984741210938, 24.000015258789062],
          [-20.999984741210938, 25],
          [-22.000045776367188, 26.000022888183594],
          [-22.000045776367188, 27.00000762939453],
          [-22.000045776367188, 27.99999237060547],
          [-22.000045776367188, 29.000015258789062],
          [-22.000045776367188, 30],
          [-22.000045776367188, 31.000022888183594],
          [-20.999984741210938, 32.00000762939453],
          [-20.999984741210938, 32.99999237060547],
          [-20, 34.00001525878906],
          [-17.000045776367188, 34.00001525878906],
          [-15, 34.00001525878906],
          [-14.000015258789062, 34.00001525878906],
          [-12.000045776367188, 34.00001525878906],
          [-10.999984741210938, 34.00001525878906],
          [-9.000015258789062, 34.00001525878906],
          [-8.000030517578125, 34.00001525878906],
          [-7.0000457763671875, 34.00001525878906],
          [-5.9999847412109375, 34.00001525878906],
          [-5, 34.00001525878906],
          [-4.0000152587890625, 34.00001525878906],
          [-3.000030517578125, 34.00001525878906],
          [-2.0000457763671875, 34.00001525878906],
          [-0.9999847412109375, 34.00001525878906],
          [0, 34.00001525878906],
          [0.9999847412109375, 34.00001525878906],
          [1.999969482421875, 34.00001525878906],
          [2.9999542236328125, 34.00001525878906],
          [4.0000152587890625, 34.00001525878906],
          [5, 34.00001525878906],
          [5.9999847412109375, 34.00001525878906],
          [6.999969482421875, 34.00001525878906],
          [7.9999542236328125, 34.00001525878906],
          [9.000015258789062, 34.00001525878906],
          [10, 34.00001525878906],
          [10, 35],
          [10, 36.000022888183594],
          [10, 37.00000762939453],
          [10, 37.99999237060547],
          [10, 39.00001525878906],
          [10, 40],
          [10, 41.000022888183594],
          [7.9999542236328125, 41.000022888183594],
          [6.999969482421875, 42.00000762939453],
          [5.9999847412109375, 42.00000762939453],
          [4.0000152587890625, 42.99999237060547],
          [2.9999542236328125, 45],
          [0.9999847412109375, 46.000022888183594],
          [-0.9999847412109375, 47.00000762939453],
          [-3.000030517578125, 47.00000762939453],
          [-3.000030517578125, 47.99999237060547],
          [-5, 49.00001525878906],
          [-5, 50],
          [-5.9999847412109375, 50],
          [-5.9999847412109375, 51.000022888183594],
          [-5.9999847412109375, 52.00000762939453],
          [-5.9999847412109375, 52.99999237060547],
          [-5.9999847412109375, 54.00001525878906],
          [-5.9999847412109375, 55],
          [-5.9999847412109375, 56.000022888183594],
          [-5, 57.00000762939453],
          [-4.0000152587890625, 57.00000762939453],
          [-4.0000152587890625, 57.99999237060547],
          [-3.000030517578125, 59.00001525878906],
          [-2.0000457763671875, 60],
          [-0.9999847412109375, 60],
          [1.999969482421875, 61.000022888183594],
          [5, 62.00000762939453],
          [7.9999542236328125, 62.99999237060547],
          [10, 64.00001525878906],
          [12.999954223632812, 64.00001525878906],
          [15, 65],
          [16.999969482421875, 65],
          [17.999954223632812, 65],
          [19.000015258789062, 65],
          [20, 65],
          [20.999984741210938, 66.0000228881836],
          [20.999984741210938, 67.00000762939453],
          [20.999984741210938, 67.99999237060547],
          [20.999984741210938, 69.00001525878906],
          [20, 70],
          [19.000015258789062, 71.0000228881836],
          [16.999969482421875, 71.0000228881836],
          [15.999984741210938, 72.00000762939453],
          [15, 72.00000762939453],
          [12.999954223632812, 72.99999237060547],
          [11.999969482421875, 72.99999237060547],
          [10.999984741210938, 74.00001525878906],
          [10, 74.00001525878906],
          [9.000015258789062, 74.00001525878906],
          [7.9999542236328125, 75],
          [6.999969482421875, 76.0000228881836],
          [5.9999847412109375, 76.0000228881836],
          [5, 77.00000762939453],
          [4.0000152587890625, 77.99999237060547],
          [2.9999542236328125, 79.00001525878906],
          [1.999969482421875, 80],
          [0.9999847412109375, 80],
          [0.9999847412109375, 81.0000228881836],
          [0, 81.0000228881836],
          [-0.9999847412109375, 82.00000762939453],
          [-2.0000457763671875, 82.99999237060547],
          [-3.000030517578125, 84.00001525878906],
          [-4.0000152587890625, 84.00001525878906],
          [-5, 85],
          [-5.9999847412109375, 85],
          [-8.000030517578125, 85],
          [-9.000015258789062, 85],
          [-10, 85],
          [-10.999984741210938, 85],
          [-12.000045776367188, 85],
          [-13.000030517578125, 85],
          [-14.000015258789062, 86.0000228881836],
          [-14.000015258789062, 87.00000762939453],
          [-15, 87.00000762939453],
          [-15, 87.99999237060547],
          [-15.999984741210938, 90],
          [-15.999984741210938, 91.0000228881836],
          [-15.999984741210938, 92.00000762939453],
          [-17.000045776367188, 92.00000762939453],
          [-17.000045776367188, 92.99999237060547],
          [-17.000045776367188, 94.00001525878906],
          [-17.000045776367188, 95],
          [-17.000045776367188, 96.0000228881836],
          [-17.000045776367188, 97.00000762939453],
          [-17.000045776367188, 99.00001525878906],
          [-15, 100],
          [-13.000030517578125, 101.0000228881836],
          [-10.999984741210938, 102.00000762939453],
          [-8.000030517578125, 102.99999237060547],
          [-5, 104.00001525878906],
          [-2.0000457763671875, 105],
          [0.9999847412109375, 105],
          [4.0000152587890625, 105],
          [6.999969482421875, 105],
          [7.9999542236328125, 105],
          [10, 105],
          [10, 106.0000228881836],
          [7.9999542236328125, 107.00000762939453],
          [6.999969482421875, 107.99999237060547],
          [6.999969482421875, 109.00001525878906],
          [5, 110],
          [4.0000152587890625, 111.0000228881836],
          [4.0000152587890625, 112.00000762939453],
          [1.999969482421875, 112.99999237060547],
          [0.9999847412109375, 114.00001525878906],
          [0, 115],
          [-2.0000457763671875, 115],
          [-4.0000152587890625, 115],
          [-5, 115],
          [-7.0000457763671875, 115],
          [-9.000015258789062, 115],
          [-12.000045776367188, 115],
          [-15, 115],
          [-17.000045776367188, 115],
          [-20.999984741210938, 115],
          [-23.000030517578125, 114.00001525878906],
          [-24.000015258789062, 114.00001525878906],
          [-25, 114.00001525878906],
          [-25.999984741210938, 114.00001525878906],
          [-25.999984741210938, 115],
          [-25.999984741210938, 116.0000228881836],
          [-25.999984741210938, 117.99999237060547],
          [-25.999984741210938, 119.00001525878906],
          [-25.999984741210938, 120],
          [-25.999984741210938, 122.00008392333984],
          [-25.999984741210938, 124.00001525878906],
          [-25.999984741210938, 125],
          [-25.999984741210938, 126.0000228881836],
          [-25.999984741210938, 127.00008392333984],
          [-25.999984741210938, 129.00001525878906],
          [-25.999984741210938, 130],
          [-25, 131.0000228881836],
          [-24.000015258789062, 132.00008392333984],
          [-22.000045776367188, 132.99999237060547],
          [-20, 135],
          [-20, 136.0000228881836],
          [-18.000030517578125, 137.00008392333984],
          [-17.000045776367188, 137.00008392333984],
          [-15.999984741210938, 137.99999237060547],
          [-15, 137.99999237060547],
          [-14.000015258789062, 139.00001525878906],
          [-13.000030517578125, 140],
          [-12.000045776367188, 141.0000228881836],
          [-10.999984741210938, 142.00008392333984],
          [-10, 142.00008392333984],
          [-9.000015258789062, 142.99999237060547],
          [-9.000015258789062, 144.00001525878906],
          [-9.000015258789062, 145],
          [-9.000015258789062, 146.0000228881836],
          [-9.000015258789062, 147.00008392333984],
          [-9.000015258789062, 147.99999237060547],
          [-9.000015258789062, 149.00001525878906],
          [-10, 150],
          [-10, 151.0000228881836],
          [-10, 152.00008392333984],
          [-12.000045776367188, 154.00001525878906],
          [-14.000015258789062, 155],
          [-15, 157.00008392333984],
          [-15, 159.00001525878906],
          [-15, 160],
          [-15.999984741210938, 161.0000228881836],
          [-17.000045776367188, 162.00008392333984],
          [-18.000030517578125, 162.00008392333984],
          [-19.000015258789062, 162.00008392333984],
          [-20.999984741210938, 161.0000228881836],
          [-23.000030517578125, 159.00001525878906],
          [-24.000015258789062, 157.99999237060547],
          [-25.999984741210938, 157.99999237060547],
          [-28.000030517578125, 157.99999237060547],
          [-29.000015258789062, 157.99999237060547],
          [-30, 157.99999237060547],
          [-32.00004577636719, 157.99999237060547],
          [-34.00001525878906, 157.00008392333984],
          [-35.99998474121094, 157.00008392333984],
          [-37.00004577636719, 156.0000228881836],
          [-38.000030517578125, 155],
          [-40.99998474121094, 154.00001525878906],
          [-43.000030517578125, 152.99999237060547],
          [-45, 152.00008392333984],
          [-47.00004577636719, 151.0000228881836],
          [-49.00001525878906, 151.0000228881836],
          [-50, 150],
          [-50.99998474121094, 150],
          [-52.00004577636719, 150],
          [-53.000030517578125, 150],
          [-54.00001525878906, 150],
          [-55, 150],
          [-57.00004577636719, 151.0000228881836],
          [-59.00001525878906, 151.0000228881836],
          [-60, 151.0000228881836],
          [-62.00004577636719, 151.0000228881836],
          [-65, 152.00008392333984],
          [-67.00004577636719, 152.00008392333984],
          [-69.00001525878906, 152.00008392333984],
          [-70.99998474121094, 152.00008392333984],
          [-73.00003051757812, 152.00008392333984],
          [-74.00001525878906, 152.00008392333984],
          [-75.99998474121094, 152.00008392333984],
          [-79.00001525878906, 152.00008392333984],
          [-80.99998474121094, 151.0000228881836],
          [-84.00001525878906, 151.0000228881836],
          [-87.00004577636719, 150],
          [-88.00003051757812, 150],
          [-90, 150],
          [-92.00004577636719, 150],
          [-92.00004577636719, 147.99999237060547],
          [-92.00004577636719, 147.00008392333984],
          [-92.00004577636719, 146.0000228881836],
          [-92.00004577636719, 145],
          [-92.00004577636719, 144.00001525878906],
          [-92.00004577636719, 142.99999237060547],
          [-92.00004577636719, 142.00008392333984],
          [-92.00004577636719, 142.99999237060547],
          [-92.00004577636719, 144.00001525878906],
          [-92.00004577636719, 145],
          [-92.00004577636719, 147.00008392333984],
          [-92.00004577636719, 147.99999237060547],
          [-92.00004577636719, 150],
          [-92.00004577636719, 151.0000228881836],
          [-92.00004577636719, 152.00008392333984],
          [-92.00004577636719, 152.99999237060547],
          [-92.00004577636719, 155],
          [-90.99998474121094, 156.0000228881836],
          [-90, 157.00008392333984],
          [-89.00001525878906, 159.00001525878906],
          [-88.00003051757812, 160],
          [-85.99998474121094, 161.0000228881836],
          [-84.00001525878906, 162.00008392333984],
          [-83.00003051757812, 162.99999237060547],
          [-80, 164.00001525878906],
          [-77.00004577636719, 165],
          [-74.00001525878906, 165],
          [-73.00003051757812, 165],
          [-69.00001525878906, 167.00008392333984],
          [-68.00003051757812, 167.00008392333984],
          [-65.99998474121094, 167.00008392333984],
          [-65, 167.00008392333984],
          [-63.000030517578125, 167.00008392333984],
          [-62.00004577636719, 167.99999237060547],
          [-60.99998474121094, 167.99999237060547],
          [-60, 167.99999237060547],
          [-59.00001525878906, 169.00001525878906],
          [-58.000030517578125, 170],
          [-57.00004577636719, 171.0000228881836],
          [-57.00004577636719, 172.00008392333984],
          [-55.99998474121094, 172.99999237060547],
          [-55, 172.99999237060547],
          [-55, 175],
          [-54.00001525878906, 176.0000228881836],
          [-52.00004577636719, 177.00008392333984],
          [-50.99998474121094, 177.99999237060547],
          [-50.99998474121094, 179.00001525878906],
          [-49.00001525878906, 179.00001525878906],
          [-48.000030517578125, 180],
          [-45.99998474121094, 181.0000228881836],
          [-44.00001525878906, 182.00008392333984],
          [-42.00004577636719, 182.99999237060547],
          [-40.99998474121094, 184.00001525878906],
          [-40, 184.00001525878906],
          [-39.00001525878906, 185],
          [-39.00001525878906, 186.0000228881836],
          [-39.00001525878906, 187.00008392333984],
          [-39.00001525878906, 187.99999237060547],
          [-39.00001525878906, 189.00001525878906],
          [-40, 189.00001525878906],
          [-40.99998474121094, 189.00001525878906],
          [-42.00004577636719, 189.00001525878906],
          [-43.000030517578125, 189.00001525878906],
          [-44.00001525878906, 190],
          [-45, 190],
          [-45.99998474121094, 191.0000228881836],
          [-47.00004577636719, 191.0000228881836],
          [-49.00001525878906, 192.00008392333984],
          [-50.99998474121094, 192.00008392333984],
          [-50.99998474121094, 192.99999237060547],
          [-50.99998474121094, 194.00001525878906],
          [-50.99998474121094, 195],
          [-50.99998474121094, 196.0000228881836],
          [-50.99998474121094, 197.00008392333984],
          [-50.99998474121094, 197.99999237060547],
          [-50.99998474121094, 199.00001525878906],
          [-53.000030517578125, 200],
          [-53.000030517578125, 201.0000228881836],
          [-54.00001525878906, 202.99999237060547],
          [-54.00001525878906, 204.00001525878906],
          [-55.99998474121094, 205],
          [-55.99998474121094, 207.00008392333984],
          [-55.99998474121094, 207.99999237060547],
          [-55.99998474121094, 209.00001525878906],
          [-55.99998474121094, 210],
          [-57.00004577636719, 211.0000228881836],
          [-58.000030517578125, 211.0000228881836],
          [-59.00001525878906, 211.0000228881836],
          [-60, 211.0000228881836],
          [-60.99998474121094, 211.0000228881836],
          [-63.000030517578125, 211.0000228881836],
          [-64.00001525878906, 211.0000228881836],
          [-67.00004577636719, 211.0000228881836],
          [-70, 209.00001525878906],
          [-72.00004577636719, 207.99999237060547],
          [-73.00003051757812, 207.00008392333984],
          [-74.00001525878906, 207.00008392333984],
          [-75, 207.00008392333984],
          [-75.99998474121094, 207.99999237060547],
          [-75.99998474121094, 209.00001525878906],
          [-75.99998474121094, 210],
          [-75.99998474121094, 211.0000228881836],
          [-75.99998474121094, 212.0000457763672],
          [-75.99998474121094, 213.00003051757812],
          [-75.99998474121094, 214.00001525878906],
          [-77.00004577636719, 214.00001525878906],
          [-78.00003051757812, 214.00001525878906],
          [-79.00001525878906, 214.00001525878906],
          [-80, 214.00001525878906],
          [-80.99998474121094, 214.00001525878906],
          [-80.99998474121094, 213.00003051757812],
          [-80.99998474121094, 211.0000228881836],
          [-83.00003051757812, 211.0000228881836],
          [-83.00003051757812, 210],
          [-83.00003051757812, 209.00001525878906],
          [-84.00001525878906, 207.99999237060547],
          [-85, 207.00008392333984],
          [-85.99998474121094, 207.00008392333984],
          [-87.00004577636719, 207.00008392333984],
          [-88.00003051757812, 207.00008392333984],
          [-89.00001525878906, 206.0000228881836],
          [-90, 206.0000228881836],
          [-90.99998474121094, 206.0000228881836],
          [-93.00003051757812, 206.0000228881836],
          [-95.99998474121094, 206.0000228881836],
          [-98.00003051757812, 206.0000228881836],
          [-100.99998474121094, 206.0000228881836],
          [-103.00003051757812, 206.0000228881836],
          [-104.00001525878906, 206.0000228881836],
          [-105.99998474121094, 206.0000228881836],
          [-107.00004577636719, 206.0000228881836],
          [-109.00001525878906, 206.0000228881836],
          [-110, 206.0000228881836],
          [-110.99998474121094, 206.0000228881836],
          [-112.00004577636719, 206.0000228881836],
          [-113.00003051757812, 206.0000228881836],
          [-114.00001525878906, 206.0000228881836],
          [-115, 206.0000228881836],
          [-115.99998474121094, 206.0000228881836],
          [-115.99998474121094, 207.00008392333984],
          [-115.99998474121094, 210],
          [-115, 210],
          [-114.00001525878906, 211.0000228881836],
          [-113.00003051757812, 212.0000457763672],
          [-113.00003051757812, 213.00003051757812],
          [-112.00004577636719, 214.00001525878906],
          [-110.99998474121094, 214.00001525878906],
          [-110.99998474121094, 215],
          [-110, 215],
          [-108.00003051757812, 215.99998474121094],
          [-107.00004577636719, 217.0000457763672],
          [-105, 218.00003051757812],
          [-103.00003051757812, 219.00001525878906],
          [-100.99998474121094, 220],
          [-100, 220],
          [-99.00001525878906, 220],
          [-98.00003051757812, 220],
          [-97.00004577636719, 220],
          [-95.99998474121094, 220],
          [-95, 220.99998474121094],
          [-95, 222.0000457763672],
          [-94.00001525878906, 222.0000457763672],
          [-92.00004577636719, 223.00003051757812],
          [-90.99998474121094, 225],
          [-89.00001525878906, 227.0000457763672],
          [-88.00003051757812, 227.0000457763672],
          [-88.00003051757812, 228.00003051757812],
          [-85, 230.99998474121094],
          [-84.00001525878906, 233.00003051757812],
          [-83.00003051757812, 235],
          [-82.00004577636719, 235.99998474121094],
          [-80.99998474121094, 235.99998474121094],
          [-80, 237.0000457763672],
          [-80, 239.00001525878906],
          [-80, 240],
          [-79.00001525878906, 242.0000457763672],
          [-78.00003051757812, 243.00003051757812],
          [-78.00003051757812, 244.00001525878906],
          [-78.00003051757812, 245],
          [-79.00001525878906, 245],
          [-80.99998474121094, 245],
          [-84.00001525878906, 245],
          [-85, 245],
          [-87.00004577636719, 245],
          [-88.00003051757812, 245],
          [-94.00001525878906, 245],
          [-97.00004577636719, 245],
          [-99.00001525878906, 245],
          [-99.00001525878906, 245.99998474121094],
          [-100, 247.0000457763672],
          [-100.99998474121094, 248.00003051757812],
          [-100.99998474121094, 249.00001525878906],
          [-100.99998474121094, 250],
          [-100.99998474121094, 252.0000457763672],
          [-100.99998474121094, 253.00003051757812],
          [-100.99998474121094, 254.00001525878906],
          [-100.99998474121094, 255],
          [-100.99998474121094, 255.99998474121094],
          [-100.99998474121094, 257.0000457763672],
          [-102.00004577636719, 258.0000305175781],
          [-103.00003051757812, 259.00001525878906],
          [-103.00003051757812, 260],
          [-104.00001525878906, 260],
          [-104.00001525878906, 260.99998474121094],
          [-105, 262.0000457763672],
          [-105.99998474121094, 262.0000457763672],
          [-107.00004577636719, 262.0000457763672],
          [-108.00003051757812, 262.0000457763672],
          [-109.00001525878906, 262.0000457763672],
          [-110.99998474121094, 260.99998474121094],
          [-113.00003051757812, 260],
          [-115, 259.00001525878906],
          [-115.99998474121094, 258.0000305175781],
          [-117.00004577636719, 258.0000305175781],
          [-118.00003051757812, 257.0000457763672],
          [-119.00001525878906, 257.0000457763672],
          [-120, 255.99998474121094],
          [-122.00004577636719, 255.99998474121094],
          [-123.00003051757812, 255],
          [-124.00001525878906, 255],
          [-125, 255.99998474121094],
          [-125, 257.0000457763672],
          [-125, 258.0000305175781],
          [-125.99998474121094, 259.00001525878906],
          [-125.99998474121094, 260],
          [-125.99998474121094, 260.99998474121094],
          [-125.99998474121094, 263.0000305175781],
          [-127.00004577636719, 264.00001525878906],
          [-127.00004577636719, 265],
          [-127.00004577636719, 265.99998474121094],
          [-127.00004577636719, 267.0000457763672],
          [-127.00004577636719, 268.0000305175781],
          [-127.00004577636719, 269.00001525878906],
          [-127.00004577636719, 270.99998474121094],
          [-127.00004577636719, 272.0000457763672],
          [-127.00004577636719, 273.0000305175781],
          [-127.00004577636719, 275],
          [-127.00004577636719, 275.99998474121094],
          [-127.00004577636719, 277.0000457763672],
          [-127.00004577636719, 278.0000305175781],
          [-127.00004577636719, 279.00001525878906],
          [-128.00003051757812, 279.00001525878906],
          [-129.00001525878906, 279.00001525878906],
          [-130.99998474121094, 277.0000457763672],
          [-133.00003051757812, 275],
          [-135, 273.0000305175781],
          [-135.99998474121094, 270.99998474121094],
          [-138.00003051757812, 269.00001525878906],
          [-139.00001525878906, 269.00001525878906],
          [-140, 269.00001525878906],
          [-142.0000457763672, 269.00001525878906],
          [-143.00003051757812, 269.00001525878906],
          [-144.00001525878906, 270],
          [-145, 270],
          [-145, 270.99998474121094],
          [-145, 272.0000457763672],
          [-145, 273.0000305175781],
          [-145.99998474121094, 274.00001525878906],
          [-145.99998474121094, 275],
          [-147.0000457763672, 277.0000457763672],
          [-147.0000457763672, 278.0000305175781],
          [-148.00003051757812, 278.0000305175781],
          [-148.00003051757812, 279.00001525878906],
          [-148.00003051757812, 280],
          [-148.00003051757812, 280.99998474121094],
          [-148.00003051757812, 282.0000457763672],
          [-148.00003051757812, 283.0000305175781],
          [-148.00003051757812, 285],
          [-148.00003051757812, 285.99998474121094],
          [-148.00003051757812, 287.0000457763672],
          [-148.00003051757812, 288.0000305175781],
          [-148.00003051757812, 289.00001525878906],
          [-148.00003051757812, 290],
          [-148.00003051757812, 292.0000457763672],
          [-150, 292.0000457763672],
          [-152.0000457763672, 292.0000457763672],
          [-154.00001525878906, 290],
          [-157.0000457763672, 287.0000457763672],
          [-159.00001525878906, 285.99998474121094],
          [-162.0000457763672, 284.00001525878906],
          [-164.00001525878906, 282.0000457763672],
          [-165, 280],
          [-165.99998474121094, 279.00001525878906],
          [-167.0000457763672, 279.00001525878906],
          [-168.00003051757812, 279.00001525878906],
          [-169.00001525878906, 279.00001525878906],
          [-170, 279.00001525878906],
          [-170.99998474121094, 279.00001525878906],
          [-172.0000457763672, 279.00001525878906],
          [-173.00003051757812, 279.00001525878906],
          [-174.00001525878906, 279.00001525878906],
          [-175, 279.00001525878906],
          [-175.99998474121094, 279.00001525878906],
          [-177.0000457763672, 279.00001525878906],
          [-178.00003051757812, 279.00001525878906],
          [-179.00001525878906, 279.00001525878906],
          [-180, 279.00001525878906],
          [-180.99998474121094, 279.00001525878906],
          [-182.0000457763672, 279.00001525878906],
          [-183.00003051757812, 279.00001525878906],
          [-184.00001525878906, 278.0000305175781],
          [-185, 277.0000457763672],
          [-185.99998474121094, 277.0000457763672],
          [-187.0000457763672, 277.0000457763672],
          [-188.00003051757812, 277.0000457763672],
          [-188.00003051757812, 278.0000305175781],
          [-188.00003051757812, 279.00001525878906],
          [-188.00003051757812, 280],
          [-188.00003051757812, 280.99998474121094],
          [-188.00003051757812, 282.0000457763672],
          [-188.00003051757812, 283.0000305175781],
          [-188.00003051757812, 284.00001525878906],
          [-188.00003051757812, 285],
          [-188.00003051757812, 285.99998474121094],
          [-188.00003051757812, 287.0000457763672],
          [-188.00003051757812, 288.0000305175781],
          [-188.00003051757812, 289.00001525878906],
          [-188.00003051757812, 290],
          [-188.00003051757812, 290.99998474121094],
          [-188.00003051757812, 292.0000457763672],
          [-189.00001525878906, 293.0000305175781],
          [-190, 294.00001525878906],
          [-190.99998474121094, 294.00001525878906],
          [-190.99998474121094, 294.00001525878906],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 692,
        versionNonce: 1698386585,
        isDeleted: false,
        id: "WhMyw_2wYvpbR9yzTlSwh",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -945.5444159885595,
        y: -1635.218971331636,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 314.00005340576183,
        height: 260.99998474121094,
        seed: 1719809751,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [-1.00006103515625, 0],
          [-3.000030517578125, -0.9999847412109375],
          [-7.0000457763671875, -3.000030517578125],
          [-9.000015258789062, -5],
          [-10, -5],
          [-10, -5.9999847412109375],
          [-11.00006103515625, -6.999969482421875],
          [-12.000045776367188, -6.999969482421875],
          [-13.000030517578125, -6.999969482421875],
          [-15, -8.000030517578125],
          [-16.00006103515625, -8.000030517578125],
          [-17.000045776367188, -8.000030517578125],
          [-18.000030517578125, -8.000030517578125],
          [-19.000015258789062, -8.000030517578125],
          [-21.00006103515625, -10],
          [-22.000045776367188, -10],
          [-22.000045776367188, -10.999984741210938],
          [-23.000030517578125, -10.999984741210938],
          [-23.000030517578125, -11.999969482421875],
          [-23.000030517578125, -13.000030517578125],
          [-23.000030517578125, -15],
          [-23.000030517578125, -15.999984741210938],
          [-23.000030517578125, -16.999969482421875],
          [-24.000015258789062, -16.999969482421875],
          [-25, -16.999969482421875],
          [-26.00006103515625, -16.999969482421875],
          [-27.000045776367188, -16.999969482421875],
          [-28.000030517578125, -16.999969482421875],
          [-29.000015258789062, -16.999969482421875],
          [-30, -15.999984741210938],
          [-30, -15],
          [-30, -14.000015258789062],
          [-30, -13.000030517578125],
          [-31.00006103515625, -13.000030517578125],
          [-31.00006103515625, -11.999969482421875],
          [-31.00006103515625, -10.999984741210938],
          [-32.00004577636719, -10.999984741210938],
          [-32.00004577636719, -10],
          [-33.000030517578125, -9.000015258789062],
          [-33.000030517578125, -8.000030517578125],
          [-34.00001525878906, -8.000030517578125],
          [-35, -8.000030517578125],
          [-35, -6.999969482421875],
          [-36.00006103515625, -5.9999847412109375],
          [-37.00004577636719, -5.9999847412109375],
          [-38.000030517578125, -5],
          [-39.00001525878906, -5],
          [-40, -5],
          [-41.00006103515625, -5],
          [-43.000030517578125, -4.0000152587890625],
          [-44.00001525878906, -4.0000152587890625],
          [-46.00006103515625, -4.0000152587890625],
          [-49.00001525878906, -4.0000152587890625],
          [-50.000000000000114, -4.0000152587890625],
          [-50.000000000000114, -5],
          [-50.000000000000114, -5.9999847412109375],
          [-50.000000000000114, -6.999969482421875],
          [-52.0000457763673, -6.999969482421875],
          [-53.00003051757824, -8.000030517578125],
          [-53.00003051757824, -10],
          [-54.000015258789176, -10],
          [-56.000061035156364, -10.999984741210938],
          [-56.000061035156364, -11.999969482421875],
          [-57.0000457763673, -11.999969482421875],
          [-58.00003051757824, -11.999969482421875],
          [-57.0000457763673, -10.999984741210938],
          [-56.000061035156364, -10],
          [-55.000000000000114, -9.000015258789062],
          [-54.000015258789176, -9.000015258789062],
          [-54.000015258789176, -8.000030517578125],
          [-53.00003051757824, -6.999969482421875],
          [-52.0000457763673, -6.999969482421875],
          [-52.0000457763673, -5],
          [-51.000061035156364, -3.000030517578125],
          [-49.00001525878906, -0.9999847412109375],
          [-48.000030517578125, 0],
          [-47.00004577636719, 1.999969482421875],
          [-46.00006103515625, 4.0000152587890625],
          [-45, 5],
          [-45, 6.999969482421875],
          [-45, 8.000030517578125],
          [-45, 9.000015258789062],
          [-44.00001525878906, 9.000015258789062],
          [-44.00001525878906, 10],
          [-43.000030517578125, 10.999984741210938],
          [-43.000030517578125, 11.999969482421875],
          [-43.000030517578125, 13.000030517578125],
          [-44.00001525878906, 14.000015258789062],
          [-46.00006103515625, 15],
          [-47.00004577636719, 15.999984741210938],
          [-48.000030517578125, 15.999984741210938],
          [-50.000000000000114, 15.999984741210938],
          [-51.000061035156364, 15.999984741210938],
          [-53.00003051757824, 16.999969482421875],
          [-54.000015258789176, 16.999969482421875],
          [-56.000061035156364, 16.999969482421875],
          [-57.0000457763673, 16.999969482421875],
          [-58.00003051757824, 16.999969482421875],
          [-59.000015258789176, 16.999969482421875],
          [-60.000000000000114, 16.999969482421875],
          [-61.000061035156364, 16.999969482421875],
          [-62.0000457763673, 16.999969482421875],
          [-63.00003051757824, 16.999969482421875],
          [-64.00001525878918, 16.999969482421875],
          [-65.00000000000011, 16.999969482421875],
          [-66.00006103515636, 16.999969482421875],
          [-67.0000457763673, 16.999969482421875],
          [-68.00003051757824, 16.999969482421875],
          [-71.00006103515636, 16.999969482421875],
          [-73.00003051757824, 15.999984741210938],
          [-75.00000000000011, 14.000015258789062],
          [-76.00006103515636, 13.000030517578125],
          [-78.00003051757824, 10.999984741210938],
          [-80.00000000000011, 10],
          [-81.00006103515636, 9.000015258789062],
          [-81.00006103515636, 6.999969482421875],
          [-82.0000457763673, 6.999969482421875],
          [-82.0000457763673, 5.9999847412109375],
          [-82.0000457763673, 5],
          [-82.0000457763673, 3.000030517578125],
          [-82.0000457763673, 0],
          [-82.0000457763673, -3.000030517578125],
          [-82.0000457763673, -5],
          [-80.00000000000011, -6.999969482421875],
          [-79.00001525878918, -9.000015258789062],
          [-78.00003051757824, -10],
          [-76.00006103515636, -11.999969482421875],
          [-74.00001525878918, -13.000030517578125],
          [-73.00003051757824, -14.000015258789062],
          [-72.0000457763673, -14.000015258789062],
          [-71.00006103515636, -14.000015258789062],
          [-71.00006103515636, -15],
          [-70.00000000000011, -15.999984741210938],
          [-69.00001525878918, -15.999984741210938],
          [-69.00001525878918, -16.999969482421875],
          [-69.00001525878918, -18.000030517578125],
          [-69.00001525878918, -19.000015258789062],
          [-70.00000000000011, -19.000015258789062],
          [-71.00006103515636, -19.000015258789062],
          [-72.0000457763673, -19.000015258789062],
          [-73.00003051757824, -20],
          [-74.00001525878918, -20.999984741210938],
          [-75.00000000000011, -20.999984741210938],
          [-75.00000000000011, -21.999969482421875],
          [-76.00006103515636, -24.000015258789062],
          [-76.00006103515636, -25],
          [-77.0000457763673, -26.999969482421875],
          [-77.0000457763673, -28.000030517578125],
          [-77.0000457763673, -29.000015258789062],
          [-77.0000457763673, -30],
          [-77.0000457763673, -31.999969482421875],
          [-77.0000457763673, -34.00001525878906],
          [-77.0000457763673, -35],
          [-77.0000457763673, -35.99998474121094],
          [-77.0000457763673, -36.999969482421875],
          [-78.00003051757824, -38.000030517578125],
          [-79.00001525878918, -39.00001525878906],
          [-80.00000000000011, -39.00001525878906],
          [-81.00006103515636, -40],
          [-83.00003051757824, -40.99998474121094],
          [-85.00000000000011, -41.999969482421875],
          [-86.00006103515636, -43.000030517578125],
          [-87.0000457763673, -43.000030517578125],
          [-89.00001525878918, -43.000030517578125],
          [-91.00006103515636, -43.000030517578125],
          [-92.0000457763673, -43.000030517578125],
          [-93.00003051757824, -43.000030517578125],
          [-94.00005340576183, -43.000030517578125],
          [-95.00003814697277, -43.000030517578125],
          [-96.00002288818371, -43.000030517578125],
          [-97.0000457763673, -43.000030517578125],
          [-98.00003051757824, -43.000030517578125],
          [-98.00003051757824, -41.999969482421875],
          [-98.00003051757824, -40],
          [-98.00003051757824, -38.000030517578125],
          [-98.00003051757824, -35.99998474121094],
          [-98.00003051757824, -34.00001525878906],
          [-98.00003051757824, -31.999969482421875],
          [-98.00003051757824, -30],
          [-98.00003051757824, -29.000015258789062],
          [-97.0000457763673, -29.000015258789062],
          [-97.0000457763673, -28.000030517578125],
          [-97.0000457763673, -26.999969482421875],
          [-96.00002288818371, -26.999969482421875],
          [-95.00003814697277, -25.999984741210938],
          [-95.00003814697277, -25],
          [-94.00005340576183, -25],
          [-93.00003051757824, -24.000015258789062],
          [-92.0000457763673, -23.000030517578125],
          [-92.0000457763673, -21.999969482421875],
          [-92.0000457763673, -20.999984741210938],
          [-92.0000457763673, -20],
          [-92.0000457763673, -19.000015258789062],
          [-92.0000457763673, -18.000030517578125],
          [-92.0000457763673, -16.999969482421875],
          [-93.00003051757824, -15.999984741210938],
          [-95.00003814697277, -14.000015258789062],
          [-96.00002288818371, -13.000030517578125],
          [-97.0000457763673, -11.999969482421875],
          [-98.00003051757824, -10.999984741210938],
          [-99.00005340576183, -10],
          [-100.00003814697277, -9.000015258789062],
          [-101.00002288818371, -9.000015258789062],
          [-101.00002288818371, -8.000030517578125],
          [-101.00002288818371, -6.999969482421875],
          [-101.00002288818371, -5.9999847412109375],
          [-101.00002288818371, -5],
          [-101.00002288818371, -4.0000152587890625],
          [-100.00003814697277, -4.0000152587890625],
          [-99.00005340576183, -3.000030517578125],
          [-99.00005340576183, -1.999969482421875],
          [-98.00003051757824, -1.999969482421875],
          [-97.0000457763673, -0.9999847412109375],
          [-96.00002288818371, -0.9999847412109375],
          [-96.00002288818371, 0],
          [-95.00003814697277, 0.9999847412109375],
          [-95.00003814697277, 1.999969482421875],
          [-95.00003814697277, 3.000030517578125],
          [-95.00003814697277, 4.0000152587890625],
          [-95.00003814697277, 5],
          [-95.00003814697277, 5.9999847412109375],
          [-95.00003814697277, 6.999969482421875],
          [-95.00003814697277, 8.000030517578125],
          [-95.00003814697277, 9.000015258789062],
          [-95.00003814697277, 10],
          [-95.00003814697277, 10.999984741210938],
          [-96.00002288818371, 10.999984741210938],
          [-97.0000457763673, 11.999969482421875],
          [-98.00003051757824, 11.999969482421875],
          [-99.00005340576183, 11.999969482421875],
          [-100.00003814697277, 11.999969482421875],
          [-101.00002288818371, 11.999969482421875],
          [-102.0000457763673, 11.999969482421875],
          [-103.00003051757824, 11.999969482421875],
          [-104.00005340576183, 10.999984741210938],
          [-106.00002288818371, 9.000015258789062],
          [-107.0000457763673, 6.999969482421875],
          [-109.00005340576183, 6.999969482421875],
          [-111.00002288818371, 5],
          [-112.0000457763673, 4.0000152587890625],
          [-114.00005340576183, 3.000030517578125],
          [-115.00003814697277, 3.000030517578125],
          [-115.00003814697277, 1.999969482421875],
          [-116.00002288818371, 1.999969482421875],
          [-117.0000457763673, 1.999969482421875],
          [-118.00003051757824, 0.9999847412109375],
          [-119.00005340576183, 0],
          [-120.00003814697277, 0],
          [-121.00002288818371, 0],
          [-123.00003051757824, 0],
          [-124.00005340576183, 0],
          [-126.00002288818371, 0],
          [-126.00002288818371, -0.9999847412109375],
          [-127.0000457763673, -1.999969482421875],
          [-128.00003051757824, -1.999969482421875],
          [-131.0000228881837, -1.999969482421875],
          [-132.0000457763673, -1.999969482421875],
          [-133.00003051757824, -1.999969482421875],
          [-134.00005340576183, -1.999969482421875],
          [-135.00003814697277, -1.999969482421875],
          [-136.0000228881837, -1.999969482421875],
          [-137.0000457763673, -1.999969482421875],
          [-138.00003051757824, -1.999969482421875],
          [-139.00005340576183, -1.999969482421875],
          [-141.0000228881837, -1.999969482421875],
          [-142.0000457763673, -0.9999847412109375],
          [-142.0000457763673, 0],
          [-143.00003051757824, 0],
          [-144.00005340576183, 0.9999847412109375],
          [-144.00005340576183, 1.999969482421875],
          [-145.00003814697277, 1.999969482421875],
          [-145.00003814697277, 3.000030517578125],
          [-145.00003814697277, 4.0000152587890625],
          [-146.0000228881837, 4.0000152587890625],
          [-147.0000457763673, 5],
          [-149.00005340576183, 5],
          [-150.00003814697277, 5],
          [-151.0000228881837, 5],
          [-153.00003051757824, 4.0000152587890625],
          [-155.00003814697277, 1.999969482421875],
          [-156.0000228881837, 0],
          [-158.00003051757824, -1.999969482421875],
          [-161.0000228881837, -4.0000152587890625],
          [-164.00005340576183, -6.999969482421875],
          [-167.0000457763673, -10],
          [-169.00005340576183, -13.000030517578125],
          [-171.0000228881837, -15.999984741210938],
          [-171.0000228881837, -18.000030517578125],
          [-171.0000228881837, -19.000015258789062],
          [-171.0000228881837, -20],
          [-171.0000228881837, -20.999984741210938],
          [-171.0000228881837, -21.999969482421875],
          [-171.0000228881837, -23.000030517578125],
          [-170.00003814697277, -24.000015258789062],
          [-169.00005340576183, -25],
          [-167.0000457763673, -25.999984741210938],
          [-165.00003814697277, -25.999984741210938],
          [-162.0000457763673, -26.999969482421875],
          [-161.0000228881837, -28.000030517578125],
          [-160.00003814697277, -29.000015258789062],
          [-159.00005340576183, -29.000015258789062],
          [-158.00003051757824, -29.000015258789062],
          [-157.0000457763673, -29.000015258789062],
          [-156.0000228881837, -29.000015258789062],
          [-155.00003814697277, -29.000015258789062],
          [-154.00005340576183, -29.000015258789062],
          [-153.00003051757824, -30],
          [-152.0000457763673, -30.999984741210938],
          [-151.0000228881837, -30.999984741210938],
          [-150.00003814697277, -31.999969482421875],
          [-149.00005340576183, -31.999969482421875],
          [-148.00003051757824, -33.000030517578125],
          [-148.00003051757824, -34.00001525878906],
          [-148.00003051757824, -35],
          [-147.0000457763673, -35.99998474121094],
          [-147.0000457763673, -36.999969482421875],
          [-147.0000457763673, -38.000030517578125],
          [-147.0000457763673, -39.00001525878906],
          [-147.0000457763673, -40],
          [-147.0000457763673, -40.99998474121094],
          [-147.0000457763673, -41.999969482421875],
          [-147.0000457763673, -43.000030517578125],
          [-147.0000457763673, -44.00001525878906],
          [-147.0000457763673, -45.99998474121094],
          [-147.0000457763673, -46.999969482421875],
          [-147.0000457763673, -48.000030517578125],
          [-148.00003051757824, -49.00001525878906],
          [-149.00005340576183, -50],
          [-149.00005340576183, -50.99998474121094],
          [-150.00003814697277, -51.999969482421875],
          [-151.0000228881837, -51.999969482421875],
          [-152.0000457763673, -51.999969482421875],
          [-153.00003051757824, -51.999969482421875],
          [-155.00003814697277, -51.999969482421875],
          [-155.00003814697277, -50.99998474121094],
          [-157.0000457763673, -50],
          [-158.00003051757824, -49.00001525878906],
          [-160.00003814697277, -49.00001525878906],
          [-162.0000457763673, -48.000030517578125],
          [-163.00003051757824, -46.999969482421875],
          [-164.00005340576183, -45.99998474121094],
          [-165.00003814697277, -45.99998474121094],
          [-165.00003814697277, -45],
          [-165.00003814697277, -44.00001525878906],
          [-165.00003814697277, -43.000030517578125],
          [-166.0000228881837, -41.999969482421875],
          [-166.0000228881837, -40.99998474121094],
          [-166.0000228881837, -40],
          [-166.0000228881837, -39.00001525878906],
          [-166.0000228881837, -38.000030517578125],
          [-166.0000228881837, -35.99998474121094],
          [-167.0000457763673, -35],
          [-168.00003051757824, -35],
          [-169.00005340576183, -35],
          [-170.00003814697277, -35],
          [-171.0000228881837, -35],
          [-173.00003051757824, -35],
          [-174.00005340576183, -35],
          [-177.0000457763673, -35],
          [-181.0000228881837, -35],
          [-184.00005340576183, -35.99998474121094],
          [-186.0000228881837, -36.999969482421875],
          [-190.00003814697277, -38.000030517578125],
          [-193.00003051757824, -39.00001525878906],
          [-196.0000228881837, -39.00001525878906],
          [-197.0000457763673, -39.00001525878906],
          [-199.00005340576183, -40],
          [-200.00003814697277, -40],
          [-200.00003814697277, -40.99998474121094],
          [-201.0000228881837, -40.99998474121094],
          [-202.0000457763673, -40.99998474121094],
          [-202.0000457763673, -40],
          [-203.00003051757824, -39.00001525878906],
          [-203.00003051757824, -38.000030517578125],
          [-203.00003051757824, -36.999969482421875],
          [-203.00003051757824, -35.99998474121094],
          [-204.00005340576183, -35.99998474121094],
          [-205.00003814697277, -35.99998474121094],
          [-206.0000228881837, -36.999969482421875],
          [-208.00003051757824, -38.000030517578125],
          [-208.00003051757824, -39.00001525878906],
          [-208.00003051757824, -40],
          [-208.00003051757824, -40.99998474121094],
          [-208.00003051757824, -41.999969482421875],
          [-208.00003051757824, -43.000030517578125],
          [-209.00005340576183, -44.00001525878906],
          [-210.00003814697277, -45],
          [-210.00003814697277, -45.99998474121094],
          [-211.0000228881837, -46.999969482421875],
          [-211.0000228881837, -48.000030517578125],
          [-212.0000457763673, -48.000030517578125],
          [-213.00003051757824, -48.000030517578125],
          [-213.00003051757824, -49.00001525878906],
          [-213.00003051757824, -50],
          [-213.00003051757824, -50.99998474121094],
          [-213.00003051757824, -51.999969482421875],
          [-213.00003051757824, -53.000030517578125],
          [-213.00003051757824, -54.00001525878906],
          [-213.00003051757824, -55],
          [-213.00003051757824, -55.99998474121094],
          [-213.00003051757824, -58.000030517578125],
          [-213.00003051757824, -59.00001525878906],
          [-212.0000457763673, -60],
          [-212.0000457763673, -60.99998474121094],
          [-212.0000457763673, -61.999969482421875],
          [-212.0000457763673, -63.000030517578125],
          [-212.0000457763673, -64.00001525878906],
          [-212.0000457763673, -65],
          [-212.0000457763673, -65.99998474121094],
          [-212.0000457763673, -66.99996948242188],
          [-212.0000457763673, -68.00003051757812],
          [-212.0000457763673, -69.00001525878906],
          [-212.0000457763673, -70],
          [-212.0000457763673, -70.99998474121094],
          [-212.0000457763673, -71.99996948242188],
          [-213.00003051757824, -73.00003051757812],
          [-214.00005340576183, -74.00001525878906],
          [-216.0000228881837, -74.00001525878906],
          [-216.0000228881837, -75.99998474121094],
          [-218.00003051757824, -75.99998474121094],
          [-219.00005340576183, -75.99998474121094],
          [-220.00003814697277, -75.99998474121094],
          [-221.0000228881837, -76.99996948242188],
          [-223.00003051757824, -76.99996948242188],
          [-225.00003814697277, -78.00003051757812],
          [-227.0000457763673, -79.00001525878906],
          [-228.00003051757824, -80],
          [-230.00003814697277, -80],
          [-231.0000228881837, -80],
          [-231.0000228881837, -76.99996948242188],
          [-231.0000228881837, -75],
          [-231.0000228881837, -74.00001525878906],
          [-231.0000228881837, -73.00003051757812],
          [-231.0000228881837, -70.99998474121094],
          [-231.0000228881837, -70],
          [-231.0000228881837, -69.00001525878906],
          [-231.0000228881837, -68.00003051757812],
          [-231.0000228881837, -66.99996948242188],
          [-231.0000228881837, -65.99998474121094],
          [-231.0000228881837, -65],
          [-231.0000228881837, -64.00001525878906],
          [-231.0000228881837, -63.000030517578125],
          [-231.0000228881837, -61.999969482421875],
          [-230.00003814697277, -60.99998474121094],
          [-230.00003814697277, -60],
          [-230.00003814697277, -59.00001525878906],
          [-230.00003814697277, -58.000030517578125],
          [-230.00003814697277, -56.999969482421875],
          [-231.0000228881837, -56.999969482421875],
          [-232.0000457763673, -56.999969482421875],
          [-233.00003051757824, -56.999969482421875],
          [-234.00005340576183, -56.999969482421875],
          [-237.0000457763673, -59.00001525878906],
          [-238.00003051757824, -59.00001525878906],
          [-239.00005340576183, -60],
          [-240.00003814697277, -60.99998474121094],
          [-241.0000228881837, -61.999969482421875],
          [-241.0000228881837, -63.000030517578125],
          [-242.0000457763673, -64.00001525878906],
          [-242.0000457763673, -65],
          [-242.0000457763673, -65.99998474121094],
          [-242.0000457763673, -66.99996948242188],
          [-242.0000457763673, -68.00003051757812],
          [-242.0000457763673, -69.00001525878906],
          [-242.0000457763673, -70],
          [-242.0000457763673, -71.99996948242188],
          [-242.0000457763673, -74.00001525878906],
          [-242.0000457763673, -75.99998474121094],
          [-242.0000457763673, -76.99996948242188],
          [-241.0000228881837, -76.99996948242188],
          [-241.0000228881837, -78.00003051757812],
          [-241.0000228881837, -79.00001525878906],
          [-241.0000228881837, -80],
          [-241.0000228881837, -80.99998474121094],
          [-241.0000228881837, -81.99996948242188],
          [-242.0000457763673, -82.99999237060547],
          [-243.00003051757824, -82.99999237060547],
          [-243.00003051757824, -84.00001525878906],
          [-244.00005340576183, -84.00001525878906],
          [-245.00003814697277, -84.00001525878906],
          [-246.0000228881837, -84.00001525878906],
          [-247.0000457763673, -85],
          [-248.00003051757824, -85],
          [-249.00005340576183, -85],
          [-250.00003814697277, -86.0000228881836],
          [-251.0000228881837, -86.99993133544922],
          [-252.0000457763673, -87.99999237060547],
          [-253.00003051757824, -89.00001525878906],
          [-253.00003051757824, -90],
          [-253.00003051757824, -91.99993133544922],
          [-253.00003051757824, -94.00001525878906],
          [-253.00003051757824, -95],
          [-253.00003051757824, -97.99999237060547],
          [-253.00003051757824, -99.00001525878906],
          [-253.00003051757824, -100],
          [-252.0000457763673, -101.0000228881836],
          [-252.0000457763673, -102.99999237060547],
          [-253.00003051757824, -104.00001525878906],
          [-254.00005340576183, -104.00001525878906],
          [-258.00003051757824, -104.00001525878906],
          [-259.00005340576183, -104.00001525878906],
          [-262.0000457763673, -104.00001525878906],
          [-263.00003051757824, -104.00001525878906],
          [-264.00005340576183, -104.00001525878906],
          [-266.0000228881837, -104.00001525878906],
          [-267.0000457763673, -104.00001525878906],
          [-268.00003051757824, -102.99999237060547],
          [-269.00005340576183, -102.99999237060547],
          [-270.00003814697277, -102.99999237060547],
          [-271.0000228881837, -102.99999237060547],
          [-272.0000457763673, -102.99999237060547],
          [-273.00003051757824, -102.99999237060547],
          [-273.00003051757824, -104.00001525878906],
          [-273.00003051757824, -105],
          [-274.00005340576183, -106.99993133544922],
          [-274.00005340576183, -107.99999237060547],
          [-274.00005340576183, -109.00001525878906],
          [-274.00005340576183, -110],
          [-274.00005340576183, -111.0000228881836],
          [-274.00005340576183, -111.99993133544922],
          [-274.00005340576183, -112.99999237060547],
          [-274.00005340576183, -114.00001525878906],
          [-274.00005340576183, -115],
          [-274.00005340576183, -116.0000228881836],
          [-274.00005340576183, -116.99993133544922],
          [-273.00003051757824, -117.99999237060547],
          [-272.0000457763673, -117.99999237060547],
          [-272.0000457763673, -119.00001525878906],
          [-272.0000457763673, -120],
          [-271.0000228881837, -121.0000228881836],
          [-271.0000228881837, -121.99993133544922],
          [-271.0000228881837, -122.99999237060547],
          [-271.0000228881837, -124.00001525878906],
          [-271.0000228881837, -125],
          [-271.0000228881837, -126.0000228881836],
          [-271.0000228881837, -127.99999237060547],
          [-271.0000228881837, -129.00001525878906],
          [-270.00003814697277, -131.0000228881836],
          [-270.00003814697277, -132.99999237060547],
          [-270.00003814697277, -134.00001525878906],
          [-270.00003814697277, -135],
          [-270.00003814697277, -136.0000228881836],
          [-272.0000457763673, -136.99993133544922],
          [-274.00005340576183, -136.99993133544922],
          [-275.00003814697277, -136.99993133544922],
          [-277.0000457763673, -136.99993133544922],
          [-278.00003051757824, -136.99993133544922],
          [-280.00003814697277, -136.99993133544922],
          [-281.0000228881837, -136.99993133544922],
          [-282.0000457763673, -136.99993133544922],
          [-283.00003051757824, -136.99993133544922],
          [-284.00005340576183, -136.99993133544922],
          [-285.00003814697277, -136.99993133544922],
          [-286.0000228881837, -136.99993133544922],
          [-287.0000457763673, -136.99993133544922],
          [-288.00003051757824, -136.99993133544922],
          [-289.00005340576183, -136.99993133544922],
          [-290.00003814697277, -136.99993133544922],
          [-291.0000228881837, -136.99993133544922],
          [-292.0000457763673, -136.99993133544922],
          [-294.00005340576183, -136.99993133544922],
          [-294.00005340576183, -137.99999237060547],
          [-294.00005340576183, -139.00001525878906],
          [-294.00005340576183, -140],
          [-294.00005340576183, -141.0000228881836],
          [-294.00005340576183, -141.99993133544922],
          [-294.00005340576183, -142.99999237060547],
          [-294.00005340576183, -144.00001525878906],
          [-294.00005340576183, -145],
          [-294.00005340576183, -146.0000228881836],
          [-295.00003814697277, -146.99993133544922],
          [-295.00003814697277, -147.99999237060547],
          [-295.00003814697277, -149.00001525878906],
          [-295.00003814697277, -151.0000228881836],
          [-296.0000228881837, -151.99993133544922],
          [-296.0000228881837, -154.00001525878906],
          [-296.0000228881837, -155],
          [-296.0000228881837, -156.0000228881836],
          [-296.0000228881837, -156.99993133544922],
          [-297.0000457763673, -157.99999237060547],
          [-297.0000457763673, -159.00001525878906],
          [-298.00003051757824, -160],
          [-298.00003051757824, -161.0000228881836],
          [-298.00003051757824, -161.99993133544922],
          [-299.00005340576183, -161.99993133544922],
          [-299.00005340576183, -162.99999237060547],
          [-299.00005340576183, -165],
          [-299.00005340576183, -166.0000228881836],
          [-299.00005340576183, -166.99993133544922],
          [-299.00005340576183, -167.99999237060547],
          [-299.00005340576183, -170],
          [-299.00005340576183, -171.0000228881836],
          [-299.00005340576183, -171.99993133544922],
          [-299.00005340576183, -172.99999237060547],
          [-299.00005340576183, -174.00001525878906],
          [-299.00005340576183, -175],
          [-299.00005340576183, -176.0000228881836],
          [-299.00005340576183, -176.99993133544922],
          [-299.00005340576183, -177.99999237060547],
          [-299.00005340576183, -179.00001525878906],
          [-299.00005340576183, -180],
          [-299.00005340576183, -181.0000228881836],
          [-299.00005340576183, -182.00000762939453],
          [-299.00005340576183, -182.99999237060547],
          [-300.00003814697277, -185],
          [-301.0000228881837, -186.0000228881836],
          [-302.0000457763673, -187.00000762939453],
          [-303.00003051757824, -189.00001525878906],
          [-305.00003814697277, -191.0000228881836],
          [-309.00005340576183, -192.99999237060547],
          [-309.00005340576183, -194.00001525878906],
          [-311.0000228881837, -195],
          [-312.0000457763673, -195],
          [-312.0000457763673, -196.0000228881836],
          [-313.00003051757824, -197.00000762939453],
          [-314.00005340576183, -197.99999237060547],
          [-314.00005340576183, -200],
          [-314.00005340576183, -201.0000228881836],
          [-314.00005340576183, -202.00000762939453],
          [-314.00005340576183, -202.99999237060547],
          [-314.00005340576183, -204.00001525878906],
          [-314.00005340576183, -205],
          [-314.00005340576183, -206.0000228881836],
          [-314.00005340576183, -207.00000762939453],
          [-314.00005340576183, -207.99999237060547],
          [-313.00003051757824, -207.99999237060547],
          [-313.00003051757824, -209.00001525878906],
          [-312.0000457763673, -210],
          [-311.0000228881837, -210],
          [-310.00003814697277, -210],
          [-309.00005340576183, -211.0000228881836],
          [-308.00003051757824, -212.00000762939453],
          [-307.0000457763673, -212.00000762939453],
          [-305.00003814697277, -212.99999237060547],
          [-304.00005340576183, -214.00001525878906],
          [-303.00003051757824, -214.00001525878906],
          [-302.0000457763673, -215],
          [-300.00003814697277, -216.0000228881836],
          [-299.00005340576183, -217.00000762939453],
          [-298.00003051757824, -217.00000762939453],
          [-297.0000457763673, -217.99999237060547],
          [-295.00003814697277, -219.00001525878906],
          [-295.00003814697277, -220],
          [-294.00005340576183, -220],
          [-293.00003051757824, -220],
          [-292.0000457763673, -220],
          [-291.0000228881837, -220],
          [-290.00003814697277, -220],
          [-288.00003051757824, -220],
          [-287.0000457763673, -220],
          [-285.00003814697277, -220],
          [-283.00003051757824, -220],
          [-282.0000457763673, -220],
          [-281.0000228881837, -220],
          [-280.00003814697277, -220],
          [-279.00005340576183, -220],
          [-278.00003051757824, -220],
          [-278.00003051757824, -221.0000228881836],
          [-277.0000457763673, -222.00000762939453],
          [-276.0000228881837, -222.00000762939453],
          [-276.0000228881837, -224.00001525878906],
          [-275.00003814697277, -225],
          [-275.00003814697277, -226.0000228881836],
          [-275.00003814697277, -227.00000762939453],
          [-275.00003814697277, -227.99999237060547],
          [-274.00005340576183, -229.00001525878906],
          [-274.00005340576183, -230],
          [-273.00003051757824, -230],
          [-273.00003051757824, -231.0000228881836],
          [-272.0000457763673, -232.00000762939453],
          [-271.0000228881837, -232.00000762939453],
          [-270.00003814697277, -232.99999237060547],
          [-270.00003814697277, -234.00001525878906],
          [-270.00003814697277, -235],
          [-269.00005340576183, -235],
          [-269.00005340576183, -236.0000228881836],
          [-268.00003051757824, -237.00000762939453],
          [-268.00003051757824, -237.99999237060547],
          [-268.00003051757824, -239.00001525878906],
          [-267.0000457763673, -240],
          [-267.0000457763673, -241.0000228881836],
          [-267.0000457763673, -242.00000762939453],
          [-267.0000457763673, -242.99999237060547],
          [-267.0000457763673, -244.00001525878906],
          [-267.0000457763673, -244.00001525878906],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 452,
        versionNonce: 483098487,
        isDeleted: false,
        id: "M1-qCENmBzX9DNgAtaEMB",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -1212.5444617649268,
        y: -1879.2189865904252,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 177.00000762939453,
        height: 248.0000114440918,
        seed: 837478839,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [-2.0000076293945312, 0],
          [-2.0000076293945312, -2.0000076293945312],
          [-2.9999923706054688, -2.9999923706054688],
          [-3.9999771118164062, -2.9999923706054688],
          [-5, -2.9999923706054688],
          [-5, -3.9999771118164062],
          [-5.9999847412109375, -3.9999771118164062],
          [-7.000007629394531, -5],
          [-7.999992370605469, -5],
          [-8.999977111816406, -5],
          [-10, -5],
          [-10.999984741210938, -5],
          [-12.000007629394531, -5],
          [-12.999992370605469, -5],
          [-12.999992370605469, -3.9999771118164062],
          [-12.999992370605469, -2.9999923706054688],
          [-12.999992370605469, -2.0000076293945312],
          [-12.999992370605469, -0.9999847412109375],
          [-12.999992370605469, 0],
          [-12.999992370605469, 1.0000228881835938],
          [-12.999992370605469, 2.9999923706054688],
          [-12.999992370605469, 4.0000152587890625],
          [-12.999992370605469, 5],
          [-12.999992370605469, 6.000022888183594],
          [-12.999992370605469, 7.000007629394531],
          [-12.999992370605469, 7.999992370605469],
          [-13.999977111816406, 9.000015258789062],
          [-15, 10],
          [-15, 11.000022888183594],
          [-15.999984741210938, 11.000022888183594],
          [-17.00000762939453, 11.000022888183594],
          [-17.99999237060547, 11.000022888183594],
          [-20, 11.000022888183594],
          [-20.999984741210938, 11.000022888183594],
          [-22.99999237060547, 11.000022888183594],
          [-25.999984741210938, 10],
          [-27.00000762939453, 9.000015258789062],
          [-27.99999237060547, 9.000015258789062],
          [-28.999977111816406, 9.000015258789062],
          [-30, 9.000015258789062],
          [-32.00000762939453, 9.000015258789062],
          [-32.99999237060547, 9.000015258789062],
          [-33.999977111816406, 9.000015258789062],
          [-35, 9.000015258789062],
          [-35.99998474121094, 9.000015258789062],
          [-37.00000762939453, 9.000015258789062],
          [-38.999977111816406, 9.000015258789062],
          [-40, 9.000015258789062],
          [-42.00000762939453, 9.000015258789062],
          [-42.99999237060547, 9.000015258789062],
          [-43.999977111816406, 9.000015258789062],
          [-45, 9.000015258789062],
          [-45.99998474121094, 9.000015258789062],
          [-45.99998474121094, 10],
          [-47.00000762939453, 10],
          [-47.99999237060547, 10],
          [-48.999977111816406, 10],
          [-50, 10],
          [-50.99998474121094, 10],
          [-52.99999237060547, 7.999992370605469],
          [-55, 5],
          [-57.00000762939453, 2.0000076293945312],
          [-57.99999237060547, 0],
          [-57.99999237060547, -2.0000076293945312],
          [-57.99999237060547, -2.9999923706054688],
          [-57.99999237060547, -3.9999771118164062],
          [-57.00000762939453, -3.9999771118164062],
          [-57.00000762939453, -5],
          [-55.99998474121094, -5],
          [-55, -5.9999847412109375],
          [-53.999977111816406, -5.9999847412109375],
          [-53.999977111816406, -7.000007629394531],
          [-53.999977111816406, -7.999992370605469],
          [-52.99999237060547, -7.999992370605469],
          [-52.99999237060547, -8.999977111816406],
          [-52.99999237060547, -10],
          [-52.99999237060547, -10.999984741210938],
          [-52.99999237060547, -12.000007629394531],
          [-52.99999237060547, -12.999992370605469],
          [-52.99999237060547, -13.999977111816406],
          [-52.99999237060547, -15],
          [-52.99999237060547, -15.999984741210938],
          [-52.99999237060547, -17.00000762939453],
          [-52.99999237060547, -17.99999237060547],
          [-53.999977111816406, -17.99999237060547],
          [-55, -18.999977111816406],
          [-57.00000762939453, -20.999984741210938],
          [-57.99999237060547, -22.00000762939453],
          [-58.999977111816406, -22.99999237060547],
          [-60, -22.99999237060547],
          [-60.99998474121094, -23.999977111816406],
          [-60.99998474121094, -25],
          [-60.99998474121094, -25.999984741210938],
          [-60.99998474121094, -27.99999237060547],
          [-60.99998474121094, -30],
          [-60.99998474121094, -30.999984741210938],
          [-60.99998474121094, -32.99999237060547],
          [-60.99998474121094, -35],
          [-60.99998474121094, -35.99998474121094],
          [-60.99998474121094, -37.00000762939453],
          [-60.99998474121094, -37.99999237060547],
          [-60.99998474121094, -38.999977111816406],
          [-60.99998474121094, -40],
          [-60.99998474121094, -40.99998474121094],
          [-60.99998474121094, -42.00000762939453],
          [-60, -42.99999237060547],
          [-58.999977111816406, -42.99999237060547],
          [-57.99999237060547, -42.99999237060547],
          [-57.00000762939453, -43.999977111816406],
          [-55.99998474121094, -45],
          [-55, -45],
          [-53.999977111816406, -45.99998474121094],
          [-52.00000762939453, -47.00000762939453],
          [-50, -47.00000762939453],
          [-48.999977111816406, -47.00000762939453],
          [-47.00000762939453, -47.99999237060547],
          [-45, -48.999977111816406],
          [-43.999977111816406, -48.999977111816406],
          [-42.99999237060547, -50],
          [-42.00000762939453, -50],
          [-40.99998474121094, -50.99998474121094],
          [-40, -50.99998474121094],
          [-38.999977111816406, -50.99998474121094],
          [-37.99999237060547, -50.99998474121094],
          [-35.99998474121094, -50.99998474121094],
          [-35, -50.99998474121094],
          [-33.999977111816406, -50.99998474121094],
          [-32.00000762939453, -50.99998474121094],
          [-30.999984741210938, -50.99998474121094],
          [-28.999977111816406, -50.99998474121094],
          [-27.99999237060547, -50.99998474121094],
          [-25, -50.99998474121094],
          [-23.999977111816406, -50.99998474121094],
          [-22.99999237060547, -50.99998474121094],
          [-22.00000762939453, -50],
          [-20, -50],
          [-18.999977111816406, -50],
          [-17.99999237060547, -50],
          [-17.00000762939453, -50],
          [-15.999984741210938, -50],
          [-15, -50],
          [-15, -50.99998474121094],
          [-15, -52.99999237060547],
          [-15, -53.999977111816406],
          [-15, -55],
          [-15, -55.99998474121094],
          [-15, -57.00000762939453],
          [-15.999984741210938, -57.99999237060547],
          [-17.00000762939453, -60],
          [-17.00000762939453, -60.99998474121094],
          [-17.00000762939453, -62.00000762939453],
          [-17.99999237060547, -62.00000762939453],
          [-17.99999237060547, -62.99999237060547],
          [-18.999977111816406, -63.999977111816406],
          [-20, -63.999977111816406],
          [-20, -65],
          [-20.999984741210938, -65],
          [-20.999984741210938, -65.99998474121094],
          [-22.00000762939453, -67.00000762939453],
          [-23.999977111816406, -67.99999237060547],
          [-25, -68.9999771118164],
          [-25.999984741210938, -70],
          [-27.99999237060547, -70.99998474121094],
          [-28.999977111816406, -70.99998474121094],
          [-30.999984741210938, -70.99998474121094],
          [-32.00000762939453, -70.99998474121094],
          [-35, -70.99998474121094],
          [-35.99998474121094, -70.99998474121094],
          [-35.99998474121094, -72.00000762939453],
          [-37.99999237060547, -75],
          [-38.999977111816406, -77.00000762939453],
          [-38.999977111816406, -78.9999771118164],
          [-38.999977111816406, -80],
          [-40, -82.00000762939453],
          [-40.99998474121094, -82.99999237060547],
          [-42.00000762939453, -82.99999237060547],
          [-42.00000762939453, -83.9999771118164],
          [-42.99999237060547, -85],
          [-42.99999237060547, -85.99998474121094],
          [-43.999977111816406, -85.99998474121094],
          [-43.999977111816406, -87.00000762939453],
          [-43.999977111816406, -87.99999237060547],
          [-43.999977111816406, -88.9999771118164],
          [-43.999977111816406, -90.99998474121094],
          [-43.999977111816406, -92.00000762939453],
          [-43.999977111816406, -92.99999237060547],
          [-43.999977111816406, -93.9999771118164],
          [-45, -95],
          [-45.99998474121094, -95],
          [-47.00000762939453, -95],
          [-47.99999237060547, -95],
          [-48.999977111816406, -95],
          [-50, -95],
          [-52.00000762939453, -95],
          [-53.999977111816406, -95],
          [-48.999977111816406, -97.00000762939453],
          [-45, -98.9999771118164],
          [-42.00000762939453, -100],
          [-37.99999237060547, -100.99998474121094],
          [-33.999977111816406, -102.00000762939453],
          [-30, -102.00000762939453],
          [-27.99999237060547, -102.00000762939453],
          [-25.999984741210938, -102.00000762939453],
          [-25, -102.00000762939453],
          [-23.999977111816406, -102.00000762939453],
          [-22.99999237060547, -102.00000762939453],
          [-22.00000762939453, -102.00000762939453],
          [-20.999984741210938, -102.00000762939453],
          [-20, -102.00000762939453],
          [-18.999977111816406, -102.00000762939453],
          [-17.99999237060547, -102.00000762939453],
          [-17.00000762939453, -102.00000762939453],
          [-15.999984741210938, -102.00000762939453],
          [-15.999984741210938, -103.9999771118164],
          [-15.999984741210938, -105.99998474121094],
          [-15.999984741210938, -107.00000762939453],
          [-15.999984741210938, -107.99999237060547],
          [-15.999984741210938, -108.9999771118164],
          [-17.00000762939453, -110],
          [-17.99999237060547, -110],
          [-18.999977111816406, -110.99998474121094],
          [-20.999984741210938, -112.00000762939453],
          [-22.99999237060547, -113.9999771118164],
          [-23.999977111816406, -115.99998474121094],
          [-25.999984741210938, -115.99998474121094],
          [-27.99999237060547, -115.99998474121094],
          [-28.999977111816406, -117.00000762939453],
          [-30, -117.00000762939453],
          [-30.999984741210938, -117.00000762939453],
          [-32.00000762939453, -117.99999237060547],
          [-32.00000762939453, -118.9999771118164],
          [-32.00000762939453, -120],
          [-32.00000762939453, -120.99998474121094],
          [-32.99999237060547, -122.00000762939453],
          [-32.99999237060547, -122.99999237060547],
          [-32.99999237060547, -125],
          [-32.99999237060547, -125.99998474121094],
          [-32.99999237060547, -127.00000762939453],
          [-32.99999237060547, -127.99999237060547],
          [-32.99999237060547, -130],
          [-32.99999237060547, -132.00000762939453],
          [-32.99999237060547, -132.99999237060547],
          [-32.99999237060547, -133.9999771118164],
          [-32.99999237060547, -135],
          [-32.99999237060547, -135.99998474121094],
          [-32.99999237060547, -137.00000762939453],
          [-32.00000762939453, -137.99999237060547],
          [-30.999984741210938, -138.9999771118164],
          [-30, -140],
          [-30, -140.99998474121094],
          [-28.999977111816406, -142.00000762939453],
          [-27.99999237060547, -142.00000762939453],
          [-27.99999237060547, -142.99999237060547],
          [-27.00000762939453, -143.9999771118164],
          [-25.999984741210938, -143.9999771118164],
          [-25, -143.9999771118164],
          [-23.999977111816406, -143.9999771118164],
          [-22.99999237060547, -143.9999771118164],
          [-22.00000762939453, -143.9999771118164],
          [-20.999984741210938, -143.9999771118164],
          [-20, -143.9999771118164],
          [-18.999977111816406, -143.9999771118164],
          [-17.99999237060547, -143.9999771118164],
          [-17.00000762939453, -143.9999771118164],
          [-15.999984741210938, -143.9999771118164],
          [-15, -143.9999771118164],
          [-13.999977111816406, -143.9999771118164],
          [-12.999992370605469, -143.9999771118164],
          [-12.000007629394531, -143.9999771118164],
          [-10.999984741210938, -143.9999771118164],
          [-10.999984741210938, -145],
          [-10.999984741210938, -145.99998474121094],
          [-10.999984741210938, -147.00000762939453],
          [-10.999984741210938, -147.99999237060547],
          [-10.999984741210938, -148.9999771118164],
          [-10.999984741210938, -150],
          [-10, -150],
          [-7.999992370605469, -150.99998474121094],
          [-7.000007629394531, -150.99998474121094],
          [-3.9999771118164062, -150.99998474121094],
          [-2.0000076293945312, -150.99998474121094],
          [0, -150.99998474121094],
          [2.9999923706054688, -150.99998474121094],
          [4.0000152587890625, -150.99998474121094],
          [5, -152.00000762939453],
          [5, -152.99999237060547],
          [5, -153.9999771118164],
          [5, -155.99998474121094],
          [5, -157.99999237060547],
          [5, -158.99995803833008],
          [4.0000152587890625, -161.00000381469727],
          [2.0000076293945312, -162.99999237060547],
          [2.0000076293945312, -165],
          [0, -166.9999885559082],
          [-2.0000076293945312, -167.99999237060547],
          [-5, -168.99995803833008],
          [-7.999992370605469, -171.00000381469727],
          [-8.999977111816406, -171.9999885559082],
          [-10.999984741210938, -172.99999237060547],
          [-12.999992370605469, -173.99995803833008],
          [-13.999977111816406, -175],
          [-15, -175],
          [-15, -176.00000381469727],
          [-15, -176.9999885559082],
          [-15, -177.99999237060547],
          [-13.999977111816406, -177.99999237060547],
          [-12.999992370605469, -177.99999237060547],
          [-10.999984741210938, -177.99999237060547],
          [-8.999977111816406, -177.99999237060547],
          [-7.000007629394531, -177.99999237060547],
          [-5.9999847412109375, -177.99999237060547],
          [-5, -177.99999237060547],
          [-2.9999923706054688, -177.99999237060547],
          [-2.0000076293945312, -178.99995803833008],
          [-0.9999847412109375, -180],
          [0, -181.00000381469727],
          [0, -181.9999885559082],
          [0, -182.99999237060547],
          [0, -183.99995803833008],
          [0, -185],
          [1.0000228881835938, -185],
          [1.0000228881835938, -186.00000381469727],
          [2.0000076293945312, -186.9999885559082],
          [2.9999923706054688, -187.99999237060547],
          [4.0000152587890625, -188.99995803833008],
          [4.0000152587890625, -190],
          [5, -190],
          [6.000022888183594, -191.00000381469727],
          [7.000007629394531, -191.9999885559082],
          [7.999992370605469, -192.99999237060547],
          [9.000015258789062, -193.99995803833008],
          [9.000015258789062, -195],
          [10, -195],
          [11.000022888183594, -196.00000381469727],
          [12.000007629394531, -196.9999885559082],
          [12.999992370605469, -196.9999885559082],
          [14.000015258789062, -196.9999885559082],
          [15, -196.9999885559082],
          [16.000022888183594, -196.9999885559082],
          [17.00000762939453, -196.9999885559082],
          [19.000015258789062, -196.9999885559082],
          [20, -196.9999885559082],
          [22.00000762939453, -196.9999885559082],
          [25, -196.9999885559082],
          [26.000022888183594, -196.9999885559082],
          [27.99999237060547, -196.9999885559082],
          [29.000015258789062, -196.00000381469727],
          [29.000015258789062, -195],
          [29.000015258789062, -193.99995803833008],
          [29.000015258789062, -192.99999237060547],
          [29.000015258789062, -191.9999885559082],
          [30, -191.00000381469727],
          [31.000022888183594, -191.00000381469727],
          [32.00000762939453, -191.00000381469727],
          [32.99999237060547, -191.00000381469727],
          [35, -191.00000381469727],
          [36.000022888183594, -191.00000381469727],
          [37.99999237060547, -192.99999237060547],
          [41.000022888183594, -195],
          [42.99999237060547, -196.00000381469727],
          [44.00001525878906, -196.9999885559082],
          [44.00001525878906, -197.99999237060547],
          [44.00001525878906, -198.99995803833008],
          [44.00001525878906, -200],
          [44.00001525878906, -201.00000381469727],
          [44.00001525878906, -201.9999885559082],
          [44.00001525878906, -202.99999237060547],
          [44.00001525878906, -203.99995803833008],
          [44.00001525878906, -205],
          [45, -205],
          [45, -206.00000381469727],
          [45, -206.9999885559082],
          [46.000022888183594, -207.99999237060547],
          [47.99999237060547, -210],
          [47.99999237060547, -211.00000381469727],
          [49.00001525878906, -211.9999885559082],
          [50, -212.99999237060547],
          [50, -213.99995803833008],
          [50, -215],
          [51.000022888183594, -216.00000381469727],
          [52.00000762939453, -216.00000381469727],
          [52.99999237060547, -216.00000381469727],
          [52.99999237060547, -216.9999885559082],
          [55, -216.9999885559082],
          [56.000022888183594, -216.9999885559082],
          [57.99999237060547, -217.99999237060547],
          [61.000022888183594, -217.99999237060547],
          [62.00000762939453, -217.99999237060547],
          [64.00001525878906, -217.99999237060547],
          [66.0000228881836, -217.99999237060547],
          [67.00000762939453, -217.99999237060547],
          [67.99999237060547, -217.99999237060547],
          [69.00001525878906, -216.9999885559082],
          [69.00001525878906, -216.00000381469727],
          [70, -216.00000381469727],
          [71.0000228881836, -216.00000381469727],
          [72.00000762939453, -216.00000381469727],
          [72.00000762939453, -216.9999885559082],
          [72.00000762939453, -217.99999237060547],
          [72.99999237060547, -220],
          [72.99999237060547, -221.00000381469727],
          [72.99999237060547, -222.99999237060547],
          [72.99999237060547, -225],
          [72.99999237060547, -225.99998474121094],
          [72.99999237060547, -227.99999237060547],
          [72.99999237060547, -228.99995803833008],
          [72.99999237060547, -230],
          [74.00001525878906, -230.99998474121094],
          [75, -231.9999885559082],
          [76.0000228881836, -231.9999885559082],
          [77.00000762939453, -232.99999237060547],
          [77.00000762939453, -233.99995803833008],
          [77.99999237060547, -233.99995803833008],
          [79.00001525878906, -235],
          [80, -235.99998474121094],
          [81.0000228881836, -235.99998474121094],
          [82.00000762939453, -235.99998474121094],
          [82.99999237060547, -236.9999885559082],
          [84.00001525878906, -236.9999885559082],
          [85, -236.9999885559082],
          [87.00000762939453, -236.9999885559082],
          [87.99999237060547, -236.9999885559082],
          [89.00001525878906, -235.99998474121094],
          [89.00001525878906, -233.99995803833008],
          [90, -232.99999237060547],
          [91.0000228881836, -231.9999885559082],
          [91.0000228881836, -230.99998474121094],
          [92.00000762939453, -230],
          [92.99999237060547, -228.99995803833008],
          [92.99999237060547, -227.99999237060547],
          [94.00001525878906, -226.9999885559082],
          [95, -226.9999885559082],
          [96.0000228881836, -226.9999885559082],
          [97.99999237060547, -226.9999885559082],
          [99.00001525878906, -226.9999885559082],
          [102.00000762939453, -226.9999885559082],
          [105, -226.9999885559082],
          [106.0000228881836, -226.9999885559082],
          [109.00001525878906, -226.9999885559082],
          [112.00000762939453, -226.9999885559082],
          [114.00001525878906, -226.9999885559082],
          [115, -226.9999885559082],
          [116.0000228881836, -226.9999885559082],
          [116.0000228881836, -227.99999237060547],
          [116.0000228881836, -228.99995803833008],
          [116.0000228881836, -228.99995803833008],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 112,
        versionNonce: 122132345,
        isDeleted: false,
        id: "83xOt2WAs941dpGf2yx8K",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -1098.5444465061378,
        y: -2107.2189789610306,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 60.99998474121094,
        height: 47.00000762939453,
        seed: 1721786807,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [0, -0.9999656677246094],
          [0, -2.0000076293945312],
          [0, -3.9999961853027344],
          [0, -5],
          [0.9999847412109375, -7.000007629394531],
          [0.9999847412109375, -8.999996185302734],
          [2.0000076293945312, -10],
          [3.9999771118164062, -12.000007629394531],
          [5.9999847412109375, -13.999996185302734],
          [7.999992370605469, -15.99996566772461],
          [10, -17.00000762939453],
          [10.999984741210938, -18.999996185302734],
          [12.000007629394531, -20],
          [12.999992370605469, -20],
          [13.999977111816406, -20],
          [13.999977111816406, -20.99996566772461],
          [15, -22.00000762939453],
          [15.999984741210938, -22.00000762939453],
          [17.00000762939453, -22.99999237060547],
          [17.00000762939453, -23.999996185302734],
          [17.99999237060547, -25],
          [17.99999237060547, -27.00000762939453],
          [17.99999237060547, -28.000011444091797],
          [17.99999237060547, -28.999996185302734],
          [17.99999237060547, -30],
          [17.00000762939453, -30],
          [15, -30],
          [13.999977111816406, -30],
          [12.000007629394531, -30],
          [10, -30],
          [8.999977111816406, -30],
          [7.999992370605469, -30],
          [7.999992370605469, -31.000003814697266],
          [7.999992370605469, -32.00000762939453],
          [7.999992370605469, -33.0000114440918],
          [8.999977111816406, -35],
          [10, -36.000003814697266],
          [12.000007629394531, -37.00000762939453],
          [13.999977111816406, -38.999996185302734],
          [15.999984741210938, -40],
          [17.00000762939453, -41.000003814697266],
          [18.999977111816406, -41.000003814697266],
          [20, -42.00000762939453],
          [20.999984741210938, -42.00000762939453],
          [22.00000762939453, -42.00000762939453],
          [22.99999237060547, -42.00000762939453],
          [23.999977111816406, -42.00000762939453],
          [25, -42.00000762939453],
          [25.999984741210938, -42.00000762939453],
          [27.00000762939453, -42.00000762939453],
          [27.99999237060547, -42.00000762939453],
          [28.999977111816406, -41.000003814697266],
          [30, -40],
          [30, -38.999996185302734],
          [30.999984741210938, -38.0000114440918],
          [32.00000762939453, -38.0000114440918],
          [32.00000762939453, -37.00000762939453],
          [32.00000762939453, -36.000003814697266],
          [32.00000762939453, -35],
          [32.99999237060547, -35],
          [32.99999237060547, -33.999996185302734],
          [32.99999237060547, -33.0000114440918],
          [32.99999237060547, -32.00000762939453],
          [33.999977111816406, -31.000003814697266],
          [33.999977111816406, -30],
          [35, -27.00000762939453],
          [35, -25.99996566772461],
          [35, -25],
          [35, -23.999996185302734],
          [35, -20],
          [35, -18.999996185302734],
          [35, -17.99999237060547],
          [35, -17.00000762939453],
          [35, -15.99996566772461],
          [35, -15],
          [35, -13.999996185302734],
          [35, -12.999992370605469],
          [35, -12.000007629394531],
          [35.99998474121094, -10.99996566772461],
          [37.00000762939453, -10],
          [38.999977111816406, -7.999992370605469],
          [40.99998474121094, -5.999965667724609],
          [43.999977111816406, -3.9999961853027344],
          [47.00000762939453, -2.0000076293945312],
          [50, -0.9999656677246094],
          [52.99999237060547, -0.9999656677246094],
          [53.999977111816406, 0],
          [53.999977111816406, 1.0000038146972656],
          [53.999977111816406, 2.0000076293945312],
          [53.999977111816406, 2.9999923706054688],
          [53.999977111816406, 4.000034332275391],
          [53.999977111816406, 5],
          [55, 5],
          [57.00000762939453, 5],
          [57.99999237060547, 4.000034332275391],
          [58.999977111816406, 0],
          [58.999977111816406, -0.9999656677246094],
          [58.999977111816406, -2.9999923706054688],
          [58.999977111816406, -3.9999961853027344],
          [58.999977111816406, -5],
          [58.999977111816406, -5.999965667724609],
          [58.999977111816406, -7.000007629394531],
          [60, -7.999992370605469],
          [60, -8.999996185302734],
          [60.99998474121094, -10],
          [60.99998474121094, -10],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 200,
        versionNonce: 607811735,
        isDeleted: false,
        id: "rYLuwWMp4ytccM8Ut60ar",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -1083.5444465061378,
        y: -2037.2189789610306,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 68.9999771118164,
        height: 59.00001525878906,
        seed: 486970167,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [-1.0000228881835938, 0],
          [-2.0000076293945312, 0],
          [-2.9999923706054688, 0],
          [-4.0000152587890625, 0],
          [-6.000022888183594, 0],
          [-7.000007629394531, -2.0000076293945312],
          [-9.000015258789062, -3.000011444091797],
          [-10, -3.9999961853027344],
          [-11.000022888183594, -5],
          [-12.999992370605469, -5],
          [-15, -5.999965667724609],
          [-16.000022888183594, -7.000007629394531],
          [-17.00000762939453, -7.000007629394531],
          [-19.000015258789062, -7.000007629394531],
          [-21.000022888183594, -8.000011444091797],
          [-22.99999237060547, -8.000011444091797],
          [-22.99999237060547, -8.999996185302734],
          [-24.000015258789062, -8.999996185302734],
          [-25, -8.999996185302734],
          [-26.000022888183594, -8.999996185302734],
          [-27.00000762939453, -8.999996185302734],
          [-27.99999237060547, -8.999996185302734],
          [-27.99999237060547, -10.99996566772461],
          [-27.99999237060547, -12.000007629394531],
          [-27.99999237060547, -13.000011444091797],
          [-27.99999237060547, -13.999996185302734],
          [-27.99999237060547, -15],
          [-27.99999237060547, -15.99996566772461],
          [-27.99999237060547, -18.999996185302734],
          [-27.99999237060547, -20.99996566772461],
          [-27.99999237060547, -22.00000762939453],
          [-27.99999237060547, -23.000011444091797],
          [-27.99999237060547, -23.999996185302734],
          [-27.00000762939453, -23.999996185302734],
          [-27.00000762939453, -25],
          [-26.000022888183594, -25.99996566772461],
          [-25, -27.00000762939453],
          [-24.000015258789062, -27.00000762939453],
          [-22.99999237060547, -27.00000762939453],
          [-22.00000762939453, -27.00000762939453],
          [-21.000022888183594, -27.00000762939453],
          [-19.000015258789062, -27.00000762939453],
          [-17.99999237060547, -27.00000762939453],
          [-17.00000762939453, -27.00000762939453],
          [-16.000022888183594, -27.00000762939453],
          [-15, -27.00000762939453],
          [-14.000015258789062, -27.00000762939453],
          [-12.999992370605469, -27.00000762939453],
          [-12.000007629394531, -27.00000762939453],
          [-10, -27.00000762939453],
          [-9.000015258789062, -27.00000762939453],
          [-7.999992370605469, -27.00000762939453],
          [-6.000022888183594, -27.00000762939453],
          [-2.9999923706054688, -27.00000762939453],
          [-1.0000228881835938, -27.00000762939453],
          [2.0000076293945312, -27.00000762939453],
          [2.9999923706054688, -27.00000762939453],
          [3.9999771118164062, -27.00000762939453],
          [5, -27.00000762939453],
          [5.9999847412109375, -25.99996566772461],
          [7.999992370605469, -25],
          [10, -23.000011444091797],
          [10.999984741210938, -23.000011444091797],
          [12.000007629394531, -22.00000762939453],
          [12.999992370605469, -20.99996566772461],
          [13.999977111816406, -18.000011444091797],
          [13.999977111816406, -17.00000762939453],
          [13.999977111816406, -15],
          [13.999977111816406, -13.000011444091797],
          [13.999977111816406, -12.000007629394531],
          [13.999977111816406, -10.99996566772461],
          [13.999977111816406, -10],
          [13.999977111816406, -8.999996185302734],
          [13.999977111816406, -8.000011444091797],
          [13.999977111816406, -7.000007629394531],
          [13.999977111816406, -5.999965667724609],
          [15, -5.999965667724609],
          [15.999984741210938, -5.999965667724609],
          [17.00000762939453, -5.999965667724609],
          [17.99999237060547, -5.999965667724609],
          [18.999977111816406, -5.999965667724609],
          [20, -5.999965667724609],
          [20.999984741210938, -5],
          [20.999984741210938, -3.9999961853027344],
          [20.999984741210938, -3.000011444091797],
          [22.00000762939453, -3.000011444091797],
          [22.00000762939453, -2.0000076293945312],
          [22.00000762939453, -0.9999656677246094],
          [22.99999237060547, 0],
          [23.999977111816406, 0],
          [23.999977111816406, 0.9999847412109375],
          [25, 2.0000076293945312],
          [25.999984741210938, 2.0000076293945312],
          [25.999984741210938, 2.9999923706054688],
          [25.999984741210938, 4.0000152587890625],
          [27.00000762939453, 5],
          [27.99999237060547, 7.000007629394531],
          [28.999977111816406, 9.000015258789062],
          [30, 10],
          [30, 12.000007629394531],
          [30.999984741210938, 14.000015258789062],
          [32.00000762939453, 15],
          [32.99999237060547, 17.00000762939453],
          [33.999977111816406, 19.000015258789062],
          [35, 19.000015258789062],
          [35, 20],
          [35.99998474121094, 20.999984741210938],
          [37.00000762939453, 22.00000762939453],
          [37.99999237060547, 22.00000762939453],
          [38.999977111816406, 22.00000762939453],
          [40, 22.00000762939453],
          [40.99998474121094, 22.00000762939453],
          [40.99998474121094, 22.99999237060547],
          [40.99998474121094, 24.000015258789062],
          [40, 25.999984741210938],
          [37.99999237060547, 25.999984741210938],
          [37.99999237060547, 27.00000762939453],
          [37.00000762939453, 27.00000762939453],
          [35, 27.99999237060547],
          [33.999977111816406, 29.000015258789062],
          [32.00000762939453, 30],
          [30.999984741210938, 30.999984741210938],
          [30, 30.999984741210938],
          [28.999977111816406, 30.999984741210938],
          [27.99999237060547, 30.999984741210938],
          [27.00000762939453, 30.999984741210938],
          [25, 30.999984741210938],
          [23.999977111816406, 30.999984741210938],
          [22.00000762939453, 32.00000762939453],
          [20.999984741210938, 32.00000762939453],
          [20, 32.00000762939453],
          [18.999977111816406, 32.00000762939453],
          [17.00000762939453, 32.00000762939453],
          [15.999984741210938, 32.00000762939453],
          [15, 32.00000762939453],
          [13.999977111816406, 32.00000762939453],
          [12.999992370605469, 32.00000762939453],
          [12.000007629394531, 32.00000762939453],
          [10, 30.999984741210938],
          [8.999977111816406, 30.999984741210938],
          [7.999992370605469, 30.999984741210938],
          [7.999992370605469, 30],
          [7.999992370605469, 29.000015258789062],
          [7.999992370605469, 27.99999237060547],
          [7.999992370605469, 27.00000762939453],
          [7.999992370605469, 25.999984741210938],
          [7.999992370605469, 25],
          [8.999977111816406, 24.000015258789062],
          [10, 24.000015258789062],
          [10.999984741210938, 22.99999237060547],
          [12.000007629394531, 22.00000762939453],
          [12.000007629394531, 20.999984741210938],
          [12.999992370605469, 20.999984741210938],
          [15, 20],
          [15.999984741210938, 19.000015258789062],
          [17.00000762939453, 19.000015258789062],
          [17.99999237060547, 19.000015258789062],
          [17.99999237060547, 17.99999237060547],
          [17.99999237060547, 17.00000762939453],
          [17.99999237060547, 15.999984741210938],
          [17.00000762939453, 15],
          [15.999984741210938, 15],
          [13.999977111816406, 14.000015258789062],
          [12.999992370605469, 12.999992370605469],
          [12.999992370605469, 12.000007629394531],
          [10.999984741210938, 12.000007629394531],
          [8.999977111816406, 10.999984741210938],
          [8.999977111816406, 10],
          [8.999977111816406, 9.000015258789062],
          [8.999977111816406, 7.999992370605469],
          [8.999977111816406, 7.000007629394531],
          [7.999992370605469, 7.000007629394531],
          [7.999992370605469, 5.9999847412109375],
          [7.999992370605469, 5],
          [7.000007629394531, 4.0000152587890625],
          [5.9999847412109375, 2.9999923706054688],
          [5, 2.9999923706054688],
          [3.9999771118164062, 2.0000076293945312],
          [2.9999923706054688, 0.9999847412109375],
          [2.0000076293945312, 0],
          [2.0000076293945312, -0.9999656677246094],
          [0.9999847412109375, -0.9999656677246094],
          [-1.0000228881835938, -3.000011444091797],
          [-1.0000228881835938, -3.9999961853027344],
          [-2.0000076293945312, -3.9999961853027344],
          [-4.0000152587890625, -5],
          [-5, -5],
          [-7.000007629394531, -5],
          [-7.999992370605469, -5],
          [-9.000015258789062, -5],
          [-10, -5],
          [-10, -3.9999961853027344],
          [-10, -3.000011444091797],
          [-10, -3.000011444091797],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 116,
        versionNonce: 758661209,
        isDeleted: false,
        id: "MQWLn2OwNrzHS-eJAoDVs",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -902.5444617649267,
        y: -2071.2189751463334,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 44.00001525878906,
        height: 41.000003814697266,
        seed: 1068898231,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [0, 0.9999847412109375],
          [-0.9999847412109375, 0.9999847412109375],
          [-1.999969482421875, 0.9999847412109375],
          [-2.9999542236328125, 0.9999847412109375],
          [-5, 1.9999885559082031],
          [-6.999969482421875, 3.000030517578125],
          [-7.9999542236328125, 3.9999961853027344],
          [-10, 3.9999961853027344],
          [-10.999984741210938, 3.9999961853027344],
          [-11.999969482421875, 5],
          [-12.999954223632812, 5.9999847412109375],
          [-12.999954223632812, 6.999988555908203],
          [-12.999954223632812, 8.000030517578125],
          [-14.000015258789062, 8.999996185302734],
          [-15, 10],
          [-15, 10.999984741210938],
          [-15, 11.999988555908203],
          [-15, 13.000030517578125],
          [-15, 13.999996185302734],
          [-15, 15],
          [-15, 16.999988555908203],
          [-15, 18.000030517578125],
          [-14.000015258789062, 18.000030517578125],
          [-12.999954223632812, 18.999996185302734],
          [-11.999969482421875, 18.999996185302734],
          [-10.999984741210938, 18.999996185302734],
          [-10, 18.999996185302734],
          [-9.000015258789062, 18.999996185302734],
          [-7.9999542236328125, 18.999996185302734],
          [-6.999969482421875, 18.999996185302734],
          [-5.9999847412109375, 18.999996185302734],
          [-5.9999847412109375, 20],
          [-5, 20],
          [-5, 20.999984741210938],
          [-5, 21.999988555908203],
          [-5, 23.000030517578125],
          [-5, 23.999996185302734],
          [-5, 25],
          [-4.0000152587890625, 25],
          [-2.9999542236328125, 25],
          [0, 25.999984741210938],
          [4.0000152587890625, 25.999984741210938],
          [9.000015258789062, 26.999988555908203],
          [13.000030517578125, 26.999988555908203],
          [15.999984741210938, 26.999988555908203],
          [20, 26.999988555908203],
          [24.000015258789062, 26.999988555908203],
          [25, 26.999988555908203],
          [27.000045776367188, 26.999988555908203],
          [29.000015258789062, 23.999996185302734],
          [29.000015258789062, 23.000030517578125],
          [29.000015258789062, 21.999988555908203],
          [28.000030517578125, 20.999984741210938],
          [27.000045776367188, 20.999984741210938],
          [25.999984741210938, 20],
          [25, 20],
          [25, 18.999996185302734],
          [23.000030517578125, 18.999996185302734],
          [20.999984741210938, 18.000030517578125],
          [20.999984741210938, 16.999988555908203],
          [20, 16.999988555908203],
          [19.000015258789062, 15.999984741210938],
          [18.000030517578125, 15.999984741210938],
          [17.000045776367188, 15],
          [17.000045776367188, 13.999996185302734],
          [17.000045776367188, 13.000030517578125],
          [17.000045776367188, 11.999988555908203],
          [17.000045776367188, 10.999984741210938],
          [17.000045776367188, 10],
          [17.000045776367188, 8.000030517578125],
          [17.000045776367188, 5.9999847412109375],
          [18.000030517578125, 5],
          [18.000030517578125, 3.9999961853027344],
          [18.000030517578125, 1.9999885559082031],
          [18.000030517578125, 0.9999847412109375],
          [18.000030517578125, 0],
          [18.000030517578125, -1.0000038146972656],
          [17.000045776367188, -1.999969482421875],
          [15.999984741210938, -3.000011444091797],
          [15.999984741210938, -4.0000152587890625],
          [15, -5],
          [14.000015258789062, -5],
          [13.000030517578125, -6.000003814697266],
          [12.000045776367188, -6.000003814697266],
          [10, -6.000003814697266],
          [9.000015258789062, -6.999969482421875],
          [8.000030517578125, -6.999969482421875],
          [5.9999847412109375, -8.000011444091797],
          [5, -8.000011444091797],
          [4.0000152587890625, -8.000011444091797],
          [4.0000152587890625, -9.000015258789062],
          [5, -11.000003814697266],
          [7.0000457763671875, -13.000011444091797],
          [8.000030517578125, -14.000015258789062],
          [5.9999847412109375, -14.000015258789062],
          [4.0000152587890625, -14.000015258789062],
          [2.0000457763671875, -13.000011444091797],
          [2.0000457763671875, -11.000003814697266],
          [0.9999847412109375, -9.000015258789062],
          [0.9999847412109375, -8.000011444091797],
          [0.9999847412109375, -6.000003814697266],
          [0.9999847412109375, -4.0000152587890625],
          [0, -1.0000038146972656],
          [0, 0.9999847412109375],
          [-0.9999847412109375, 0.9999847412109375],
          [-1.999969482421875, 3.000030517578125],
          [-1.999969482421875, 3.9999961853027344],
          [-1.999969482421875, 5],
          [0, 0],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 113,
        versionNonce: 296570295,
        isDeleted: false,
        id: "eZIGHsG8okEz2OMgloK7x",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -837.5444617649267,
        y: -1862.2189789610306,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 45,
        height: 25,
        seed: 1656059319,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [0.9999847412109375, 0],
          [0.9999847412109375, -0.9999847412109375],
          [3.000030517578125, -2.0000076293945312],
          [5, -2.9999923706054688],
          [5.9999847412109375, -2.9999923706054688],
          [9.000015258789062, -5],
          [12.000045776367188, -7.000007629394531],
          [14.000015258789062, -7.000007629394531],
          [15, -7.999992370605469],
          [15.999984741210938, -7.999992370605469],
          [17.000045776367188, -7.999992370605469],
          [17.000045776367188, -7.000007629394531],
          [17.000045776367188, -5.9999847412109375],
          [17.000045776367188, -5],
          [17.000045776367188, -4.0000152587890625],
          [18.000030517578125, -4.0000152587890625],
          [19.000015258789062, -4.0000152587890625],
          [20, -4.0000152587890625],
          [22.000045776367188, -4.0000152587890625],
          [24.000015258789062, -4.0000152587890625],
          [25, -4.0000152587890625],
          [27.000045776367188, -4.0000152587890625],
          [28.000030517578125, -4.0000152587890625],
          [29.000015258789062, -4.0000152587890625],
          [30, -4.0000152587890625],
          [30.999984741210938, -4.0000152587890625],
          [32.00004577636719, -4.0000152587890625],
          [33.000030517578125, -4.0000152587890625],
          [35, -4.0000152587890625],
          [35.99998474121094, -4.0000152587890625],
          [37.00004577636719, -4.0000152587890625],
          [38.000030517578125, -4.0000152587890625],
          [39.00001525878906, -4.0000152587890625],
          [40.99998474121094, -4.0000152587890625],
          [40.99998474121094, -2.9999923706054688],
          [42.00004577636719, -2.0000076293945312],
          [42.00004577636719, 0],
          [42.00004577636719, 0.9999847412109375],
          [43.000030517578125, 2.0000076293945312],
          [44.00001525878906, 2.9999923706054688],
          [44.00001525878906, 4.0000152587890625],
          [44.00001525878906, 5],
          [44.00001525878906, 5.9999847412109375],
          [45, 5.9999847412109375],
          [45, 7.000007629394531],
          [45, 7.999992370605469],
          [45, 9.000015258789062],
          [45, 10],
          [45, 10.999984741210938],
          [45, 12.000007629394531],
          [45, 12.999992370605469],
          [45, 14.000015258789062],
          [43.000030517578125, 15],
          [42.00004577636719, 15],
          [40.99998474121094, 15],
          [40, 15],
          [39.00001525878906, 15],
          [39.00001525878906, 14.000015258789062],
          [39.00001525878906, 12.999992370605469],
          [39.00001525878906, 12.000007629394531],
          [39.00001525878906, 10.999984741210938],
          [38.000030517578125, 10.999984741210938],
          [37.00004577636719, 10],
          [37.00004577636719, 9.000015258789062],
          [35.99998474121094, 9.000015258789062],
          [34.00001525878906, 9.000015258789062],
          [33.000030517578125, 9.000015258789062],
          [32.00004577636719, 7.999992370605469],
          [30.999984741210938, 7.999992370605469],
          [30, 7.999992370605469],
          [29.000015258789062, 9.000015258789062],
          [28.000030517578125, 10],
          [25.999984741210938, 10.999984741210938],
          [24.000015258789062, 12.999992370605469],
          [23.000030517578125, 14.000015258789062],
          [23.000030517578125, 15],
          [22.000045776367188, 15.999984741210938],
          [20.999984741210938, 17.00000762939453],
          [20, 17.00000762939453],
          [20, 15],
          [20, 12.999992370605469],
          [20, 10],
          [20, 9.000015258789062],
          [20, 7.000007629394531],
          [20, 5.9999847412109375],
          [20, 5],
          [20, 4.0000152587890625],
          [20, 2.9999923706054688],
          [20, 0.9999847412109375],
          [20, 0],
          [19.000015258789062, 0],
          [18.000030517578125, 0],
          [17.000045776367188, 0],
          [15, 0],
          [14.000015258789062, 0],
          [13.000030517578125, 0],
          [10.999984741210938, 0],
          [10, 0],
          [9.000015258789062, 0],
          [8.000030517578125, 0],
          [7.0000457763671875, 0],
          [5.9999847412109375, 0],
          [5, 0],
          [4.0000152587890625, 0.9999847412109375],
          [3.000030517578125, 0.9999847412109375],
          [0, 0],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 98,
        versionNonce: 618712377,
        isDeleted: false,
        id: "vJ910jDKhPxhMHxetcepo",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -1222.5444617649268,
        y: -1961.2189942198197,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 62.00000762939453,
        height: 35.99998474121094,
        seed: 1910512247,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [1.0000228881835938, 0],
          [2.9999923706054688, 0],
          [7.999992370605469, 1.0000228881835938],
          [17.00000762939453, 2.0000076293945312],
          [24.000015258789062, 3.000030517578125],
          [32.99999237060547, 6.000022888183594],
          [41.000022888183594, 7.000007629394531],
          [49.00001525878906, 9.000015258789062],
          [52.00000762939453, 10],
          [55, 11.000022888183594],
          [57.00000762939453, 12.000007629394531],
          [57.99999237060547, 13.000030517578125],
          [57.99999237060547, 14.000015258789062],
          [57.99999237060547, 15],
          [59.00001525878906, 15],
          [60, 15],
          [60, 13.000030517578125],
          [61.000022888183594, 12.000007629394531],
          [61.000022888183594, 10],
          [62.00000762939453, 8.000030517578125],
          [62.00000762939453, 7.000007629394531],
          [62.00000762939453, 6.000022888183594],
          [62.00000762939453, 5],
          [62.00000762939453, 4.0000152587890625],
          [62.00000762939453, 3.000030517578125],
          [61.000022888183594, 3.000030517578125],
          [60, 3.000030517578125],
          [59.00001525878906, 3.000030517578125],
          [57.99999237060547, 3.000030517578125],
          [57.99999237060547, 2.0000076293945312],
          [56.000022888183594, 2.0000076293945312],
          [54.00001525878906, 0],
          [52.99999237060547, -0.9999847412109375],
          [50, -1.999969482421875],
          [47.00000762939453, -2.9999923706054688],
          [41.000022888183594, -3.9999771118164062],
          [37.99999237060547, -5],
          [35, -5],
          [31.000022888183594, -5.9999847412109375],
          [27.00000762939453, -5.9999847412109375],
          [24.000015258789062, -5.9999847412109375],
          [21.000022888183594, -6.999969482421875],
          [19.000015258789062, -6.999969482421875],
          [17.99999237060547, -6.999969482421875],
          [19.000015258789062, -6.999969482421875],
          [20, -6.999969482421875],
          [21.000022888183594, -6.999969482421875],
          [22.99999237060547, -8.999977111816406],
          [25, -10.999984741210938],
          [26.000022888183594, -11.999969482421875],
          [27.00000762939453, -12.999992370605469],
          [27.00000762939453, -13.999977111816406],
          [27.99999237060547, -15],
          [29.000015258789062, -15.999984741210938],
          [29.000015258789062, -16.999969482421875],
          [30, -17.99999237060547],
          [31.000022888183594, -18.999977111816406],
          [31.000022888183594, -20],
          [31.000022888183594, -20.999984741210938],
          [30, -20.999984741210938],
          [29.000015258789062, -20.999984741210938],
          [27.99999237060547, -20.999984741210938],
          [25, -20.999984741210938],
          [24.000015258789062, -20.999984741210938],
          [22.99999237060547, -20.999984741210938],
          [22.00000762939453, -20.999984741210938],
          [20, -20.999984741210938],
          [19.000015258789062, -20.999984741210938],
          [17.99999237060547, -20.999984741210938],
          [16.000022888183594, -20.999984741210938],
          [15, -20.999984741210938],
          [14.000015258789062, -20.999984741210938],
          [12.999992370605469, -20],
          [12.999992370605469, -18.999977111816406],
          [12.999992370605469, -17.99999237060547],
          [12.999992370605469, -16.999969482421875],
          [12.999992370605469, -15.999984741210938],
          [12.999992370605469, -15],
          [12.999992370605469, -13.999977111816406],
          [12.999992370605469, -12.999992370605469],
          [12.999992370605469, -11.999969482421875],
          [12.999992370605469, -10],
          [12.999992370605469, -8.999977111816406],
          [12.999992370605469, -7.999992370605469],
          [12.000007629394531, -6.999969482421875],
          [12.000007629394531, -5.9999847412109375],
          [12.000007629394531, -5],
          [11.000022888183594, -3.9999771118164062],
          [10, -3.9999771118164062],
          [9.000015258789062, -3.9999771118164062],
          [7.999992370605469, -5],
          [0, 0],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 868,
        versionNonce: 810057431,
        isDeleted: false,
        id: "5oCcrVpmIPBHIInWx5JC3",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -965.5444159885595,
        y: -1997.2189789610306,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 281.0000228881837,
        height: 263.0000877380371,
        seed: 326469975,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [0.9999847412109375, -0.9999847412109375],
          [3.99993896484375, -2.9999923706054688],
          [5, -2.9999923706054688],
          [5.9999847412109375, -4.0000152587890625],
          [7.9999542236328125, -5.9999847412109375],
          [8.99993896484375, -7.000007629394531],
          [10, -7.999992370605469],
          [10.999984741210938, -9.000015258789062],
          [10.999984741210938, -10],
          [11.999969482421875, -10.999984741210938],
          [12.999954223632812, -12.000007629394531],
          [12.999954223632812, -12.999992370605469],
          [12.999954223632812, -14.000015258789062],
          [12.999954223632812, -15],
          [12.999954223632812, -15.999984741210938],
          [12.999954223632812, -17.00000762939453],
          [12.999954223632812, -19.000015258789062],
          [12.999954223632812, -20],
          [12.999954223632812, -20.999984741210938],
          [12.999954223632812, -22.00000762939453],
          [12.999954223632812, -22.99999237060547],
          [12.999954223632812, -24.000015258789062],
          [12.999954223632812, -25],
          [12.999954223632812, -25.999984741210938],
          [11.999969482421875, -27.00000762939453],
          [11.999969482421875, -27.99999237060547],
          [10.999984741210938, -30],
          [10, -32.00000762939453],
          [10, -32.99999237060547],
          [8.99993896484375, -32.99999237060547],
          [8.99993896484375, -34.00001525878906],
          [7.9999542236328125, -35],
          [7.9999542236328125, -35.99998474121094],
          [7.9999542236328125, -37.00000762939453],
          [7.9999542236328125, -37.99999237060547],
          [7.9999542236328125, -39.00001525878906],
          [7.9999542236328125, -40],
          [7.9999542236328125, -40.99996566772461],
          [7.9999542236328125, -42.00000762939453],
          [8.99993896484375, -43.0000114440918],
          [10, -43.0000114440918],
          [10, -43.999996185302734],
          [10.999984741210938, -45],
          [11.999969482421875, -45.99996566772461],
          [12.999954223632812, -45.99996566772461],
          [13.99993896484375, -45.99996566772461],
          [15, -47.00000762939453],
          [15, -48.0000114440918],
          [15, -48.999996185302734],
          [15, -50],
          [15, -50.99996566772461],
          [15, -52.00000762939453],
          [15, -53.0000114440918],
          [15, -53.999996185302734],
          [15, -55],
          [13.99993896484375, -55],
          [12.999954223632812, -55.99996566772461],
          [11.999969482421875, -55.99996566772461],
          [10.999984741210938, -55.99996566772461],
          [8.99993896484375, -55.99996566772461],
          [6.999969482421875, -55.99996566772461],
          [6.999969482421875, -57.00000762939453],
          [5.9999847412109375, -57.00000762939453],
          [5, -57.00000762939453],
          [5, -58.0000114440918],
          [3.99993896484375, -58.0000114440918],
          [2.9999542236328125, -58.0000114440918],
          [1.999969482421875, -58.0000114440918],
          [0, -58.0000114440918],
          [-1.00006103515625, -58.0000114440918],
          [-2.0000457763671875, -57.00000762939453],
          [-3.000030517578125, -55.99996566772461],
          [-4.0000152587890625, -55.99996566772461],
          [-5, -55],
          [-5, -53.999996185302734],
          [-6.00006103515625, -53.0000114440918],
          [-6.00006103515625, -52.00000762939453],
          [-7.0000457763671875, -50.99996566772461],
          [-7.0000457763671875, -50],
          [-7.0000457763671875, -48.999996185302734],
          [-7.0000457763671875, -48.0000114440918],
          [-7.0000457763671875, -47.00000762939453],
          [-7.0000457763671875, -45.99996566772461],
          [-7.0000457763671875, -45],
          [-6.00006103515625, -43.999996185302734],
          [-6.00006103515625, -43.0000114440918],
          [-6.00006103515625, -42.00000762939453],
          [-6.00006103515625, -40.99996566772461],
          [-7.0000457763671875, -40],
          [-7.0000457763671875, -39.00001525878906],
          [-9.000015258789062, -39.00001525878906],
          [-11.00006103515625, -39.00001525878906],
          [-12.000045776367188, -37.99999237060547],
          [-13.000030517578125, -37.00000762939453],
          [-14.000015258789062, -35.99998474121094],
          [-15, -35],
          [-15, -34.00001525878906],
          [-15, -32.99999237060547],
          [-15, -32.00000762939453],
          [-15, -30.999984741210938],
          [-15, -30],
          [-16.00006103515625, -27.99999237060547],
          [-16.00006103515625, -27.00000762939453],
          [-16.00006103515625, -25.999984741210938],
          [-16.00006103515625, -25],
          [-16.00006103515625, -24.000015258789062],
          [-16.00006103515625, -22.99999237060547],
          [-16.00006103515625, -22.00000762939453],
          [-16.00006103515625, -20],
          [-16.00006103515625, -19.000015258789062],
          [-16.00006103515625, -17.99999237060547],
          [-16.00006103515625, -17.00000762939453],
          [-16.00006103515625, -15.999984741210938],
          [-16.00006103515625, -15],
          [-16.00006103515625, -14.000015258789062],
          [-16.00006103515625, -12.999992370605469],
          [-16.00006103515625, -12.000007629394531],
          [-17.000045776367188, -10.999984741210938],
          [-17.000045776367188, -10],
          [-18.000030517578125, -10],
          [-18.000030517578125, -9.000015258789062],
          [-18.000030517578125, -7.999992370605469],
          [-19.000015258789062, -7.000007629394531],
          [-19.000015258789062, -5],
          [-19.000015258789062, -4.0000152587890625],
          [-20, -2.9999923706054688],
          [-20, -2.0000076293945312],
          [-21.00006103515625, -2.0000076293945312],
          [-21.00006103515625, -0.9999847412109375],
          [-21.00006103515625, 0],
          [-22.000045776367188, 0.9999847412109375],
          [-22.000045776367188, 2.0000076293945312],
          [-23.000030517578125, 2.9999923706054688],
          [-23.000030517578125, 4.0000152587890625],
          [-24.000015258789062, 4.0000152587890625],
          [-24.000015258789062, 5],
          [-24.000015258789062, 7.000007629394531],
          [-24.000015258789062, 9.000015258789062],
          [-24.000015258789062, 10.999984741210938],
          [-24.000015258789062, 12.999992370605469],
          [-24.000015258789062, 14.000015258789062],
          [-24.000015258789062, 15],
          [-24.000015258789062, 15.999984741210938],
          [-24.000015258789062, 17.00000762939453],
          [-24.000015258789062, 20],
          [-24.000015258789062, 22.00000762939453],
          [-24.000015258789062, 22.99999237060547],
          [-24.000015258789062, 25],
          [-26.00006103515625, 25.999984741210938],
          [-26.00006103515625, 27.00000762939453],
          [-27.000045776367188, 27.00000762939453],
          [-28.000030517578125, 27.99999237060547],
          [-29.000015258789062, 29.000015258789062],
          [-30.000000000000114, 29.000015258789062],
          [-31.000061035156364, 30],
          [-33.00003051757824, 30.999984741210938],
          [-35.000000000000114, 30.999984741210938],
          [-37.0000457763673, 30.999984741210938],
          [-40.000000000000114, 30.999984741210938],
          [-43.00003051757824, 30.999984741210938],
          [-44.000015258789176, 30.999984741210938],
          [-46.000061035156364, 30.999984741210938],
          [-47.0000457763673, 30.999984741210938],
          [-47.0000457763673, 32.00000762939453],
          [-48.00003051757824, 32.99999237060547],
          [-48.00003051757824, 34.00001525878906],
          [-49.000015258789176, 35],
          [-49.000015258789176, 35.99998474121094],
          [-49.000015258789176, 37.00000762939453],
          [-49.000015258789176, 39.00001525878906],
          [-49.000015258789176, 40],
          [-49.000015258789176, 42.99999237060547],
          [-49.000015258789176, 45],
          [-49.000015258789176, 45.99998474121094],
          [-49.000015258789176, 47.99999237060547],
          [-49.000015258789176, 49.00001525878906],
          [-49.000015258789176, 50.99998474121094],
          [-49.000015258789176, 52.00000762939453],
          [-50.000000000000114, 52.00000762939453],
          [-51.000061035156364, 52.00000762939453],
          [-51.000061035156364, 52.99999237060547],
          [-53.00003051757824, 54.00001525878906],
          [-54.000015258789176, 54.00001525878906],
          [-55.000000000000114, 54.00001525878906],
          [-56.000061035156364, 54.00001525878906],
          [-57.0000457763673, 54.00001525878906],
          [-59.000015258789176, 54.00001525878906],
          [-61.000061035156364, 54.00001525878906],
          [-64.00001525878918, 52.00000762939453],
          [-67.0000457763673, 50.99998474121094],
          [-70.00000000000011, 50.99998474121094],
          [-72.0000457763673, 50],
          [-74.00005340576183, 50],
          [-76.00002288818371, 50],
          [-76.00002288818371, 52.00000762939453],
          [-77.0000457763673, 52.99999237060547],
          [-78.00003051757824, 52.99999237060547],
          [-79.00005340576183, 54.00001525878906],
          [-79.00005340576183, 55],
          [-80.00003814697277, 55],
          [-81.00002288818371, 55],
          [-82.0000457763673, 55],
          [-83.00003051757824, 55],
          [-84.00005340576183, 55],
          [-86.00002288818371, 55],
          [-87.0000457763673, 55],
          [-89.00005340576183, 55],
          [-92.0000457763673, 52.99999237060547],
          [-95.00003814697277, 50.99998474121094],
          [-97.0000457763673, 49.00001525878906],
          [-99.00005340576183, 47.99999237060547],
          [-101.00002288818371, 45.99998474121094],
          [-102.0000457763673, 45],
          [-102.0000457763673, 44.00001525878906],
          [-103.00003051757824, 44.00001525878906],
          [-103.00003051757824, 42.99999237060547],
          [-103.00003051757824, 42.00000762939453],
          [-104.00005340576183, 40.99998474121094],
          [-105.00003814697277, 40.99998474121094],
          [-106.00002288818371, 40.99998474121094],
          [-107.0000457763673, 40.99998474121094],
          [-108.00003051757824, 40.99998474121094],
          [-110.00003814697277, 40.99998474121094],
          [-111.00002288818371, 40.99998474121094],
          [-113.00003051757824, 40.99998474121094],
          [-115.00003814697277, 40.99998474121094],
          [-116.00002288818371, 40.99998474121094],
          [-117.0000457763673, 40.99998474121094],
          [-119.00005340576183, 40.99998474121094],
          [-120.00003814697277, 40.99998474121094],
          [-122.0000457763673, 39.00001525878906],
          [-124.00005340576183, 39.00001525878906],
          [-126.00002288818371, 39.00001525878906],
          [-127.0000457763673, 39.00001525878906],
          [-128.00003051757824, 39.00001525878906],
          [-130.00003814697277, 39.00001525878906],
          [-131.0000228881837, 39.00001525878906],
          [-133.00003051757824, 39.00001525878906],
          [-135.00003814697277, 39.00001525878906],
          [-136.0000228881837, 39.00001525878906],
          [-138.00003051757824, 39.00001525878906],
          [-139.00005340576183, 39.00001525878906],
          [-139.00005340576183, 40],
          [-139.00005340576183, 40.99998474121094],
          [-140.00003814697277, 42.00000762939453],
          [-140.00003814697277, 42.99999237060547],
          [-140.00003814697277, 44.00001525878906],
          [-140.00003814697277, 45],
          [-140.00003814697277, 45.99998474121094],
          [-140.00003814697277, 47.00000762939453],
          [-139.00005340576183, 47.00000762939453],
          [-138.00003051757824, 47.00000762939453],
          [-137.0000457763673, 49.00001525878906],
          [-136.0000228881837, 49.00001525878906],
          [-135.00003814697277, 49.00001525878906],
          [-133.00003051757824, 50],
          [-131.0000228881837, 50],
          [-129.00005340576183, 50.99998474121094],
          [-126.00002288818371, 50.99998474121094],
          [-121.00002288818371, 50.99998474121094],
          [-119.00005340576183, 50.99998474121094],
          [-116.00002288818371, 50.99998474121094],
          [-115.00003814697277, 50.99998474121094],
          [-112.0000457763673, 50.99998474121094],
          [-109.00005340576183, 50.99998474121094],
          [-107.0000457763673, 50.99998474121094],
          [-105.00003814697277, 50.99998474121094],
          [-105.00003814697277, 52.00000762939453],
          [-104.00005340576183, 52.00000762939453],
          [-103.00003051757824, 52.00000762939453],
          [-102.0000457763673, 52.99999237060547],
          [-100.00003814697277, 54.00001525878906],
          [-98.00003051757824, 55],
          [-97.0000457763673, 57.00000762939453],
          [-95.00003814697277, 57.99999237060547],
          [-93.00003051757824, 60],
          [-91.00002288818371, 62.00000762939453],
          [-89.00005340576183, 65],
          [-88.00003051757824, 67.00000762939453],
          [-87.0000457763673, 67.00000762939453],
          [-86.00002288818371, 67.99999237060547],
          [-85.00003814697277, 69.00001525878906],
          [-84.00005340576183, 69.00001525878906],
          [-83.00003051757824, 69.00001525878906],
          [-82.0000457763673, 69.00001525878906],
          [-82.0000457763673, 70],
          [-82.0000457763673, 70.99998474121094],
          [-82.0000457763673, 72.00000762939453],
          [-82.0000457763673, 72.99999237060547],
          [-82.0000457763673, 74.00001525878906],
          [-82.0000457763673, 75],
          [-82.0000457763673, 75.99998474121094],
          [-83.00003051757824, 77.00000762939453],
          [-84.00005340576183, 77.00000762939453],
          [-85.00003814697277, 77.00000762939453],
          [-85.00003814697277, 77.99999237060547],
          [-86.00002288818371, 77.99999237060547],
          [-87.0000457763673, 77.99999237060547],
          [-89.00005340576183, 77.99999237060547],
          [-90.00003814697277, 77.99999237060547],
          [-91.00002288818371, 77.99999237060547],
          [-94.00005340576183, 77.99999237060547],
          [-97.0000457763673, 77.99999237060547],
          [-99.00005340576183, 77.99999237060547],
          [-102.0000457763673, 77.00000762939453],
          [-106.00002288818371, 75.99998474121094],
          [-110.00003814697277, 75],
          [-114.00005340576183, 72.99999237060547],
          [-119.00005340576183, 70.99998474121094],
          [-120.00003814697277, 70.99998474121094],
          [-121.00002288818371, 70.99998474121094],
          [-121.00002288818371, 72.00000762939453],
          [-122.0000457763673, 72.99999237060547],
          [-123.00003051757824, 72.99999237060547],
          [-124.00005340576183, 74.00001525878906],
          [-124.00005340576183, 75],
          [-125.00003814697277, 75],
          [-126.00002288818371, 75.99998474121094],
          [-126.00002288818371, 77.00000762939453],
          [-128.00003051757824, 77.99999237060547],
          [-129.00005340576183, 80],
          [-130.00003814697277, 80.99998474121094],
          [-131.0000228881837, 82.00000762939453],
          [-132.0000457763673, 82.99999237060547],
          [-133.00003051757824, 84.00001525878906],
          [-134.00005340576183, 85],
          [-135.00003814697277, 85],
          [-136.0000228881837, 85.99998474121094],
          [-137.0000457763673, 87.00000762939453],
          [-139.00005340576183, 87.99999237060547],
          [-141.0000228881837, 87.99999237060547],
          [-142.0000457763673, 87.99999237060547],
          [-144.00005340576183, 87.99999237060547],
          [-145.00003814697277, 87.99999237060547],
          [-146.0000228881837, 87.99999237060547],
          [-147.0000457763673, 87.99999237060547],
          [-149.00005340576183, 89.00001525878906],
          [-150.00003814697277, 89.00001525878906],
          [-152.0000457763673, 90],
          [-157.0000457763673, 92.00000762939453],
          [-160.00003814697277, 92.99999237060547],
          [-162.0000457763673, 92.99999237060547],
          [-163.00003051757824, 92.99999237060547],
          [-164.00005340576183, 92.99999237060547],
          [-165.00003814697277, 92.99999237060547],
          [-165.00003814697277, 94.00001525878906],
          [-165.00003814697277, 95],
          [-166.0000228881837, 95.99998474121094],
          [-167.0000457763673, 97.00000762939453],
          [-168.00003051757824, 97.99999237060547],
          [-170.00003814697277, 97.99999237060547],
          [-172.0000457763673, 99.00001525878906],
          [-172.0000457763673, 100],
          [-172.0000457763673, 100.99998474121094],
          [-173.00003051757824, 102.00000762939453],
          [-173.00003051757824, 102.99999237060547],
          [-174.00005340576183, 102.99999237060547],
          [-176.0000228881837, 105],
          [-177.0000457763673, 107.00000762939453],
          [-178.00003051757824, 107.99999237060547],
          [-179.00005340576183, 107.99999237060547],
          [-179.00005340576183, 110],
          [-179.00005340576183, 110.99998474121094],
          [-177.0000457763673, 110.99998474121094],
          [-171.0000228881837, 110.99998474121094],
          [-166.0000228881837, 110.99998474121094],
          [-160.00003814697277, 110],
          [-152.0000457763673, 107.99999237060547],
          [-143.00003051757824, 107.00000762939453],
          [-136.0000228881837, 104.00001525878906],
          [-133.00003051757824, 102.99999237060547],
          [-131.0000228881837, 102.00000762939453],
          [-131.0000228881837, 100],
          [-131.0000228881837, 99.00001525878906],
          [-131.0000228881837, 97.99999237060547],
          [-131.0000228881837, 97.00000762939453],
          [-131.0000228881837, 95.99998474121094],
          [-130.00003814697277, 94.00001525878906],
          [-129.00005340576183, 92.00000762939453],
          [-128.00003051757824, 92.00000762939453],
          [-127.0000457763673, 90],
          [-125.00003814697277, 89.00001525878906],
          [-124.00005340576183, 89.00001525878906],
          [-123.00003051757824, 87.99999237060547],
          [-122.0000457763673, 87.99999237060547],
          [-121.00002288818371, 87.99999237060547],
          [-121.00002288818371, 87.00000762939453],
          [-120.00003814697277, 85.99998474121094],
          [-119.00005340576183, 85.99998474121094],
          [-118.00003051757824, 85.99998474121094],
          [-117.0000457763673, 85.99998474121094],
          [-116.00002288818371, 85.99998474121094],
          [-115.00003814697277, 85.99998474121094],
          [-113.00003051757824, 85.99998474121094],
          [-109.00005340576183, 87.00000762939453],
          [-106.00002288818371, 87.99999237060547],
          [-103.00003051757824, 89.00001525878906],
          [-102.0000457763673, 89.00001525878906],
          [-100.00003814697277, 89.00001525878906],
          [-99.00005340576183, 89.00001525878906],
          [-97.0000457763673, 89.00001525878906],
          [-95.00003814697277, 89.00001525878906],
          [-91.00002288818371, 89.00001525878906],
          [-89.00005340576183, 89.00001525878906],
          [-89.00005340576183, 87.99999237060547],
          [-88.00003051757824, 87.99999237060547],
          [-87.0000457763673, 87.00000762939453],
          [-87.0000457763673, 85.99998474121094],
          [-86.00002288818371, 85],
          [-85.00003814697277, 85],
          [-84.00005340576183, 85],
          [-83.00003051757824, 85],
          [-82.0000457763673, 85],
          [-80.00003814697277, 85],
          [-79.00005340576183, 85],
          [-78.00003051757824, 85],
          [-77.0000457763673, 85],
          [-76.00002288818371, 85.99998474121094],
          [-75.00003814697277, 87.00000762939453],
          [-74.00005340576183, 87.99999237060547],
          [-73.00003051757824, 90.99998474121094],
          [-71.00006103515636, 95],
          [-66.00006103515636, 100.99998474121094],
          [-63.00003051757824, 105],
          [-60.000000000000114, 107.99999237060547],
          [-58.00003051757824, 110],
          [-56.000061035156364, 110.99998474121094],
          [-53.00003051757824, 112.00000762939453],
          [-52.0000457763673, 112.99999237060547],
          [-51.000061035156364, 112.99999237060547],
          [-50.000000000000114, 110.99998474121094],
          [-50.000000000000114, 109.00001525878906],
          [-50.000000000000114, 107.99999237060547],
          [-50.000000000000114, 107.00000762939453],
          [-50.000000000000114, 105.99998474121094],
          [-50.000000000000114, 105],
          [-50.000000000000114, 104.00001525878906],
          [-50.000000000000114, 102.99999237060547],
          [-51.000061035156364, 102.00000762939453],
          [-52.0000457763673, 102.00000762939453],
          [-55.000000000000114, 102.00000762939453],
          [-57.0000457763673, 102.00000762939453],
          [-63.00003051757824, 102.99999237060547],
          [-67.0000457763673, 104.00001525878906],
          [-72.0000457763673, 105],
          [-76.00002288818371, 105.99998474121094],
          [-81.00002288818371, 107.00000762939453],
          [-87.0000457763673, 107.99999237060547],
          [-90.00003814697277, 109.00001525878906],
          [-91.00002288818371, 110],
          [-92.0000457763673, 110.99998474121094],
          [-94.00005340576183, 112.00000762939453],
          [-95.00003814697277, 112.00000762939453],
          [-96.00002288818371, 112.00000762939453],
          [-96.00002288818371, 112.99999237060547],
          [-96.00002288818371, 114.00001525878906],
          [-96.00002288818371, 115],
          [-96.00002288818371, 115.99998474121094],
          [-96.00002288818371, 117.00000762939453],
          [-96.00002288818371, 117.99999237060547],
          [-95.00003814697277, 119.00001525878906],
          [-94.00005340576183, 120],
          [-91.00002288818371, 120.99998474121094],
          [-87.0000457763673, 122.00000762939453],
          [-84.00005340576183, 122.00000762939453],
          [-82.0000457763673, 122.00000762939453],
          [-81.00002288818371, 122.00000762939453],
          [-80.00003814697277, 122.00000762939453],
          [-78.00003051757824, 122.00000762939453],
          [-77.0000457763673, 122.00000762939453],
          [-76.00002288818371, 122.00000762939453],
          [-75.00003814697277, 122.00000762939453],
          [-74.00005340576183, 122.00000762939453],
          [-73.00003051757824, 122.00000762939453],
          [-72.0000457763673, 122.00000762939453],
          [-70.00000000000011, 122.00000762939453],
          [-69.00001525878918, 122.00000762939453],
          [-68.00003051757824, 122.00000762939453],
          [-65.00000000000011, 122.99999237060547],
          [-63.00003051757824, 122.99999237060547],
          [-61.000061035156364, 122.99999237060547],
          [-60.000000000000114, 122.99999237060547],
          [-59.000015258789176, 122.99999237060547],
          [-58.00003051757824, 122.99999237060547],
          [-57.0000457763673, 122.99999237060547],
          [-56.000061035156364, 122.99999237060547],
          [-54.000015258789176, 122.99999237060547],
          [-53.00003051757824, 122.99999237060547],
          [-52.0000457763673, 124.00001525878906],
          [-52.0000457763673, 125],
          [-52.0000457763673, 125.99998474121094],
          [-52.0000457763673, 127.00000762939453],
          [-53.00003051757824, 127.99999237060547],
          [-53.00003051757824, 129.00001525878906],
          [-53.00003051757824, 130],
          [-53.00003051757824, 132.00000762939453],
          [-53.00003051757824, 132.99999237060547],
          [-53.00003051757824, 134.00001525878906],
          [-53.00003051757824, 135],
          [-53.00003051757824, 135.99998474121094],
          [-52.0000457763673, 135.99998474121094],
          [-51.000061035156364, 137.00000762939453],
          [-51.000061035156364, 137.99999237060547],
          [-49.000015258789176, 137.99999237060547],
          [-47.0000457763673, 139.00001525878906],
          [-46.000061035156364, 139.00001525878906],
          [-45.000000000000114, 139.00001525878906],
          [-44.000015258789176, 139.00001525878906],
          [-42.0000457763673, 140],
          [-38.00003051757824, 140],
          [-37.0000457763673, 140],
          [-35.000000000000114, 140.99998474121094],
          [-35.000000000000114, 142.00000762939453],
          [-33.00003051757824, 142.00000762939453],
          [-31.000061035156364, 142.99999237060547],
          [-27.000045776367188, 147.00000762939453],
          [-25, 147.99999237060547],
          [-23.000030517578125, 149.00001525878906],
          [-23.000030517578125, 150],
          [-23.000030517578125, 150.99998474121094],
          [-23.000030517578125, 152.00000762939453],
          [-23.000030517578125, 152.99999237060547],
          [-23.000030517578125, 154.00001525878906],
          [-23.000030517578125, 155],
          [-23.000030517578125, 157.00000762939453],
          [-23.000030517578125, 157.99999237060547],
          [-23.000030517578125, 159.00001525878906],
          [-23.000030517578125, 160],
          [-23.000030517578125, 160.99998474121094],
          [-24.000015258789062, 162.00000762939453],
          [-25, 162.99999237060547],
          [-25, 165],
          [-26.00006103515625, 165.99998474121094],
          [-26.00006103515625, 167.99999237060547],
          [-26.00006103515625, 169.00001525878906],
          [-26.00006103515625, 170],
          [-26.00006103515625, 172.00000762939453],
          [-26.00006103515625, 172.99999237060547],
          [-26.00006103515625, 175],
          [-26.00006103515625, 177.00000762939453],
          [-27.000045776367188, 177.99999237060547],
          [-27.000045776367188, 179.00001525878906],
          [-27.000045776367188, 180],
          [-28.000030517578125, 180.99998474121094],
          [-28.000030517578125, 182.99999237060547],
          [-29.000015258789062, 187.00000762939453],
          [-30.000000000000114, 189.00001525878906],
          [-30.000000000000114, 190.99998474121094],
          [-30.000000000000114, 194.00001525878906],
          [-30.000000000000114, 195.0000762939453],
          [-30.000000000000114, 195.99998474121094],
          [-30.000000000000114, 197.00000762939453],
          [-30.000000000000114, 197.99999237060547],
          [-30.000000000000114, 199.00001525878906],
          [-30.000000000000114, 200.0000762939453],
          [-29.000015258789062, 200.99998474121094],
          [-27.000045776367188, 202.99999237060547],
          [-26.00006103515625, 204.00001525878906],
          [-24.000015258789062, 205.0000762939453],
          [-23.000030517578125, 205.0000762939453],
          [-19.000015258789062, 205.0000762939453],
          [-17.000045776367188, 205.0000762939453],
          [-15, 204.00001525878906],
          [-13.000030517578125, 202.00000762939453],
          [-12.000045776367188, 200.99998474121094],
          [-12.000045776367188, 200.0000762939453],
          [-12.000045776367188, 197.99999237060547],
          [-17.000045776367188, 195.0000762939453],
          [-19.000015258789062, 192.99999237060547],
          [-22.000045776367188, 190.0000762939453],
          [-25, 187.00000762939453],
          [-27.000045776367188, 185.0000762939453],
          [-29.000015258789062, 185.0000762939453],
          [-30.000000000000114, 184.00001525878906],
          [-30.000000000000114, 182.00000762939453],
          [-30.000000000000114, 180],
          [-30.000000000000114, 179.00001525878906],
          [-30.000000000000114, 177.99999237060547],
          [-30.000000000000114, 177.00000762939453],
          [-30.000000000000114, 175.99998474121094],
          [-30.000000000000114, 175],
          [-28.000030517578125, 175],
          [-25, 175],
          [-23.000030517578125, 175],
          [-22.000045776367188, 175],
          [-20, 175],
          [-19.000015258789062, 175],
          [-18.000030517578125, 175],
          [-18.000030517578125, 174.00001525878906],
          [-18.000030517578125, 172.99999237060547],
          [-18.000030517578125, 172.00000762939453],
          [-18.000030517578125, 170.99998474121094],
          [-18.000030517578125, 169.00001525878906],
          [-18.000030517578125, 167.00000762939453],
          [-18.000030517578125, 165.99998474121094],
          [-18.000030517578125, 165],
          [-17.000045776367188, 164.00001525878906],
          [-17.000045776367188, 162.00000762939453],
          [-17.000045776367188, 160],
          [-17.000045776367188, 159.00001525878906],
          [-17.000045776367188, 157.99999237060547],
          [-17.000045776367188, 157.00000762939453],
          [-17.000045776367188, 155.99998474121094],
          [-17.000045776367188, 155],
          [-17.000045776367188, 154.00001525878906],
          [-17.000045776367188, 152.99999237060547],
          [-17.000045776367188, 152.00000762939453],
          [-17.000045776367188, 150.99998474121094],
          [-17.000045776367188, 150],
          [-17.000045776367188, 149.00001525878906],
          [-17.000045776367188, 147.99999237060547],
          [-18.000030517578125, 147.00000762939453],
          [-18.000030517578125, 145.99998474121094],
          [-18.000030517578125, 144.00001525878906],
          [-18.000030517578125, 142.99999237060547],
          [-18.000030517578125, 142.00000762939453],
          [-18.000030517578125, 140],
          [-18.000030517578125, 139.00001525878906],
          [-18.000030517578125, 135.99998474121094],
          [-18.000030517578125, 135],
          [-18.000030517578125, 132.99999237060547],
          [-17.000045776367188, 130.99998474121094],
          [-16.00006103515625, 129.00001525878906],
          [-15, 129.00001525878906],
          [-15, 127.99999237060547],
          [-15, 127.00000762939453],
          [-14.000015258789062, 129.00001525878906],
          [-13.000030517578125, 129.00001525878906],
          [-12.000045776367188, 130],
          [-12.000045776367188, 130.99998474121094],
          [-11.00006103515625, 130.99998474121094],
          [-10, 130.99998474121094],
          [-9.000015258789062, 130.99998474121094],
          [-7.0000457763671875, 130.99998474121094],
          [-6.00006103515625, 130.99998474121094],
          [-5, 130.99998474121094],
          [-4.0000152587890625, 130.99998474121094],
          [-2.0000457763671875, 130.99998474121094],
          [-2.0000457763671875, 129.00001525878906],
          [-1.00006103515625, 129.00001525878906],
          [0.9999847412109375, 129.00001525878906],
          [1.999969482421875, 127.99999237060547],
          [2.9999542236328125, 127.99999237060547],
          [5, 125.99998474121094],
          [5.9999847412109375, 125],
          [6.999969482421875, 125],
          [7.9999542236328125, 124.00001525878906],
          [7.9999542236328125, 122.99999237060547],
          [7.9999542236328125, 122.00000762939453],
          [7.9999542236328125, 120],
          [8.99993896484375, 120],
          [8.99993896484375, 119.00001525878906],
          [8.99993896484375, 117.99999237060547],
          [8.99993896484375, 117.00000762939453],
          [8.99993896484375, 115],
          [10, 115],
          [10, 114.00001525878906],
          [10.999984741210938, 112.99999237060547],
          [11.999969482421875, 112.99999237060547],
          [12.999954223632812, 112.99999237060547],
          [15, 112.99999237060547],
          [15.999984741210938, 112.99999237060547],
          [18.99993896484375, 112.99999237060547],
          [22.999954223632812, 112.99999237060547],
          [27.999954223632812, 112.99999237060547],
          [32.99995422363281, 112.00000762939453],
          [35.99998474121094, 112.00000762939453],
          [37.99995422363281, 112.00000762939453],
          [40, 112.00000762939453],
          [41.999969482421875, 110.99998474121094],
          [41.999969482421875, 110],
          [41.999969482421875, 109.00001525878906],
          [41.999969482421875, 107.99999237060547],
          [41.999969482421875, 107.00000762939453],
          [41.999969482421875, 105],
          [41.999969482421875, 104.00001525878906],
          [41.999969482421875, 102.99999237060547],
          [41.999969482421875, 102.00000762939453],
          [41.999969482421875, 100.99998474121094],
          [41.999969482421875, 100],
          [41.999969482421875, 99.00001525878906],
          [42.99995422363281, 99.00001525878906],
          [43.99993896484375, 99.00001525878906],
          [45, 99.00001525878906],
          [45.99998474121094, 99.00001525878906],
          [46.999969482421875, 99.00001525878906],
          [47.99995422363281, 99.00001525878906],
          [48.99993896484375, 100.99998474121094],
          [50, 102.00000762939453],
          [51.999969482421875, 104.00001525878906],
          [52.99995422363281, 107.00000762939453],
          [55, 109.00001525878906],
          [56.999969482421875, 110],
          [57.99995422363281, 112.00000762939453],
          [58.99993896484375, 112.99999237060547],
          [58.99993896484375, 114.00001525878906],
          [60, 114.00001525878906],
          [60.99998474121094, 115],
          [60.99998474121094, 115.99998474121094],
          [61.999969482421875, 115.99998474121094],
          [63.99993896484375, 117.00000762939453],
          [65, 117.00000762939453],
          [67.99995422363281, 117.00000762939453],
          [72.99995422363281, 117.00000762939453],
          [75.99998474121094, 117.00000762939453],
          [77.99995422363281, 117.00000762939453],
          [78.99993896484375, 117.00000762939453],
          [80, 117.00000762939453],
          [81.99996948242188, 117.00000762939453],
          [85, 117.00000762939453],
          [85.99998474121094, 117.00000762939453],
          [87.99995422363281, 117.00000762939453],
          [88.99993896484375, 117.00000762939453],
          [90.99998474121094, 117.00000762939453],
          [91.99996948242188, 117.00000762939453],
          [92.99995422363281, 115.99998474121094],
          [93.99993896484375, 115],
          [95, 114.00001525878906],
          [95, 112.99999237060547],
          [95.99998474121094, 112.99999237060547],
          [96.99996948242188, 112.00000762939453],
          [96.99996948242188, 110.99998474121094],
          [97.99995422363281, 110],
          [98.99993896484375, 107.99999237060547],
          [100, 107.00000762939453],
          [100, 105.99998474121094],
          [100.99998474121094, 105.99998474121094],
          [101.99996948242188, 105],
          [101.99996948242188, 104.00001525878906],
          [101.99996948242188, 102.99999237060547],
          [101.99996948242188, 102.00000762939453],
          [101.99996948242188, 100.99998474121094],
          [101.99996948242188, 100],
          [100.99998474121094, 99.00001525878906],
          [100.99998474121094, 97.99999237060547],
          [100, 95],
          [98.99993896484375, 92.00000762939453],
          [97.99995422363281, 89.00001525878906],
          [96.99996948242188, 87.00000762939453],
          [95, 84.00001525878906],
          [93.99993896484375, 82.00000762939453],
          [93.99993896484375, 80.99998474121094],
          [92.99995422363281, 80],
          [92.99995422363281, 79.00001525878906],
          [91.99996948242188, 79.00001525878906],
          [90.99998474121094, 79.00001525878906],
          [90, 79.00001525878906],
          [88.99993896484375, 79.00001525878906],
          [87.99995422363281, 79.00001525878906],
          [85.99998474121094, 79.00001525878906],
          [85, 80],
          [82.99995422363281, 80],
          [80.99998474121094, 80],
          [78.99993896484375, 80],
          [76.99996948242188, 80],
          [75.99998474121094, 80],
          [73.99993896484375, 80],
          [72.99995422363281, 80],
          [70.99998474121094, 80],
          [68.99993896484375, 80],
          [67.99995422363281, 80],
          [65.99998474121094, 80],
          [65, 80],
          [63.99993896484375, 80],
          [62.99995422363281, 80],
          [62.99995422363281, 79.00001525878906],
          [61.999969482421875, 79.00001525878906],
          [60.99998474121094, 79.00001525878906],
          [60.99998474121094, 77.99999237060547],
          [60, 77.00000762939453],
          [58.99993896484375, 75.99998474121094],
          [57.99995422363281, 75.99998474121094],
          [56.999969482421875, 74.00001525878906],
          [55.99998474121094, 72.00000762939453],
          [55, 72.00000762939453],
          [55, 70.99998474121094],
          [53.99993896484375, 70],
          [52.99995422363281, 70],
          [52.99995422363281, 69.00001525878906],
          [51.999969482421875, 67.99999237060547],
          [51.999969482421875, 67.00000762939453],
          [50.99998474121094, 65.99998474121094],
          [50.99998474121094, 65],
          [50, 65],
          [48.99993896484375, 64.00001525878906],
          [47.99995422363281, 62.99999237060547],
          [46.999969482421875, 62.99999237060547],
          [45.99998474121094, 62.99999237060547],
          [45, 62.99999237060547],
          [43.99993896484375, 62.99999237060547],
          [42.99995422363281, 62.00000762939453],
          [41.999969482421875, 62.00000762939453],
          [40.99998474121094, 62.00000762939453],
          [38.99993896484375, 62.00000762939453],
          [37.99995422363281, 62.00000762939453],
          [36.999969482421875, 62.00000762939453],
          [35.99998474121094, 62.00000762939453],
          [35, 60.99998474121094],
          [33.99993896484375, 60.99998474121094],
          [32.99995422363281, 60.99998474121094],
          [30.999984741210938, 59.00001525878906],
          [30, 59.00001525878906],
          [27.999954223632812, 59.00001525878906],
          [25.999984741210938, 57.99999237060547],
          [23.99993896484375, 55.99998474121094],
          [20.999984741210938, 55.99998474121094],
          [17.999954223632812, 55],
          [16.999969482421875, 54.00001525878906],
          [15, 52.99999237060547],
          [12.999954223632812, 52.99999237060547],
          [12.999954223632812, 52.00000762939453],
          [12.999954223632812, 50.99998474121094],
          [12.999954223632812, 50],
          [12.999954223632812, 49.00001525878906],
          [12.999954223632812, 47.00000762939453],
          [12.999954223632812, 45],
          [12.999954223632812, 44.00001525878906],
          [12.999954223632812, 42.99999237060547],
          [10.999984741210938, 42.00000762939453],
          [10, 40],
          [7.9999542236328125, 37.99999237060547],
          [6.999969482421875, 35.99998474121094],
          [5, 35],
          [2.9999542236328125, 32.99999237060547],
          [-1.00006103515625, 30],
          [-1.00006103515625, 29.000015258789062],
          [-2.0000457763671875, 29.000015258789062],
          [-2.0000457763671875, 27.99999237060547],
          [-2.0000457763671875, 27.00000762939453],
          [-2.0000457763671875, 25],
          [-3.000030517578125, 24.000015258789062],
          [-3.000030517578125, 22.99999237060547],
          [-3.000030517578125, 22.00000762939453],
          [-3.000030517578125, 20],
          [-3.000030517578125, 19.000015258789062],
          [-3.000030517578125, 17.00000762939453],
          [-3.000030517578125, 15.999984741210938],
          [-3.000030517578125, 15],
          [-3.000030517578125, 14.000015258789062],
          [-3.000030517578125, 12.999992370605469],
          [-3.000030517578125, 12.000007629394531],
          [-3.000030517578125, 10.999984741210938],
          [-2.0000457763671875, 9.000015258789062],
          [-2.0000457763671875, 7.999992370605469],
          [-2.0000457763671875, 7.000007629394531],
          [-2.0000457763671875, 5.9999847412109375],
          [-1.00006103515625, 5],
          [0, 4.0000152587890625],
          [0.9999847412109375, 4.0000152587890625],
          [0.9999847412109375, 2.9999923706054688],
          [0.9999847412109375, 2.0000076293945312],
          [1.999969482421875, 0.9999847412109375],
          [2.9999542236328125, -0.9999847412109375],
          [2.9999542236328125, -2.0000076293945312],
          [2.9999542236328125, -2.9999923706054688],
          [2.9999542236328125, -4.0000152587890625],
          [3.99993896484375, -4.0000152587890625],
          [3.99993896484375, -5],
          [3.99993896484375, -5.9999847412109375],
          [3.99993896484375, -7.000007629394531],
          [5, -7.000007629394531],
          [0, 0],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 175,
        versionNonce: 899989017,
        isDeleted: false,
        id: "8XGu5VpwEwAFcL25Iwog6",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -988.5444465061377,
        y: -1936.2189942198197,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 80.99998474121105,
        height: 57.99999237060547,
        seed: 958517879,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [0, 1.0000228881835938],
          [-1.999969482421875, 2.0000076293945312],
          [-4.0000152587890625, 2.0000076293945312],
          [-5.9999847412109375, 3.000030517578125],
          [-5.9999847412109375, 4.0000152587890625],
          [-6.999969482421989, 5],
          [-9.000015258789176, 5],
          [-9.000015258789176, 6.000022888183594],
          [-10.000000000000114, 7.000007629394531],
          [-10.999984741211051, 8.000030517578125],
          [-11.999969482421989, 8.000030517578125],
          [-13.000030517578239, 10],
          [-13.000030517578239, 11.000022888183594],
          [-13.000030517578239, 12.000007629394531],
          [-13.000030517578239, 13.000030517578125],
          [-14.000015258789176, 14.000015258789062],
          [-14.000015258789176, 15],
          [-15.000000000000114, 16.000022888183594],
          [-15.000000000000114, 17.00000762939453],
          [-15.999984741211051, 17.00000762939453],
          [-16.99996948242199, 17.00000762939453],
          [-18.00003051757824, 17.00000762939453],
          [-19.000015258789176, 17.00000762939453],
          [-20.000000000000114, 17.00000762939453],
          [-20.99998474121105, 17.00000762939453],
          [-21.99996948242199, 17.00000762939453],
          [-23.00003051757824, 17.00000762939453],
          [-24.000015258789176, 17.00000762939453],
          [-25.000000000000114, 17.00000762939453],
          [-25.99998474121105, 17.00000762939453],
          [-26.99996948242199, 17.00000762939453],
          [-28.00003051757824, 17.00000762939453],
          [-29.000015258789176, 17.00000762939453],
          [-29.000015258789176, 16.000022888183594],
          [-30.000000000000114, 15],
          [-30.000000000000114, 14.000015258789062],
          [-30.000000000000114, 13.000030517578125],
          [-29.000015258789176, 12.000007629394531],
          [-28.00003051757824, 11.000022888183594],
          [-28.00003051757824, 10],
          [-26.99996948242199, 10],
          [-25.99998474121105, 10],
          [-24.000015258789176, 10],
          [-24.000015258789176, 11.000022888183594],
          [-23.00003051757824, 12.000007629394531],
          [-21.99996948242199, 15],
          [-21.99996948242199, 18.000030517578125],
          [-21.99996948242199, 21.000022888183594],
          [-20.99998474121105, 23.000030517578125],
          [-20.000000000000114, 26.000022888183594],
          [-19.000015258789176, 28.000030517578125],
          [-19.000015258789176, 29.000015258789062],
          [-19.000015258789176, 30],
          [-19.000015258789176, 31.000022888183594],
          [-19.000015258789176, 32.00000762939453],
          [-19.000015258789176, 33.000030517578125],
          [-19.000015258789176, 34.00001525878906],
          [-19.000015258789176, 35],
          [-19.000015258789176, 36.000022888183594],
          [-19.000015258789176, 37.00000762939453],
          [-19.000015258789176, 38.000030517578125],
          [-19.000015258789176, 40],
          [-19.000015258789176, 41.000022888183594],
          [-19.000015258789176, 42.00000762939453],
          [-19.000015258789176, 43.000030517578125],
          [-19.000015258789176, 44.00001525878906],
          [-19.000015258789176, 46.000022888183594],
          [-18.00003051757824, 46.000022888183594],
          [-16.99996948242199, 47.00000762939453],
          [-15.999984741211051, 48.000030517578125],
          [-15.000000000000114, 49.00001525878906],
          [-14.000015258789176, 49.00001525878906],
          [-14.000015258789176, 50],
          [-13.000030517578239, 51.000022888183594],
          [-10.999984741211051, 52.00000762939453],
          [-9.000015258789176, 52.00000762939453],
          [-8.000030517578239, 53.000030517578125],
          [-5.9999847412109375, 53.000030517578125],
          [-4.0000152587890625, 54.00001525878906],
          [-3.000030517578125, 54.00001525878906],
          [-1.999969482421875, 54.00001525878906],
          [-0.9999847412109375, 55],
          [0, 55],
          [0, 56.000022888183594],
          [0, 54.00001525878906],
          [0, 52.00000762939453],
          [0, 51.000022888183594],
          [0, 48.000030517578125],
          [0, 47.00000762939453],
          [0, 45],
          [0, 43.000030517578125],
          [0, 42.00000762939453],
          [0, 41.000022888183594],
          [0.9999847412109375, 40],
          [1.999969482421875, 40],
          [3.000030517578125, 40],
          [4.0000152587890625, 40],
          [5, 40],
          [6.999969482421875, 40],
          [9.000015258789062, 40],
          [10, 39.00001525878906],
          [11.999969482421875, 39.00001525878906],
          [13.000030517578125, 39.00001525878906],
          [14.000015258789062, 39.00001525878906],
          [15.999984741210938, 39.00001525878906],
          [16.999969482421875, 39.00001525878906],
          [18.000030517578125, 39.00001525878906],
          [19.000015258789062, 39.00001525878906],
          [20, 39.00001525878906],
          [20.999984741210938, 39.00001525878906],
          [21.999969482421875, 39.00001525878906],
          [24.000015258789062, 39.00001525878906],
          [25, 39.00001525878906],
          [26.999969482421875, 39.00001525878906],
          [28.000030517578125, 39.00001525878906],
          [29.000015258789062, 39.00001525878906],
          [30, 39.00001525878906],
          [30.999984741210938, 39.00001525878906],
          [33.000030517578125, 39.00001525878906],
          [34.00001525878906, 39.00001525878906],
          [35, 39.00001525878906],
          [36.999969482421875, 37.00000762939453],
          [40, 35],
          [41.999969482421875, 32.00000762939453],
          [45, 29.000015258789062],
          [46.999969482421875, 27.00000762939453],
          [49.00001525878906, 25],
          [50, 24.000015258789062],
          [50.99998474121094, 23.000030517578125],
          [50.99998474121094, 22.00000762939453],
          [50.99998474121094, 21.000022888183594],
          [50.99998474121094, 20],
          [50.99998474121094, 19.000015258789062],
          [50, 19.000015258789062],
          [46.999969482421875, 18.000030517578125],
          [45, 18.000030517578125],
          [43.000030517578125, 17.00000762939453],
          [40, 15],
          [35.99998474121094, 13.000030517578125],
          [31.999969482421875, 13.000030517578125],
          [29.000015258789062, 11.000022888183594],
          [25.999984741210938, 10],
          [24.000015258789062, 9.000015258789062],
          [21.999969482421875, 9.000015258789062],
          [20, 9.000015258789062],
          [19.000015258789062, 9.000015258789062],
          [18.000030517578125, 9.000015258789062],
          [15.999984741210938, 9.000015258789062],
          [15.999984741210938, 10],
          [15, 11.000022888183594],
          [15, 12.000007629394531],
          [14.000015258789062, 12.000007629394531],
          [13.000030517578125, 12.000007629394531],
          [11.999969482421875, 10],
          [10.999984741210938, 7.000007629394531],
          [10, 4.0000152587890625],
          [8.000030517578125, 3.000030517578125],
          [8.000030517578125, 0],
          [8.000030517578125, -1.999969482421875],
          [6.999969482421875, -1.999969482421875],
          [5.9999847412109375, -1.999969482421875],
          [5, -1.999969482421875],
          [4.0000152587890625, -1.999969482421875],
          [0.9999847412109375, -1.999969482421875],
          [-1.999969482421875, -1.999969482421875],
          [-5, -1.999969482421875],
          [-8.000030517578239, -1.999969482421875],
          [-10.000000000000114, -1.999969482421875],
          [-10.999984741211051, -1.999969482421875],
          [-10.999984741211051, -1.999969482421875],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 139,
        versionNonce: 1923124215,
        isDeleted: false,
        id: "6bBADLV0FHxXcJWibXdAj",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -937.5444617649267,
        y: -1854.2189865904252,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 37.00004577636719,
        height: 49.00001525878906,
        seed: 1690479607,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [0, -0.9999847412109375],
          [-0.9999847412109375, -0.9999847412109375],
          [-1.999969482421875, -2.9999923706054688],
          [-1.999969482421875, -3.9999771118164062],
          [-1.999969482421875, -5],
          [-1.999969482421875, -5.9999847412109375],
          [-1.999969482421875, -7.000007629394531],
          [-1.999969482421875, -7.999992370605469],
          [-1.999969482421875, -8.999977111816406],
          [-1.999969482421875, -10],
          [-0.9999847412109375, -10],
          [0, -10],
          [0.9999847412109375, -10],
          [2.0000457763671875, -10],
          [3.000030517578125, -10],
          [4.0000152587890625, -10],
          [5, -10],
          [5.9999847412109375, -10],
          [7.0000457763671875, -8.999977111816406],
          [8.000030517578125, -8.999977111816406],
          [9.000015258789062, -7.999992370605469],
          [10, -7.999992370605469],
          [10.999984741210938, -7.999992370605469],
          [12.000045776367188, -7.000007629394531],
          [13.000030517578125, -5.9999847412109375],
          [13.000030517578125, -5],
          [13.000030517578125, -3.9999771118164062],
          [13.000030517578125, -2.9999923706054688],
          [13.000030517578125, -2.0000076293945312],
          [13.000030517578125, 0],
          [13.000030517578125, 1.0000228881835938],
          [13.000030517578125, 2.9999923706054688],
          [13.000030517578125, 4.0000152587890625],
          [12.000045776367188, 4.0000152587890625],
          [12.000045776367188, 5],
          [10.999984741210938, 6.000022888183594],
          [10.999984741210938, 7.000007629394531],
          [10.999984741210938, 7.999992370605469],
          [10.999984741210938, 9.000015258789062],
          [10.999984741210938, 10],
          [12.000045776367188, 11.000022888183594],
          [12.000045776367188, 12.000007629394531],
          [14.000015258789062, 12.000007629394531],
          [15.999984741210938, 12.999992370605469],
          [15.999984741210938, 14.000015258789062],
          [18.000030517578125, 15],
          [20, 16.000022888183594],
          [20.999984741210938, 16.000022888183594],
          [23.000030517578125, 16.000022888183594],
          [24.000015258789062, 16.000022888183594],
          [25, 16.000022888183594],
          [25.999984741210938, 16.000022888183594],
          [27.000045776367188, 16.000022888183594],
          [27.000045776367188, 17.00000762939453],
          [27.000045776367188, 17.99999237060547],
          [25.999984741210938, 19.000015258789062],
          [25, 20],
          [25, 22.00000762939453],
          [25, 24.000015258789062],
          [25, 25],
          [24.000015258789062, 26.000022888183594],
          [23.000030517578125, 26.000022888183594],
          [23.000030517578125, 27.00000762939453],
          [22.000045776367188, 27.00000762939453],
          [20.999984741210938, 27.99999237060547],
          [20, 29.000015258789062],
          [19.000015258789062, 30],
          [18.000030517578125, 30],
          [15.999984741210938, 31.000022888183594],
          [15.999984741210938, 32.00000762939453],
          [14.000015258789062, 32.99999237060547],
          [13.000030517578125, 32.99999237060547],
          [13.000030517578125, 34.00001525878906],
          [10.999984741210938, 35],
          [10, 36.000022888183594],
          [9.000015258789062, 36.000022888183594],
          [8.000030517578125, 37.00000762939453],
          [5.9999847412109375, 39.00001525878906],
          [4.0000152587890625, 39.00001525878906],
          [2.0000457763671875, 39.00001525878906],
          [0.9999847412109375, 39.00001525878906],
          [-0.9999847412109375, 39.00001525878906],
          [-1.999969482421875, 39.00001525878906],
          [-2.9999542236328125, 39.00001525878906],
          [-4.0000152587890625, 39.00001525878906],
          [-4.0000152587890625, 37.00000762939453],
          [-4.0000152587890625, 36.000022888183594],
          [-4.0000152587890625, 34.00001525878906],
          [-4.0000152587890625, 32.99999237060547],
          [-4.0000152587890625, 32.00000762939453],
          [-5, 31.000022888183594],
          [-5.9999847412109375, 31.000022888183594],
          [-5, 30],
          [-5, 27.99999237060547],
          [-5, 27.00000762939453],
          [-4.0000152587890625, 27.00000762939453],
          [-2.9999542236328125, 26.000022888183594],
          [-1.999969482421875, 25],
          [-0.9999847412109375, 24.000015258789062],
          [0, 22.99999237060547],
          [0, 22.00000762939453],
          [0, 21.000022888183594],
          [0, 20],
          [0, 19.000015258789062],
          [-0.9999847412109375, 17.99999237060547],
          [-1.999969482421875, 17.00000762939453],
          [-2.9999542236328125, 17.00000762939453],
          [-4.0000152587890625, 17.00000762939453],
          [-5, 16.000022888183594],
          [-6.999969482421875, 15],
          [-6.999969482421875, 14.000015258789062],
          [-6.999969482421875, 12.999992370605469],
          [-6.999969482421875, 12.000007629394531],
          [-6.999969482421875, 11.000022888183594],
          [-6.999969482421875, 10],
          [-6.999969482421875, 9.000015258789062],
          [-5.9999847412109375, 9.000015258789062],
          [-5.9999847412109375, 7.999992370605469],
          [-5.9999847412109375, 7.000007629394531],
          [-5, 7.000007629394531],
          [-4.0000152587890625, 7.000007629394531],
          [-4.0000152587890625, 6.000022888183594],
          [-4.0000152587890625, 5],
          [-4.0000152587890625, 4.0000152587890625],
          [-4.0000152587890625, 2.9999923706054688],
          [-4.0000152587890625, 2.0000076293945312],
          [-4.0000152587890625, 1.0000228881835938],
          [-4.0000152587890625, 0],
          [-4.0000152587890625, -0.9999847412109375],
          [-5, -0.9999847412109375],
          [-6.999969482421875, -0.9999847412109375],
          [-9.000015258789062, -0.9999847412109375],
          [-10, -0.9999847412109375],
          [-10, -0.9999847412109375],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 118,
        versionNonce: 1853330169,
        isDeleted: false,
        id: "ir8CleHLwft410n-Q2UEs",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -1210.5444541355323,
        y: -1842.2189789610306,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 50,
        height: 32.99999237060547,
        seed: 1725666551,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [0, 0.9999847412109375],
          [0, 2.0000076293945312],
          [0, 4.0000152587890625],
          [0, 5],
          [0, 5.9999847412109375],
          [0.9999847412109375, 7.000007629394531],
          [2.0000076293945312, 7.000007629394531],
          [2.0000076293945312, 7.999992370605469],
          [2.9999923706054688, 7.999992370605469],
          [5, 7.999992370605469],
          [5.9999847412109375, 7.999992370605469],
          [9.000015258789062, 7.999992370605469],
          [12.000007629394531, 7.999992370605469],
          [14.000015258789062, 7.999992370605469],
          [17.99999237060547, 7.999992370605469],
          [20.999984741210938, 7.999992370605469],
          [24.000015258789062, 7.999992370605469],
          [27.00000762939453, 5.9999847412109375],
          [29.000015258789062, 5.9999847412109375],
          [30, 4.0000152587890625],
          [30.999984741210938, 2.9999923706054688],
          [32.00000762939453, 2.9999923706054688],
          [32.99999237060547, 2.0000076293945312],
          [34.00001525878906, 0.9999847412109375],
          [35, 0],
          [35, 0.9999847412109375],
          [34.00001525878906, 2.0000076293945312],
          [32.99999237060547, 2.0000076293945312],
          [32.00000762939453, 4.0000152587890625],
          [32.00000762939453, 5],
          [30, 7.000007629394531],
          [30, 7.999992370605469],
          [29.000015258789062, 7.999992370605469],
          [27.99999237060547, 9.000015258789062],
          [27.00000762939453, 10.999984741210938],
          [25.999984741210938, 10.999984741210938],
          [24.000015258789062, 12.999992370605469],
          [24.000015258789062, 15],
          [24.000015258789062, 15.999984741210938],
          [24.000015258789062, 17.00000762939453],
          [24.000015258789062, 17.99999237060547],
          [24.000015258789062, 19.000015258789062],
          [24.000015258789062, 20],
          [24.000015258789062, 20.999984741210938],
          [22.99999237060547, 22.00000762939453],
          [22.00000762939453, 22.00000762939453],
          [20.999984741210938, 22.00000762939453],
          [19.000015258789062, 22.00000762939453],
          [17.99999237060547, 22.99999237060547],
          [17.00000762939453, 22.99999237060547],
          [15.999984741210938, 22.99999237060547],
          [15, 22.99999237060547],
          [12.999992370605469, 22.99999237060547],
          [12.000007629394531, 22.99999237060547],
          [10.999984741210938, 22.99999237060547],
          [10, 24.000015258789062],
          [9.000015258789062, 25],
          [9.000015258789062, 25.999984741210938],
          [9.000015258789062, 27.00000762939453],
          [9.000015258789062, 27.99999237060547],
          [9.000015258789062, 30.000076293945312],
          [9.000015258789062, 32.00000762939453],
          [9.000015258789062, 32.99999237060547],
          [7.999992370605469, 32.99999237060547],
          [7.000007629394531, 32.99999237060547],
          [5.9999847412109375, 32.99999237060547],
          [5, 32.99999237060547],
          [4.0000152587890625, 32.99999237060547],
          [2.9999923706054688, 32.99999237060547],
          [2.0000076293945312, 32.99999237060547],
          [2.0000076293945312, 32.00000762939453],
          [0.9999847412109375, 30.999984741210938],
          [0.9999847412109375, 30.000076293945312],
          [0, 29.000015258789062],
          [-0.9999847412109375, 27.99999237060547],
          [-0.9999847412109375, 27.00000762939453],
          [-2.0000076293945312, 25.999984741210938],
          [-2.9999923706054688, 25.999984741210938],
          [-4.0000152587890625, 25.999984741210938],
          [-5, 25],
          [-5.9999847412109375, 25],
          [-7.000007629394531, 25],
          [-7.999992370605469, 25],
          [-9.000015258789062, 25],
          [-10, 25],
          [-12.000007629394531, 25],
          [-12.999992370605469, 25],
          [-15, 25],
          [-15, 24.000015258789062],
          [-15, 22.99999237060547],
          [-15, 22.00000762939453],
          [-15, 20],
          [-12.999992370605469, 20],
          [-12.000007629394531, 19.000015258789062],
          [-10, 17.99999237060547],
          [-9.000015258789062, 17.00000762939453],
          [-7.000007629394531, 15],
          [-5, 14.000015258789062],
          [-4.0000152587890625, 12.000007629394531],
          [-2.9999923706054688, 10],
          [-2.0000076293945312, 9.000015258789062],
          [-0.9999847412109375, 9.000015258789062],
          [-0.9999847412109375, 7.999992370605469],
          [0, 7.000007629394531],
          [0, 5.9999847412109375],
          [0, 5],
          [0, 4.0000152587890625],
          [0, 2.0000076293945312],
          [-0.9999847412109375, 2.0000076293945312],
          [-2.0000076293945312, 2.0000076293945312],
          [-2.9999923706054688, 2.0000076293945312],
          [0, 0],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 393,
        versionNonce: 79295767,
        isDeleted: false,
        id: "OY2klskvpF3kkTROkOnNu",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -1149.5444693943214,
        y: -1844.2189865904252,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 166.0000228881837,
        height: 122.99999237060547,
        seed: 1559160791,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [1.0000228881835938, 0],
          [2.0000076293945312, 0],
          [4.0000152587890625, 0],
          [6.000022888183594, -2.0000076293945312],
          [7.000007629394531, -2.9999923706054688],
          [10, -5],
          [17.00000762939453, -8.999977111816406],
          [21.000022888183594, -8.999977111816406],
          [23.000030517578125, -10],
          [26.000022888183594, -10.999984741210938],
          [29.000015258789062, -10.999984741210938],
          [31.000022888183594, -12.000007629394531],
          [32.00000762939453, -12.000007629394531],
          [34.00001525878906, -12.000007629394531],
          [35, -12.999992370605469],
          [34.00001525878906, -12.999992370605469],
          [33.000030517578125, -12.999992370605469],
          [31.000022888183594, -12.000007629394531],
          [30, -10.999984741210938],
          [29.000015258789062, -8.999977111816406],
          [28.000030517578125, -7.999992370605469],
          [28.000030517578125, -7.000007629394531],
          [28.000030517578125, -5.9999847412109375],
          [28.000030517578125, -5],
          [28.000030517578125, -3.9999771118164062],
          [28.000030517578125, -2.9999923706054688],
          [28.000030517578125, -2.0000076293945312],
          [28.000030517578125, -0.9999847412109375],
          [28.000030517578125, 0],
          [28.000030517578125, 1.0000228881835938],
          [28.000030517578125, 2.0000076293945312],
          [28.000030517578125, 2.9999923706054688],
          [28.000030517578125, 4.0000152587890625],
          [28.000030517578125, 5],
          [28.000030517578125, 6.000022888183594],
          [29.000015258789062, 7.000007629394531],
          [29.000015258789062, 7.999992370605469],
          [30, 9.000015258789062],
          [30, 10],
          [30, 11.000022888183594],
          [30, 12.000007629394531],
          [30, 12.999992370605469],
          [30, 14.000015258789062],
          [30, 15],
          [30, 16.000022888183594],
          [30, 17.00000762939453],
          [30, 17.99999237060547],
          [30, 19.000015258789062],
          [31.000022888183594, 19.000015258789062],
          [32.00000762939453, 19.000015258789062],
          [33.000030517578125, 17.99999237060547],
          [33.000030517578125, 16.000022888183594],
          [34.00001525878906, 15],
          [35, 12.999992370605469],
          [35, 11.000022888183594],
          [36.000022888183594, 7.999992370605469],
          [36.000022888183594, 6.000022888183594],
          [37.00000762939453, 6.000022888183594],
          [37.00000762939453, 5],
          [37.00000762939453, 4.0000152587890625],
          [38.000030517578125, 4.0000152587890625],
          [38.000030517578125, 2.9999923706054688],
          [38.000030517578125, 2.0000076293945312],
          [38.000030517578125, 1.0000228881835938],
          [39.00001525878906, 0],
          [40, -0.9999847412109375],
          [40, -2.0000076293945312],
          [41.000022888183594, -2.9999923706054688],
          [42.00000762939453, -2.9999923706054688],
          [42.00000762939453, -3.9999771118164062],
          [43.000030517578125, -3.9999771118164062],
          [44.00001525878906, -3.9999771118164062],
          [44.00001525878906, -5],
          [45, -5],
          [46.000022888183594, -5.9999847412109375],
          [47.00000762939453, -5.9999847412109375],
          [47.00000762939453, -7.000007629394531],
          [48.000030517578125, -7.999992370605469],
          [49.00001525878906, -8.999977111816406],
          [50, -8.999977111816406],
          [51.000022888183594, -8.999977111816406],
          [52.00000762939453, -8.999977111816406],
          [54.00001525878906, -8.999977111816406],
          [55, -8.999977111816406],
          [56.000022888183594, -8.999977111816406],
          [57.00000762939453, -8.999977111816406],
          [58.000030517578125, -8.999977111816406],
          [59.00001525878906, -8.999977111816406],
          [60, -8.999977111816406],
          [61.000022888183594, -8.999977111816406],
          [62.00000762939453, -8.999977111816406],
          [63.000030517578125, -8.999977111816406],
          [64.00001525878906, -8.999977111816406],
          [66.0000228881836, -8.999977111816406],
          [67.00000762939453, -8.999977111816406],
          [68.00003051757812, -8.999977111816406],
          [70, -8.999977111816406],
          [71.0000228881836, -8.999977111816406],
          [72.00000762939453, -8.999977111816406],
          [73.00003051757812, -8.999977111816406],
          [74.00001525878906, -8.999977111816406],
          [74.00001525878906, -7.999992370605469],
          [74.00001525878906, -7.000007629394531],
          [74.00001525878906, -5.9999847412109375],
          [74.00001525878906, -5],
          [74.00001525878906, -3.9999771118164062],
          [74.00001525878906, -2.0000076293945312],
          [74.00001525878906, -0.9999847412109375],
          [73.00003051757812, -0.9999847412109375],
          [73.00003051757812, 0],
          [72.00000762939453, 1.0000228881835938],
          [71.0000228881836, 2.0000076293945312],
          [69.00001525878906, 4.0000152587890625],
          [67.00000762939453, 6.000022888183594],
          [66.0000228881836, 7.000007629394531],
          [64.00001525878906, 7.999992370605469],
          [63.000030517578125, 9.000015258789062],
          [63.000030517578125, 10],
          [61.000022888183594, 11.000022888183594],
          [59.00001525878906, 12.000007629394531],
          [58.000030517578125, 12.999992370605469],
          [58.000030517578125, 14.000015258789062],
          [58.000030517578125, 15],
          [57.00000762939453, 16.000022888183594],
          [56.000022888183594, 17.00000762939453],
          [56.000022888183594, 17.99999237060547],
          [55, 19.000015258789062],
          [55, 20],
          [54.00001525878906, 21.000022888183594],
          [53.000030517578125, 21.000022888183594],
          [53.000030517578125, 22.00000762939453],
          [52.00000762939453, 22.00000762939453],
          [51.000022888183594, 22.00000762939453],
          [50, 22.99999237060547],
          [50, 24.000015258789062],
          [49.00001525878906, 24.000015258789062],
          [48.000030517578125, 25],
          [48.000030517578125, 26.000022888183594],
          [48.000030517578125, 27.00000762939453],
          [48.000030517578125, 27.99999237060547],
          [48.000030517578125, 29.000015258789062],
          [48.000030517578125, 30],
          [48.000030517578125, 31.000022888183594],
          [48.000030517578125, 32.000083923339844],
          [48.000030517578125, 32.99999237060547],
          [48.000030517578125, 34.00001525878906],
          [48.000030517578125, 35],
          [48.000030517578125, 36.000022888183594],
          [49.00001525878906, 36.000022888183594],
          [50, 37.000083923339844],
          [52.00000762939453, 37.99999237060547],
          [52.00000762939453, 39.00001525878906],
          [53.000030517578125, 39.00001525878906],
          [54.00001525878906, 40],
          [55, 40],
          [55, 41.000022888183594],
          [56.000022888183594, 42.000083923339844],
          [57.00000762939453, 42.99999237060547],
          [57.00000762939453, 44.00001525878906],
          [57.00000762939453, 45],
          [57.00000762939453, 46.000022888183594],
          [57.00000762939453, 47.000083923339844],
          [58.000030517578125, 47.000083923339844],
          [59.00001525878906, 47.000083923339844],
          [60, 47.000083923339844],
          [61.000022888183594, 47.000083923339844],
          [62.00000762939453, 47.000083923339844],
          [63.000030517578125, 47.000083923339844],
          [64.00001525878906, 46.000022888183594],
          [65, 46.000022888183594],
          [65, 45],
          [66.0000228881836, 45],
          [67.00000762939453, 45],
          [68.00003051757812, 45],
          [69.00001525878906, 44.00001525878906],
          [70, 44.00001525878906],
          [74.00001525878906, 42.000083923339844],
          [75, 42.000083923339844],
          [77.00000762939453, 42.000083923339844],
          [75, 42.000083923339844],
          [74.00001525878906, 42.000083923339844],
          [73.00003051757812, 42.000083923339844],
          [73.00003051757812, 42.99999237060547],
          [73.00003051757812, 44.00001525878906],
          [73.00003051757812, 45],
          [73.00003051757812, 46.000022888183594],
          [72.00000762939453, 46.000022888183594],
          [72.00000762939453, 47.000083923339844],
          [72.00000762939453, 47.99999237060547],
          [72.00000762939453, 50],
          [71.0000228881836, 51.000022888183594],
          [71.0000228881836, 52.000083923339844],
          [71.0000228881836, 52.99999237060547],
          [71.0000228881836, 54.00001525878906],
          [71.0000228881836, 55],
          [71.0000228881836, 56.000022888183594],
          [71.0000228881836, 57.000083923339844],
          [72.00000762939453, 57.99999237060547],
          [73.00003051757812, 57.99999237060547],
          [74.00001525878906, 57.99999237060547],
          [76.0000228881836, 57.99999237060547],
          [78.00003051757812, 57.99999237060547],
          [79.00001525878906, 57.99999237060547],
          [81.0000228881836, 59.00001525878906],
          [82.00000762939453, 59.00001525878906],
          [84.00001525878906, 59.00001525878906],
          [86.0000228881836, 59.00001525878906],
          [87.00000762939453, 59.00001525878906],
          [87.00000762939453, 57.99999237060547],
          [87.00000762939453, 57.000083923339844],
          [87.00000762939453, 56.000022888183594],
          [88.00003051757812, 56.000022888183594],
          [89.00001525878906, 55],
          [89.00001525878906, 54.00001525878906],
          [90, 54.00001525878906],
          [91.0000228881836, 52.99999237060547],
          [92.00000762939453, 52.99999237060547],
          [92.00000762939453, 52.000083923339844],
          [93.00003051757812, 51.000022888183594],
          [94.00001525878906, 51.000022888183594],
          [96.0000228881836, 50],
          [97.00000762939453, 49.00001525878906],
          [98.00003051757812, 47.99999237060547],
          [99.00001525878906, 47.99999237060547],
          [100, 47.99999237060547],
          [101.0000228881836, 47.000083923339844],
          [101.0000228881836, 46.000022888183594],
          [101.0000228881836, 45],
          [102.00000762939453, 45],
          [103.00003051757812, 45],
          [103.00003051757812, 44.00001525878906],
          [103.00003051757812, 42.99999237060547],
          [104.00001525878906, 42.000083923339844],
          [104.00001525878906, 41.000022888183594],
          [104.00001525878906, 40],
          [105, 40],
          [106.0000228881836, 40],
          [107.00000762939453, 40],
          [108.00003051757812, 40],
          [109.00001525878906, 40],
          [110, 40],
          [111.0000228881836, 40],
          [111.0000228881836, 41.000022888183594],
          [111.0000228881836, 42.000083923339844],
          [111.0000228881836, 42.99999237060547],
          [111.0000228881836, 44.00001525878906],
          [110, 44.00001525878906],
          [109.00001525878906, 45],
          [109.00001525878906, 47.000083923339844],
          [108.00003051757812, 47.99999237060547],
          [107.00000762939453, 50],
          [104.00001525878906, 54.00001525878906],
          [103.00003051757812, 55],
          [101.0000228881836, 57.000083923339844],
          [101.0000228881836, 59.00001525878906],
          [99.00001525878906, 62.000083923339844],
          [98.00003051757812, 62.99999237060547],
          [97.00000762939453, 65],
          [97.00000762939453, 67.00008392333984],
          [96.0000228881836, 67.00008392333984],
          [96.0000228881836, 67.99999237060547],
          [96.0000228881836, 69.00001525878906],
          [96.0000228881836, 70],
          [96.0000228881836, 71.0000228881836],
          [96.0000228881836, 72.00008392333984],
          [96.0000228881836, 72.99999237060547],
          [96.0000228881836, 74.00001525878906],
          [96.0000228881836, 75],
          [97.00000762939453, 75],
          [97.00000762939453, 76.0000228881836],
          [98.00003051757812, 77.00008392333984],
          [99.00001525878906, 77.00008392333984],
          [100, 77.99999237060547],
          [100, 79.00001525878906],
          [101.0000228881836, 79.00001525878906],
          [103.00003051757812, 80],
          [104.00001525878906, 80],
          [106.0000228881836, 80],
          [108.00003051757812, 80],
          [110, 80],
          [112.00000762939453, 80],
          [112.99999237060547, 80],
          [114.00005340576172, 80],
          [115.00003814697266, 80],
          [116.0000228881836, 80],
          [117.00000762939453, 80],
          [117.99999237060547, 77.99999237060547],
          [119.00005340576172, 77.99999237060547],
          [120.00003814697266, 77.99999237060547],
          [121.0000228881836, 77.99999237060547],
          [122.99999237060547, 77.99999237060547],
          [122.99999237060547, 79.00001525878906],
          [122.99999237060547, 80],
          [122.99999237060547, 81.0000228881836],
          [122.99999237060547, 82.00008392333984],
          [122.99999237060547, 82.99999237060547],
          [122.99999237060547, 84.00001525878906],
          [124.00005340576172, 85],
          [124.00005340576172, 86.0000228881836],
          [125.00003814697266, 87.00008392333984],
          [125.00003814697266, 87.99999237060547],
          [126.0000228881836, 89.00001525878906],
          [127.00000762939453, 90],
          [127.99999237060547, 90],
          [129.00005340576172, 90],
          [130.00003814697266, 90],
          [131.0000228881836, 89.00001525878906],
          [131.0000228881836, 87.99999237060547],
          [131.0000228881836, 86.0000228881836],
          [131.0000228881836, 85],
          [131.0000228881836, 84.00001525878906],
          [131.0000228881836, 82.99999237060547],
          [135.00003814697266, 82.99999237060547],
          [136.0000228881836, 84.00001525878906],
          [137.00000762939453, 84.00001525878906],
          [137.99999237060547, 84.00001525878906],
          [139.00005340576172, 84.00001525878906],
          [140.00003814697266, 84.00001525878906],
          [142.00000762939453, 84.00001525878906],
          [142.99999237060547, 84.00001525878906],
          [145.00003814697266, 84.00001525878906],
          [146.0000228881836, 84.00001525878906],
          [147.99999237060547, 82.99999237060547],
          [150.00003814697266, 82.99999237060547],
          [150.00003814697266, 82.00008392333984],
          [150.00003814697266, 81.0000228881836],
          [149.00005340576172, 81.0000228881836],
          [147.99999237060547, 80],
          [147.00000762939453, 80],
          [146.0000228881836, 79.00001525878906],
          [146.0000228881836, 77.99999237060547],
          [144.00005340576172, 77.00008392333984],
          [142.99999237060547, 76.0000228881836],
          [142.99999237060547, 75],
          [142.99999237060547, 74.00001525878906],
          [142.00000762939453, 72.99999237060547],
          [142.00000762939453, 72.00008392333984],
          [142.00000762939453, 71.0000228881836],
          [142.99999237060547, 71.0000228881836],
          [144.00005340576172, 71.0000228881836],
          [145.00003814697266, 71.0000228881836],
          [146.0000228881836, 72.00008392333984],
          [147.00000762939453, 72.00008392333984],
          [147.00000762939453, 72.99999237060547],
          [147.00000762939453, 74.00001525878906],
          [147.99999237060547, 75],
          [147.99999237060547, 76.0000228881836],
          [149.00005340576172, 77.00008392333984],
          [149.00005340576172, 77.99999237060547],
          [150.00003814697266, 80],
          [150.00003814697266, 82.00008392333984],
          [150.00003814697266, 82.99999237060547],
          [150.00003814697266, 84.00001525878906],
          [150.00003814697266, 85],
          [149.00005340576172, 86.0000228881836],
          [147.00000762939453, 87.99999237060547],
          [146.0000228881836, 89.00001525878906],
          [145.00003814697266, 90],
          [145.00003814697266, 91.0000228881836],
          [145.00003814697266, 92.00008392333984],
          [145.00003814697266, 92.99999237060547],
          [145.00003814697266, 94.00001525878906],
          [145.00003814697266, 95],
          [146.0000228881836, 96.0000228881836],
          [147.00000762939453, 96.0000228881836],
          [147.99999237060547, 96.0000228881836],
          [150.00003814697266, 96.0000228881836],
          [151.0000228881836, 96.0000228881836],
          [152.00000762939453, 96.0000228881836],
          [154.00005340576172, 96.0000228881836],
          [154.00005340576172, 97.00008392333984],
          [154.00005340576172, 97.99999237060547],
          [154.00005340576172, 99.00001525878906],
          [154.00005340576172, 101.0000228881836],
          [154.00005340576172, 102.00008392333984],
          [154.00005340576172, 102.99999237060547],
          [154.00005340576172, 104.00001525878906],
          [154.00005340576172, 105],
          [155.00003814697277, 105],
          [156.0000228881837, 105],
          [157.00000762939464, 105],
          [159.00005340576183, 105],
          [161.0000228881837, 105],
          [162.00000762939464, 106.0000228881836],
          [164.00005340576183, 107.00008392333984],
          [165.00003814697277, 107.99999237060547],
          [166.0000228881837, 109.00001525878906],
          [166.0000228881837, 110],
          [166.0000228881837, 110],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 398,
        versionNonce: 870108121,
        isDeleted: false,
        id: "Gtc5IoM8F0qfMrA3k-hic",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -984.5444312473486,
        y: -1735.218971331636,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 182.00000762939464,
        height: 151.0000228881836,
        seed: 1542856631,
        groupIds: ["UymVODq4Az1zT0QqaOrvk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700489751473,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [-0.9999847412109375, 0],
          [-2.0000457763671875, 0],
          [-3.000030517578125, 0],
          [-5, 0.9999847412109375],
          [-5.9999847412109375, 3.0000686645507812],
          [-8.000030517578125, 5],
          [-10, 5.9999847412109375],
          [-10.999984741211051, 8.000068664550781],
          [-15.000000000000114, 10],
          [-17.0000457763673, 10.999984741210938],
          [-18.00003051757824, 13.000068664550781],
          [-20.000000000000114, 13.999977111816406],
          [-22.0000457763673, 15],
          [-23.00003051757824, 15],
          [-24.000015258789176, 15.999984741210938],
          [-25.000000000000114, 17.00000762939453],
          [-25.99998474121105, 18.000030517578125],
          [-25.99998474121105, 19.000015258789062],
          [-27.0000457763673, 20],
          [-27.0000457763673, 20.999984741210938],
          [-27.0000457763673, 21.999969482421875],
          [-27.0000457763673, 23.000030517578125],
          [-27.0000457763673, 25],
          [-27.0000457763673, 25.999984741210938],
          [-25.99998474121105, 25.999984741210938],
          [-25.99998474121105, 26.999969482421875],
          [-25.000000000000114, 26.999969482421875],
          [-24.000015258789176, 26.999969482421875],
          [-23.00003051757824, 28.000030517578125],
          [-20.99998474121105, 28.000030517578125],
          [-20.000000000000114, 29.000015258789062],
          [-19.000015258789176, 29.000015258789062],
          [-17.0000457763673, 30],
          [-15.999984741211051, 30],
          [-14.000015258789176, 30],
          [-13.000030517578239, 30],
          [-10.999984741211051, 30],
          [-10.999984741211051, 30.999984741210938],
          [-10.999984741211051, 31.999969482421875],
          [-13.000030517578239, 33.000030517578125],
          [-14.000015258789176, 33.000030517578125],
          [-14.000015258789176, 34.00001525878906],
          [-15.000000000000114, 35],
          [-15.999984741211051, 35],
          [-17.0000457763673, 35],
          [-18.00003051757824, 35],
          [-19.000015258789176, 35],
          [-20.000000000000114, 35],
          [-20.99998474121105, 35],
          [-22.0000457763673, 35],
          [-23.00003051757824, 35],
          [-24.000015258789176, 35],
          [-25.000000000000114, 35],
          [-27.0000457763673, 35],
          [-27.0000457763673, 34.00001525878906],
          [-28.00003051757824, 33.000030517578125],
          [-28.00003051757824, 31.999969482421875],
          [-28.00003051757824, 30.999984741210938],
          [-28.00003051757824, 30],
          [-28.00003051757824, 29.000015258789062],
          [-28.00003051757824, 28.000030517578125],
          [-28.00003051757824, 26.999969482421875],
          [-28.00003051757824, 25.999984741210938],
          [-28.00003051757824, 24.000015258789062],
          [-28.00003051757824, 23.000030517578125],
          [-29.000015258789176, 20.999984741210938],
          [-30.000000000000114, 20],
          [-30.99998474121105, 19.000015258789062],
          [-32.0000457763673, 18.000030517578125],
          [-33.00003051757824, 17.00000762939453],
          [-34.000015258789176, 15.999984741210938],
          [-34.000015258789176, 15],
          [-35.000000000000114, 13.999977111816406],
          [-35.99998474121105, 13.999977111816406],
          [-37.0000457763673, 13.999977111816406],
          [-37.0000457763673, 13.000068664550781],
          [-37.0000457763673, 12.000007629394531],
          [-38.00003051757824, 12.000007629394531],
          [-39.000015258789176, 10.999984741210938],
          [-39.000015258789176, 10],
          [-39.000015258789176, 8.999977111816406],
          [-40.000000000000114, 8.999977111816406],
          [-40.99998474121105, 8.999977111816406],
          [-42.0000457763673, 8.999977111816406],
          [-44.000015258789176, 8.999977111816406],
          [-45.000000000000114, 8.999977111816406],
          [-49.000015258789176, 8.999977111816406],
          [-50.000000000000114, 8.999977111816406],
          [-53.00003051757824, 8.999977111816406],
          [-54.000015258789176, 8.999977111816406],
          [-56.00002288818371, 8.999977111816406],
          [-58.00003051757824, 8.999977111816406],
          [-59.000015258789176, 8.999977111816406],
          [-60.00003814697277, 8.999977111816406],
          [-61.00002288818371, 8.999977111816406],
          [-62.000007629394645, 8.999977111816406],
          [-63.00003051757824, 8.999977111816406],
          [-64.00001525878918, 8.999977111816406],
          [-65.00003814697277, 8.999977111816406],
          [-66.00002288818371, 7.000007629394531],
          [-66.00002288818371, 5.9999847412109375],
          [-66.00002288818371, 5],
          [-66.00002288818371, 3.9999771118164062],
          [-67.00000762939464, 3.9999771118164062],
          [-67.00000762939464, 3.0000686645507812],
          [-68.00003051757824, 2.0000076293945312],
          [-68.00003051757824, 0.9999847412109375],
          [-68.00003051757824, 0],
          [-68.00003051757824, -1.0000228881835938],
          [-69.00001525878918, -1.0000228881835938],
          [-70.00003814697277, -1.9999313354492188],
          [-71.00002288818371, -2.9999923706054688],
          [-72.00000762939464, -2.9999923706054688],
          [-73.00003051757824, -2.9999923706054688],
          [-73.00003051757824, -4.0000152587890625],
          [-74.00001525878918, -4.0000152587890625],
          [-76.00002288818371, -5],
          [-76.00002288818371, -6.000022888183594],
          [-77.00000762939464, -6.000022888183594],
          [-78.00003051757824, -6.999931335449219],
          [-78.00003051757824, -7.999992370605469],
          [-79.00001525878918, -7.999992370605469],
          [-79.00001525878918, -10],
          [-79.00001525878918, -11.000022888183594],
          [-80.00003814697277, -11.000022888183594],
          [-81.00002288818371, -11.000022888183594],
          [-81.00002288818371, -10],
          [-81.00002288818371, -7.999992370605469],
          [-81.00002288818371, -6.999931335449219],
          [-82.00000762939464, -6.999931335449219],
          [-83.00003051757824, -5],
          [-83.00003051757824, -4.0000152587890625],
          [-83.00003051757824, -2.9999923706054688],
          [-83.00003051757824, -1.0000228881835938],
          [-83.00003051757824, 0],
          [-83.00003051757824, 0.9999847412109375],
          [-84.00001525878918, 2.0000076293945312],
          [-84.00001525878918, 3.0000686645507812],
          [-85.00003814697277, 3.9999771118164062],
          [-86.00002288818371, 5],
          [-87.00000762939464, 5],
          [-89.00001525878918, 5.9999847412109375],
          [-91.00002288818371, 5.9999847412109375],
          [-93.00003051757824, 5.9999847412109375],
          [-95.00003814697277, 5.9999847412109375],
          [-96.00002288818371, 5.9999847412109375],
          [-97.00000762939464, 5.9999847412109375],
          [-99.00001525878918, 5.9999847412109375],
          [-100.00003814697277, 5.9999847412109375],
          [-101.00002288818371, 5.9999847412109375],
          [-102.00000762939464, 5.9999847412109375],
          [-103.00003051757824, 5.9999847412109375],
          [-104.00001525878918, 5.9999847412109375],
          [-105.00003814697277, 5.9999847412109375],
          [-106.00002288818371, 5.9999847412109375],
          [-107.00000762939464, 5.9999847412109375],
          [-107.00000762939464, 7.000007629394531],
          [-108.00003051757824, 7.000007629394531],
          [-108.00003051757824, 8.000068664550781],
          [-108.00003051757824, 8.999977111816406],
          [-108.00003051757824, 10],
          [-109.00001525878918, 10.999984741210938],
          [-109.00001525878918, 12.000007629394531],
          [-109.00001525878918, 13.000068664550781],
          [-109.00001525878918, 10.999984741210938],
          [-109.00001525878918, 10],
          [-108.00003051757824, 8.999977111816406],
          [-108.00003051757824, 8.000068664550781],
          [-107.00000762939464, 5.9999847412109375],
          [-105.00003814697277, 3.9999771118164062],
          [-104.00001525878918, 2.0000076293945312],
          [-102.00000762939464, 0.9999847412109375],
          [-102.00000762939464, -1.0000228881835938],
          [-101.00002288818371, -2.9999923706054688],
          [-100.00003814697277, -5],
          [-99.00001525878918, -6.000022888183594],
          [-97.00000762939464, -7.999992370605469],
          [-96.00002288818371, -10],
          [-95.00003814697277, -10],
          [-95.00003814697277, -11.000022888183594],
          [-94.00001525878918, -11.999931335449219],
          [-94.00001525878918, -12.999992370605469],
          [-94.00001525878918, -14.000015258789062],
          [-94.00001525878918, -15],
          [-94.00001525878918, -16.99993133544922],
          [-94.00001525878918, -17.99999237060547],
          [-94.00001525878918, -19.000015258789062],
          [-94.00001525878918, -20],
          [-94.00001525878918, -21.000022888183594],
          [-94.00001525878918, -22.99999237060547],
          [-94.00001525878918, -24.000015258789062],
          [-95.00003814697277, -25],
          [-95.00003814697277, -26.000022888183594],
          [-96.00002288818371, -26.99993133544922],
          [-97.00000762939464, -26.99993133544922],
          [-98.00003051757824, -26.99993133544922],
          [-98.00003051757824, -27.99999237060547],
          [-99.00001525878918, -27.99999237060547],
          [-100.00003814697277, -27.99999237060547],
          [-101.00002288818371, -27.99999237060547],
          [-101.00002288818371, -29.000015258789062],
          [-102.00000762939464, -29.000015258789062],
          [-103.00003051757824, -29.000015258789062],
          [-103.00003051757824, -30],
          [-104.00001525878918, -31.000022888183594],
          [-105.00003814697277, -31.000022888183594],
          [-105.00003814697277, -31.99993133544922],
          [-106.00002288818371, -31.99993133544922],
          [-107.00000762939464, -32.99999237060547],
          [-108.00003051757824, -32.99999237060547],
          [-109.00001525878918, -34.00001525878906],
          [-110.00003814697277, -34.00001525878906],
          [-111.00002288818371, -34.00001525878906],
          [-112.00000762939464, -35],
          [-112.00000762939464, -36.000022888183594],
          [-113.00003051757824, -36.99993133544922],
          [-113.00003051757824, -37.99999237060547],
          [-114.00001525878918, -39.00001525878906],
          [-114.00001525878918, -40],
          [-114.00001525878918, -41.000022888183594],
          [-114.00001525878918, -41.99993133544922],
          [-114.00001525878918, -42.99999237060547],
          [-114.00001525878918, -44.00001525878906],
          [-114.00001525878918, -45],
          [-114.00001525878918, -46.000022888183594],
          [-115.00003814697277, -46.99993133544922],
          [-116.00002288818371, -46.99993133544922],
          [-117.00000762939464, -46.99993133544922],
          [-118.00003051757824, -46.99993133544922],
          [-120.00003814697277, -46.99993133544922],
          [-123.00003051757824, -46.99993133544922],
          [-124.00001525878918, -46.99993133544922],
          [-125.00003814697277, -46.99993133544922],
          [-127.00000762939464, -46.99993133544922],
          [-128.00003051757824, -46.99993133544922],
          [-130.00003814697277, -46.99993133544922],
          [-132.00000762939464, -46.99993133544922],
          [-133.00003051757824, -46.000022888183594],
          [-135.00003814697277, -45],
          [-136.0000228881837, -44.00001525878906],
          [-136.0000228881837, -42.99999237060547],
          [-137.00000762939464, -42.99999237060547],
          [-137.00000762939464, -41.99993133544922],
          [-137.00000762939464, -41.000022888183594],
          [-138.00003051757824, -41.000022888183594],
          [-138.00003051757824, -40],
          [-138.00003051757824, -39.00001525878906],
          [-138.00003051757824, -37.99999237060547],
          [-138.00003051757824, -36.99993133544922],
          [-138.00003051757824, -36.000022888183594],
          [-138.00003051757824, -35],
          [-139.00001525878918, -34.00001525878906],
          [-139.00001525878918, -32.99999237060547],
          [-139.00001525878918, -31.99993133544922],
          [-139.00001525878918, -31.000022888183594],
          [-140.00003814697277, -31.000022888183594],
          [-141.0000228881837, -30],
          [-141.0000228881837, -29.000015258789062],
          [-142.00000762939464, -27.99999237060547],
          [-143.00003051757824, -27.99999237060547],
          [-144.00001525878918, -27.99999237060547],
          [-149.00001525878918, -27.99999237060547],
          [-151.0000228881837, -27.99999237060547],
          [-154.00001525878918, -27.99999237060547],
          [-156.0000228881837, -27.99999237060547],
          [-157.00000762939464, -27.99999237060547],
          [-159.00001525878918, -27.99999237060547],
          [-159.00001525878918, -30],
          [-159.00001525878918, -31.000022888183594],
          [-159.00001525878918, -31.99993133544922],
          [-159.00001525878918, -34.00001525878906],
          [-159.00001525878918, -35],
          [-159.00001525878918, -36.000022888183594],
          [-159.00001525878918, -36.99993133544922],
          [-159.00001525878918, -37.99999237060547],
          [-159.00001525878918, -39.00001525878906],
          [-158.00003051757824, -41.000022888183594],
          [-157.00000762939464, -41.000022888183594],
          [-156.0000228881837, -41.99993133544922],
          [-155.00003814697277, -41.99993133544922],
          [-154.00001525878918, -42.99999237060547],
          [-153.00003051757824, -44.00001525878906],
          [-152.00000762939464, -45],
          [-151.0000228881837, -46.000022888183594],
          [-150.00003814697277, -46.000022888183594],
          [-150.00003814697277, -46.99993133544922],
          [-150.00003814697277, -47.99999237060547],
          [-150.00003814697277, -49.00001525878906],
          [-150.00003814697277, -51.000022888183594],
          [-150.00003814697277, -51.99993133544922],
          [-150.00003814697277, -52.99999237060547],
          [-150.00003814697277, -55],
          [-150.00003814697277, -56.000022888183594],
          [-150.00003814697277, -57.99999237060547],
          [-150.00003814697277, -61.99993133544922],
          [-150.00003814697277, -62.99999237060547],
          [-150.00003814697277, -64.00001525878906],
          [-150.00003814697277, -65],
          [-150.00003814697277, -66.0000228881836],
          [-150.00003814697277, -66.99993133544922],
          [-149.00001525878918, -67.99999237060547],
          [-148.00003051757824, -69.00001525878906],
          [-148.00003051757824, -70],
          [-148.00003051757824, -71.0000228881836],
          [-147.00000762939464, -71.99993133544922],
          [-147.00000762939464, -72.99999237060547],
          [-147.00000762939464, -74.00001525878906],
          [-147.00000762939464, -75],
          [-147.00000762939464, -76.0000228881836],
          [-147.00000762939464, -76.99993133544922],
          [-147.00000762939464, -79.00001525878906],
          [-147.00000762939464, -81.0000228881836],
          [-147.00000762939464, -82.00000762939453],
          [-147.00000762939464, -82.99999237060547],
          [-147.00000762939464, -85],
          [-147.00000762939464, -86.0000228881836],
          [-147.00000762939464, -87.00000762939453],
          [-147.00000762939464, -87.99999237060547],
          [-148.00003051757824, -89.00001525878906],
          [-148.00003051757824, -90],
          [-149.00001525878918, -90],
          [-149.00001525878918, -91.0000228881836],
          [-150.00003814697277, -91.0000228881836],
          [-151.0000228881837, -91.0000228881836],
          [-152.00000762939464, -91.0000228881836],
          [-153.00003051757824, -91.0000228881836],
          [-154.00001525878918, -91.0000228881836],
          [-154.00001525878918, -90],
          [-155.00003814697277, -89.00001525878906],
          [-156.0000228881837, -89.00001525878906],
          [-156.0000228881837, -87.99999237060547],
          [-157.00000762939464, -87.00000762939453],
          [-157.00000762939464, -86.0000228881836],
          [-158.00003051757824, -86.0000228881836],
          [-159.00001525878918, -85],
          [-159.00001525878918, -84.00001525878906],
          [-160.00003814697277, -82.99999237060547],
          [-161.0000228881837, -82.00000762939453],
          [-161.0000228881837, -81.0000228881836],
          [-162.00000762939464, -81.0000228881836],
          [-163.00003051757824, -81.0000228881836],
          [-164.00001525878918, -81.0000228881836],
          [-165.00003814697277, -80],
          [-167.00000762939464, -80],
          [-168.00003051757824, -79.00001525878906],
          [-171.0000228881837, -79.00001525878906],
          [-174.00001525878918, -77.99999237060547],
          [-177.00000762939464, -77.99999237060547],
          [-179.00001525878918, -77.99999237060547],
          [-181.0000228881837, -77.99999237060547],
          [-182.00000762939464, -77.99999237060547],
          [-182.00000762939464, -79.00001525878906],
          [-182.00000762939464, -80],
          [-182.00000762939464, -82.00000762939453],
          [-182.00000762939464, -85],
          [-182.00000762939464, -87.00000762939453],
          [-182.00000762939464, -90],
          [-181.0000228881837, -92.00000762939453],
          [-180.00003814697277, -95],
          [-179.00001525878918, -96.0000228881836],
          [-178.00003051757824, -97.99999237060547],
          [-177.00000762939464, -99.00001525878906],
          [-176.0000228881837, -101.0000228881836],
          [-175.00003814697277, -101.0000228881836],
          [-174.00001525878918, -102.00000762939453],
          [-173.00003051757824, -102.99999237060547],
          [-172.00000762939464, -104.00001525878906],
          [-172.00000762939464, -105],
          [-171.0000228881837, -105],
          [-170.00003814697277, -106.0000228881836],
          [-169.00001525878918, -107.00000762939453],
          [-169.00001525878918, -107.99999237060547],
          [-168.00003051757824, -109.00001525878906],
          [-167.00000762939464, -110],
          [-167.00000762939464, -111.0000228881836],
          [-167.00000762939464, -112.00000762939453],
          [-166.0000228881837, -112.99999237060547],
          [-166.0000228881837, -114.00001525878906],
          [-166.0000228881837, -115],
          [-165.00003814697277, -115],
          [-163.00003051757824, -115],
          [-162.00000762939464, -115],
          [-162.00000762939464, -116.0000228881836],
          [-161.0000228881837, -116.0000228881836],
          [-160.00003814697277, -116.0000228881836],
          [-159.00001525878918, -116.0000228881836],
          [-158.00003051757824, -116.0000228881836],
          [-157.00000762939464, -116.0000228881836],
          [-156.0000228881837, -116.0000228881836],
          [-155.00003814697277, -116.0000228881836],
          [-155.00003814697277, -115],
          [-155.00003814697277, -114.00001525878906],
          [-156.0000228881837, -112.99999237060547],
          [-156.0000228881837, -112.99999237060547],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
    ],
    id: "ICHCME6BMslorQYLiJ0ic",
    created: 1700489752803,
  },
  {
    status: "published",
    elements: [
      {
        type: "ellipse",
        version: 221,
        versionNonce: 1124157571,
        isDeleted: false,
        id: "_PyimaiM0zrC6y8NmTtff",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 76.70357904620225,
        y: 27.98961792112378,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 117.63278600093656,
        height: 117.63278600093656,
        seed: 1713547683,
        groupIds: ["8d1f-Whtn2KG_iHQHXJep"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1702037280705,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 1662,
        versionNonce: 1593292077,
        isDeleted: false,
        id: "39Iten9E56t8C-9N4khVI",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 79.13732368896903,
        y: 71.79770232791303,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 172.7985929843901,
        height: 74.63594980840287,
        seed: 738426179,
        groupIds: ["8d1f-Whtn2KG_iHQHXJep"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1702037280705,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-28.394120758846725, -4.05636485982086],
          [-17.84770829070942, -47.05313915808324],
          [21.092824936274724, -73.01345337989176],
          [80.31481109673507, -74.63594980840287],
          [114.387793143914, -65.71215755731953],
          [133.04681154315028, -43.80802251251794],
          [144.40447222554337, -7.30135771684354],
          [128.1792603633454, -2.4338065370384356],
          [110.33149017836445, -3.24511664556519],
        ],
      },
    ],
    id: "qGKEbFMb3CP49NAf3HCjz",
    created: 1702037282527,
  },
  {
    status: "published",
    elements: [
      {
        type: "freedraw",
        version: 1138,
        versionNonce: 721047779,
        isDeleted: false,
        id: "v2dFVGNn52lI5MFNFYptu",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 124.754888756029,
        y: 78.70742716892858,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 552.0000610351562,
        height: 505.5999755859375,
        seed: 122690147,
        groupIds: ["CAVROA4MzXIBSXv3n0fco"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037223574,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [0, -1.60003662109375],
          [0, -3.20001220703125],
          [0, -4.79998779296875],
          [0, -6.4000244140625],
          [0, -8],
          [0, -11.20001220703125],
          [1.5999755859375, -12.79998779296875],
          [3.199951171875, -14.4000244140625],
          [4.7999267578125, -16],
          [4.7999267578125, -19.20001220703125],
          [4.7999267578125, -20.79998779296875],
          [6.4000244140625, -22.4000244140625],
          [8, -24],
          [8, -25.60003662109375],
          [8, -27.20001220703125],
          [8, -28.79998779296875],
          [9.5999755859375, -30.4000244140625],
          [9.5999755859375, -32],
          [11.199951171875, -33.60003662109375],
          [11.199951171875, -35.20001220703125],
          [12.7999267578125, -36.79998779296875],
          [14.4000244140625, -36.79998779296875],
          [16, -38.4000244140625],
          [17.5999755859375, -40],
          [17.5999755859375, -41.60003662109375],
          [17.5999755859375, -43.20001220703125],
          [17.5999755859375, -44.79998779296875],
          [17.5999755859375, -46.4000244140625],
          [17.5999755859375, -48],
          [17.5999755859375, -49.60003662109375],
          [17.5999755859375, -51.20001220703125],
          [17.5999755859375, -52.79998779296875],
          [17.5999755859375, -54.4000244140625],
          [17.5999755859375, -56],
          [17.5999755859375, -57.60003662109375],
          [17.5999755859375, -59.20001220703125],
          [17.5999755859375, -60.79998779296875],
          [17.5999755859375, -62.4000244140625],
          [17.5999755859375, -64],
          [17.5999755859375, -65.60003662109375],
          [17.5999755859375, -67.20001220703125],
          [17.5999755859375, -68.79998779296875],
          [19.199951171875, -70.4000244140625],
          [19.199951171875, -72],
          [19.199951171875, -73.60003662109375],
          [20.7999267578125, -76.79998779296875],
          [20.7999267578125, -78.4000244140625],
          [20.7999267578125, -80],
          [20.7999267578125, -81.60003662109375],
          [22.4000244140625, -83.20001220703125],
          [22.4000244140625, -84.79998779296875],
          [22.4000244140625, -86.4000244140625],
          [22.4000244140625, -88],
          [22.4000244140625, -89.60003662109375],
          [22.4000244140625, -91.20001220703125],
          [22.4000244140625, -92.79998779296875],
          [22.4000244140625, -94.4000244140625],
          [22.4000244140625, -96],
          [22.4000244140625, -97.60003662109375],
          [22.4000244140625, -99.20001220703125],
          [22.4000244140625, -100.79998779296875],
          [22.4000244140625, -102.4000244140625],
          [22.4000244140625, -104],
          [22.4000244140625, -105.60003662109375],
          [22.4000244140625, -107.20001220703125],
          [24, -108.79998779296875],
          [25.5999755859375, -108.79998779296875],
          [27.199951171875, -108.79998779296875],
          [28.7999267578125, -108.79998779296875],
          [28.7999267578125, -112],
          [30.4000244140625, -112],
          [32, -112],
          [33.5999755859375, -112],
          [33.5999755859375, -113.60003662109375],
          [35.199951171875, -115.20001220703125],
          [36.7999267578125, -115.20001220703125],
          [36.7999267578125, -116.79998779296875],
          [38.4000244140625, -116.79998779296875],
          [40, -116.79998779296875],
          [41.5999755859375, -116.79998779296875],
          [44.7999267578125, -120],
          [46.4000244140625, -120],
          [48, -120],
          [51.199951171875, -120],
          [54.4000244140625, -120],
          [56, -120],
          [59.199951171875, -121.60003662109375],
          [60.7999267578125, -121.60003662109375],
          [65.5999755859375, -123.20001220703125],
          [68.7999267578125, -124.79995727539062],
          [72, -124.79995727539062],
          [73.5999755859375, -126.4000244140625],
          [78.4000244140625, -126.4000244140625],
          [80, -128.00003051757812],
          [81.5999755859375, -128.00003051757812],
          [81.5999755859375, -129.60000610351562],
          [83.199951171875, -129.60000610351562],
          [84.7999267578125, -129.60000610351562],
          [86.4000244140625, -129.60000610351562],
          [89.5999755859375, -131.20001220703125],
          [91.199951171875, -131.20001220703125],
          [92.7999267578125, -131.20001220703125],
          [96, -131.20001220703125],
          [100.7999267578125, -131.20001220703125],
          [104, -132.79995727539062],
          [108.7999267578125, -132.79995727539062],
          [113.5999755859375, -134.4000244140625],
          [118.4000244140625, -136.00003051757812],
          [121.5999755859375, -136.00003051757812],
          [124.7999267578125, -136.00003051757812],
          [128, -139.20001220703125],
          [129.5999755859375, -140.79995727539062],
          [131.199951171875, -142.4000244140625],
          [132.7999267578125, -142.4000244140625],
          [132.7999267578125, -144.00003051757812],
          [134.4000244140625, -145.60000610351562],
          [136, -145.60000610351562],
          [137.5999755859375, -147.20001220703125],
          [137.5999755859375, -148.79995727539062],
          [139.199951171875, -150.4000244140625],
          [140.7999267578125, -150.4000244140625],
          [140.7999267578125, -152.00003051757812],
          [140.7999267578125, -153.60000610351562],
          [142.4000244140625, -153.60000610351562],
          [144, -156.79995727539062],
          [145.5999755859375, -160.00003051757812],
          [148.7999267578125, -161.60000610351562],
          [148.7999267578125, -164.79995727539062],
          [150.4000244140625, -168.00003051757812],
          [152, -169.60000610351562],
          [153.5999755859375, -169.60000610351562],
          [153.5999755859375, -171.20001220703125],
          [153.5999755859375, -172.79995727539062],
          [155.199951171875, -172.79995727539062],
          [156.7999267578125, -174.4000244140625],
          [158.4000244140625, -176.00003051757812],
          [158.4000244140625, -177.60000610351562],
          [160, -177.60000610351562],
          [161.5999755859375, -177.60000610351562],
          [163.199951171875, -179.20001220703125],
          [164.7999267578125, -179.20001220703125],
          [166.4000244140625, -179.20001220703125],
          [168, -180.79995727539062],
          [169.5999755859375, -180.79995727539062],
          [171.199951171875, -180.79995727539062],
          [172.7999267578125, -182.4000244140625],
          [174.4000244140625, -182.4000244140625],
          [176, -184.00003051757812],
          [179.199951171875, -185.60000610351562],
          [180.7999267578125, -187.20001220703125],
          [182.4000244140625, -190.4000244140625],
          [184, -190.4000244140625],
          [185.5999755859375, -190.4000244140625],
          [187.199951171875, -190.4000244140625],
          [188.7999267578125, -190.4000244140625],
          [190.4000244140625, -190.4000244140625],
          [192, -190.4000244140625],
          [193.5999755859375, -190.4000244140625],
          [195.199951171875, -190.4000244140625],
          [196.7999267578125, -190.4000244140625],
          [198.4000244140625, -190.4000244140625],
          [200, -190.4000244140625],
          [201.5999755859375, -192.00003051757812],
          [203.199951171875, -193.60000610351562],
          [204.7999267578125, -195.20001220703125],
          [206.4000244140625, -192.00003051757812],
          [206.4000244140625, -190.4000244140625],
          [208, -190.4000244140625],
          [209.5999755859375, -188.79995727539062],
          [209.5999755859375, -187.20001220703125],
          [211.199951171875, -187.20001220703125],
          [211.199951171875, -185.60000610351562],
          [211.199951171875, -184.00003051757812],
          [212.7999267578125, -182.4000244140625],
          [214.4000244140625, -182.4000244140625],
          [214.4000244140625, -180.79995727539062],
          [216, -179.20001220703125],
          [217.5999755859375, -179.20001220703125],
          [219.199951171875, -179.20001220703125],
          [219.199951171875, -177.60000610351562],
          [220.7999267578125, -176.00003051757812],
          [222.4000244140625, -176.00003051757812],
          [222.4000244140625, -174.4000244140625],
          [224, -174.4000244140625],
          [225.5999755859375, -174.4000244140625],
          [227.199951171875, -172.79995727539062],
          [230.4000244140625, -172.79995727539062],
          [232, -172.79995727539062],
          [235.199951171875, -171.20001220703125],
          [240, -171.20001220703125],
          [241.5999755859375, -171.20001220703125],
          [243.199951171875, -169.60000610351562],
          [244.7999267578125, -169.60000610351562],
          [246.4000244140625, -169.60000610351562],
          [248, -168.00003051757812],
          [249.5999755859375, -166.4000244140625],
          [249.5999755859375, -164.79995727539062],
          [249.5999755859375, -161.60000610351562],
          [249.5999755859375, -160.00003051757812],
          [249.5999755859375, -156.79995727539062],
          [249.5999755859375, -153.60000610351562],
          [249.5999755859375, -150.4000244140625],
          [249.5999755859375, -147.20001220703125],
          [249.5999755859375, -145.60000610351562],
          [249.5999755859375, -142.4000244140625],
          [249.5999755859375, -140.79995727539062],
          [249.5999755859375, -139.20001220703125],
          [249.5999755859375, -137.60000610351562],
          [249.5999755859375, -136.00003051757812],
          [249.5999755859375, -134.4000244140625],
          [249.5999755859375, -132.79995727539062],
          [249.5999755859375, -131.20001220703125],
          [249.5999755859375, -129.60000610351562],
          [249.5999755859375, -128.00003051757812],
          [249.5999755859375, -126.4000244140625],
          [249.5999755859375, -124.79995727539062],
          [251.199951171875, -123.20001220703125],
          [252.7999267578125, -120],
          [256, -116.79998779296875],
          [257.5999755859375, -115.20001220703125],
          [259.199951171875, -113.60003662109375],
          [260.7999267578125, -113.60003662109375],
          [260.7999267578125, -110.4000244140625],
          [260.7999267578125, -107.20001220703125],
          [260.7999267578125, -105.60003662109375],
          [260.7999267578125, -102.4000244140625],
          [260.7999267578125, -97.60003662109375],
          [260.7999267578125, -96],
          [260.7999267578125, -94.4000244140625],
          [260.7999267578125, -91.20001220703125],
          [260.7999267578125, -89.60003662109375],
          [260.7999267578125, -88],
          [260.7999267578125, -86.4000244140625],
          [260.7999267578125, -84.79998779296875],
          [260.7999267578125, -83.20001220703125],
          [260.7999267578125, -81.60003662109375],
          [260.7999267578125, -80],
          [260.7999267578125, -78.4000244140625],
          [260.7999267578125, -76.79998779296875],
          [260.7999267578125, -75.20001220703125],
          [259.199951171875, -73.60003662109375],
          [259.199951171875, -72],
          [259.199951171875, -70.4000244140625],
          [259.199951171875, -67.20001220703125],
          [259.199951171875, -65.60003662109375],
          [259.199951171875, -64],
          [259.199951171875, -62.4000244140625],
          [259.199951171875, -60.79998779296875],
          [259.199951171875, -57.60003662109375],
          [260.7999267578125, -52.79998779296875],
          [262.4000244140625, -49.60003662109375],
          [264, -46.4000244140625],
          [267.199951171875, -43.20001220703125],
          [267.199951171875, -40],
          [268.7999267578125, -38.4000244140625],
          [270.4000244140625, -35.20001220703125],
          [272, -33.60003662109375],
          [273.5999755859375, -32],
          [273.5999755859375, -30.4000244140625],
          [275.199951171875, -28.79998779296875],
          [276.7999267578125, -28.79998779296875],
          [276.7999267578125, -27.20001220703125],
          [278.4000244140625, -27.20001220703125],
          [280, -25.60003662109375],
          [281.5999755859375, -25.60003662109375],
          [281.5999755859375, -24],
          [283.199951171875, -24],
          [284.7999267578125, -24],
          [284.7999267578125, -22.4000244140625],
          [286.4000244140625, -20.79998779296875],
          [288, -20.79998779296875],
          [288, -19.20001220703125],
          [288, -17.60003662109375],
          [288, -16],
          [288, -14.4000244140625],
          [288, -12.79998779296875],
          [284.7999267578125, -9.60003662109375],
          [284.7999267578125, -8],
          [283.199951171875, -8],
          [283.199951171875, -6.4000244140625],
          [281.5999755859375, -4.79998779296875],
          [281.5999755859375, -3.20001220703125],
          [281.5999755859375, -1.60003662109375],
          [281.5999755859375, 0],
          [281.5999755859375, 1.5999755859375],
          [281.5999755859375, 3.20001220703125],
          [280, 3.20001220703125],
          [280, 4.79998779296875],
          [280, 6.39996337890625],
          [276.7999267578125, 8],
          [275.199951171875, 11.20001220703125],
          [275.199951171875, 12.79998779296875],
          [273.5999755859375, 14.39996337890625],
          [273.5999755859375, 16],
          [273.5999755859375, 17.5999755859375],
          [273.5999755859375, 19.20001220703125],
          [272, 22.39996337890625],
          [272, 24],
          [272, 25.5999755859375],
          [270.4000244140625, 27.20001220703125],
          [268.7999267578125, 27.20001220703125],
          [268.7999267578125, 28.79998779296875],
          [267.199951171875, 30.39996337890625],
          [267.199951171875, 32],
          [267.199951171875, 33.5999755859375],
          [267.199951171875, 35.20001220703125],
          [265.5999755859375, 35.20001220703125],
          [265.5999755859375, 36.79998779296875],
          [265.5999755859375, 38.39996337890625],
          [264, 38.39996337890625],
          [264, 40],
          [264, 41.5999755859375],
          [264, 43.20001220703125],
          [264, 44.79998779296875],
          [264, 46.39996337890625],
          [264, 48],
          [264, 49.5999755859375],
          [262.4000244140625, 52.79998779296875],
          [262.4000244140625, 54.39996337890625],
          [262.4000244140625, 57.5999755859375],
          [262.4000244140625, 59.20001220703125],
          [262.4000244140625, 60.79998779296875],
          [262.4000244140625, 62.39996337890625],
          [262.4000244140625, 64],
          [262.4000244140625, 65.5999755859375],
          [262.4000244140625, 67.20001220703125],
          [260.7999267578125, 67.20001220703125],
          [259.199951171875, 68.79998779296875],
          [259.199951171875, 70.39996337890625],
          [257.5999755859375, 72],
          [256, 72],
          [256, 73.5999755859375],
          [254.4000244140625, 75.20001220703125],
          [249.5999755859375, 76.79998779296875],
          [246.4000244140625, 78.39996337890625],
          [244.7999267578125, 80],
          [243.199951171875, 81.5999755859375],
          [243.199951171875, 83.20001220703125],
          [243.199951171875, 84.79998779296875],
          [241.5999755859375, 86.39996337890625],
          [241.5999755859375, 88],
          [240, 89.5999755859375],
          [240, 91.20001220703125],
          [240, 92.79998779296875],
          [240, 94.39996337890625],
          [240, 96],
          [240, 97.5999755859375],
          [240, 99.20001220703125],
          [240, 100.79998779296875],
          [240, 102.39996337890625],
          [240, 104],
          [240, 105.5999755859375],
          [240, 107.20001220703125],
          [240, 108.79998779296875],
          [240, 110.39996337890625],
          [238.4000244140625, 113.5999755859375],
          [236.7999267578125, 115.20001220703125],
          [235.199951171875, 116.79998779296875],
          [235.199951171875, 118.39996337890625],
          [235.199951171875, 120],
          [235.199951171875, 121.5999755859375],
          [233.5999755859375, 121.5999755859375],
          [233.5999755859375, 123.20001220703125],
          [233.5999755859375, 124.79998779296875],
          [232, 124.79998779296875],
          [232, 126.39996337890625],
          [232, 128],
          [232, 129.5999755859375],
          [232, 131.20001220703125],
          [232, 132.79998779296875],
          [232, 134.39996337890625],
          [232, 137.5999755859375],
          [232, 139.20001220703125],
          [232, 140.79998779296875],
          [232, 142.39996337890625],
          [232, 145.5999755859375],
          [232, 147.20001220703125],
          [232, 148.79998779296875],
          [230.4000244140625, 150.39996337890625],
          [228.7999267578125, 152],
          [227.199951171875, 153.5999755859375],
          [225.5999755859375, 153.5999755859375],
          [224, 153.5999755859375],
          [222.4000244140625, 153.5999755859375],
          [220.7999267578125, 153.5999755859375],
          [219.199951171875, 153.5999755859375],
          [217.5999755859375, 153.5999755859375],
          [216, 153.5999755859375],
          [214.4000244140625, 153.5999755859375],
          [211.199951171875, 155.20001220703125],
          [211.199951171875, 156.79998779296875],
          [211.199951171875, 158.39996337890625],
          [209.5999755859375, 158.39996337890625],
          [208, 160],
          [208, 161.5999755859375],
          [206.4000244140625, 161.5999755859375],
          [206.4000244140625, 163.20001220703125],
          [204.7999267578125, 164.79998779296875],
          [203.199951171875, 164.79998779296875],
          [203.199951171875, 166.39996337890625],
          [203.199951171875, 168],
          [203.199951171875, 169.5999755859375],
          [201.5999755859375, 169.5999755859375],
          [200, 171.20001220703125],
          [198.4000244140625, 172.79998779296875],
          [196.7999267578125, 174.39996337890625],
          [195.199951171875, 176],
          [195.199951171875, 177.5999755859375],
          [193.5999755859375, 177.5999755859375],
          [192, 177.5999755859375],
          [192, 179.20001220703125],
          [190.4000244140625, 179.20001220703125],
          [188.7999267578125, 179.20001220703125],
          [185.5999755859375, 180.79998779296875],
          [184, 182.39996337890625],
          [182.4000244140625, 182.39996337890625],
          [180.7999267578125, 182.39996337890625],
          [179.199951171875, 182.39996337890625],
          [177.5999755859375, 184],
          [176, 184],
          [174.4000244140625, 184],
          [174.4000244140625, 185.5999755859375],
          [172.7999267578125, 185.5999755859375],
          [171.199951171875, 185.5999755859375],
          [171.199951171875, 187.20001220703125],
          [169.5999755859375, 188.79998779296875],
          [168, 192],
          [168, 193.5999755859375],
          [166.4000244140625, 193.5999755859375],
          [166.4000244140625, 195.20001220703125],
          [166.4000244140625, 196.79998779296875],
          [166.4000244140625, 198.39996337890625],
          [164.7999267578125, 200],
          [164.7999267578125, 201.5999755859375],
          [163.199951171875, 203.20001220703125],
          [161.5999755859375, 206.39996337890625],
          [161.5999755859375, 208],
          [161.5999755859375, 209.5999755859375],
          [161.5999755859375, 211.20001220703125],
          [160, 212.79998779296875],
          [158.4000244140625, 214.39996337890625],
          [158.4000244140625, 216],
          [156.7999267578125, 217.5999755859375],
          [153.5999755859375, 220.79998779296875],
          [152, 220.79998779296875],
          [148.7999267578125, 220.79998779296875],
          [147.199951171875, 220.79998779296875],
          [144, 222.39996337890625],
          [142.4000244140625, 224],
          [140.7999267578125, 225.5999755859375],
          [139.199951171875, 225.5999755859375],
          [136, 225.5999755859375],
          [134.4000244140625, 225.5999755859375],
          [132.7999267578125, 225.5999755859375],
          [131.199951171875, 227.20001220703125],
          [129.5999755859375, 227.20001220703125],
          [128, 227.20001220703125],
          [126.4000244140625, 227.20001220703125],
          [124.7999267578125, 227.20001220703125],
          [121.5999755859375, 227.20001220703125],
          [121.5999755859375, 228.79998779296875],
          [120, 228.79998779296875],
          [118.4000244140625, 228.79998779296875],
          [116.7999267578125, 230.39996337890625],
          [113.5999755859375, 230.39996337890625],
          [112, 228.79998779296875],
          [110.4000244140625, 227.20001220703125],
          [108.7999267578125, 224],
          [105.5999755859375, 224],
          [104, 224],
          [104, 222.39996337890625],
          [102.4000244140625, 222.39996337890625],
          [100.7999267578125, 220.79998779296875],
          [99.199951171875, 219.20001220703125],
          [96, 216],
          [96, 214.39996337890625],
          [94.4000244140625, 212.79998779296875],
          [92.7999267578125, 209.5999755859375],
          [91.199951171875, 208],
          [89.5999755859375, 206.39996337890625],
          [89.5999755859375, 204.79998779296875],
          [88, 201.5999755859375],
          [88, 200],
          [88, 196.79998779296875],
          [88, 195.20001220703125],
          [88, 193.5999755859375],
          [88, 190.39996337890625],
          [88, 188.79998779296875],
          [88, 187.20001220703125],
          [88, 185.5999755859375],
          [88, 184],
          [88, 182.39996337890625],
          [88, 180.79998779296875],
          [88, 177.5999755859375],
          [88, 176],
          [88, 174.39996337890625],
          [88, 172.79998779296875],
          [88, 171.20001220703125],
          [88, 169.5999755859375],
          [88, 168],
          [88, 166.39996337890625],
          [88, 164.79998779296875],
          [84.7999267578125, 161.5999755859375],
          [84.7999267578125, 158.39996337890625],
          [84.7999267578125, 155.20001220703125],
          [84.7999267578125, 153.5999755859375],
          [84.7999267578125, 152],
          [84.7999267578125, 150.39996337890625],
          [84.7999267578125, 147.20001220703125],
          [83.199951171875, 144],
          [83.199951171875, 140.79998779296875],
          [81.5999755859375, 137.5999755859375],
          [81.5999755859375, 134.39996337890625],
          [81.5999755859375, 132.79998779296875],
          [81.5999755859375, 129.5999755859375],
          [80, 129.5999755859375],
          [80, 128],
          [80, 126.39996337890625],
          [78.4000244140625, 124.79998779296875],
          [78.4000244140625, 123.20001220703125],
          [78.4000244140625, 121.5999755859375],
          [76.7999267578125, 121.5999755859375],
          [76.7999267578125, 120],
          [76.7999267578125, 118.39996337890625],
          [75.199951171875, 116.79998779296875],
          [75.199951171875, 113.5999755859375],
          [75.199951171875, 112],
          [73.5999755859375, 110.39996337890625],
          [73.5999755859375, 107.20001220703125],
          [72, 104],
          [72, 100.79998779296875],
          [70.4000244140625, 94.39996337890625],
          [68.7999267578125, 94.39996337890625],
          [68.7999267578125, 89.5999755859375],
          [64, 81.5999755859375],
          [62.4000244140625, 76.79998779296875],
          [60.7999267578125, 72],
          [60.7999267578125, 67.20001220703125],
          [59.199951171875, 62.39996337890625],
          [59.199951171875, 57.5999755859375],
          [57.5999755859375, 54.39996337890625],
          [57.5999755859375, 51.20001220703125],
          [56, 48],
          [54.4000244140625, 46.39996337890625],
          [54.4000244140625, 44.79998779296875],
          [52.7999267578125, 43.20001220703125],
          [52.7999267578125, 41.5999755859375],
          [52.7999267578125, 40],
          [51.199951171875, 40],
          [49.5999755859375, 36.79998779296875],
          [49.5999755859375, 35.20001220703125],
          [48, 35.20001220703125],
          [46.4000244140625, 32],
          [40, 27.20001220703125],
          [38.4000244140625, 27.20001220703125],
          [36.7999267578125, 27.20001220703125],
          [36.7999267578125, 25.5999755859375],
          [35.199951171875, 25.5999755859375],
          [35.199951171875, 24],
          [33.5999755859375, 24],
          [32, 24],
          [30.4000244140625, 24],
          [28.7999267578125, 22.39996337890625],
          [27.199951171875, 20.79998779296875],
          [24, 20.79998779296875],
          [20.7999267578125, 19.20001220703125],
          [19.199951171875, 17.5999755859375],
          [17.5999755859375, 17.5999755859375],
          [16, 17.5999755859375],
          [14.4000244140625, 16],
          [12.7999267578125, 14.39996337890625],
          [11.199951171875, 14.39996337890625],
          [9.5999755859375, 12.79998779296875],
          [6.4000244140625, 9.5999755859375],
          [3.199951171875, 8],
          [1.5999755859375, 6.39996337890625],
          [0, 6.39996337890625],
          [0, 4.79998779296875],
          [-1.5999755859375, 4.79998779296875],
          [-3.2000732421875, 3.20001220703125],
          [-4.800048828125, 1.5999755859375],
          [-6.4000244140625, 1.5999755859375],
          [-8, 1.5999755859375],
          [-11.2000732421875, 3.20001220703125],
          [-14.4000244140625, 4.79998779296875],
          [-16, 4.79998779296875],
          [-19.2000732421875, 4.79998779296875],
          [-22.4000244140625, 6.39996337890625],
          [-24, 8],
          [-25.5999755859375, 9.5999755859375],
          [-27.2000732421875, 9.5999755859375],
          [-28.800048828125, 9.5999755859375],
          [-28.800048828125, 11.20001220703125],
          [-30.4000244140625, 11.20001220703125],
          [-32, 12.79998779296875],
          [-33.5999755859375, 14.39996337890625],
          [-36.800048828125, 16],
          [-38.4000244140625, 17.5999755859375],
          [-43.2000732421875, 20.79998779296875],
          [-43.2000732421875, 22.39996337890625],
          [-44.800048828125, 22.39996337890625],
          [-46.4000244140625, 24],
          [-46.4000244140625, 25.5999755859375],
          [-48, 27.20001220703125],
          [-49.5999755859375, 27.20001220703125],
          [-51.2000732421875, 28.79998779296875],
          [-51.2000732421875, 30.39996337890625],
          [-52.800048828125, 30.39996337890625],
          [-52.800048828125, 32],
          [-54.4000244140625, 33.5999755859375],
          [-54.4000244140625, 35.20001220703125],
          [-56, 35.20001220703125],
          [-57.5999755859375, 36.79998779296875],
          [-59.2000732421875, 38.39996337890625],
          [-59.2000732421875, 40],
          [-60.800048828125, 41.5999755859375],
          [-62.4000244140625, 43.20001220703125],
          [-64, 44.79998779296875],
          [-67.2000732421875, 48],
          [-68.800048828125, 49.5999755859375],
          [-70.4000244140625, 51.20001220703125],
          [-72, 51.20001220703125],
          [-72, 52.79998779296875],
          [-73.5999755859375, 56],
          [-75.2000732421875, 57.5999755859375],
          [-75.2000732421875, 59.20001220703125],
          [-75.2000732421875, 60.79998779296875],
          [-75.2000732421875, 64],
          [-75.2000732421875, 65.5999755859375],
          [-75.2000732421875, 67.20001220703125],
          [-75.2000732421875, 68.79998779296875],
          [-75.2000732421875, 72],
          [-75.2000732421875, 73.5999755859375],
          [-75.2000732421875, 76.79998779296875],
          [-76.800048828125, 80],
          [-76.800048828125, 81.5999755859375],
          [-76.800048828125, 84.79998779296875],
          [-78.4000244140625, 88],
          [-80, 88],
          [-80, 89.5999755859375],
          [-81.5999755859375, 91.20001220703125],
          [-83.2000732421875, 92.79998779296875],
          [-83.2000732421875, 94.39996337890625],
          [-83.2000732421875, 96],
          [-84.800048828125, 97.5999755859375],
          [-86.4000244140625, 99.20001220703125],
          [-86.4000244140625, 100.79998779296875],
          [-88, 102.39996337890625],
          [-89.5999755859375, 104],
          [-91.2000732421875, 104],
          [-91.2000732421875, 105.5999755859375],
          [-91.2000732421875, 107.20001220703125],
          [-92.800048828125, 108.79998779296875],
          [-94.4000244140625, 108.79998779296875],
          [-96, 110.39996337890625],
          [-99.2000732421875, 113.5999755859375],
          [-100.800048828125, 113.5999755859375],
          [-100.800048828125, 115.20001220703125],
          [-100.800048828125, 116.79998779296875],
          [-102.4000244140625, 118.39996337890625],
          [-104, 118.39996337890625],
          [-104, 120],
          [-104, 121.5999755859375],
          [-107.2000732421875, 123.20001220703125],
          [-107.2000732421875, 126.39996337890625],
          [-107.2000732421875, 128],
          [-110.4000244140625, 129.5999755859375],
          [-110.4000244140625, 132.79998779296875],
          [-110.4000244140625, 134.39996337890625],
          [-110.4000244140625, 139.20001220703125],
          [-110.4000244140625, 142.39996337890625],
          [-110.4000244140625, 144],
          [-110.4000244140625, 145.5999755859375],
          [-112, 148.79998779296875],
          [-112, 150.39996337890625],
          [-112, 153.5999755859375],
          [-112, 155.20001220703125],
          [-113.5999755859375, 155.20001220703125],
          [-113.5999755859375, 158.39996337890625],
          [-115.2000732421875, 161.5999755859375],
          [-115.2000732421875, 163.20001220703125],
          [-115.2000732421875, 164.79998779296875],
          [-116.800048828125, 166.39996337890625],
          [-116.800048828125, 168],
          [-116.800048828125, 169.5999755859375],
          [-116.800048828125, 171.20001220703125],
          [-116.800048828125, 172.79998779296875],
          [-116.800048828125, 174.39996337890625],
          [-116.800048828125, 176],
          [-116.800048828125, 179.20001220703125],
          [-116.800048828125, 180.79998779296875],
          [-116.800048828125, 182.39996337890625],
          [-116.800048828125, 184],
          [-116.800048828125, 185.5999755859375],
          [-116.800048828125, 187.20001220703125],
          [-118.4000244140625, 187.20001220703125],
          [-120, 188.79998779296875],
          [-120, 190.39996337890625],
          [-120, 192],
          [-121.5999755859375, 193.5999755859375],
          [-121.5999755859375, 195.20001220703125],
          [-123.2000732421875, 195.20001220703125],
          [-123.2000732421875, 196.79998779296875],
          [-123.2000732421875, 198.39996337890625],
          [-124.800048828125, 198.39996337890625],
          [-124.800048828125, 200],
          [-124.800048828125, 201.5999755859375],
          [-124.800048828125, 203.20001220703125],
          [-124.800048828125, 204.79998779296875],
          [-126.4000244140625, 206.39996337890625],
          [-128, 206.39996337890625],
          [-128, 208],
          [-129.5999755859375, 209.5999755859375],
          [-131.2000732421875, 209.5999755859375],
          [-132.800048828125, 209.5999755859375],
          [-134.4000244140625, 209.5999755859375],
          [-137.5999755859375, 209.5999755859375],
          [-140.800048828125, 206.39996337890625],
          [-142.4000244140625, 204.79998779296875],
          [-144, 204.79998779296875],
          [-145.5999755859375, 203.20001220703125],
          [-147.2000732421875, 203.20001220703125],
          [-148.800048828125, 203.20001220703125],
          [-150.4000244140625, 203.20001220703125],
          [-152, 200],
          [-155.2000732421875, 198.39996337890625],
          [-160, 195.20001220703125],
          [-161.5999755859375, 193.5999755859375],
          [-163.2000732421875, 192],
          [-164.800048828125, 190.39996337890625],
          [-166.4000244140625, 188.79998779296875],
          [-168, 187.20001220703125],
          [-169.5999755859375, 185.5999755859375],
          [-172.800048828125, 182.39996337890625],
          [-174.4000244140625, 180.79998779296875],
          [-176, 179.20001220703125],
          [-177.5999755859375, 177.5999755859375],
          [-179.2000732421875, 176],
          [-180.800048828125, 176],
          [-182.4000244140625, 174.39996337890625],
          [-187.2000732421875, 174.39996337890625],
          [-188.800048828125, 174.39996337890625],
          [-192, 174.39996337890625],
          [-196.800048828125, 174.39996337890625],
          [-200, 174.39996337890625],
          [-201.5999755859375, 174.39996337890625],
          [-203.2000732421875, 174.39996337890625],
          [-203.2000732421875, 172.79998779296875],
          [-206.4000244140625, 169.5999755859375],
          [-209.5999755859375, 166.39996337890625],
          [-211.2000732421875, 164.79998779296875],
          [-212.800048828125, 160],
          [-216, 155.20001220703125],
          [-217.5999755859375, 150.39996337890625],
          [-219.2000732421875, 147.20001220703125],
          [-222.4000244140625, 137.5999755859375],
          [-222.4000244140625, 134.39996337890625],
          [-222.4000244140625, 132.79998779296875],
          [-222.4000244140625, 131.20001220703125],
          [-224, 129.5999755859375],
          [-224, 128],
          [-224, 126.39996337890625],
          [-224, 124.79998779296875],
          [-224, 123.20001220703125],
          [-224, 121.5999755859375],
          [-224, 120],
          [-224, 118.39996337890625],
          [-224, 116.79998779296875],
          [-224, 115.20001220703125],
          [-224, 113.5999755859375],
          [-225.5999755859375, 113.5999755859375],
          [-228.800048828125, 113.5999755859375],
          [-230.4000244140625, 113.5999755859375],
          [-233.5999755859375, 113.5999755859375],
          [-238.4000244140625, 113.5999755859375],
          [-241.5999755859375, 113.5999755859375],
          [-246.4000244140625, 113.5999755859375],
          [-252.800048828125, 110.39996337890625],
          [-257.60003662109375, 110.39996337890625],
          [-260.800048828125, 110.39996337890625],
          [-262.4000244140625, 108.79998779296875],
          [-262.4000244140625, 107.20001220703125],
          [-264.00006103515625, 105.5999755859375],
          [-264.00006103515625, 104],
          [-264.00006103515625, 102.39996337890625],
          [-264.00006103515625, 100.79998779296875],
          [-264.00006103515625, 99.20001220703125],
          [-264.00006103515625, 97.5999755859375],
          [-264.00006103515625, 96],
          [-264.00006103515625, 92.79998779296875],
          [-264.00006103515625, 91.20001220703125],
          [-264.00006103515625, 89.5999755859375],
          [-264.00006103515625, 88],
          [-264.00006103515625, 86.39996337890625],
          [-264.00006103515625, 84.79998779296875],
          [-264.00006103515625, 83.20001220703125],
          [-264.00006103515625, 81.5999755859375],
          [-264.00006103515625, 80],
          [-264.00006103515625, 78.39996337890625],
          [-264.00006103515625, 76.79998779296875],
          [-264.00006103515625, 75.20001220703125],
          [-264.00006103515625, 73.5999755859375],
          [-264.00006103515625, 72],
          [-262.4000244140625, 70.39996337890625],
          [-262.4000244140625, 68.79998779296875],
          [-262.4000244140625, 67.20001220703125],
          [-262.4000244140625, 65.5999755859375],
          [-260.800048828125, 62.39996337890625],
          [-259.20001220703125, 60.79998779296875],
          [-257.60003662109375, 59.20001220703125],
          [-257.60003662109375, 57.5999755859375],
          [-256.00006103515625, 54.39996337890625],
          [-256.00006103515625, 52.79998779296875],
          [-256.00006103515625, 51.20001220703125],
          [-254.4000244140625, 49.5999755859375],
          [-254.4000244140625, 48],
          [-254.4000244140625, 44.79998779296875],
          [-254.4000244140625, 41.5999755859375],
          [-254.4000244140625, 40],
          [-254.4000244140625, 38.39996337890625],
          [-254.4000244140625, 35.20001220703125],
          [-254.4000244140625, 33.5999755859375],
          [-254.4000244140625, 32],
          [-254.4000244140625, 30.39996337890625],
          [-254.4000244140625, 28.79998779296875],
          [-254.4000244140625, 27.20001220703125],
          [-254.4000244140625, 25.5999755859375],
          [-254.4000244140625, 22.39996337890625],
          [-254.4000244140625, 20.79998779296875],
          [-254.4000244140625, 16],
          [-254.4000244140625, 14.39996337890625],
          [-254.4000244140625, 12.79998779296875],
          [-254.4000244140625, 11.20001220703125],
          [-254.4000244140625, 9.5999755859375],
          [-254.4000244140625, 8],
          [-254.4000244140625, 6.39996337890625],
          [-254.4000244140625, 3.20001220703125],
          [-254.4000244140625, 1.5999755859375],
          [-256.00006103515625, -1.60003662109375],
          [-256.00006103515625, -3.20001220703125],
          [-256.00006103515625, -4.79998779296875],
          [-256.00006103515625, -8],
          [-256.00006103515625, -9.60003662109375],
          [-256.00006103515625, -11.20001220703125],
          [-256.00006103515625, -12.79998779296875],
          [-256.00006103515625, -14.4000244140625],
          [-256.00006103515625, -16],
          [-257.60003662109375, -20.79998779296875],
          [-257.60003662109375, -24],
          [-257.60003662109375, -27.20001220703125],
          [-257.60003662109375, -30.4000244140625],
          [-259.20001220703125, -30.4000244140625],
          [-259.20001220703125, -32],
          [-259.20001220703125, -33.60003662109375],
          [-259.20001220703125, -35.20001220703125],
          [-259.20001220703125, -38.4000244140625],
          [-260.800048828125, -38.4000244140625],
          [-260.800048828125, -40],
          [-260.800048828125, -41.60003662109375],
          [-262.4000244140625, -43.20001220703125],
          [-262.4000244140625, -46.4000244140625],
          [-262.4000244140625, -48],
          [-262.4000244140625, -51.20001220703125],
          [-260.800048828125, -56],
          [-259.20001220703125, -59.20001220703125],
          [-256.00006103515625, -64],
          [-254.4000244140625, -65.60003662109375],
          [-252.800048828125, -68.79998779296875],
          [-251.2000732421875, -70.4000244140625],
          [-251.2000732421875, -72],
          [-251.2000732421875, -75.20001220703125],
          [-249.5999755859375, -78.4000244140625],
          [-249.5999755859375, -80],
          [-249.5999755859375, -83.20001220703125],
          [-248, -86.4000244140625],
          [-248, -89.60003662109375],
          [-248, -92.79998779296875],
          [-248, -94.4000244140625],
          [-248, -97.60003662109375],
          [-248, -99.20001220703125],
          [-246.4000244140625, -99.20001220703125],
          [-246.4000244140625, -100.79998779296875],
          [-246.4000244140625, -102.4000244140625],
          [-246.4000244140625, -104],
          [-246.4000244140625, -105.60003662109375],
          [-246.4000244140625, -108.79998779296875],
          [-246.4000244140625, -112],
          [-246.4000244140625, -113.60003662109375],
          [-246.4000244140625, -115.20001220703125],
          [-246.4000244140625, -116.79998779296875],
          [-246.4000244140625, -118.4000244140625],
          [-244.800048828125, -120],
          [-243.2000732421875, -121.60003662109375],
          [-241.5999755859375, -123.20001220703125],
          [-241.5999755859375, -124.79995727539062],
          [-240, -126.4000244140625],
          [-240, -128.00003051757812],
          [-238.4000244140625, -128.00003051757812],
          [-238.4000244140625, -129.60000610351562],
          [-238.4000244140625, -131.20001220703125],
          [-236.800048828125, -132.79995727539062],
          [-236.800048828125, -134.4000244140625],
          [-236.800048828125, -136.00003051757812],
          [-236.800048828125, -139.20001220703125],
          [-236.800048828125, -140.79995727539062],
          [-236.800048828125, -142.4000244140625],
          [-236.800048828125, -144.00003051757812],
          [-236.800048828125, -145.60000610351562],
          [-236.800048828125, -148.79995727539062],
          [-236.800048828125, -152.00003051757812],
          [-236.800048828125, -155.20001220703125],
          [-236.800048828125, -156.79995727539062],
          [-236.800048828125, -158.4000244140625],
          [-236.800048828125, -160.00003051757812],
          [-236.800048828125, -161.60000610351562],
          [-236.800048828125, -164.79995727539062],
          [-236.800048828125, -166.4000244140625],
          [-236.800048828125, -169.60000610351562],
          [-236.800048828125, -171.20001220703125],
          [-236.800048828125, -172.79995727539062],
          [-235.2000732421875, -174.4000244140625],
          [-233.5999755859375, -177.60000610351562],
          [-232, -179.20001220703125],
          [-232, -180.79995727539062],
          [-230.4000244140625, -182.4000244140625],
          [-227.2000732421875, -185.60000610351562],
          [-220.800048828125, -187.20001220703125],
          [-217.5999755859375, -187.20001220703125],
          [-216, -187.20001220703125],
          [-212.800048828125, -187.20001220703125],
          [-209.5999755859375, -187.20001220703125],
          [-208, -187.20001220703125],
          [-206.4000244140625, -188.79995727539062],
          [-204.800048828125, -188.79995727539062],
          [-204.800048828125, -190.4000244140625],
          [-203.2000732421875, -192.00003051757812],
          [-201.5999755859375, -193.60000610351562],
          [-200, -193.60000610351562],
          [-200, -195.20001220703125],
          [-200, -196.79995727539062],
          [-198.4000244140625, -196.79995727539062],
          [-198.4000244140625, -198.4000244140625],
          [-196.800048828125, -200.00003051757812],
          [-195.2000732421875, -201.60000610351562],
          [-195.2000732421875, -203.20001220703125],
          [-193.5999755859375, -206.4000244140625],
          [-193.5999755859375, -208.00003051757812],
          [-192, -209.60000610351562],
          [-192, -211.20001220703125],
          [-192, -212.79995727539062],
          [-192, -216.00003051757812],
          [-188.800048828125, -216.00003051757812],
          [-184, -220.79995727539062],
          [-182.4000244140625, -220.79995727539062],
          [-180.800048828125, -220.79995727539062],
          [-180.800048828125, -222.4000244140625],
          [-179.2000732421875, -225.60000610351562],
          [-176, -227.20001220703125],
          [-172.800048828125, -230.4000244140625],
          [-171.2000732421875, -232],
          [-168, -235.20001220703125],
          [-164.800048828125, -238.4000244140625],
          [-163.2000732421875, -238.4000244140625],
          [-161.5999755859375, -238.4000244140625],
          [-160, -238.4000244140625],
          [-158.4000244140625, -240],
          [-158.4000244140625, -241.60000610351562],
          [-156.800048828125, -241.60000610351562],
          [-155.2000732421875, -241.60000610351562],
          [-148.800048828125, -244.79995727539062],
          [-147.2000732421875, -246.4000244140625],
          [-144, -249.60000610351562],
          [-140.800048828125, -251.20001220703125],
          [-139.2000732421875, -251.20001220703125],
          [-137.5999755859375, -251.20001220703125],
          [-136, -252.79995727539062],
          [-134.4000244140625, -252.79995727539062],
          [-132.800048828125, -252.79995727539062],
          [-131.2000732421875, -252.79995727539062],
          [-129.5999755859375, -252.79995727539062],
          [-128, -252.79995727539062],
          [-126.4000244140625, -252.79995727539062],
          [-121.5999755859375, -252.79995727539062],
          [-120, -252.79995727539062],
          [-118.4000244140625, -252.79995727539062],
          [-116.800048828125, -252.79995727539062],
          [-113.5999755859375, -252.79995727539062],
          [-112, -252.79995727539062],
          [-110.4000244140625, -252.79995727539062],
          [-108.800048828125, -252.79995727539062],
          [-107.2000732421875, -252.79995727539062],
          [-105.5999755859375, -252.79995727539062],
          [-104, -254.4000244140625],
          [-102.4000244140625, -254.4000244140625],
          [-99.2000732421875, -256],
          [-97.5999755859375, -259.20001220703125],
          [-94.4000244140625, -260.7999572753906],
          [-91.2000732421875, -262.4000244140625],
          [-88, -264],
          [-84.800048828125, -265.6000061035156],
          [-80, -265.6000061035156],
          [-75.2000732421875, -267.20001220703125],
          [-73.5999755859375, -268.7999572753906],
          [-70.4000244140625, -268.7999572753906],
          [-67.2000732421875, -270.4000244140625],
          [-65.5999755859375, -270.4000244140625],
          [-62.4000244140625, -270.4000244140625],
          [-62.4000244140625, -272],
          [-60.800048828125, -272],
          [-59.2000732421875, -272],
          [-57.5999755859375, -272],
          [-56, -272],
          [-54.4000244140625, -272],
          [-52.800048828125, -272],
          [-51.2000732421875, -272],
          [-49.5999755859375, -272],
          [-48, -272],
          [-46.4000244140625, -272],
          [-44.800048828125, -272],
          [-43.2000732421875, -272],
          [-41.5999755859375, -272],
          [-38.4000244140625, -272],
          [-36.800048828125, -272],
          [-32, -270.4000244140625],
          [-30.4000244140625, -270.4000244140625],
          [-27.2000732421875, -270.4000244140625],
          [-24, -270.4000244140625],
          [-19.2000732421875, -270.4000244140625],
          [-14.4000244140625, -270.4000244140625],
          [-11.2000732421875, -270.4000244140625],
          [-6.4000244140625, -270.4000244140625],
          [-1.5999755859375, -270.4000244140625],
          [1.5999755859375, -270.4000244140625],
          [6.4000244140625, -270.4000244140625],
          [11.199951171875, -270.4000244140625],
          [12.7999267578125, -270.4000244140625],
          [16, -270.4000244140625],
          [19.199951171875, -270.4000244140625],
          [20.7999267578125, -270.4000244140625],
          [22.4000244140625, -270.4000244140625],
          [24, -270.4000244140625],
          [25.5999755859375, -270.4000244140625],
          [30.4000244140625, -270.4000244140625],
          [33.5999755859375, -272],
          [35.199951171875, -272],
          [36.7999267578125, -272],
          [38.4000244140625, -272],
          [40, -272],
          [41.5999755859375, -272],
          [46.4000244140625, -273.6000061035156],
          [49.5999755859375, -275.20001220703125],
          [51.199951171875, -275.20001220703125],
          [54.4000244140625, -275.20001220703125],
          [56, -275.20001220703125],
          [59.199951171875, -275.20001220703125],
          [60.7999267578125, -275.20001220703125],
          [64, -275.20001220703125],
          [67.199951171875, -275.20001220703125],
          [68.7999267578125, -275.20001220703125],
          [72, -273.6000061035156],
          [75.199951171875, -273.6000061035156],
          [76.7999267578125, -272],
          [78.4000244140625, -270.4000244140625],
          [80, -268.7999572753906],
          [81.5999755859375, -268.7999572753906],
          [83.199951171875, -267.20001220703125],
          [84.7999267578125, -265.6000061035156],
          [86.4000244140625, -265.6000061035156],
          [89.5999755859375, -264],
          [96, -260.7999572753906],
          [100.7999267578125, -259.20001220703125],
          [105.5999755859375, -257.6000061035156],
          [107.199951171875, -256],
          [110.4000244140625, -254.4000244140625],
          [113.5999755859375, -252.79995727539062],
          [115.199951171875, -252.79995727539062],
          [116.7999267578125, -252.79995727539062],
          [118.4000244140625, -251.20001220703125],
          [120, -251.20001220703125],
          [121.5999755859375, -251.20001220703125],
          [123.199951171875, -251.20001220703125],
          [124.7999267578125, -251.20001220703125],
          [128, -249.60000610351562],
          [131.199951171875, -248],
          [132.7999267578125, -248],
          [136, -248],
          [140.7999267578125, -248],
          [145.5999755859375, -248],
          [147.199951171875, -248],
          [148.7999267578125, -246.4000244140625],
          [150.4000244140625, -246.4000244140625],
          [152, -246.4000244140625],
          [153.5999755859375, -246.4000244140625],
          [155.199951171875, -246.4000244140625],
          [158.4000244140625, -244.79995727539062],
          [161.5999755859375, -243.20001220703125],
          [163.199951171875, -241.60000610351562],
          [166.4000244140625, -240],
          [169.5999755859375, -240],
          [169.5999755859375, -238.4000244140625],
          [171.199951171875, -236.79995727539062],
          [172.7999267578125, -235.20001220703125],
          [172.7999267578125, -233.60000610351562],
          [174.4000244140625, -230.4000244140625],
          [174.4000244140625, -228.79995727539062],
          [174.4000244140625, -225.60000610351562],
          [174.4000244140625, -224.00003051757812],
          [176, -217.60000610351562],
          [177.5999755859375, -216.00003051757812],
          [179.199951171875, -212.79995727539062],
          [179.199951171875, -211.20001220703125],
          [180.7999267578125, -209.60000610351562],
          [180.7999267578125, -208.00003051757812],
          [179.199951171875, -206.4000244140625],
          [179.199951171875, -204.79995727539062],
          [177.5999755859375, -204.79995727539062],
          [176, -204.79995727539062],
          [176, -203.20001220703125],
          [176, -201.60000610351562],
          [174.4000244140625, -200.00003051757812],
          [174.4000244140625, -198.4000244140625],
          [174.4000244140625, -196.79995727539062],
          [174.4000244140625, -195.20001220703125],
          [174.4000244140625, -193.60000610351562],
          [174.4000244140625, -192.00003051757812],
          [174.4000244140625, -190.4000244140625],
          [174.4000244140625, -190.4000244140625],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 312,
        versionNonce: 1429856973,
        isDeleted: false,
        id: "vY01f18RGx0cuUm3S8nQh",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -88.045160072096,
        y: -34.892609452165175,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 456,
        height: 185.60003662109375,
        seed: 1338299907,
        groupIds: ["CAVROA4MzXIBSXv3n0fco"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037223574,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [1.5999755859375, 0],
          [3.2000732421875, 0],
          [4.800048828125, 0],
          [6.4000244140625, 0],
          [8, 0],
          [9.5999755859375, 0],
          [14.4000244140625, 0],
          [19.2000732421875, 0],
          [24, 0],
          [28.800048828125, 0],
          [33.5999755859375, 0],
          [36.800048828125, 0],
          [41.5999755859375, 0],
          [44.800048828125, 0],
          [46.4000244140625, 0],
          [48, 1.60003662109375],
          [49.5999755859375, 1.60003662109375],
          [49.5999755859375, 3.20001220703125],
          [51.2000732421875, 3.20001220703125],
          [52.800048828125, 3.20001220703125],
          [54.4000244140625, 3.20001220703125],
          [57.5999755859375, 3.20001220703125],
          [65.5999755859375, 3.20001220703125],
          [72, 4.800048828125],
          [76.800048828125, 6.4000244140625],
          [81.5999755859375, 6.4000244140625],
          [83.2000732421875, 6.4000244140625],
          [86.4000244140625, 8],
          [88, 8],
          [89.5999755859375, 8],
          [91.2000732421875, 8],
          [92.800048828125, 8],
          [94.4000244140625, 8],
          [96, 8],
          [97.5999755859375, 8],
          [99.2000732421875, 8],
          [100.800048828125, 8],
          [100.800048828125, 4.800048828125],
          [100.800048828125, 3.20001220703125],
          [104, 3.20001220703125],
          [105.5999755859375, 3.20001220703125],
          [107.2000732421875, 3.20001220703125],
          [108.800048828125, 3.20001220703125],
          [108.800048828125, 4.800048828125],
          [110.4000244140625, 6.4000244140625],
          [112, 6.4000244140625],
          [112, 8],
          [115.2000732421875, 9.60003662109375],
          [116.800048828125, 9.60003662109375],
          [116.800048828125, 11.20001220703125],
          [118.4000244140625, 11.20001220703125],
          [121.5999755859375, 12.800048828125],
          [121.5999755859375, 14.4000244140625],
          [123.2000732421875, 14.4000244140625],
          [124.800048828125, 14.4000244140625],
          [126.4000244140625, 16],
          [128, 17.60003662109375],
          [129.5999755859375, 17.60003662109375],
          [131.2000732421875, 17.60003662109375],
          [132.800048828125, 19.20001220703125],
          [134.4000244140625, 20.800048828125],
          [134.4000244140625, 22.4000244140625],
          [136, 22.4000244140625],
          [137.5999755859375, 22.4000244140625],
          [137.5999755859375, 24],
          [139.2000732421875, 24],
          [140.800048828125, 25.60003662109375],
          [142.4000244140625, 25.60003662109375],
          [144, 25.60003662109375],
          [145.5999755859375, 25.60003662109375],
          [147.2000732421875, 27.20001220703125],
          [148.800048828125, 27.20001220703125],
          [150.4000244140625, 28.800048828125],
          [150.4000244140625, 30.4000244140625],
          [152, 32],
          [153.5999755859375, 33.60003662109375],
          [155.2000732421875, 36.800048828125],
          [156.800048828125, 38.4000244140625],
          [158.4000244140625, 38.4000244140625],
          [158.4000244140625, 40],
          [160, 41.60003662109375],
          [161.5999755859375, 41.60003662109375],
          [163.2000732421875, 43.20001220703125],
          [164.800048828125, 43.20001220703125],
          [166.4000244140625, 44.800048828125],
          [166.4000244140625, 46.4000244140625],
          [168, 46.4000244140625],
          [169.5999755859375, 48],
          [171.2000732421875, 49.60003662109375],
          [171.2000732421875, 51.20001220703125],
          [172.800048828125, 52.800048828125],
          [176, 54.4000244140625],
          [177.5999755859375, 57.60003662109375],
          [180.800048828125, 59.20001220703125],
          [182.4000244140625, 62.4000244140625],
          [184, 64],
          [184, 65.60003662109375],
          [185.5999755859375, 65.60003662109375],
          [187.2000732421875, 67.20001220703125],
          [188.800048828125, 70.4000244140625],
          [190.4000244140625, 72],
          [192, 73.60003662109375],
          [193.5999755859375, 75.20001220703125],
          [195.2000732421875, 76.800048828125],
          [196.800048828125, 76.800048828125],
          [198.4000244140625, 78.4000244140625],
          [201.5999755859375, 80],
          [203.2000732421875, 81.60003662109375],
          [203.2000732421875, 83.20001220703125],
          [206.4000244140625, 83.20001220703125],
          [208, 84.800048828125],
          [208, 86.4000244140625],
          [209.5999755859375, 86.4000244140625],
          [209.5999755859375, 88],
          [209.5999755859375, 89.60003662109375],
          [211.2000732421875, 91.20001220703125],
          [211.2000732421875, 92.800048828125],
          [211.2000732421875, 94.4000244140625],
          [211.2000732421875, 96],
          [211.2000732421875, 97.60003662109375],
          [209.5999755859375, 99.20001220703125],
          [208, 100.800048828125],
          [208, 102.4000244140625],
          [206.4000244140625, 102.4000244140625],
          [206.4000244140625, 104],
          [203.2000732421875, 104],
          [203.2000732421875, 105.60003662109375],
          [203.2000732421875, 107.20001220703125],
          [203.2000732421875, 108.800048828125],
          [203.2000732421875, 112],
          [204.800048828125, 112],
          [206.4000244140625, 112],
          [209.5999755859375, 108.800048828125],
          [214.4000244140625, 107.20001220703125],
          [216, 107.20001220703125],
          [217.5999755859375, 107.20001220703125],
          [219.2000732421875, 107.20001220703125],
          [222.4000244140625, 107.20001220703125],
          [224, 107.20001220703125],
          [225.5999755859375, 107.20001220703125],
          [228.800048828125, 105.60003662109375],
          [232, 104],
          [235.2000732421875, 102.4000244140625],
          [236.800048828125, 102.4000244140625],
          [240, 102.4000244140625],
          [241.5999755859375, 102.4000244140625],
          [243.2000732421875, 102.4000244140625],
          [244.800048828125, 102.4000244140625],
          [246.4000244140625, 102.4000244140625],
          [249.5999755859375, 102.4000244140625],
          [251.2000732421875, 102.4000244140625],
          [252.800048828125, 102.4000244140625],
          [256, 102.4000244140625],
          [257.5999755859375, 102.4000244140625],
          [260.800048828125, 102.4000244140625],
          [262.4000244140625, 102.4000244140625],
          [265.5999755859375, 102.4000244140625],
          [270.4000244140625, 100.800048828125],
          [273.5999755859375, 99.20001220703125],
          [276.800048828125, 97.60003662109375],
          [280, 97.60003662109375],
          [281.5999755859375, 97.60003662109375],
          [283.2000732421875, 97.60003662109375],
          [284.800048828125, 97.60003662109375],
          [286.4000244140625, 97.60003662109375],
          [288, 97.60003662109375],
          [291.2000732421875, 97.60003662109375],
          [292.800048828125, 97.60003662109375],
          [294.4000244140625, 97.60003662109375],
          [297.5999755859375, 97.60003662109375],
          [299.2000732421875, 97.60003662109375],
          [300.800048828125, 97.60003662109375],
          [302.4000244140625, 97.60003662109375],
          [304, 97.60003662109375],
          [305.5999755859375, 97.60003662109375],
          [307.2000732421875, 97.60003662109375],
          [308.800048828125, 97.60003662109375],
          [310.4000244140625, 99.20001220703125],
          [310.4000244140625, 100.800048828125],
          [310.4000244140625, 102.4000244140625],
          [310.4000244140625, 104],
          [312, 104],
          [313.5999755859375, 105.60003662109375],
          [313.5999755859375, 107.20001220703125],
          [315.2000732421875, 108.800048828125],
          [318.4000244140625, 108.800048828125],
          [320, 110.4000244140625],
          [321.5999755859375, 112],
          [324.800048828125, 113.60003662109375],
          [326.4000244140625, 115.20001220703125],
          [329.5999755859375, 115.20001220703125],
          [334.4000244140625, 116.800048828125],
          [339.2000732421875, 116.800048828125],
          [340.800048828125, 118.4000244140625],
          [344, 118.4000244140625],
          [348.800048828125, 120],
          [352, 120],
          [355.2000732421875, 120],
          [360, 120],
          [361.5999755859375, 121.60003662109375],
          [369.5999755859375, 126.4000244140625],
          [371.2000732421875, 126.4000244140625],
          [372.800048828125, 128],
          [376, 129.60003662109375],
          [377.5999755859375, 131.20001220703125],
          [379.2000732421875, 132.800048828125],
          [382.4000244140625, 132.800048828125],
          [384, 132.800048828125],
          [387.2000732421875, 134.4000244140625],
          [388.800048828125, 136],
          [390.4000244140625, 137.60003662109375],
          [392, 137.60003662109375],
          [393.5999755859375, 137.60003662109375],
          [393.5999755859375, 139.20001220703125],
          [396.800048828125, 140.800048828125],
          [400, 140.800048828125],
          [400, 142.4000244140625],
          [401.5999755859375, 142.4000244140625],
          [403.2000732421875, 142.4000244140625],
          [403.2000732421875, 144],
          [404.800048828125, 145.60003662109375],
          [406.4000244140625, 145.60003662109375],
          [408, 147.20001220703125],
          [409.5999755859375, 148.800048828125],
          [412.800048828125, 150.4000244140625],
          [414.4000244140625, 150.4000244140625],
          [419.2000732421875, 152],
          [424, 155.20001220703125],
          [428.800048828125, 156.800048828125],
          [432, 158.4000244140625],
          [435.2000732421875, 158.4000244140625],
          [436.800048828125, 160],
          [438.4000244140625, 161.60003662109375],
          [440, 161.60003662109375],
          [441.5999755859375, 161.60003662109375],
          [441.5999755859375, 163.20001220703125],
          [443.2000732421875, 164.800048828125],
          [444.800048828125, 164.800048828125],
          [446.4000244140625, 166.4000244140625],
          [448, 166.4000244140625],
          [449.5999755859375, 168],
          [451.2000732421875, 169.60003662109375],
          [452.800048828125, 171.20001220703125],
          [452.800048828125, 172.800048828125],
          [452.800048828125, 174.4000244140625],
          [452.800048828125, 176],
          [452.800048828125, 177.60003662109375],
          [454.4000244140625, 179.20001220703125],
          [454.4000244140625, 180.800048828125],
          [456, 180.800048828125],
          [456, 182.4000244140625],
          [456, 184],
          [456, 185.60003662109375],
          [456, 185.60003662109375],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
      {
        type: "freedraw",
        version: 138,
        versionNonce: 501496963,
        isDeleted: false,
        id: "6SpEYRlRi4sw8adLcqokd",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -3.245111243970996,
        y: 288.3074027548661,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 233.5999755859375,
        height: 49.60003662109375,
        seed: 224468387,
        groupIds: ["CAVROA4MzXIBSXv3n0fco"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037223574,
        link: null,
        locked: false,
        points: [
          [0, 0],
          [1.5999755859375, 0],
          [1.5999755859375, 1.60003662109375],
          [1.5999755859375, 3.20001220703125],
          [1.5999755859375, 4.79998779296875],
          [1.5999755859375, 8],
          [1.5999755859375, 11.20001220703125],
          [1.5999755859375, 12.79998779296875],
          [3.199951171875, 16],
          [3.199951171875, 17.60003662109375],
          [3.199951171875, 19.20001220703125],
          [3.199951171875, 20.79998779296875],
          [4.7999267578125, 22.4000244140625],
          [6.4000244140625, 22.4000244140625],
          [6.4000244140625, 24],
          [8, 25.60003662109375],
          [9.5999755859375, 27.20013427734375],
          [9.5999755859375, 28.79998779296875],
          [11.199951171875, 30.4000244140625],
          [12.7999267578125, 32],
          [14.4000244140625, 32],
          [16, 32],
          [17.5999755859375, 32],
          [19.199951171875, 32],
          [20.7999267578125, 32],
          [22.4000244140625, 32],
          [24, 32],
          [27.199951171875, 32],
          [28.7999267578125, 32],
          [30.4000244140625, 32],
          [32, 32],
          [33.5999755859375, 32],
          [36.7999267578125, 32],
          [40, 32],
          [44.7999267578125, 33.60003662109375],
          [49.5999755859375, 33.60003662109375],
          [54.4000244140625, 35.20013427734375],
          [59.199951171875, 35.20013427734375],
          [64, 35.20013427734375],
          [65.5999755859375, 35.20013427734375],
          [68.7999267578125, 35.20013427734375],
          [70.4000244140625, 35.20013427734375],
          [72, 35.20013427734375],
          [73.5999755859375, 35.20013427734375],
          [75.199951171875, 35.20013427734375],
          [76.7999267578125, 35.20013427734375],
          [78.4000244140625, 35.20013427734375],
          [81.5999755859375, 35.20013427734375],
          [83.199951171875, 35.20013427734375],
          [84.7999267578125, 35.20013427734375],
          [86.4000244140625, 35.20013427734375],
          [88, 35.20013427734375],
          [89.5999755859375, 35.20013427734375],
          [91.199951171875, 35.20013427734375],
          [92.7999267578125, 35.20013427734375],
          [96, 36.79998779296875],
          [99.199951171875, 38.4000244140625],
          [100.7999267578125, 40],
          [102.4000244140625, 41.60003662109375],
          [105.5999755859375, 43.20013427734375],
          [107.199951171875, 44.79998779296875],
          [108.7999267578125, 46.4000244140625],
          [113.5999755859375, 48],
          [115.199951171875, 49.60003662109375],
          [116.7999267578125, 49.60003662109375],
          [120, 49.60003662109375],
          [121.5999755859375, 49.60003662109375],
          [126.4000244140625, 49.60003662109375],
          [129.5999755859375, 49.60003662109375],
          [131.199951171875, 49.60003662109375],
          [132.7999267578125, 49.60003662109375],
          [134.4000244140625, 48],
          [136, 48],
          [137.5999755859375, 48],
          [139.199951171875, 48],
          [140.7999267578125, 48],
          [142.4000244140625, 48],
          [144, 48],
          [145.5999755859375, 48],
          [147.199951171875, 48],
          [148.7999267578125, 48],
          [152, 48],
          [153.5999755859375, 48],
          [153.5999755859375, 46.4000244140625],
          [156.7999267578125, 44.79998779296875],
          [158.4000244140625, 44.79998779296875],
          [161.5999755859375, 43.20013427734375],
          [163.199951171875, 43.20013427734375],
          [164.7999267578125, 43.20013427734375],
          [166.4000244140625, 43.20013427734375],
          [168, 43.20013427734375],
          [169.5999755859375, 41.60003662109375],
          [171.199951171875, 41.60003662109375],
          [172.7999267578125, 40],
          [172.7999267578125, 38.4000244140625],
          [174.4000244140625, 38.4000244140625],
          [176, 38.4000244140625],
          [177.5999755859375, 35.20013427734375],
          [179.199951171875, 35.20013427734375],
          [180.7999267578125, 33.60003662109375],
          [182.4000244140625, 33.60003662109375],
          [184, 33.60003662109375],
          [185.5999755859375, 33.60003662109375],
          [187.199951171875, 33.60003662109375],
          [187.199951171875, 32],
          [188.7999267578125, 32],
          [190.4000244140625, 32],
          [193.5999755859375, 32],
          [198.4000244140625, 32],
          [200, 32],
          [203.199951171875, 32],
          [204.7999267578125, 32],
          [206.4000244140625, 32],
          [208, 32],
          [211.199951171875, 32],
          [214.4000244140625, 32],
          [217.5999755859375, 32],
          [219.199951171875, 32],
          [220.7999267578125, 32],
          [220.7999267578125, 30.4000244140625],
          [222.4000244140625, 30.4000244140625],
          [224, 28.79998779296875],
          [225.5999755859375, 28.79998779296875],
          [228.7999267578125, 27.20013427734375],
          [230.4000244140625, 25.60003662109375],
          [230.4000244140625, 24],
          [230.4000244140625, 22.4000244140625],
          [232, 20.79998779296875],
          [233.5999755859375, 17.60003662109375],
          [233.5999755859375, 16],
          [233.5999755859375, 14.4000244140625],
          [233.5999755859375, 12.79998779296875],
          [233.5999755859375, 12.79998779296875],
        ],
        lastCommittedPoint: null,
        simulatePressure: true,
        pressures: [],
      },
    ],
    id: "DFvgl0fmuJlSSR-2oYNmr",
    created: 1702037226096,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 150,
        versionNonce: 1849273965,
        isDeleted: false,
        id: "kk3Zxi7u-UHxMNZKXeL2v",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 85.7804421201223,
        y: 51.76021707900682,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 279.9496758234009,
        height: 64.23963174824037,
        seed: 961065123,
        groupIds: ["0bbmUSqo5eeSsP0GW1U2Q"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1702037162450,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 114,
        versionNonce: 1850768099,
        isDeleted: false,
        id: "pcuFFf5cYoxfv4_dhUO6v",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 85.10419392621687,
        y: -73.33811499090936,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 279.949675823401,
        height: 62.8872385414172,
        seed: 1523622979,
        groupIds: ["0bbmUSqo5eeSsP0GW1U2Q"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1702037162450,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 129,
        versionNonce: 959666381,
        isDeleted: false,
        id: "Kou-PWVKPgEZsVBLu4DuH",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 365.73006635302966,
        y: -118.64398389115522,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 27.724473463827234,
        height: 267.7779306000165,
        seed: 1515791331,
        groupIds: ["0bbmUSqo5eeSsP0GW1U2Q"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1702037162450,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 158,
        versionNonce: 686106243,
        isDeleted: false,
        id: "uBLFcefjzWtFZfrmpDKvt",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 57.37972046238963,
        y: -123.3774246031536,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 27.048276860415402,
        height: 273.8638161093321,
        seed: 1072738179,
        groupIds: ["0bbmUSqo5eeSsP0GW1U2Q"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1702037162450,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 361,
        versionNonce: 541636397,
        isDeleted: false,
        id: "jBT2IKsBEUICspgT0lNy-",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 85.60358982238718,
        y: -112.55811127946247,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 38.543773889894965,
        height: 39.219996288553126,
        seed: 232113955,
        groupIds: ["0bbmUSqo5eeSsP0GW1U2Q"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1702037162450,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 477,
        versionNonce: 1864588835,
        isDeleted: false,
        id: "o1COO3yVlDusk7UD_a1Ll",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 87.13283532694548,
        y: 11.863959698925328,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 38.543773889894965,
        height: 39.219996288553126,
        seed: 2054613699,
        groupIds: ["0bbmUSqo5eeSsP0GW1U2Q"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1702037162450,
        link: null,
        locked: false,
      },
    ],
    id: "hXBxZErI3r6dOFVQkGkjI",
    created: 1702037163953,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 131,
        versionNonce: 1690344237,
        isDeleted: false,
        id: "StVSNCy_zUwIVp-zGdZi6",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -370.65863370741874,
        y: -235.02036402778282,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 269.6820007080678,
        height: 390.2851556692435,
        seed: 236515555,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 117,
        versionNonce: 965458467,
        isDeleted: false,
        id: "AghIJVJJyDi7ErPTAbOJN",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -233.30510622997454,
        y: -231.67032786323546,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 125.62827310584099,
        height: 358.4594926168248,
        seed: 1680792707,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 131,
        versionNonce: 1103015309,
        isDeleted: false,
        id: "WdbWtzZXcqd3exgE53Hiu",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -367.3085975428716,
        y: -235.02036402778282,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 134.00349131289704,
        height: 365.15950104807513,
        seed: 1137071139,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 126,
        versionNonce: 1089895875,
        isDeleted: false,
        id: "9mDka9Co5TPEhCuML29J4",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -363.95856137832425,
        y: 130.1391370202923,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 259.63176441873804,
        height: 21.77561845662105,
        seed: 483252163,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 104,
        versionNonce: 1309373421,
        isDeleted: false,
        id: "5psQkfNXAoUeTR2eWGxkW",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -358.9333793358155,
        y: 76.53785551125293,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 125.62827310584099,
        height: 56.95138157143069,
        seed: 1566828387,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 111,
        versionNonce: 25677155,
        isDeleted: false,
        id: "0LUxKcbnCrg4NQwqPDY3z",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -360.608397418089,
        y: 21.261300328563664,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 125.62827310584099,
        height: 53.601409304727696,
        seed: 735511299,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 82,
        versionNonce: 363884109,
        isDeleted: false,
        id: "Z4xC7I676VTKsRLsftmHM",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -367.3085975428716,
        y: -198.1694550350121,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 135.67850939517072,
        height: 1.6750180822727772,
        seed: 1621469859,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [135.67850939517072, -1.6750180822727772],
        ],
      },
      {
        type: "line",
        version: 199,
        versionNonce: 781068547,
        isDeleted: false,
        id: "OTjqZh0leYrvu_QYk6X8J",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -315.3822702182615,
        y: -189.79423682795579,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 46.90127307778902,
        height: 43.551173015397715,
        seed: 1082023491,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1.6750180822736864, 16.750436414112585],
          [-23.45063653889451, 43.551173015397715],
          [23.45063653889451, 43.551173015397715],
          [1.6750180822736864, 21.775618456621505],
        ],
      },
      {
        type: "line",
        version: 185,
        versionNonce: 1736907949,
        isDeleted: false,
        id: "3PcelxmTUM6vOkjyO11ei",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -276.85621534752835,
        y: -183.0940367031732,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 18.425454496385818,
        height: 26.800672703441656,
        seed: 1983880675,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-1.6750180822736864, 11.725318269447142],
          [-18.425454496385818, 26.800672703441656],
        ],
      },
      {
        type: "line",
        version: 169,
        versionNonce: 227020963,
        isDeleted: false,
        id: "_JC96sidwo0SPk1UvI0-V",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -276.0432823295305,
        y: -168.96413015845894,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 41.172493076798204,
        height: 22.4577105162222,
        seed: 1291315587,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [26.616563424454053, 20.79418521763424],
          [-14.555929652344147, 22.4577105162222],
        ],
      },
      {
        type: "rectangle",
        version: 117,
        versionNonce: 2067332877,
        isDeleted: false,
        id: "I9EJBHpOgrK0ivFm1IYl6",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -225.72136044873653,
        y: -72.06319848781422,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 22.87360373939798,
        height: 44.91540516774071,
        seed: 702923043,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 114,
        versionNonce: 807666755,
        isDeleted: false,
        id: "M-Cbqd_-wyNxax_lVClec",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -333.4352462219654,
        y: -27.563670678544923,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 76.938485305248,
        height: 20.378291994459136,
        seed: 1182246083,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 109,
        versionNonce: 1463784813,
        isDeleted: false,
        id: "PbTr6y2XDPC_h5WTzplHA",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -357.1406046783053,
        y: -3.0265416405587757,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 71.53198445689937,
        height: 22.457726380926797,
        seed: 709629027,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 122,
        versionNonce: 1849721827,
        isDeleted: false,
        id: "n8uTwQ_MwTe4z-CbRDOR-",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -283.11329261176274,
        y: -2.194771058912721,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 42.836002510682015,
        height: 19.962414635988186,
        seed: 1697746947,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 89,
        versionNonce: 1994678221,
        isDeleted: false,
        id: "rxmO9_ov3RNaXH1KrYzDg",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -308.8981013192749,
        y: 47.2953961049393,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 24.121267544219588,
        height: 9.98119938564202,
        seed: 153524131,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 104,
        versionNonce: 535564163,
        isDeleted: false,
        id: "zmTjGM96K4D68Huf0WC6B",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 70,
        angle: 0,
        x: -307.2345601559823,
        y: 100.11261386596772,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 22.041849022456073,
        height: 8.733583174933305,
        seed: 1683598147,
        groupIds: ["mMPPyYLhJFSrUq3tKj94i"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702037069648,
        link: null,
        locked: false,
      },
    ],
    id: "I9iU_T9cFNxzfK-VkO3OY",
    created: 1702037149576,
  },
  {
    status: "published",
    elements: [
      {
        type: "line",
        version: 326,
        versionNonce: 443601923,
        isDeleted: false,
        id: "krTz741Du2VaKUiBS26EE",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 70.54938921676428,
        y: -168.51427773469365,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 191.37116120979903,
        height: 87.19013341382878,
        seed: 474832035,
        groupIds: ["RcHMxK74-tD59Hay-LM1p"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036971123,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.8942611899259187, -87.19013341382878],
          [190.92403914314332, -84.50735837235749],
          [191.37116120979903, 0],
        ],
      },
      {
        type: "line",
        version: 294,
        versionNonce: 548970925,
        isDeleted: false,
        id: "dwpA_0v3cqJPLMgWvnOxD",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 265.90944739566896,
        y: -168.96139980134933,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 175.23925816032892,
        height: 40.24160003715149,
        seed: 1348456515,
        groupIds: ["RcHMxK74-tD59Hay-LM1p"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036971123,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-26.380611291445042, -0.8942611899267241],
          [-27.686707634158363, -38.4530947139134],
          [-175.23925816032892, -37.55883352398748],
          [-173.89785784713277, 1.7885053232380916],
        ],
      },
      {
        type: "line",
        version: 323,
        versionNonce: 1574788003,
        isDeleted: false,
        id: "P91jcffgjSM0zYtr5-Ssd",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 69.06664251317068,
        y: -168.18107673067647,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 23.377309018936984,
        height: 0.5194878291000059,
        seed: 757256163,
        groupIds: ["RcHMxK74-tD59Hay-LM1p"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036971123,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [23.377309018936984, -0.5194878291000059],
        ],
      },
      {
        type: "rectangle",
        version: 158,
        versionNonce: 2113004557,
        isDeleted: false,
        id: "tctfFn2CPTdVQdeorfV-i",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 88.28800926492539,
        y: -342.2121549827651,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 153.25124801303312,
        height: 87.79479068688505,
        seed: 1688525699,
        groupIds: ["RcHMxK74-tD59Hay-LM1p"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036971123,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 112,
        versionNonce: 2107109187,
        isDeleted: false,
        id: "fP0Pxt8cRZPgPVKWskrnr",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 129.32818191393568,
        y: -304.80846649762293,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 72.20987837321259,
        height: 47.27413559276063,
        seed: 1663333155,
        groupIds: ["RcHMxK74-tD59Hay-LM1p"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1702036971123,
        link: null,
        locked: false,
      },
    ],
    id: "V6RboisGoA3iQb1e6c_dj",
    created: 1702036975601,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 178,
        versionNonce: 613209773,
        isDeleted: false,
        id: "eWyslipaqHdw8C8PGxErU",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 376.836657153092,
        y: -288.8253120622313,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 185.27951196796866,
        height: 258.5678334099141,
        seed: 922668547,
        groupIds: ["cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 169,
        versionNonce: 1481832099,
        isDeleted: false,
        id: "sMExGuWyTaBLkWAhZy-Vr",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 383.42433543896226,
        y: -264.94486047841076,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 82.34644976354151,
        height: 154.81131927292245,
        seed: 1788614051,
        groupIds: ["cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 178,
        versionNonce: 1131903245,
        isDeleted: false,
        id: "F3K2VExz1YhIw8eyr2oZq",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 474.82894493677753,
        y: -264.1213771331689,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 79.87603114049335,
        height: 153.16444682047188,
        seed: 913271107,
        groupIds: ["cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 190,
        versionNonce: 1822566979,
        isDeleted: false,
        id: "Ot85kz5tnCo-0Jwpv0YIr",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 382.6008835063978,
        y: -106.83960782451993,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 80.69954589841288,
        height: 60.93632256473636,
        seed: 1071073507,
        groupIds: ["cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 161,
        versionNonce: 1168035693,
        isDeleted: false,
        id: "9d0onHhNu9YlMt35MDmzU",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 476.4758488019065,
        y: -104.3692834395045,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 79.87603114049301,
        height: 57.642514834479094,
        seed: 2086810755,
        groupIds: ["cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 135,
        versionNonce: 67758563,
        isDeleted: false,
        id: "Y6Y-c2IisYu7fsESv4969",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 385.8947540620104,
        y: -26.963608096704604,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 13.998871329660014,
        height: 13.998871329660014,
        seed: 734964771,
        groupIds: ["cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 127,
        versionNonce: 155998669,
        isDeleted: false,
        id: "NUI6QNnj6bDyxqf69XwA7",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 541.5295566801753,
        y: -28.610511961833254,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 16.469289952708166,
        height: 14.822323262224161,
        seed: 302723011,
        groupIds: ["cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 127,
        versionNonce: 1134081411,
        isDeleted: false,
        id: "mzTNUXvHxzsQGlgZaZyiQ",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 484.71049377826046,
        y: -213.89005534247957,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 6.587741111225618,
        height: 27.9978054846754,
        seed: 1899380579,
        groupIds: ["cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 169,
        versionNonce: 1738119213,
        isDeleted: false,
        id: "E02xRHytJknw5-HMbHyBg",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 449.30149524979527,
        y: -215.38766818159777,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 9.881611666838598,
        height: 29.64470934980403,
        seed: 1134451459,
        groupIds: ["cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 384,
        versionNonce: 713887011,
        isDeleted: false,
        id: "E79AZzgjU9INs6jNJII9C",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 411.3549912143623,
        y: -82.02154673660294,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 31.3229964511739,
        height: 9.05432203990555,
        seed: 901907107,
        groupIds: ["7VltgPcNVM61cLvLOnRfk", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 9.05432203990555],
          [31.3229964511739, 9.05432203990555],
        ],
      },
      {
        type: "line",
        version: 308,
        versionNonce: 1517292173,
        isDeleted: false,
        id: "kc0IXsMOfzcDZMLu5Oiay",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 444.42030762209424,
        y: -73.21195048409365,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 0,
        height: 9.054303369949425,
        seed: 1615836739,
        groupIds: ["7VltgPcNVM61cLvLOnRfk", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, -9.054303369949425],
        ],
      },
      {
        type: "line",
        version: 305,
        versionNonce: 1280667843,
        isDeleted: false,
        id: "w3u3lEAZTQBIgby-SS-DN",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 444.1462490401319,
        y: -82.51097030646096,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 3.6706627714675966,
        height: 0.24471645241787415,
        seed: 1109784035,
        groupIds: ["7VltgPcNVM61cLvLOnRfk", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-3.6706627714675966, 0.24471645241787415],
        ],
      },
      {
        type: "line",
        version: 393,
        versionNonce: 1653158125,
        isDeleted: false,
        id: "DQxBo3AukrO0k_Aj5lZAi",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 440.0886583353156,
        y: -82.22678780005,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 26.104773281819618,
        height: 6.5895510414988525,
        seed: 2052276611,
        groupIds: ["7VltgPcNVM61cLvLOnRfk", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.506881104624978, 6.5895510414988525],
          [-25.59789217719464, 6.462830765342693],
        ],
      },
      {
        type: "line",
        version: 274,
        versionNonce: 1250975843,
        isDeleted: false,
        id: "--mXy6upW1Z6vNXk6Hl4K",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 414.3640458819648,
        y: -75.89067731086382,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 0.38017049660602814,
        height: 6.716280985792221,
        seed: 1108744483,
        groupIds: ["7VltgPcNVM61cLvLOnRfk", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.38017049660602814, -6.716280985792221],
        ],
      },
      {
        type: "line",
        version: 241,
        versionNonce: 762219341,
        isDeleted: false,
        id: "s-dOB8dLJl9KnKcWkIWcI",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 414.87093665472696,
        y: -82.73367857281221,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 3.928386569667943,
        height: 0.38016082846881866,
        seed: 652016835,
        groupIds: ["7VltgPcNVM61cLvLOnRfk", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-3.928386569667943, 0.38016082846881866],
        ],
      },
      {
        type: "line",
        version: 243,
        versionNonce: 252468227,
        isDeleted: false,
        id: "-8GW-SkSuvW3ZVmKDe5Hi",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 442.4963725866966,
        y: -72.97607228672027,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 1.7741128705995624,
        height: 0,
        seed: 71724131,
        groupIds: ["7VltgPcNVM61cLvLOnRfk", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1.7741128705995624, 0],
        ],
      },
      {
        type: "line",
        version: 386,
        versionNonce: 2071566765,
        isDeleted: false,
        id: "a7P9Mc0r2jWdJSXEsnz8x",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 501.3891482483099,
        y: -81.07535345025664,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 31.3229964511739,
        height: 9.05432203990555,
        seed: 1042343939,
        groupIds: ["mQGFuDNV7CbXCKQp1-wdv", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 9.05432203990555],
          [31.3229964511739, 9.05432203990555],
        ],
      },
      {
        type: "line",
        version: 310,
        versionNonce: 1170727843,
        isDeleted: false,
        id: "fmiJVMTWf4zUxCbuKvLYj",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 534.4544646560419,
        y: -72.26575719774803,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 0,
        height: 9.054303369949425,
        seed: 980086691,
        groupIds: ["mQGFuDNV7CbXCKQp1-wdv", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, -9.054303369949425],
        ],
      },
      {
        type: "line",
        version: 307,
        versionNonce: 1171512333,
        isDeleted: false,
        id: "K-Etj6DmxZuZ3zNeuD12-",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 534.1804060740791,
        y: -81.56477702011466,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 3.6706627714675966,
        height: 0.24471645241787415,
        seed: 458425155,
        groupIds: ["mQGFuDNV7CbXCKQp1-wdv", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-3.6706627714675966, 0.24471645241787415],
        ],
      },
      {
        type: "line",
        version: 395,
        versionNonce: 384923459,
        isDeleted: false,
        id: "zMDMaJCmaqqrwSDshXz6O",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 530.1228153692631,
        y: -81.2805945137037,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 26.104773281819618,
        height: 6.5895510414988525,
        seed: 959947491,
        groupIds: ["mQGFuDNV7CbXCKQp1-wdv", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.506881104624978, 6.5895510414988525],
          [-25.59789217719464, 6.462830765342693],
        ],
      },
      {
        type: "line",
        version: 276,
        versionNonce: 611766893,
        isDeleted: false,
        id: "x8rGIibgaOW42WOB0fA36",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 504.398202915912,
        y: -74.9444840245182,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 0.38017049660602814,
        height: 6.716280985792221,
        seed: 1554468483,
        groupIds: ["mQGFuDNV7CbXCKQp1-wdv", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.38017049660602814, -6.716280985792221],
        ],
      },
      {
        type: "line",
        version: 243,
        versionNonce: 552048355,
        isDeleted: false,
        id: "iXKeC73G9SS_E6NzgP9c8",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 504.9050936886742,
        y: -81.78748528646591,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 3.928386569667943,
        height: 0.38016082846881866,
        seed: 945849891,
        groupIds: ["mQGFuDNV7CbXCKQp1-wdv", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-3.928386569667943, 0.38016082846881866],
        ],
      },
      {
        type: "line",
        version: 245,
        versionNonce: 1949039821,
        isDeleted: false,
        id: "qOALZeKg1joz03QLD_gsf",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 532.5305296206445,
        y: -72.02987900037465,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 1.7741128705995624,
        height: 0,
        seed: 592562627,
        groupIds: ["mQGFuDNV7CbXCKQp1-wdv", "cX2KeH-qLBUYeENepXsPJ"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036935649,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1.7741128705995624, 0],
        ],
      },
    ],
    id: "sYLHkxtlLhvBcsQc140UP",
    created: 1702036938479,
  },
  {
    status: "published",
    elements: [
      {
        type: "line",
        version: 183,
        versionNonce: 166136045,
        isDeleted: false,
        id: "t6Kzw4NtghFNquZxwILC5",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1417.1400001052614,
        y: 215.58424306292454,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 182.42114500876946,
        height: 1.3340428373799447,
        seed: 438307619,
        groupIds: ["9FcJGuOGoWc5RJAwdD-BX"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036762900,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [182.42114500876946, -1.3340428373799447],
        ],
      },
      {
        type: "rectangle",
        version: 107,
        versionNonce: 849086563,
        isDeleted: false,
        id: "7O5iJulUoteCAI0N7DDgJ",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 1435.17537481836,
        y: 125.08491439172937,
        strokeColor: "#6d7379",
        backgroundColor: "#6d7379",
        width: 15.658419933780037,
        height: 39.827812057984154,
        seed: 215527107,
        groupIds: ["9FcJGuOGoWc5RJAwdD-BX"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036762900,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 118,
        versionNonce: 1532060493,
        isDeleted: false,
        id: "O13NG6PvUZCQWiCkqb6lV",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 1440.9777905840608,
        y: 450.1347044238688,
        strokeColor: "#495057",
        backgroundColor: "#6d7379",
        width: 14.852289434282904,
        height: 21.300925907718153,
        seed: 1409015395,
        groupIds: ["9FcJGuOGoWc5RJAwdD-BX"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036762900,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 375,
        versionNonce: 1197671427,
        isDeleted: false,
        id: "uL_zjvru7OxDVVL8wASJT",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 1439.3949302017802,
        y: 246.34001104654067,
        strokeColor: "#6d7379",
        backgroundColor: "#6d7379",
        width: 12.771064787132099,
        height: 68.25545417829794,
        seed: 441981443,
        groupIds: ["9FcJGuOGoWc5RJAwdD-BX"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036762900,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 196,
        versionNonce: 390164909,
        isDeleted: false,
        id: "a7B8PYclCGiT6J7LzGnDv",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 1562.2296638949254,
        y: 449.22430868644346,
        strokeColor: "#495057",
        backgroundColor: "#6d7379",
        width: 14.852289434282904,
        height: 21.300925907718153,
        seed: 753656227,
        groupIds: ["9FcJGuOGoWc5RJAwdD-BX"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036762900,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 369,
        versionNonce: 218729379,
        isDeleted: false,
        id: "QUuzpxVe-WA69yq_WD24t",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 1414.5467426493076,
        y: 91.99294109698457,
        strokeColor: "#6d7379",
        backgroundColor: "transparent",
        width: 186.0493718442421,
        height: 355.97106120533647,
        seed: 887469379,
        groupIds: ["9FcJGuOGoWc5RJAwdD-BX"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036762900,
        link: null,
        locked: false,
      },
    ],
    id: "WShYS9a63ifD6CnPFLVYC",
    created: 1702036876316,
  },
  {
    status: "published",
    elements: [
      {
        type: "line",
        version: 1923,
        versionNonce: 579377709,
        isDeleted: false,
        id: "cqTu0u04NXwVmUTlS1LgS",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1203.8345608847735,
        y: 474.68726507099507,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 296.7703874305463,
        height: 170.63355049173234,
        seed: 1710577827,
        groupIds: ["qQ67pDa-vJGbS0vBpo07c", "V6f5vjmFaofy3VyT4HwAu"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036712256,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-79.58738833039303, 102.93073267219893],
          [217.18299910015327, 101.09936434447431],
          [126.16190455321885, 2.7714724674323428],
          [126.16190455321885, -67.70281781953341],
          [0.9406729477867234, -67.37563700183142],
          [0, 0],
        ],
      },
      {
        type: "line",
        version: 893,
        versionNonce: 461843235,
        isDeleted: false,
        id: "trW2iSCxtjz0y0rt9Ed7S",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1420.5130795254381,
        y: 575.0774593777297,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 295.7254025660527,
        height: 25.864451292146548,
        seed: 464014403,
        groupIds: ["qQ67pDa-vJGbS0vBpo07c", "V6f5vjmFaofy3VyT4HwAu"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036712256,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1.6800733062594673, 24.656606432757446],
          [-294.0453292597933, 25.864451292146548],
        ],
      },
      {
        type: "line",
        version: 423,
        versionNonce: 607046797,
        isDeleted: false,
        id: "3SpCGDdaQHRF1d8nxc0nS",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1124.410485884627,
        y: 578.5819448558293,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.9733649267399428,
        height: 22.278144703892668,
        seed: 1912327139,
        groupIds: ["qQ67pDa-vJGbS0vBpo07c", "V6f5vjmFaofy3VyT4HwAu"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036712256,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.9733649267399428, 22.278144703892668],
        ],
      },
      {
        type: "ellipse",
        version: 500,
        versionNonce: 341561027,
        isDeleted: false,
        id: "oSSE6v_qQNfXxdF1AN-p6",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 1391.4121658595222,
        y: 581.0498321892593,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 13.9717932678783,
        height: 12.93182708209942,
        seed: 486430595,
        groupIds: ["qQ67pDa-vJGbS0vBpo07c", "V6f5vjmFaofy3VyT4HwAu"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036712256,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 603,
        versionNonce: 1260368621,
        isDeleted: false,
        id: "-mYOOno0RGjtXUgmN76vA",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 1368.2242752033494,
        y: 580.9991850018307,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 13.9717932678783,
        height: 12.93182708209942,
        seed: 1257140003,
        groupIds: ["qQ67pDa-vJGbS0vBpo07c", "V6f5vjmFaofy3VyT4HwAu"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036712256,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 711,
        versionNonce: 1041956451,
        isDeleted: false,
        id: "MeMFFbh1e7muojDNfsYj_",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 1345.6289917742417,
        y: 580.9534849922427,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 13.9717932678783,
        height: 12.93182708209942,
        seed: 1580896963,
        groupIds: ["qQ67pDa-vJGbS0vBpo07c", "V6f5vjmFaofy3VyT4HwAu"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036712256,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 292,
        versionNonce: 65326413,
        isDeleted: false,
        id: "s7LSzMgCED9Oa4mhcUWn0",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 1204.6801858388276,
        y: 473.7126649500844,
        strokeColor: "#6d7379",
        backgroundColor: "#6d7379",
        width: 125.19178603702825,
        height: 0,
        seed: 1736524387,
        groupIds: ["V6f5vjmFaofy3VyT4HwAu"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036712256,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [125.19178603702825, 0],
        ],
      },
      {
        type: "line",
        version: 134,
        versionNonce: 865286659,
        isDeleted: false,
        id: "n9Yv3XSKji1_7SphSjqp_",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 1148.680438726982,
        y: 589.2100949549172,
        strokeColor: "#6d7379",
        backgroundColor: "#6d7379",
        width: 71.04521402305767,
        height: 0.8764547684941969,
        seed: 1603337731,
        groupIds: ["V6f5vjmFaofy3VyT4HwAu"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036712256,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [71.04521402305767, 0.8764547684941969],
        ],
      },
      {
        type: "line",
        version: 129,
        versionNonce: 1842846637,
        isDeleted: false,
        id: "MCz3H4eNiJsSJ2Reh97FB",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 1207.88413220953,
        y: 440.91880333785446,
        strokeColor: "#6d7379",
        backgroundColor: "#6d7379",
        width: 118.28016638548766,
        height: 0,
        seed: 560481699,
        groupIds: ["V6f5vjmFaofy3VyT4HwAu"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1702036712256,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [118.28016638548766, 0],
        ],
      },
    ],
    id: "7cdUoddiAqAXF3rnvreFh",
    created: 1702036728143,
  },
  {
    status: "published",
    elements: [
      {
        type: "text",
        version: 157,
        versionNonce: 202653038,
        isDeleted: false,
        id: "CRDDKwL38TU6PMC-MCQAT",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 2000.7249997456856,
        y: 1455.4136602484814,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 85.63758850097656,
        height: 40.64969950123587,
        seed: 1053036014,
        groupIds: ["eZTXIbEe0-YFoMg3bjud7"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700468423615,
        link: null,
        locked: false,
        fontSize: 32.5197596009887,
        fontFamily: 1,
        text: "30mm",
        textAlign: "left",
        verticalAlign: "top",
        containerId: null,
        originalText: "30mm",
        lineHeight: 1.25,
        baseline: 28,
      },
      {
        type: "line",
        version: 97,
        versionNonce: 476780658,
        isDeleted: false,
        id: "ftFVsbxEwMc-ztp6499Nn",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 1528.0833333333321,
        y: 1489.7385099990993,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 1031,
        height: 0,
        seed: 1663574062,
        groupIds: ["cCLd_4MIVtFwzn6l79b2A", "eZTXIbEe0-YFoMg3bjud7"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700468423615,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1031, 0],
        ],
      },
      {
        type: "line",
        version: 277,
        versionNonce: 96886702,
        isDeleted: false,
        id: "79r0SwC5S62kg6MxwMPFZ",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 1570.647435181881,
        y: 1457.8906802466813,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 0,
        height: 63.69565950483582,
        seed: 2110777966,
        groupIds: ["cCLd_4MIVtFwzn6l79b2A", "eZTXIbEe0-YFoMg3bjud7"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700468423615,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 63.69565950483582],
        ],
      },
      {
        type: "line",
        version: 273,
        versionNonce: 1746090546,
        isDeleted: false,
        id: "U3XuJS1osMT1CG89DGYIe",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 1592.1987600449083,
        y: 1468.1871851360718,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 43.102649726055006,
        height: 43.10264972605512,
        seed: 2105830574,
        groupIds: ["cCLd_4MIVtFwzn6l79b2A", "eZTXIbEe0-YFoMg3bjud7"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700468423615,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-43.102649726055006, 43.10264972605512],
        ],
      },
      {
        type: "line",
        version: 384,
        versionNonce: 1098529262,
        isDeleted: false,
        id: "HIk7OhXl-xO5d3sYdxYq9",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 2525.0781865048166,
        y: 1457.8906802466813,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 0,
        height: 63.69565950483582,
        seed: 267298542,
        groupIds: ["cCLd_4MIVtFwzn6l79b2A", "eZTXIbEe0-YFoMg3bjud7"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700468423615,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 63.69565950483582],
        ],
      },
      {
        type: "line",
        version: 381,
        versionNonce: 1439769586,
        isDeleted: false,
        id: "hV-YBfmGTFgBYt_LgYulU",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 2546.6295113678443,
        y: 1468.1871851360718,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 43.102649726055006,
        height: 43.10264972605512,
        seed: 1167563054,
        groupIds: ["cCLd_4MIVtFwzn6l79b2A", "eZTXIbEe0-YFoMg3bjud7"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700468423615,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-43.102649726055006, 43.10264972605512],
        ],
      },
    ],
    id: "Fmmu1M3GqqdauQ9kZSauj",
    created: 1700468429902,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 851,
        versionNonce: 2088983179,
        isDeleted: false,
        id: "dFNxGNTWpPUqGRF8m3h5l",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11438.890107622043,
        y: 1690.5845206634306,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 36.61226581312985,
        height: 12.972627627547848,
        seed: 863354347,
        groupIds: ["YUn9CFk_Iic2rs1yE7z3F"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700291023531,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 501,
        versionNonce: 64858021,
        isDeleted: false,
        id: "CbTyP1IZ85nMgewksKFTI",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11572.46051357908,
        y: 1686.197502346218,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 39.70000000000027,
        height: 51.55224878989386,
        seed: 360499339,
        groupIds: ["YUn9CFk_Iic2rs1yE7z3F"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700291023531,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 201,
        versionNonce: 1877856555,
        isDeleted: false,
        id: "473vTM6RcZ7zVtglFTEiN",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11453.974625824188,
        y: 1694.3242850430752,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 4.5,
        height: 4.900000000000091,
        seed: 1133084459,
        groupIds: ["YUn9CFk_Iic2rs1yE7z3F"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700291023531,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 207,
        versionNonce: 728984325,
        isDeleted: false,
        id: "rKrHIZp2Yd7Xj7gfcff3q",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11454.427031367604,
        y: 1712.196610119837,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 6.5,
        height: 4.800000000000182,
        seed: 134635979,
        groupIds: ["YUn9CFk_Iic2rs1yE7z3F"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700291023531,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 189,
        versionNonce: 135295947,
        isDeleted: false,
        id: "YNHvygx9AsvY0ArunoHDC",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11575.922031055721,
        y: 1710.787724887063,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 4.700000000000273,
        height: 5.099999999999909,
        seed: 829074187,
        groupIds: ["YUn9CFk_Iic2rs1yE7z3F"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700291023531,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 1111,
        versionNonce: 372884069,
        isDeleted: false,
        id: "MvKPKNb5obCggCm67mvEf",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11433.15132429426,
        y: 1675.8379299785443,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 185.42447056488635,
        height: 85.4707139416728,
        seed: 2018560517,
        groupIds: ["YUn9CFk_Iic2rs1yE7z3F"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700291023531,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.452216008462406, 68.77046815273887],
          [47.06682395602663, 69.54884092490266],
          [46.782768674329525, 13.640590281062334],
          [132.92878206507126, 12.92726883567858],
          [134.07182051861858, 69.58921761049045],
          [184.43141330007487, 67.87545162221033],
          [183.8314885451315, -15.881496331182342],
          [-0.9930572648114763, -13.620401938268206],
          [0, 0],
        ],
      },
      {
        type: "rectangle",
        version: 928,
        versionNonce: 1426440811,
        isDeleted: false,
        id: "nBJxcLJ_s3ygcGr0qu74P",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11439.303707879086,
        y: 1708.6799703914937,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 36.61226581312985,
        height: 12.972627627547848,
        seed: 1666114533,
        groupIds: ["YUn9CFk_Iic2rs1yE7z3F"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700291023531,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 1018,
        versionNonce: 956181957,
        isDeleted: false,
        id: "39yKWSBAyFrxswYg_56SQ",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11439.205642778532,
        y: 1726.6897186218334,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 36.61226581312985,
        height: 12.972627627547848,
        seed: 903747365,
        groupIds: ["YUn9CFk_Iic2rs1yE7z3F"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700291023531,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 266,
        versionNonce: 2140683531,
        isDeleted: false,
        id: "9fwu7xvMUr1axvzgv5Leh",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11454.885242704684,
        y: 1731.067410002618,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 4.5,
        height: 4.900000000000091,
        seed: 1768510571,
        groupIds: ["YUn9CFk_Iic2rs1yE7z3F"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700291023531,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 1085,
        versionNonce: 316550437,
        isDeleted: false,
        id: "vV0fFamZfEeyI0LuJFBeP",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11439.67746543213,
        y: 1669.435427322145,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 76.4701783321488,
        height: 11.638572203078219,
        seed: 1487777867,
        groupIds: ["YUn9CFk_Iic2rs1yE7z3F"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700291023531,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 1216,
        versionNonce: 859988907,
        isDeleted: false,
        id: "qVmj-zlGYfuHeHDz9nI-w",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11528.746018149552,
        y: 1668.340058651845,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 77.56369671753059,
        height: 12.972627627547848,
        seed: 2109140299,
        groupIds: ["YUn9CFk_Iic2rs1yE7z3F"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700291023531,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 283,
        versionNonce: 1665613957,
        isDeleted: false,
        id: "nz7NO2u0JRrSijOXJCoe6",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11472.833931532796,
        y: 1673.4421365772619,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 4.5,
        height: 4.900000000000091,
        seed: 234261765,
        groupIds: ["YUn9CFk_Iic2rs1yE7z3F"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700291023531,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 371,
        versionNonce: 1211020875,
        isDeleted: false,
        id: "-79TReimokKeKlglhaThM",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11562.88822353924,
        y: 1672.8389436946309,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 4.5,
        height: 4.900000000000091,
        seed: 1513717029,
        groupIds: ["YUn9CFk_Iic2rs1yE7z3F"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700291023531,
        link: null,
        locked: false,
      },
    ],
    id: "pZTTbVIUfEI5sYf2xc81u",
    created: 1700291025857,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 784,
        versionNonce: 569296235,
        isDeleted: false,
        id: "7B-U_9x6w6axA_9MV3q2p",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12564.658233307277,
        y: 1292.1241145760155,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 323.81952632350834,
        height: 169.146381359463,
        seed: 698363115,
        groupIds: ["r8m304ZWXByQtC3BpIRwR"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290748552,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 435,
        versionNonce: 1297799877,
        isDeleted: false,
        id: "A5jWHOkRgRIjIjrJ9gWTv",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12570.8093266936,
        y: 1298.710477166242,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 312.8799744544775,
        height: 156.02974050973054,
        seed: 1518425995,
        groupIds: ["r8m304ZWXByQtC3BpIRwR"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290748552,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 668,
        versionNonce: 1852366859,
        isDeleted: false,
        id: "jlQYlUpnN2S6KGmVu-XcL",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 12878.852926440042,
        y: 1455.8240664263044,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 3.963882221163042,
        height: 3.963882221163042,
        seed: 925117995,
        groupIds: ["r8m304ZWXByQtC3BpIRwR"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290748552,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 717,
        versionNonce: 823999013,
        isDeleted: false,
        id: "vQvEhQVRpa-fgPDZbIzw9",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 12872.40723467669,
        y: 1455.5820842746932,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 3.963882221163042,
        height: 3.963882221163042,
        seed: 924039275,
        groupIds: ["r8m304ZWXByQtC3BpIRwR"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290748552,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 767,
        versionNonce: 1711711915,
        isDeleted: false,
        id: "F8bpZQRXgpPR2QxeG13qL",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 12865.901842812149,
        y: 1455.835432086896,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 3.963882221163042,
        height: 3.963882221163042,
        seed: 1436199659,
        groupIds: ["r8m304ZWXByQtC3BpIRwR"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290748552,
        link: null,
        locked: false,
      },
      {
        type: "text",
        version: 79,
        versionNonce: 1612492165,
        isDeleted: false,
        id: "NUWpb5WmTJjorNXfHjoI-",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12702.41299709987,
        y: 1350.0595788959697,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 47.555999755859375,
        height: 45,
        seed: 146349413,
        groupIds: ["r8m304ZWXByQtC3BpIRwR"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290748552,
        link: null,
        locked: false,
        fontSize: 36,
        fontFamily: 1,
        text: "TV",
        textAlign: "left",
        verticalAlign: "top",
        containerId: null,
        originalText: "TV",
        lineHeight: 1.25,
        baseline: 32,
      },
    ],
    id: "azRKKooxJpinfrbqWxl03",
    created: 1700290750614,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 119,
        versionNonce: 325146987,
        isDeleted: false,
        id: "_DgJIfc_p7GMFd57Ev1kC",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11924.140828627276,
        y: 1417.3570023538396,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 106.80000000000014,
        height: 50,
        seed: 934928203,
        groupIds: ["NU43YVqIFI_JvID-Yo3m3"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 96,
        versionNonce: 1359709893,
        isDeleted: false,
        id: "dFNxGNTWpPUqGRF8m3h5l",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11931.749172392976,
        y: 1427.996894629457,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 42,
        height: 33.700000000000045,
        seed: 863354347,
        groupIds: ["NU43YVqIFI_JvID-Yo3m3"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 64,
        versionNonce: 1013511179,
        isDeleted: false,
        id: "CbTyP1IZ85nMgewksKFTI",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11984.849172392976,
        y: 1427.3968946294572,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 39.70000000000027,
        height: 34.59999999999991,
        seed: 360499339,
        groupIds: ["NU43YVqIFI_JvID-Yo3m3"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 30,
        versionNonce: 1373136421,
        isDeleted: false,
        id: "473vTM6RcZ7zVtglFTEiN",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11966.449172392975,
        y: 1419.996894629457,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 4.5,
        height: 4.900000000000091,
        seed: 1133084459,
        groupIds: ["NU43YVqIFI_JvID-Yo3m3"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 36,
        versionNonce: 585382571,
        isDeleted: false,
        id: "rKrHIZp2Yd7Xj7gfcff3q",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11985.049172392975,
        y: 1420.496894629457,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 6.5,
        height: 4.800000000000182,
        seed: 134635979,
        groupIds: ["NU43YVqIFI_JvID-Yo3m3"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 57,
        versionNonce: 412244357,
        isDeleted: false,
        id: "q24Kg6bMY3SMMU_zC6MpQ",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11977.249172392976,
        y: 1421.2968946294573,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 3.700000000000273,
        height: 19.299999999999955,
        seed: 2081288299,
        groupIds: ["NU43YVqIFI_JvID-Yo3m3"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 34,
        versionNonce: 863874379,
        isDeleted: false,
        id: "YNHvygx9AsvY0ArunoHDC",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11950.849172392976,
        y: 1441.3968946294572,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 4.700000000000273,
        height: 5.099999999999909,
        seed: 829074187,
        groupIds: ["NU43YVqIFI_JvID-Yo3m3"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 32,
        versionNonce: 242671845,
        isDeleted: false,
        id: "G90A2nSbhdCmEQPqwxtHu",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 12002.649172392976,
        y: 1442.5968946294574,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 5.300000000000182,
        height: 4.899999999999864,
        seed: 479662507,
        groupIds: ["NU43YVqIFI_JvID-Yo3m3"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
    ],
    id: "65rX9KDm7eZUMGgLwizD3",
    created: 1700290615031,
  },
  {
    status: "published",
    elements: [
      {
        type: "ellipse",
        version: 27,
        versionNonce: 1585421675,
        isDeleted: false,
        id: "BerxpQgCemGlQT-VmNXpC",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12631.9888396163,
        y: 954.7393543895662,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 100,
        height: 100,
        seed: 1237542731,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 40,
        versionNonce: 14910149,
        isDeleted: false,
        id: "ir2MxeMIOsl0hUhIWZUhM",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 12686.805686921307,
        y: 962.1723388814614,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 45,
        height: 49.583333333333485,
        seed: 1527662059,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-45, 49.583333333333485],
        ],
      },
      {
        type: "line",
        version: 40,
        versionNonce: 320780299,
        isDeleted: false,
        id: "GXKrnF8t3xMnrDfNpvpON",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 12726.805686921307,
        y: 996.339005548128,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 43.75,
        height: 55,
        seed: 1220825227,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-43.75, 55],
        ],
      },
      {
        type: "line",
        version: 15,
        versionNonce: 1028710949,
        isDeleted: false,
        id: "LqX__EpYKTuHuJKK42e4E",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12660.347353587973,
        y: 946.5473388814614,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.20833333333337123,
        height: 12.916666666666742,
        seed: 882902827,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.20833333333337123, 12.916666666666742],
        ],
      },
      {
        type: "line",
        version: 22,
        versionNonce: 641826475,
        isDeleted: false,
        id: "JFMcz2VY0R4PxKR_XwIp0",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12660.972353587973,
        y: 946.1306722147949,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 47.70833333333326,
        height: 0.4166666666665151,
        seed: 621537739,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [47.70833333333326, 0.4166666666665151],
        ],
      },
      {
        type: "line",
        version: 16,
        versionNonce: 2006668677,
        isDeleted: false,
        id: "MMltKyj_hn_zqHKxL8Iy0",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12708.055686921307,
        y: 947.1723388814614,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.20833333333325754,
        height: 14.583333333333485,
        seed: 1864530027,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.20833333333325754, 14.583333333333485],
        ],
      },
      {
        type: "line",
        version: 12,
        versionNonce: 1653764427,
        isDeleted: false,
        id: "HeafDHi46U9ucLs6qnf4i",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12664.51402025464,
        y: 1052.7973388814614,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 1.0416666666666288,
        height: 11.875,
        seed: 1622029067,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-1.0416666666666288, 11.875],
        ],
      },
      {
        type: "line",
        version: 30,
        versionNonce: 453008613,
        isDeleted: false,
        id: "RH8zjgDjtySSxtX252rp4",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12661.180686921307,
        y: 1062.7973388814614,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 43.95833333333337,
        height: 0,
        seed: 707369387,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [43.95833333333337, 0],
        ],
      },
      {
        type: "line",
        version: 26,
        versionNonce: 1500254187,
        isDeleted: false,
        id: "LxnxUE7UUIax20L6thr7a",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12704.51402025464,
        y: 1050.505672214795,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 1.0416666666666288,
        height: 15,
        seed: 146746443,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1.0416666666666288, 15],
        ],
      },
      {
        type: "line",
        version: 21,
        versionNonce: 1326282821,
        isDeleted: false,
        id: "9O6cLRarezx4H366QXzrP",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12728.88902025464,
        y: 984.464005548128,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 14.583333333333371,
        height: 1.25,
        seed: 810117867,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [14.583333333333371, -1.25],
        ],
      },
      {
        type: "line",
        version: 33,
        versionNonce: 215538315,
        isDeleted: false,
        id: "rbFNYdQobzqHPNcIrQlFW",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12742.847353587973,
        y: 982.589005548128,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 1.6666666666666288,
        height: 47.08333333333326,
        seed: 1968362891,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-1.6666666666666288, 47.08333333333326],
        ],
      },
      {
        type: "line",
        version: 23,
        versionNonce: 1839212453,
        isDeleted: false,
        id: "1sCH5Z1LFTAoVs6eRUliq",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12740.972353587973,
        y: 1029.0473388814614,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 12.916666666666629,
        height: 0.8333333333332575,
        seed: 924183595,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-12.916666666666629, -0.8333333333332575],
        ],
      },
      {
        type: "line",
        version: 25,
        versionNonce: 1502826795,
        isDeleted: false,
        id: "xvOztlsJRqAiSzSLaS51g",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12636.38902025464,
        y: 984.464005548128,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 17.291666666666742,
        height: 0.41666666666674246,
        seed: 159198923,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-17.291666666666742, -0.41666666666674246],
        ],
      },
      {
        type: "line",
        version: 57,
        versionNonce: 954592005,
        isDeleted: false,
        id: "WHY6rTTBbS_KL8OMTHxyk",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12622.222353587973,
        y: 983.6306722147949,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.625,
        height: 50,
        seed: 522394987,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.625, 50],
        ],
      },
      {
        type: "line",
        version: 25,
        versionNonce: 554038219,
        isDeleted: false,
        id: "CBjmDi5GpGYa5NKkcQ0NA",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12621.38902025464,
        y: 1033.4223388814614,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 16.25,
        height: 1.041666666666515,
        seed: 1934768139,
        groupIds: ["I-Fl_Rm0Nk6ExJllBQZNX"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [16.25, -1.041666666666515],
        ],
      },
    ],
    id: "jiMs6lRQrjg82no0-eb98",
    created: 1700290600726,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 17,
        versionNonce: 207479653,
        isDeleted: false,
        id: "zBijU9Kn9RdhodITtcb7d",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13792.78259613992,
        y: 954.5592034718752,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 100,
        height: 100,
        seed: 596663467,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 17,
        versionNonce: 1534079339,
        isDeleted: false,
        id: "tHzqN7OLbUuYyT3ydtSBO",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13817.311838357477,
        y: 945.5087879511552,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.7017543859649322,
        height: 11.052631578947285,
        seed: 1314230091,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.7017543859649322, 11.052631578947285],
        ],
      },
      {
        type: "line",
        version: 34,
        versionNonce: 2012622533,
        isDeleted: false,
        id: "AD0x8jJGKtPgregnlF0LV",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13817.66271555046,
        y: 944.6315949686991,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 47.71929824561414,
        height: 1.052631578947512,
        seed: 1528524267,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [47.71929824561414, -1.052631578947512],
        ],
      },
      {
        type: "line",
        version: 17,
        versionNonce: 1289538571,
        isDeleted: false,
        id: "kqTVSiBK6gfz0M-ne67lL",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13865.382013796074,
        y: 943.5789633897516,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.526315789473756,
        height: 13.157894736842081,
        seed: 485259403,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.526315789473756, 13.157894736842081],
        ],
      },
      {
        type: "line",
        version: 15,
        versionNonce: 355339813,
        isDeleted: false,
        id: "mOODcE9gTuK3G-NuFJErc",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13893.452189234671,
        y: 975.1579107581726,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 12.982456140350905,
        height: 1.2280701754386882,
        seed: 562245419,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [12.982456140350905, 1.2280701754386882],
        ],
      },
      {
        type: "line",
        version: 50,
        versionNonce: 656216747,
        isDeleted: false,
        id: "j35YfMohUSPinDnRTPtu2",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13905.557452392564,
        y: 975.8596651441376,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.5263157894736423,
        height: 53.50877192982466,
        seed: 1531580875,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.5263157894736423, 53.50877192982466],
        ],
      },
      {
        type: "line",
        version: 18,
        versionNonce: 854786437,
        isDeleted: false,
        id: "dthmxd5nCRx_3d68Ei7nw",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13905.031136603091,
        y: 1029.3684370739622,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 12.456140350877263,
        height: 0.7017543859649322,
        seed: 190554219,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-12.456140350877263, -0.7017543859649322],
        ],
      },
      {
        type: "line",
        version: 13,
        versionNonce: 1792771403,
        isDeleted: false,
        id: "ompR1h2hbATVwVPIOzaYK",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13815.732890989057,
        y: 1055.8596651441376,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.3508771929824661,
        height: 11.052631578947512,
        seed: 1803705099,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.3508771929824661, 11.052631578947512],
        ],
      },
      {
        type: "line",
        version: 50,
        versionNonce: 1719830757,
        isDeleted: false,
        id: "6tQf-8NV-8kDq_itX3BtG",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13815.206575199583,
        y: 1066.7368581265937,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 52.10526315789468,
        height: 0.526315789473756,
        seed: 406372779,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [52.10526315789468, 0.526315789473756],
        ],
      },
      {
        type: "line",
        version: 24,
        versionNonce: 527606763,
        isDeleted: false,
        id: "ERaV-JFcyFJB9yobIa1s2",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13866.785522568003,
        y: 1067.0877353195763,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 1.2280701754385746,
        height: 12.280701754385973,
        seed: 984175691,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1.2280701754385746, -12.280701754385973],
        ],
      },
      {
        type: "line",
        version: 34,
        versionNonce: 1097475141,
        isDeleted: false,
        id: "OoCGv9llUwnl7Hv6jw9Kx",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13792.750434848705,
        y: 974.8070335651903,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 11.57894736842104,
        height: 0.7017543859649322,
        seed: 1924043499,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-11.57894736842104, 0.7017543859649322],
        ],
      },
      {
        type: "line",
        version: 51,
        versionNonce: 2095902347,
        isDeleted: false,
        id: "_cvrPZsKL6RhItoERHPut",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13781.171487480284,
        y: 975.3333493546638,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.7017543859649322,
        height: 56.140350877193214,
        seed: 13827467,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.7017543859649322, 56.140350877193214],
        ],
      },
      {
        type: "line",
        version: 16,
        versionNonce: 1200051109,
        isDeleted: false,
        id: "yp67QMmjHPSDVWjYPQcMg",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13781.697803269759,
        y: 1031.6491388283482,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 12.456140350877149,
        height: 0.3508771929825798,
        seed: 810619947,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [12.456140350877149, -0.3508771929825798],
        ],
      },
      {
        type: "line",
        version: 27,
        versionNonce: 1443161387,
        isDeleted: false,
        id: "OPEalCLv5ImaB7gyxpWx5",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 13830.237457741907,
        y: 957.6378145309825,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 35.652173913043384,
        height: 39.565217391304486,
        seed: 18044619,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-35.652173913043384, 39.565217391304486],
        ],
      },
      {
        type: "line",
        version: 39,
        versionNonce: 621254405,
        isDeleted: false,
        id: "q1Y8hB2cJEsl_zL5uKDow",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 13890.237457741907,
        y: 992.8552058353305,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 55.652173913043384,
        height: 56.73913043478251,
        seed: 297026923,
        groupIds: ["lmK6FRstyEgMKuMNwXtX9"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-55.652173913043384, 56.73913043478251],
        ],
      },
    ],
    id: "S5wFuv86ScFsX5ZfAgz5U",
    created: 1700290597710,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 55,
        versionNonce: 1576278987,
        isDeleted: false,
        id: "1tRrIJWnbWym2VtQ9r0e-",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13813.784580901924,
        y: 1219.4103403378085,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 60,
        height: 65.06024096385525,
        seed: 443189259,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 84,
        versionNonce: 1069849189,
        isDeleted: false,
        id: "xuFaYRBuNxYAoERvde4h3",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13821.347951652966,
        y: 1229.4199587072744,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 19.156626506024168,
        height: 19.518072289156862,
        seed: 1939033771,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 46,
        versionNonce: 3435115,
        isDeleted: false,
        id: "jK9RQEXEy_gdvpR0tYvxc",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13824.119035990316,
        y: 1266.5283924422142,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 10.96385542168673,
        height: 8.674698795180802,
        seed: 1160397131,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 43,
        versionNonce: 460195269,
        isDeleted: false,
        id: "KdMBsjCw3mzLDnlMhtaMS",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13853.51662635176,
        y: 1233.0344165385998,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 9.518072289156635,
        height: 7.590361445783174,
        seed: 948559851,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 51,
        versionNonce: 514974987,
        isDeleted: false,
        id: "bzP-l0yIqxEECO49YwEnO",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13849.420240809592,
        y: 1261.9500791892024,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 15.301204819277018,
        height: 13.493975903614228,
        seed: 1740417675,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 42,
        versionNonce: 1234371877,
        isDeleted: false,
        id: "id_kglHJPDXQ-OuaVUSlI",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13830.263614303569,
        y: 1243.5163442494431,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.12048192771089816,
        height: 8.433734939759233,
        seed: 1199382827,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.12048192771089816, 8.433734939759233],
        ],
      },
      {
        type: "line",
        version: 76,
        versionNonce: 283577259,
        isDeleted: false,
        id: "pZIFexdEfq-GDsmTlxIp6",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13830.50457815899,
        y: 1234.4801996711299,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 1.0843373493976287,
        height: 7.831325301204743,
        seed: 1362478027,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-1.0843373493976287, -7.831325301204743],
        ],
      },
      {
        type: "line",
        version: 72,
        versionNonce: 497096837,
        isDeleted: false,
        id: "eR70TIDpOhccldvJwONwR",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13843.51662635176,
        y: 1238.9380309964313,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 6.144578313253078,
        height: 0.12048192771089816,
        seed: 1828786795,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-6.144578313253078, -0.12048192771089816],
        ],
      },
      {
        type: "line",
        version: 74,
        versionNonce: 1526619723,
        isDeleted: false,
        id: "OgRco40MIasq-hAJNdIbe",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13825.082891412003,
        y: 1239.6609225626962,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 7.2289156626507065,
        height: 0.9638554216867306,
        seed: 464096523,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-7.2289156626507065, -0.9638554216867306],
        ],
      },
      {
        type: "line",
        version: 35,
        versionNonce: 559267813,
        isDeleted: false,
        id: "MfUND57TSMHFMVN5FpGNS",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13830.022650448147,
        y: 1263.1548984663107,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.12048192771089816,
        height: 5.6626506024097125,
        seed: 1621734315,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.12048192771089816, 5.6626506024097125],
        ],
      },
      {
        type: "line",
        version: 36,
        versionNonce: 399662315,
        isDeleted: false,
        id: "0VyLuyRlmNfJjqQCjm8xX",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13820.866023942122,
        y: 1270.9862237675156,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 5.542168674698814,
        height: 0.24096385542179632,
        seed: 1814824523,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [5.542168674698814, -0.24096385542179632],
        ],
      },
      {
        type: "line",
        version: 38,
        versionNonce: 1253997381,
        isDeleted: false,
        id: "lCV47Xk1I4UN4ixR0Wci_",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13829.420240809592,
        y: 1273.2753803940216,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.3614457831324671,
        height: 5.18072289156612,
        seed: 84608235,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.3614457831324671, 5.18072289156612],
        ],
      },
      {
        type: "line",
        version: 31,
        versionNonce: 585992075,
        isDeleted: false,
        id: "BMI3v77hvRG92EOHne1Z1",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13858.817831171038,
        y: 1278.9380309964313,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.9638554216867306,
        height: 6.746987951807341,
        seed: 852569995,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.9638554216867306, -6.746987951807341],
        ],
      },
      {
        type: "line",
        version: 40,
        versionNonce: 1916324517,
        isDeleted: false,
        id: "Y7jNumF9fA9P89GjjkB3c",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13866.890120327665,
        y: 1268.0946575024552,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 5.301204819277018,
        height: 0.24096385542156895,
        seed: 666437163,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-5.301204819277018, -0.24096385542156895],
        ],
      },
      {
        type: "line",
        version: 33,
        versionNonce: 42622507,
        isDeleted: false,
        id: "KnBtoZLhDua4BU21Vj5ic",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13857.37204803851,
        y: 1258.8175490687204,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.12048192771089816,
        height: 6.746987951807114,
        seed: 1789724875,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.12048192771089816, 6.746987951807114],
        ],
      },
      {
        type: "line",
        version: 29,
        versionNonce: 141700613,
        isDeleted: false,
        id: "9kD9Q7IQ_V-8oYlRS0gsS",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13846.408192616822,
        y: 1269.1789948518528,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 6.626506024096443,
        height: 0.8433734939758324,
        seed: 1095219051,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [6.626506024096443, -0.8433734939758324],
        ],
      },
      {
        type: "line",
        version: 31,
        versionNonce: 294871243,
        isDeleted: false,
        id: "-mdr_SGQBdTQnnG9OE8u_",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13858.576867315616,
        y: 1243.3958623217325,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.3614457831324671,
        height: 5.421686746987916,
        seed: 1507037707,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.3614457831324671, -5.421686746987916],
        ],
      },
      {
        type: "line",
        version: 34,
        versionNonce: 208151909,
        isDeleted: false,
        id: "vyXTx3DYnjCQw12RBuW-L",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13858.938313098748,
        y: 1234.7211635265517,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.24096385542156895,
        height: 4.578313253012084,
        seed: 894113963,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.24096385542156895, -4.578313253012084],
        ],
      },
      {
        type: "line",
        version: 35,
        versionNonce: 1772293995,
        isDeleted: false,
        id: "5qesRqbS8458BWOcGa37k",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13850.74554201441,
        y: 1237.1308020807685,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 5.180722891566347,
        height: 0.3614457831324671,
        seed: 1364773707,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [5.180722891566347, -0.3614457831324671],
        ],
      },
      {
        type: "line",
        version: 30,
        versionNonce: 1097347269,
        isDeleted: false,
        id: "kb3zLe5qRl4my15Y98UMU",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13866.649156472244,
        y: 1237.1308020807685,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 4.81927710843388,
        height: 1.2048192771082995,
        seed: 307702251,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-4.81927710843388, -1.2048192771082995],
        ],
      },
      {
        type: "line",
        version: 72,
        versionNonce: 218360331,
        isDeleted: false,
        id: "vFBn1uMFEFNdfQLfXUNzY",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13813.637108279472,
        y: 1224.7211635265517,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 59.638554216867306,
        height: 0.12048192771089816,
        seed: 1834005643,
        groupIds: ["8Zfla12si0I_FGQH3A6TO"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [59.638554216867306, 0.12048192771089816],
        ],
      },
    ],
    id: "JuLnIrBKk0QzsQbXNQwIE",
    created: 1700290594694,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 43,
        versionNonce: 2032415013,
        isDeleted: false,
        id: "G9P5-CnxSr5j6dBq2Kp8v",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13753.710844590698,
        y: 1402.3570023538396,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 180,
        height: 80,
        seed: 2056276267,
        groupIds: ["D4X1QYtUmL3RQvcHPkDZV"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 112,
        versionNonce: 1062117291,
        isDeleted: false,
        id: "eHYL9hU-4mtvOpsabtrjn",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 6.182028006737102,
        x: 13764.644003942985,
        y: 1411.5987751992457,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 161.3888888888889,
        height: 62.2222222222224,
        seed: 128183243,
        groupIds: ["D4X1QYtUmL3RQvcHPkDZV"],
        frameId: null,
        roundness: { type: 1 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 21,
        versionNonce: 439141509,
        isDeleted: false,
        id: "sqjJ45DTriyxinIT7Z0Fv",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 13776.866226165208,
        y: 1446.0432196436905,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 5.277777777777828,
        height: 5.277777777777601,
        seed: 57293419,
        groupIds: ["D4X1QYtUmL3RQvcHPkDZV"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
    ],
    id: "j-XFTt8MLEQ6WutRAUBTA",
    created: 1700290592383,
  },
  {
    status: "published",
    elements: [
      {
        type: "line",
        version: 347,
        versionNonce: 433907723,
        isDeleted: false,
        id: "Jgn4gN09v-8vIXssqxsdX",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13083.432864355425,
        y: 1504.4679800830008,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 24.66928150299571,
        height: 205.0635951198101,
        seed: 1719318603,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1.0076380061056691, 205.0635951198101],
          [23.35474575316853, 166.13502369123898],
          [24.66928150299571, 80.4207379769532],
          [0, 0],
        ],
      },
      {
        type: "line",
        version: 334,
        versionNonce: 364490411,
        isDeleted: false,
        id: "z55YYJOcPjc79XF6MqEzW",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13241.284300989055,
        y: 1502.9835869892286,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 21.775334042449607,
        height: 205.0635951198101,
        seed: 1723037419,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.8894322347472645, 205.0635951198101],
          [-20.615006164243958, 166.13502369123898],
          [-21.775334042449607, 80.4207379769532],
          [0, 0],
        ],
      },
      {
        type: "line",
        version: 524,
        versionNonce: 327459147,
        isDeleted: false,
        id: "mBZA7bxsgWhplTHpgS_Vm",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13154.849615304069,
        y: 1698.5687002542531,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 129.28571428571422,
        height: 82.63952727490393,
        seed: 1788567947,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [52.5, 3.40781555772831],
          [71.78571428571422, 53.247118089499686],
          [42.14285714285688, 78.37975782774407],
          [-22.142857142857338, 80.509642551324],
          [-57.5, 51.96918725535185],
          [-28.92857142857156, -2.1298847235799223],
          [5.714285714285552, 0.4259769447163099],
        ],
      },
      {
        type: "line",
        version: 559,
        versionNonce: 211304427,
        isDeleted: false,
        id: "_JE81a1rgLovhC032mMC3",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13161.858881138814,
        y: 1483.5572926095556,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 132.203380966419,
        height: 68.86527974826123,
        seed: 191690795,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [38.30191411176618, 2.393001998974377],
          [67.64612250384526, 16.48512488182313],
          [47.25961983145366, 65.67461041629551],
          [0.13520827809725233, 64.8222094045027],
          [-42.00855096129189, 66.47227774928686],
          [-64.55725846257371, 9.306118884900167],
          [-25.637571542553175, -2.393001998974377],
          [0, 0],
        ],
      },
      {
        type: "line",
        version: 1013,
        versionNonce: 1795099275,
        isDeleted: false,
        id: "IzHkXdpqtOU8MuqyCOPk8",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13071.992472446926,
        y: 1483.047182109039,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 179.64285714285734,
        height: 454.2857142857147,
        seed: 1460845259,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [6.428571428571104, -72.14285714285734],
          [51.07142857142844, -97.85714285714289],
          [151.78571428571422, -91.78571428571422],
          [179.64285714285734, -18.57142857142844],
          [176.42857142857156, 294.9999999999998],
          [138.21428571428578, 356.4285714285718],
          [33.92857142857156, 353.21428571428555],
          [3.2142857142857792, 278.92857142857133],
          [0, 0],
        ],
      },
      {
        type: "line",
        version: 110,
        versionNonce: 1936949547,
        isDeleted: false,
        id: "AHYJ-dqhXt8vYbqF9z8l4",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13083.77818673264,
        y: 1416.9757535376102,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 38.57142857142867,
        height: 24.28571428571422,
        seed: 975645035,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [33.92857142857156, -8.214285714285552],
          [38.57142857142867, -24.28571428571422],
        ],
      },
      {
        type: "line",
        version: 95,
        versionNonce: 1868571595,
        isDeleted: false,
        id: "3MhnRbVLNyVlJtHE55op4",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13205.206758161212,
        y: 1389.8328963947533,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 37.85714285714266,
        height: 31.42857142857156,
        seed: 703906827,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [5.714285714285779, 25.35714285714289],
          [37.85714285714266, 31.42857142857156],
        ],
      },
      {
        type: "line",
        version: 88,
        versionNonce: 383742571,
        isDeleted: false,
        id: "UCD6KC-JiI1zuif34BH1A",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13239.849615304069,
        y: 1810.190039251896,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 31.42857142857133,
        height: 25,
        seed: 242617003,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-10, 8.571428571428896],
          [-31.42857142857133, 25],
        ],
      },
      {
        type: "line",
        version: 154,
        versionNonce: 985394443,
        isDeleted: false,
        id: "HYBOnEBhExlao0U5NAByb",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13086.992472446926,
        y: 1808.761467823325,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 28.21428571428578,
        height: 34.285714285713766,
        seed: 1817541963,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [25.71428571428578, 24.285714285713766],
          [28.21428571428578, 34.285714285713766],
        ],
      },
      {
        type: "line",
        version: 170,
        versionNonce: 779097003,
        isDeleted: false,
        id: "6jPMX2sxvpGp3vwLgSkyW",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13132.349615304069,
        y: 1393.761467823325,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 27.14285714285711,
        height: 82.5,
        seed: 1324194795,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-7.85714285714289, 54.64285714285711],
          [-27.14285714285711, 82.5],
        ],
      },
      {
        type: "line",
        version: 93,
        versionNonce: 1915119179,
        isDeleted: false,
        id: "of3PxSG9V6YQbMBaAJXe6",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13196.635329589782,
        y: 1391.9757535376102,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 26.785714285714448,
        height: 93.57142857142867,
        seed: 1917380235,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [2.8571428571428896, 50],
          [26.785714285714448, 93.57142857142867],
        ],
      },
      {
        type: "line",
        version: 90,
        versionNonce: 481912043,
        isDeleted: false,
        id: "nrzFsv2luO_z-AOuZyQg1",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13108.77818673264,
        y: 1621.6186106804676,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 34.64285714285711,
        height: 0.35714285714266225,
        seed: 757194027,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-34.64285714285711, 0.35714285714266225],
        ],
      },
      {
        type: "line",
        version: 62,
        versionNonce: 95653771,
        isDeleted: false,
        id: "J67qh6yOI8aTp1nE0tgBX",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13216.27818673264,
        y: 1622.690039251896,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 39.64285714285734,
        height: 0,
        seed: 451084235,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [39.64285714285734, 0],
        ],
      },
      {
        type: "line",
        version: 86,
        versionNonce: 793732651,
        isDeleted: false,
        id: "7Z2MoQlI2_NQgg5O9ACjJ",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13236.992472446926,
        y: 1715.9043249661818,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 14.64285714285711,
        height: 7.5,
        seed: 94817899,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [14.64285714285711, 7.5],
        ],
      },
      {
        type: "line",
        version: 49,
        versionNonce: 1886873803,
        isDeleted: false,
        id: "fr_X_D69MY4kvy1gYTiCw",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13089.492472446926,
        y: 1716.6186106804676,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 17.85714285714289,
        height: 3.2142857142857792,
        seed: 272091403,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-17.85714285714289, 3.2142857142857792],
        ],
      },
      {
        type: "line",
        version: 48,
        versionNonce: 1590040427,
        isDeleted: false,
        id: "x767o0_X_OavIsbBmVgv3",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13082.349615304069,
        y: 1506.261467823325,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 11.785714285714448,
        height: 0.3571428571428896,
        seed: 537948075,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-11.785714285714448, 0.3571428571428896],
        ],
      },
      {
        type: "line",
        version: 43,
        versionNonce: 2022044171,
        isDeleted: false,
        id: "AgOnrurOXh_iCE5A1Y5Tn",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13242.706758161212,
        y: 1505.190039251896,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 7.857142857142662,
        height: 7.85714285714289,
        seed: 123421259,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [7.857142857142662, -7.85714285714289],
        ],
      },
      {
        type: "rectangle",
        version: 81,
        versionNonce: 246511787,
        isDeleted: false,
        id: "P7NUPjKyQ1YryLUJ1jv8D",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 5.966798119147106,
        x: 13055.233325796475,
        y: 1507.302853973406,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 24.347826086956502,
        height: 8.695652173913004,
        seed: 1861402859,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 1 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 96,
        versionNonce: 1861430091,
        isDeleted: false,
        id: "D87QaGZebxaZmYkwnEGpF",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0.4279977688793162,
        x: 13245.885499709517,
        y: 1504.259375712536,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 24.565217391304486,
        height: 9.565217391304486,
        seed: 6384523,
        groupIds: ["0IDVmSZ-jM5Y442YIBs6d"],
        frameId: null,
        roundness: { type: 1 },
        boundElements: [],
        updated: 1700290584379,
        link: null,
        locked: false,
      },
    ],
    id: "BmlBmNAtyG1SJsNshhMep",
    created: 1700290588013,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 86,
        versionNonce: 437702725,
        isDeleted: false,
        id: "42C17SSQKI0IUq5a3anIG",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13386.420160577734,
        y: 1157.1626830419584,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 90.44444444444434,
        height: 189.55555555555543,
        seed: 1741929195,
        groupIds: ["mpfyftmKgp95seXj38_dz"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 40,
        versionNonce: 1404109451,
        isDeleted: false,
        id: "dXKdnxYuNG_zCK_a_TmEo",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13386.420160577734,
        y: 1217.1626830419584,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 90.74475531582175,
        height: 0.6718846540072718,
        seed: 1729308043,
        groupIds: ["mpfyftmKgp95seXj38_dz"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [90.74475531582175, -0.6718846540072718],
        ],
      },
      {
        type: "line",
        version: 51,
        versionNonce: 1035894693,
        isDeleted: false,
        id: "WdggAtFbP93E8cDN6exSX",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13386.420160577734,
        y: 1217.1626830419584,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 19.240053484216332,
        height: 22.430069939916393,
        seed: 743193643,
        groupIds: ["mpfyftmKgp95seXj38_dz"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [19.240053484216332, 22.430069939916393],
        ],
      },
      {
        type: "line",
        version: 63,
        versionNonce: 1663465771,
        isDeleted: false,
        id: "2O2QmoEuuz-vJ76gKEZhb",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13405.298313991983,
        y: 1239.5357937138378,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 71.99986665967799,
        height: 21.266972226463395,
        seed: 594116299,
        groupIds: ["mpfyftmKgp95seXj38_dz"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [71.99986665967799, -21.266972226463395],
        ],
      },
      {
        type: "rectangle",
        version: 119,
        versionNonce: 595149573,
        isDeleted: false,
        id: "l-RA9Hm87Ku4f1mnPglX1",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13399.071662039343,
        y: 1168.7669564082448,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 68.03174603174557,
        height: 36.70634920634927,
        seed: 1459973483,
        groupIds: ["mpfyftmKgp95seXj38_dz"],
        frameId: null,
        roundness: { type: 1 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
    ],
    id: "GjLd0avS_fEckBdull7XD",
    created: 1700290577128,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 33,
        versionNonce: 1460221093,
        isDeleted: false,
        id: "4BfTbkZkLSJzXpEC5VZUu",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12910.391845134856,
        y: 1151.9404608197362,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 200,
        height: 200,
        seed: 822833707,
        groupIds: ["gllARUHjVpeM24O7RkA0n"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 21,
        versionNonce: 1624085547,
        isDeleted: false,
        id: "snHTGBaxy0k-2tfKJqdSP",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12910.391845134856,
        y: 1211.9404608197362,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 200,
        height: 0,
        seed: 1993471179,
        groupIds: ["gllARUHjVpeM24O7RkA0n"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [200, 0],
        ],
      },
      {
        type: "line",
        version: 15,
        versionNonce: 195385349,
        isDeleted: false,
        id: "SnCzb3T67zl7JiCG2In9h",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12910.391845134856,
        y: 1211.9404608197362,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 40,
        height: 40,
        seed: 1374626667,
        groupIds: ["gllARUHjVpeM24O7RkA0n"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [40, 40],
        ],
      },
      {
        type: "line",
        version: 20,
        versionNonce: 1845483211,
        isDeleted: false,
        id: "kxt-YwNQ_sh0UwFdcUhOg",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12950.391845134856,
        y: 1251.9404608197362,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 160,
        height: 40,
        seed: 984448523,
        groupIds: ["gllARUHjVpeM24O7RkA0n"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [160, -40],
        ],
      },
      {
        type: "rectangle",
        version: 47,
        versionNonce: 1403391845,
        isDeleted: false,
        id: "TAX9kvUI6SSABtnNlQqua",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12930.197329991162,
        y: 1164.588129369234,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 69.30232558139551,
        height: 34.883720930232585,
        seed: 1007125675,
        groupIds: ["gllARUHjVpeM24O7RkA0n"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 48,
        versionNonce: 756073835,
        isDeleted: false,
        id: "LRwOQKudX04BJLE1edMKq",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13015.31360906093,
        y: 1163.8904549506292,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 70.46511627906966,
        height: 35.1162790697673,
        seed: 497530699,
        groupIds: ["gllARUHjVpeM24O7RkA0n"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 294,
        versionNonce: 1162534923,
        isDeleted: false,
        id: "GxstkrLyYQ1L8XCWgy4GJ",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12862.717426530206,
        y: 1153.1404608197363,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 40,
        height: 40,
        seed: 198484107,
        groupIds: ["TMSrxnOTrT94gXEmhlxKa", "gllARUHjVpeM24O7RkA0n"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 282,
        versionNonce: 828062245,
        isDeleted: false,
        id: "x21HPi9hMl0LjV6b0PezY",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 12882.717426530206,
        y: 1153.1404608197363,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 20,
        height: 20,
        seed: 1361467179,
        groupIds: ["TMSrxnOTrT94gXEmhlxKa", "gllARUHjVpeM24O7RkA0n"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-20, 20],
        ],
      },
      {
        type: "line",
        version: 283,
        versionNonce: 279488171,
        isDeleted: false,
        id: "1c77856H7_r8LseLpRMES",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 12902.717426530206,
        y: 1173.1404608197363,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 20,
        height: 20,
        seed: 2085108171,
        groupIds: ["TMSrxnOTrT94gXEmhlxKa", "gllARUHjVpeM24O7RkA0n"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-20, 20],
        ],
      },
      {
        type: "rectangle",
        version: 347,
        versionNonce: 681225605,
        isDeleted: false,
        id: "Xcy57mucv6cYvSENJajeJ",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13119.22905443718,
        y: 1153.1404608197363,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 40,
        height: 40,
        seed: 978634859,
        groupIds: ["uXPL1h5OgBKdFBV13_Pu3", "gllARUHjVpeM24O7RkA0n"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 335,
        versionNonce: 1171560779,
        isDeleted: false,
        id: "BphRnHyB7GfLO-mUXVgYA",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 13139.229054437183,
        y: 1153.1404608197363,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 20,
        height: 20,
        seed: 823558923,
        groupIds: ["uXPL1h5OgBKdFBV13_Pu3", "gllARUHjVpeM24O7RkA0n"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-20, 20],
        ],
      },
      {
        type: "line",
        version: 336,
        versionNonce: 575976677,
        isDeleted: false,
        id: "DG8OO8EsveCUpPNwcdjST",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 13159.22905443718,
        y: 1173.1404608197363,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 20,
        height: 20,
        seed: 1762126251,
        groupIds: ["uXPL1h5OgBKdFBV13_Pu3", "gllARUHjVpeM24O7RkA0n"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-20, 20],
        ],
      },
    ],
    id: "EBntrH_x7oZOrD-bo2-ou",
    created: 1700290574310,
  },
  {
    status: "published",
    elements: [
      {
        type: "line",
        version: 594,
        versionNonce: 1571880555,
        isDeleted: false,
        id: "lDTEof0WAdZZPr3YlIdIT",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13402.78267470119,
        y: 1407.8810684908287,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 59.666666666666515,
        height: 70.33333333333303,
        seed: 1642883403,
        groupIds: ["9feVsQEsM9UV0--vltUfm"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.8333333333332575, 21.33333333333303],
          [8.166666666666515, 29.16666666666697],
          [8.166666666666742, 45.666666666666515],
          [12, 61.33333333333303],
          [26.833333333333258, 70.33333333333303],
          [45, 64.5],
          [49.66666666666629, 45.500000000000455],
          [47.33333333333326, 27.833333333333485],
          [58.16666666666674, 20.83333333333303],
          [58.83333333333326, 0.5],
        ],
      },
      {
        type: "line",
        version: 285,
        versionNonce: 1354019269,
        isDeleted: false,
        id: "KFyyi-QM6KYK7i6unWEvL",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13419.009445756741,
        y: 1439.5106635167065,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 30.82509729020491,
        height: 31.86059998514607,
        seed: 1254557675,
        groupIds: ["9feVsQEsM9UV0--vltUfm"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-3.550534766411093, 9.475781892133996],
          [-1.9366553271332831, 20.736856024814852],
          [5.648578037472003, 30.34996808929887],
          [16.300182336705063, 31.86059998514607],
          [27.274562523793815, 20.736856024814852],
          [25.33790719666053, 0.5493206893989473],
        ],
      },
      {
        type: "line",
        version: 69,
        versionNonce: 1199307019,
        isDeleted: false,
        id: "YxkTLEE3DTwEDWQ6o3D5V",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13403.616008034523,
        y: 1408.5477351574953,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 57.16666666666674,
        height: 0,
        seed: 135582347,
        groupIds: ["9feVsQEsM9UV0--vltUfm"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [57.16666666666674, 0],
        ],
      },
    ],
    id: "jbdPB3uml5ICoWY64wICj",
    created: 1700290559628,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 89,
        versionNonce: 273001381,
        isDeleted: false,
        id: "zZzMeyqXWb1vCh7vXocen",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12981.76230968672,
        y: 1417.2471122439495,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 60,
        height: 50.2197802197802,
        seed: 721904683,
        groupIds: ["Wq-IkBRzOPBc2fVcQ7Dv6"],
        frameId: null,
        roundness: { type: 1 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 86,
        versionNonce: 1089998123,
        isDeleted: false,
        id: "Kmfj1dMVA3qOyT8unmOJh",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12987.612780415371,
        y: 1431.1839186113966,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 50.32967032967031,
        height: 31.098901098901024,
        seed: 1887413963,
        groupIds: ["Wq-IkBRzOPBc2fVcQ7Dv6"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 40,
        versionNonce: 1950019333,
        isDeleted: false,
        id: "mPxf8zz62TYYGvx2eqj5K",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13002.118274920866,
        y: 1422.3927098201875,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 6.373626373626394,
        height: 6.373626373626394,
        seed: 1863751019,
        groupIds: ["Wq-IkBRzOPBc2fVcQ7Dv6"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 49,
        versionNonce: 879964107,
        isDeleted: false,
        id: "p-v9gCJGurkbR6ZN1A2bq",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13018.601791404382,
        y: 1421.5135889410667,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 7.032967032967008,
        height: 6.373626373626394,
        seed: 1712762891,
        groupIds: ["Wq-IkBRzOPBc2fVcQ7Dv6"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 62,
        versionNonce: 1668335205,
        isDeleted: false,
        id: "BtQGeyVRg7fwbR98MWAAx",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13011.239154041745,
        y: 1421.8432592707368,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 3.956043956044141,
        height: 15.934065934065984,
        seed: 1199208107,
        groupIds: ["Wq-IkBRzOPBc2fVcQ7Dv6"],
        frameId: null,
        roundness: { type: 1 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
    ],
    id: "M5o9fjd7AbEbgNp6mMbr6",
    created: 1700290555132,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 48,
        versionNonce: 377985195,
        isDeleted: false,
        id: "r-cbRB3gGK89XGbEqaoCW",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13211.536782601452,
        y: 2090.9823065140135,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 240,
        height: 10.303030303030027,
        seed: 1103863243,
        groupIds: ["FJb3vNtyHvVhgZFusge_Q"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422152,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 39,
        versionNonce: 702274437,
        isDeleted: false,
        id: "xIa6XQC-8fzBYubpYrT5C",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13411.536782601452,
        y: 2101.2853368170436,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 240,
        height: 9.696969696969973,
        seed: 1103866987,
        groupIds: ["FJb3vNtyHvVhgZFusge_Q"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422152,
        link: null,
        locked: false,
      },
    ],
    id: "8veQWG0QlhRoEG78o9Efk",
    created: 1700290539861,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 16,
        versionNonce: 799291243,
        isDeleted: false,
        id: "LToqd9SiGFQxguoHfTtOH",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12891.76230968672,
        y: 2090.9259206685506,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 240,
        height: 20,
        seed: 452795211,
        groupIds: ["xYjWP7lo-0LfnyCOc5Kg8"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422152,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 10,
        versionNonce: 896370885,
        isDeleted: false,
        id: "7zyOyFl1zSwS-3T6Ov85U",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12911.76230968672,
        y: 2090.9259206685506,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 20,
        seed: 1123121643,
        groupIds: ["xYjWP7lo-0LfnyCOc5Kg8"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422152,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 20],
        ],
      },
      {
        type: "line",
        version: 9,
        versionNonce: 1218449931,
        isDeleted: false,
        id: "zVOmhFf_pZtLoEcp3WbNW",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 13111.76230968672,
        y: 2090.9259206685506,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 20,
        seed: 908133515,
        groupIds: ["xYjWP7lo-0LfnyCOc5Kg8"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422152,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 20],
        ],
      },
      {
        type: "line",
        version: 67,
        versionNonce: 514467877,
        isDeleted: false,
        id: "GTDndKnDz6d2MXJkFC7Nq",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12912.827932736367,
        y: 2100.668011872712,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 197.608695652174,
        height: 1.956521739130494,
        seed: 1011235627,
        groupIds: ["xYjWP7lo-0LfnyCOc5Kg8"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422152,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [197.608695652174, -1.956521739130494],
        ],
      },
    ],
    id: "EKqPUBlAIEHfS9heC2aso",
    created: 1700290536249,
  },
  {
    status: "published",
    elements: [
      {
        type: "line",
        version: 37,
        versionNonce: 1798669867,
        isDeleted: false,
        id: "NkAU0wOusH4-bKqgWd74I",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12730.325612968161,
        y: 2153.491317137502,
        strokeColor: "#495057",
        backgroundColor: "#868e96",
        width: 0.8295367777923275,
        height: 104.72431992031625,
        seed: 620320971,
        groupIds: ["vS3EFZRsgommke2m0dmhy"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422152,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.8295367777923275, -104.72431992031625],
        ],
      },
      {
        type: "line",
        version: 68,
        versionNonce: 829261317,
        isDeleted: false,
        id: "cWS1kO2pto7hCXofQ-6Js",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12729.662742857035,
        y: 2048.7669972171857,
        strokeColor: "#495057",
        backgroundColor: "#868e96",
        width: 2.9926894305833684,
        height: 0.153955114054952,
        seed: 227149675,
        groupIds: ["vS3EFZRsgommke2m0dmhy"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422152,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-2.9926894305833684, 0.153955114054952],
        ],
      },
      {
        type: "line",
        version: 50,
        versionNonce: 177834187,
        isDeleted: false,
        id: "EfOxAld2M0zvkX36rw2GP",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12725.388502314816,
        y: 2153.0951806691883,
        strokeColor: "#495057",
        backgroundColor: "#868e96",
        width: 0.7982232258964359,
        height: 104.32818345200258,
        seed: 155968011,
        groupIds: ["vS3EFZRsgommke2m0dmhy"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422152,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.7982232258964359, -104.32818345200258],
        ],
      },
      {
        type: "line",
        version: 212,
        versionNonce: 741899621,
        isDeleted: false,
        id: "K0HoaPC8fhfxmKhuoBT_-",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12630.955073981217,
        y: 2149.984604815974,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 91.16666666666652,
        height: 98.5,
        seed: 1899587755,
        groupIds: ["vS3EFZRsgommke2m0dmhy"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422152,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [3.3333333333332575, -33.666666666666515],
          [21.166666666666515, -68.16666666666652],
          [57.33333333333303, -94.33333333333303],
          [91.16666666666652, -98.5],
        ],
      },
    ],
    id: "yzF8YHPx3Xqtg8jD-ama0",
    created: 1700290531847,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 27,
        versionNonce: 600670085,
        isDeleted: false,
        id: "LuSgZQ3T0MIpQ10RDiVQX",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11877.733462401597,
        y: 964.5280369292564,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 200,
        height: 80,
        seed: 1745740907,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 21,
        versionNonce: 1768941387,
        isDeleted: false,
        id: "ZM3Xo27uur2zjejVlGfjv",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11899.384401454949,
        y: 954.7267242169132,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 1.25,
        height: 11.111111111111086,
        seed: 21765899,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1.25, 11.111111111111086],
        ],
      },
      {
        type: "line",
        version: 54,
        versionNonce: 324880101,
        isDeleted: false,
        id: "1aaWSxbWvBr7W3S7giByM",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11899.939957010503,
        y: 955.6989464391354,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 45.2777777777776,
        height: 0,
        seed: 1479148971,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [45.2777777777776, 0],
        ],
      },
      {
        type: "line",
        version: 26,
        versionNonce: 1361757675,
        isDeleted: false,
        id: "aGi77GQdyctyVAeCVXnLR",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11944.24551256606,
        y: 955.2822797724689,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 11.25,
        seed: 1943148619,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 11.25],
        ],
      },
      {
        type: "line",
        version: 18,
        versionNonce: 1077748293,
        isDeleted: false,
        id: "2KQWpBq8kvBBPHJnZ5G_p",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11960.912179232726,
        y: 953.4767242169132,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.6944444444443434,
        height: 12.222222222222172,
        seed: 304010987,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.6944444444443434, 12.222222222222172],
        ],
      },
      {
        type: "line",
        version: 50,
        versionNonce: 1196478603,
        isDeleted: false,
        id: "X7yltoMKLB3xw4nHTFmkq",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11961.884401454949,
        y: 955.004501994691,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 42.361111111111086,
        height: 0,
        seed: 1369096587,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [42.361111111111086, 0],
        ],
      },
      {
        type: "line",
        version: 14,
        versionNonce: 793416101,
        isDeleted: false,
        id: "c8E5BKKYQLBMfJxmJpA4y",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12005.912179232726,
        y: 954.3100575502467,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 12.083333333333258,
        seed: 461396011,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 12.083333333333258],
        ],
      },
      {
        type: "line",
        version: 20,
        versionNonce: 765232939,
        isDeleted: false,
        id: "549Y-zOYK2r-o-J7bN2Vu",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12019.384401454949,
        y: 954.0322797724689,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.41666666666674246,
        height: 11.80555555555543,
        seed: 1304042187,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.41666666666674246, 11.80555555555543],
        ],
      },
      {
        type: "line",
        version: 38,
        versionNonce: 1840452869,
        isDeleted: false,
        id: "hrZ-nLEEQ9smokcn0sElV",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12019.10662367717,
        y: 955.9767242169132,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 39.44444444444457,
        height: 0,
        seed: 618826091,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [39.44444444444457, 0],
        ],
      },
      {
        type: "line",
        version: 28,
        versionNonce: 1249405387,
        isDeleted: false,
        id: "hdGOIz7YLGLr9Mqdn-ddx",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12057.578845899392,
        y: 953.3378353280245,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 11.388888888888914,
        seed: 1715741707,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 11.388888888888914],
        ],
      },
      {
        type: "line",
        version: 28,
        versionNonce: 116665445,
        isDeleted: false,
        id: "eF6vYPHS5rWab1NN0nRcP",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11895.21773478828,
        y: 1045.4211686613576,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.5555555555556566,
        height: 11.25,
        seed: 1238985387,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.5555555555556566, 11.25],
        ],
      },
      {
        type: "line",
        version: 51,
        versionNonce: 855570539,
        isDeleted: false,
        id: "WABEPk6i4_BhMblV2EHeX",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11892.71773478828,
        y: 1055.4211686613576,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 47.36111111111131,
        height: 0,
        seed: 609017163,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [47.36111111111131, 0],
        ],
      },
      {
        type: "line",
        version: 29,
        versionNonce: 987453381,
        isDeleted: false,
        id: "riYIi9YSPlElBTg47dCKB",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11937.71773478828,
        y: 1044.5878353280245,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 13.194444444444343,
        seed: 1358494699,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 13.194444444444343],
        ],
      },
      {
        type: "line",
        version: 16,
        versionNonce: 1962586891,
        isDeleted: false,
        id: "Soe4qSBrdRO2vcLn_Bett",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11959.939957010503,
        y: 1045.4211686613576,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.4166666666665151,
        height: 12.638888888888914,
        seed: 1541949067,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.4166666666665151, 12.638888888888914],
        ],
      },
      {
        type: "line",
        version: 37,
        versionNonce: 1938167589,
        isDeleted: false,
        id: "a7S9doWBLi4KbvFmgcFPp",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11958.134401454949,
        y: 1055.8378353280245,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 48.19444444444457,
        height: 0,
        seed: 1809136939,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [48.19444444444457, 0],
        ],
      },
      {
        type: "line",
        version: 21,
        versionNonce: 15079851,
        isDeleted: false,
        id: "zR9WPfo8uALxZggVtYZVm",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12007.71773478828,
        y: 1044.1711686613576,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 14.722222222222172,
        seed: 1814716363,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 14.722222222222172],
        ],
      },
      {
        type: "line",
        version: 22,
        versionNonce: 2070014597,
        isDeleted: false,
        id: "jOdo7viLCNnkz9_awk7Ga",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12024.384401454949,
        y: 1044.865613105802,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 13.611111111111313,
        seed: 1996631659,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 13.611111111111313],
        ],
      },
      {
        type: "line",
        version: 42,
        versionNonce: 1407953995,
        isDeleted: false,
        id: "PvcABFOQBWTEJbcmeaHDy",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12022.301068121615,
        y: 1057.9211686613576,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 45.138888888888914,
        height: 0,
        seed: 2133272843,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [45.138888888888914, 0],
        ],
      },
      {
        type: "line",
        version: 16,
        versionNonce: 1932284389,
        isDeleted: false,
        id: "q_NZZ7RbazRclKQqwqV42",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12065.35662367717,
        y: 1044.5878353280245,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 13.333333333333258,
        seed: 974376875,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 13.333333333333258],
        ],
      },
      {
        type: "line",
        version: 27,
        versionNonce: 1642457835,
        isDeleted: false,
        id: "62ulJp2FTW6DCSkMnF42f",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12079.10662367717,
        y: 981.1156131058019,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 15.833333333333485,
        height: 0,
        seed: 1240638027,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [15.833333333333485, 0],
        ],
      },
      {
        type: "line",
        version: 44,
        versionNonce: 133352773,
        isDeleted: false,
        id: "CB6q8vzcGZQQvHfYX-UU7",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12094.801068121615,
        y: 979.1711686613576,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 42.083333333333485,
        seed: 664674539,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 42.083333333333485],
        ],
      },
      {
        type: "line",
        version: 31,
        versionNonce: 1063212427,
        isDeleted: false,
        id: "7zCstgbdhAXjkkd6s4e3W",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12097.439957010503,
        y: 1023.4767242169132,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 19.861111111111086,
        height: 0,
        seed: 1285606283,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-19.861111111111086, 0],
        ],
      },
      {
        type: "line",
        version: 29,
        versionNonce: 1762843813,
        isDeleted: false,
        id: "3zyyapmsgGsBXD7VZ9bE1",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11876.189957010503,
        y: 978.3378353280245,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 18.333333333333485,
        height: 0,
        seed: 490021419,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-18.333333333333485, 0],
        ],
      },
      {
        type: "line",
        version: 39,
        versionNonce: 194145323,
        isDeleted: false,
        id: "VefKdOOLXQyzq-0jO2-DC",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11860.773290343837,
        y: 979.0322797724689,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 42.7777777777776,
        seed: 306554059,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 42.7777777777776],
        ],
      },
      {
        type: "line",
        version: 25,
        versionNonce: 2117730309,
        isDeleted: false,
        id: "v6d3_GGTTfp1s9nNT6URL",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 11860.49551256606,
        y: 1022.2267242169132,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 16.805555555555657,
        height: 0,
        seed: 594334571,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [16.805555555555657, 0],
        ],
      },
      {
        type: "line",
        version: 38,
        versionNonce: 443133643,
        isDeleted: false,
        id: "hctdwvf0oSwp6eRcccqUf",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 11915.380087876554,
        y: 968.9999800308665,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 36.51162790697663,
        height: 27.67441860465101,
        seed: 1895116299,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-36.51162790697663, 27.67441860465101],
        ],
      },
      {
        type: "line",
        version: 55,
        versionNonce: 1566947173,
        isDeleted: false,
        id: "ei2_7_JbFVClIM6k2PfdG",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 12075.728925085856,
        y: 992.255793984355,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 56.97674418604652,
        height: 49.76744186046517,
        seed: 769209515,
        groupIds: ["IvCjSnxbhKvLAEiYU-umM"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-56.97674418604652, 49.76744186046517],
        ],
      },
    ],
    id: "EWcxdJCCvVKq7Tf71hcB-",
    created: 1700290520211,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 23,
        versionNonce: 1138667115,
        isDeleted: false,
        id: "BY7SP_60lue5MhM8t_Lay",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11228.353697961504,
        y: 963.4810658281804,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 160,
        height: 80,
        seed: 795068747,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 20,
        versionNonce: 1903821253,
        isDeleted: false,
        id: "8PmbgK1ndL2Kr96ME_26S",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11227.504637014854,
        y: 976.8741975602816,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 11.666666666666515,
        height: 0,
        seed: 844261355,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-11.666666666666515, 0],
        ],
      },
      {
        type: "line",
        version: 36,
        versionNonce: 2087954699,
        isDeleted: false,
        id: "19Jpp_YyNej-jq8650wHf",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11215.837970348188,
        y: 976.8741975602816,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 49.30555555555566,
        seed: 657732235,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 49.30555555555566],
        ],
      },
      {
        type: "line",
        version: 24,
        versionNonce: 1958707493,
        isDeleted: false,
        id: "LSGDOsQR2FYt2wb_P8naa",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11215.004637014854,
        y: 1025.4853086713927,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 14.027777777777828,
        height: 0,
        seed: 1053010219,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [14.027777777777828, 0],
        ],
      },
      {
        type: "line",
        version: 16,
        versionNonce: 1506824107,
        isDeleted: false,
        id: "j2ZuB96IUX89TVxXENlwk",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11251.115748125965,
        y: 954.9297531158372,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 11.388888888888687,
        seed: 778783691,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 11.388888888888687],
        ],
      },
      {
        type: "line",
        version: 58,
        versionNonce: 635311237,
        isDeleted: false,
        id: "VhgEAPryA31bDuw1RQvS-",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11250.143525903743,
        y: 953.5408642269481,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 51.94444444444457,
        height: 0,
        seed: 440769131,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [51.94444444444457, 0],
        ],
      },
      {
        type: "line",
        version: 18,
        versionNonce: 1042227787,
        isDeleted: false,
        id: "vxVn6a-_ondlQgA7wwm_y",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11302.365748125965,
        y: 952.1519753380594,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 11.944444444444343,
        seed: 1058870539,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 11.944444444444343],
        ],
      },
      {
        type: "line",
        version: 20,
        versionNonce: 278084581,
        isDeleted: false,
        id: "8yLyMa_6G4bdRvowj42RV",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11326.532414792633,
        y: 952.1519753380594,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 12.777777777777828,
        seed: 1550597035,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 12.777777777777828],
        ],
      },
      {
        type: "line",
        version: 39,
        versionNonce: 1648009451,
        isDeleted: false,
        id: "lLWvxbew1lKage4GCHegv",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11326.9490814593,
        y: 951.3186420047259,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 43.888888888888914,
        height: 0,
        seed: 1583189579,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [43.888888888888914, 0],
        ],
      },
      {
        type: "line",
        version: 25,
        versionNonce: 1827436357,
        isDeleted: false,
        id: "VdbgogvblvNQS3dXaewfG",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11369.726859237076,
        y: 949.9297531158372,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 14.722222222222172,
        seed: 1204522219,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 14.722222222222172],
        ],
      },
      {
        type: "line",
        version: 15,
        versionNonce: 2142063499,
        isDeleted: false,
        id: "o-2sdK1icpc_BmcKY3C1t",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11390.837970348188,
        y: 977.1519753380594,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 12.222222222222172,
        height: 0,
        seed: 1547745163,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [12.222222222222172, 0],
        ],
      },
      {
        type: "line",
        version: 80,
        versionNonce: 356992677,
        isDeleted: false,
        id: "1unwGOT-St-ehH4qJ4Qbv",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11400.6990814593,
        y: 977.5686420047259,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 1.5277777777778283,
        height: 48.88888888888869,
        seed: 167218731,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1.5277777777778283, -2.638888888888687],
          [0.8333333333332575, 46.25],
        ],
      },
      {
        type: "line",
        version: 23,
        versionNonce: 984719915,
        isDeleted: false,
        id: "CMEs9HV9So4QLfA63xR6f",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11404.4490814593,
        y: 1023.5408642269481,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 15.69444444444457,
        height: 0,
        seed: 333091019,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-15.69444444444457, 0],
        ],
      },
      {
        type: "line",
        version: 23,
        versionNonce: 1011952133,
        isDeleted: false,
        id: "cOVWlFOPphhTEYM_dmPDo",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11254.4490814593,
        y: 1042.568642004726,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 2.361111111111086,
        height: 9,
        seed: 2072264555,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [2.361111111111086, 9],
        ],
      },
      {
        type: "line",
        version: 62,
        versionNonce: 1278354635,
        isDeleted: false,
        id: "aH6NJ04hkHoZKEHIld3s9",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11251.81019257041,
        y: 1053.957530893615,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 52.5,
        height: 0,
        seed: 725555723,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [52.5, 0],
        ],
      },
      {
        type: "line",
        version: 19,
        versionNonce: 1661444453,
        isDeleted: false,
        id: "MvtNtm9OtbZhm1Jab1y3L",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11304.726859237076,
        y: 1043.957530893615,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 14.305555555555202,
        seed: 1610981547,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 14.305555555555202],
        ],
      },
      {
        type: "line",
        version: 17,
        versionNonce: 1808356203,
        isDeleted: false,
        id: "QyPCPezNbZujWdDBZoXrB",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11329.171303681522,
        y: 1044.0964197825037,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 13.055555555555657,
        seed: 1401049931,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 13.055555555555657],
        ],
      },
      {
        type: "line",
        version: 34,
        versionNonce: 246951109,
        isDeleted: false,
        id: "Qbb5Z-7ftrlZxcdLxkaOM",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11324.171303681522,
        y: 1057.9853086713924,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 54.861111111111086,
        height: 0,
        seed: 638562795,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [54.861111111111086, 0],
        ],
      },
      {
        type: "line",
        version: 19,
        versionNonce: 2042736139,
        isDeleted: false,
        id: "JGpYA9ApxXqoLkwNSaFRN",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11374.171303681522,
        y: 1044.9297531158372,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 15.972222222222172,
        seed: 146291851,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 15.972222222222172],
        ],
      },
      {
        type: "line",
        version: 40,
        versionNonce: 1056984101,
        isDeleted: false,
        id: "eE_RuVef8n9iWTEePmWlu",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 11263.093346692274,
        y: 963.999520557697,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 30.697674418604606,
        height: 28.372093023255957,
        seed: 657879851,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-30.697674418604606, 28.372093023255957],
        ],
      },
      {
        type: "line",
        version: 36,
        versionNonce: 1763030187,
        isDeleted: false,
        id: "Q37o_wuEf1isZllHeCv0M",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 11385.651486227158,
        y: 998.3018461390925,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 38.25581395348843,
        height: 42.441860465116406,
        seed: 1375000011,
        groupIds: ["5fEfFObYxVFT9_-8PaEp-"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-38.25581395348843, 42.441860465116406],
        ],
      },
    ],
    id: "jf90x6S2oSkQH4Bm8d6l7",
    created: 1700290479476,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 19,
        versionNonce: 1478547141,
        isDeleted: false,
        id: "s6lma_RcEkD7ZvlhzChKX",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11013.330019922212,
        y: 965.061931792829,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 120,
        height: 80,
        seed: 1304501739,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 14,
        versionNonce: 1477485579,
        isDeleted: false,
        id: "eGyICqYSglznMjetjOmjy",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11047.480958975562,
        y: 954.5661746360411,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 10.833333333333258,
        seed: 257697931,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 10.833333333333258],
        ],
      },
      {
        type: "line",
        version: 16,
        versionNonce: 1774433829,
        isDeleted: false,
        id: "PkodAuFXC49s2KpX0sV4b",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11096.786514531119,
        y: 955.5383968582632,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.41666666666674246,
        height: 11.111111111111086,
        seed: 175955755,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.41666666666674246, 11.111111111111086],
        ],
      },
      {
        type: "line",
        version: 34,
        versionNonce: 1310059179,
        isDeleted: false,
        id: "2HBPVH9xOHBy1h7YvY8OT",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11046.230958975562,
        y: 953.732841302708,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 51.388888888888914,
        height: 0.13888888888914153,
        seed: 1502462411,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [51.388888888888914, -0.13888888888914153],
        ],
      },
      {
        type: "line",
        version: 33,
        versionNonce: 915646853,
        isDeleted: false,
        id: "R_W-M765DVzfcWAY2JVXl",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11044.14762564223,
        y: 1044.566174636041,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.5555555555556566,
        height: 11.944444444444798,
        seed: 1705594987,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.5555555555556566, 11.944444444444798],
        ],
      },
      {
        type: "line",
        version: 55,
        versionNonce: 1828281675,
        isDeleted: false,
        id: "MckTl7LxQ1fDMw1bkY2TE",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11041.092070086674,
        y: 1056.093952413819,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 54.16666666666674,
        height: 1.3888888888891415,
        seed: 278530827,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [54.16666666666674, 1.3888888888891415],
        ],
      },
      {
        type: "line",
        version: 20,
        versionNonce: 2144219365,
        isDeleted: false,
        id: "Ra4e7VH0XcQe5cAwjvfHR",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11094.842070086674,
        y: 1045.2606190804859,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.13888888888868678,
        height: 13.611111111110858,
        seed: 517632427,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.13888888888868678, 13.611111111110858],
        ],
      },
      {
        type: "line",
        version: 42,
        versionNonce: 1343585259,
        isDeleted: false,
        id: "IGSb4JFKQi3DPJTyy8mHx",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11133.592070086674,
        y: 979.4272857471524,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 14.027777777777828,
        height: 1.1111111111110858,
        seed: 1637155915,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [14.027777777777828, -1.1111111111110858],
        ],
      },
      {
        type: "line",
        version: 43,
        versionNonce: 50383941,
        isDeleted: false,
        id: "T9vtIxHEIBXfCID8bGiRE",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11147.480958975562,
        y: 978.3161746360411,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 46.25,
        seed: 547635947,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 46.25],
        ],
      },
      {
        type: "line",
        version: 22,
        versionNonce: 1131726475,
        isDeleted: false,
        id: "gCk3BLvR0n93diJB1hY6J",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11134.425403420008,
        y: 1026.9272857471524,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 13.333333333333258,
        height: 0,
        seed: 1150463371,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [13.333333333333258, 0],
        ],
      },
      {
        type: "line",
        version: 29,
        versionNonce: 1879636901,
        isDeleted: false,
        id: "Ggmf2ATlG-K4znvnC2Pzz",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11014.14762564223,
        y: 980.8161746360411,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 14.027777777777828,
        height: 0,
        seed: 1083884587,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-14.027777777777828, 0],
        ],
      },
      {
        type: "line",
        version: 33,
        versionNonce: 768720171,
        isDeleted: false,
        id: "hl76yiksb9ZsfQtvzyVE2",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11003.036514531119,
        y: 979.5661746360411,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 42.08333333333326,
        seed: 1017579211,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 42.08333333333326],
        ],
      },
      {
        type: "line",
        version: 27,
        versionNonce: 521420549,
        isDeleted: false,
        id: "D3tH_opBI4T95XxGPnD-_",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11000.675403420008,
        y: 1021.0939524138189,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 13.611111111111086,
        height: 0,
        seed: 2136077675,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [13.611111111111086, 0],
        ],
      },
      {
        type: "line",
        version: 36,
        versionNonce: 982966219,
        isDeleted: false,
        id: "wwnmAC7-SLwVSn0TvpQDw",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 11044.930133769261,
        y: 968.4873632665317,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 30.23255813953483,
        height: 24.186046511628092,
        seed: 400953355,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-30.23255813953483, 24.186046511628092],
        ],
      },
      {
        type: "line",
        version: 32,
        versionNonce: 111154789,
        isDeleted: false,
        id: "btNN7AvIL4vHSB6ZfjJVU",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 80,
        angle: 0,
        x: 11132.023157025076,
        y: 1015.8129446618805,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 31.62790697674427,
        height: 25.000000000000227,
        seed: 1214109355,
        groupIds: ["XWgIbfbJNd-Ioaa5fDjst"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422150,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-31.62790697674427, 25.000000000000227],
        ],
      },
    ],
    id: "31Qh2DJ-cWcCvJuQMktTb",
    created: 1700290476194,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 55,
        versionNonce: 621793317,
        isDeleted: false,
        id: "5f-Ea5wxq27Vl1ct6-eZU",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11024.033035847726,
        y: 1409.9305317656044,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 100,
        height: 64.85294117647027,
        seed: 1375726379,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 95,
        versionNonce: 1353740459,
        isDeleted: false,
        id: "Q7JXH71qekKRXVMj9xZnN",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11025.198295349528,
        y: 1416.9153670702221,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 97.94117647058829,
        height: 0,
        seed: 1422872011,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [97.94117647058829, 0],
        ],
      },
      {
        type: "rectangle",
        version: 62,
        versionNonce: 625771397,
        isDeleted: false,
        id: "vok4UPw9BxzPBP6M-Mo4O",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11059.610060055411,
        y: 1421.1800729525748,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 28.088235294117567,
        height: 48.23529411764707,
        seed: 416898155,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 56,
        versionNonce: 106111819,
        isDeleted: false,
        id: "hHd1H0R4pPP-SiEvx7A8n",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11033.874765937764,
        y: 1426.0330141290456,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 18.088235294117567,
        height: 16.911764705882433,
        seed: 2045286155,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 44,
        versionNonce: 375114469,
        isDeleted: false,
        id: "fXLSfqLjc8ztdrxRZz_iJ",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11032.25711887894,
        y: 1450.2977200113983,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 19.705882352941217,
        height: 18.382352941176578,
        seed: 1987542443,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 49,
        versionNonce: 1715896811,
        isDeleted: false,
        id: "zQn7Sn2LKDyzSCbDtHx1f",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11096.668883584822,
        y: 1425.150661187869,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 19.411764705882433,
        height: 16.764705882352928,
        seed: 1504743499,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 32,
        versionNonce: 1758144069,
        isDeleted: false,
        id: "5Y3M3dC3Rqw8CBST0uCXj",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11095.786530643647,
        y: 1451.0330141290456,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 19.852941176470722,
        height: 18.235294117647072,
        seed: 627986155,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 49,
        versionNonce: 966069387,
        isDeleted: false,
        id: "POzR7TXkZG06jkz4ET9M8",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11042.074765937763,
        y: 1422.6800729525748,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.09999999999990905,
        height: 7.5,
        seed: 1010514315,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.09999999999990905, 7.5],
        ],
      },
      {
        type: "line",
        version: 26,
        versionNonce: 527963557,
        isDeleted: false,
        id: "6qyjV3vsvnGtKECMFG_pH",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11042.974765937764,
        y: 1439.8800729525747,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.10000000000013642,
        height: 5.5,
        seed: 1263652907,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.10000000000013642, 5.5],
        ],
      },
      {
        type: "line",
        version: 36,
        versionNonce: 1140409131,
        isDeleted: false,
        id: "ypMfY4jXgv6X01CGn8v_C",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11040.674765937763,
        y: 1448.480072952575,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 1,
        height: 5.2999999999999545,
        seed: 1956418251,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1, 5.2999999999999545],
        ],
      },
      {
        type: "line",
        version: 24,
        versionNonce: 93329669,
        isDeleted: false,
        id: "z7IQgfcs-LsHcpkhWnBO2",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11041.674765937763,
        y: 1466.080072952575,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.2999999999999545,
        height: 5.899999999999864,
        seed: 951452011,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.2999999999999545, 5.899999999999864],
        ],
      },
      {
        type: "line",
        version: 29,
        versionNonce: 1843242443,
        isDeleted: false,
        id: "tYEhi4xH3cBfzCRyXOgVr",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11036.474765937764,
        y: 1459.6800729525748,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 6.7999999999999545,
        height: 0.40000000000009095,
        seed: 552115211,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-6.7999999999999545, -0.40000000000009095],
        ],
      },
      {
        type: "line",
        version: 31,
        versionNonce: 613462117,
        isDeleted: false,
        id: "WzgIZRGB6sduFJvLaIGPW",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11049.274765937764,
        y: 1460.8800729525747,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 6.800000000000182,
        height: 0.29999999999972715,
        seed: 690938539,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [6.800000000000182, -0.29999999999972715],
        ],
      },
      {
        type: "line",
        version: 31,
        versionNonce: 777827435,
        isDeleted: false,
        id: "Ri5J7ztdNobicen1WeT7O",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11054.574765937763,
        y: 1434.480072952575,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 6.2000000000000455,
        height: 0,
        seed: 1781749067,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-6.2000000000000455, 0],
        ],
      },
      {
        type: "line",
        version: 30,
        versionNonce: 2051496901,
        isDeleted: false,
        id: "EYDheMPWututzGlqSZ4gJ",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11037.374765937764,
        y: 1434.1800729525748,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 6.5,
        height: 0.40000000000009095,
        seed: 55604203,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-6.5, -0.40000000000009095],
        ],
      },
      {
        type: "line",
        version: 28,
        versionNonce: 893447947,
        isDeleted: false,
        id: "7kpsZvehcASsV1jUNX-nF",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11099.974765937764,
        y: 1435.1800729525748,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 5.2999999999999545,
        height: 0,
        seed: 1150336651,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-5.2999999999999545, 0],
        ],
      },
      {
        type: "line",
        version: 34,
        versionNonce: 1859230501,
        isDeleted: false,
        id: "0fAJCqrcItIwhOYqr6tki",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11107.774765937764,
        y: 1428.580072952575,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.09999999999990905,
        height: 6.7999999999999545,
        seed: 414102827,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.09999999999990905, -6.7999999999999545],
        ],
      },
      {
        type: "line",
        version: 32,
        versionNonce: 1426380203,
        isDeleted: false,
        id: "IJsQs3wp1HsA4HV5Xo8Gu",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11112.574765937763,
        y: 1434.980072952575,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 5.5,
        height: 0.2999999999999545,
        seed: 789895115,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [5.5, 0.2999999999999545],
        ],
      },
      {
        type: "line",
        version: 26,
        versionNonce: 178056837,
        isDeleted: false,
        id: "zfWsghUsCQURjq6UsZUac",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11105.974765937764,
        y: 1448.2800729525748,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 7,
        seed: 1709024875,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 7],
        ],
      },
      {
        type: "line",
        version: 25,
        versionNonce: 752019531,
        isDeleted: false,
        id: "byuWBctYBO-AwRnKjkyw0",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11108.174765937763,
        y: 1438.980072952575,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.5,
        height: 6.5,
        seed: 1943249163,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.5, 6.5],
        ],
      },
      {
        type: "line",
        version: 32,
        versionNonce: 1818678757,
        isDeleted: false,
        id: "gOPWojk1sX--7Nsdo1XB6",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11111.774765937764,
        y: 1459.6800729525748,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 6.7000000000000455,
        height: 0.900000000000091,
        seed: 1383155627,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [6.7000000000000455, -0.900000000000091],
        ],
      },
      {
        type: "line",
        version: 27,
        versionNonce: 310748907,
        isDeleted: false,
        id: "w3ZFxGtktXgHYFr1GIWS5",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11098.874765937764,
        y: 1459.980072952575,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 6.2000000000000455,
        height: 1,
        seed: 1742805579,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-6.2000000000000455, -1],
        ],
      },
      {
        type: "line",
        version: 23,
        versionNonce: 2134692165,
        isDeleted: false,
        id: "DBUIuB1EGI0PDhgwpNLYW",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 80,
        angle: 0,
        x: 11106.174765937763,
        y: 1465.2800729525748,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0.7000000000000455,
        height: 6.800000000000182,
        seed: 1895667947,
        groupIds: ["ihqRT9nQ9EoPYOhQmLD5a"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.7000000000000455, 6.800000000000182],
        ],
      },
    ],
    id: "2KvJlE1TDuw6ArALRyP6s",
    created: 1700290465059,
  },
  {
    status: "published",
    elements: [
      {
        type: "ellipse",
        version: 13,
        versionNonce: 796500267,
        isDeleted: false,
        id: "Df4uJzhGjHSkFUv4mMKFk",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11259.173917987973,
        y: 2050.6081353855816,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 80,
        height: 80,
        seed: 1750453963,
        groupIds: ["X4T8pu2ZF2Awi0d5ywA7F"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 13,
        versionNonce: 1464638213,
        isDeleted: false,
        id: "o8RqS3iyubrLiN4ySIU2V",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11299.173917987973,
        y: 2050.6081353855816,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 40,
        height: 40,
        seed: 1508359531,
        groupIds: ["X4T8pu2ZF2Awi0d5ywA7F"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-40, 40],
        ],
      },
      {
        type: "line",
        version: 11,
        versionNonce: 94520267,
        isDeleted: false,
        id: "t1AdOzyJOvXcNtuRLqWEr",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11299.173917987973,
        y: 2050.6081353855816,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 40,
        height: 40,
        seed: 746772491,
        groupIds: ["X4T8pu2ZF2Awi0d5ywA7F"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [40, 40],
        ],
      },
      {
        type: "line",
        version: 13,
        versionNonce: 1332540005,
        isDeleted: false,
        id: "c3j9CA2bd4JYW2_R1t2g2",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11299.173917987973,
        y: 2050.6081353855816,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 100,
        seed: 1042390699,
        groupIds: ["X4T8pu2ZF2Awi0d5ywA7F"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 100],
        ],
      },
      {
        type: "line",
        version: 139,
        versionNonce: 351232453,
        isDeleted: false,
        id: "C3LQ5TAsuK2isXCflfzlS",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11261.311528435346,
        y: 2091.3293416570577,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 77.86238955262706,
        height: 0.7212062714761487,
        seed: 1231982923,
        groupIds: ["X4T8pu2ZF2Awi0d5ywA7F"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290427444,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [77.86238955262706, -0.7212062714761487],
        ],
      },
      {
        type: "line",
        version: 9,
        versionNonce: 1145789893,
        isDeleted: false,
        id: "VTn7JIbyw-NyakN8haHh_",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11339.173917987973,
        y: 2130.6081353855816,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 20,
        seed: 1338257387,
        groupIds: ["X4T8pu2ZF2Awi0d5ywA7F"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422151,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 20],
        ],
      },
      {
        type: "line",
        version: 10,
        versionNonce: 2130054411,
        isDeleted: false,
        id: "PdbdpSLE-ulp8-y7scTA_",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11339.173917987973,
        y: 2130.6081353855816,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 20,
        height: 20,
        seed: 1127021195,
        groupIds: ["X4T8pu2ZF2Awi0d5ywA7F"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422152,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [20, 20],
        ],
      },
      {
        type: "line",
        version: 10,
        versionNonce: 486890789,
        isDeleted: false,
        id: "YFHBd8yawCCdnJTvE9Qrh",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11359.173917987973,
        y: 2130.6081353855816,
        strokeColor: "#495057",
        backgroundColor: "transparent",
        width: 0,
        height: 20,
        seed: 1885565227,
        groupIds: ["X4T8pu2ZF2Awi0d5ywA7F"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700290422152,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 20],
        ],
      },
    ],
    id: "E4WnE1JNM6PClloslBvC-",
    created: 1700290432412,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 83,
        versionNonce: 333587045,
        isDeleted: false,
        id: "v2puXSu6fnzCrZWZ5Ox4b",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11454.876483744454,
        y: 2104.257211663892,
        strokeColor: "#495057",
        backgroundColor: "#868e96",
        width: 120,
        height: 10,
        seed: 684856267,
        groupIds: ["3fezrBcfjqF-U0KsyC64s"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290160234,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 58,
        versionNonce: 580554181,
        isDeleted: false,
        id: "8vZY8CUcNpHJKYDJ7i0FN",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11574.876483744454,
        y: 2114.257211663892,
        strokeColor: "#495057",
        backgroundColor: "#868e96",
        width: 200,
        height: 10.48387096774195,
        seed: 747856491,
        groupIds: ["3fezrBcfjqF-U0KsyC64s"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290160234,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 67,
        versionNonce: 1240370469,
        isDeleted: false,
        id: "8Q17F0GIsMTF3zbZbSOMx",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11774.876483744454,
        y: 2103.9346310187307,
        strokeColor: "#495057",
        backgroundColor: "#868e96",
        width: 200,
        height: 10.322580645161452,
        seed: 1416748299,
        groupIds: ["3fezrBcfjqF-U0KsyC64s"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290160234,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 106,
        versionNonce: 424386693,
        isDeleted: false,
        id: "Pr3d12-fEEtXuCd5b3t3X",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11974.876483744454,
        y: 2114.257211663892,
        strokeColor: "#495057",
        backgroundColor: "#868e96",
        width: 520,
        height: 10,
        seed: 2078802859,
        groupIds: ["3fezrBcfjqF-U0KsyC64s"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290160234,
        link: null,
        locked: false,
      },
      {
        type: "text",
        version: 53,
        versionNonce: 1450133477,
        isDeleted: false,
        id: "MbRzbPBl6RwZVeE4s_Vv3",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11572.978966264247,
        y: 2078.670642661156,
        strokeColor: "#495057",
        backgroundColor: "#868e96",
        width: 4,
        height: 25,
        seed: 723539531,
        groupIds: ["3fezrBcfjqF-U0KsyC64s"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290160234,
        link: null,
        locked: false,
        fontSize: 20,
        fontFamily: 1,
        text: "1",
        textAlign: "left",
        verticalAlign: "top",
        containerId: null,
        originalText: "1",
        lineHeight: 1.25,
        baseline: 18,
      },
      {
        type: "text",
        version: 37,
        versionNonce: 862389061,
        isDeleted: false,
        id: "zAdtMBWK8Dg2odQe0RTPp",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11765.977019476448,
        y: 2078.222881467126,
        strokeColor: "#495057",
        backgroundColor: "#868e96",
        width: 14,
        height: 25,
        seed: 16078059,
        groupIds: ["3fezrBcfjqF-U0KsyC64s"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290160234,
        link: null,
        locked: false,
        fontSize: 20,
        fontFamily: 1,
        text: "3",
        textAlign: "left",
        verticalAlign: "top",
        containerId: null,
        originalText: "3",
        lineHeight: 1.25,
        baseline: 18,
      },
      {
        type: "text",
        version: 42,
        versionNonce: 1150309029,
        isDeleted: false,
        id: "8Xesib6OpWNPA21XUtzvt",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 11967.852425057239,
        y: 2077.2235303963926,
        strokeColor: "#495057",
        backgroundColor: "#868e96",
        width: 14,
        height: 25,
        seed: 1559330699,
        groupIds: ["3fezrBcfjqF-U0KsyC64s"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290160234,
        link: null,
        locked: false,
        fontSize: 20,
        fontFamily: 1,
        text: "5",
        textAlign: "left",
        verticalAlign: "top",
        containerId: null,
        originalText: "5",
        lineHeight: 1.25,
        baseline: 18,
      },
      {
        type: "text",
        version: 64,
        versionNonce: 915977733,
        isDeleted: false,
        id: "DkIuhpLOX5LYEDRl9OTB4",
        fillStyle: "hachure",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 12481.205173510098,
        y: 2079.7625848589682,
        strokeColor: "#495057",
        backgroundColor: "#868e96",
        width: 19,
        height: 25,
        seed: 1229936171,
        groupIds: ["3fezrBcfjqF-U0KsyC64s"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700290160234,
        link: null,
        locked: false,
        fontSize: 20,
        fontFamily: 1,
        text: "10",
        textAlign: "left",
        verticalAlign: "top",
        containerId: null,
        originalText: "10",
        lineHeight: 1.25,
        baseline: 18,
      },
    ],
    id: "KMo5uiEKNjHmNK-0GFGHv",
    created: 1700290382933,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 170,
        versionNonce: 580076946,
        isDeleted: false,
        id: "U95fxukcOOmYOwxpWKme8",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 13179.524876339137,
        y: 3084.976204990306,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 324.3289656608631,
        height: 285.5629968237172,
        seed: 1726089170,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 186,
        versionNonce: 2002457742,
        isDeleted: false,
        id: "K6gjxeplwIvVK-jbvuwHA",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 13179.87044892485,
        y: 3232.31380511327,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 327.16993612135775,
        height: 0,
        seed: 1633753298,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [327.16993612135775, 0],
        ],
      },
      {
        type: "line",
        version: 304,
        versionNonce: 251183954,
        isDeleted: false,
        id: "B392LsMVD2V1BuTTvluWY",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 13361.958100745785,
        y: 3086.311206453004,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 0,
        height: 281.60891889984623,
        seed: 835096334,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 281.60891889984623],
        ],
      },
      {
        type: "line",
        version: 137,
        versionNonce: 207966926,
        isDeleted: false,
        id: "BcM1e8mmRLQv98PE_y5CL",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 13269.730234028482,
        y: 3085.5727724014305,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 0,
        height: 145.94803456778482,
        seed: 142523538,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 145.94803456778482],
        ],
      },
      {
        type: "line",
        version: 165,
        versionNonce: 1432914194,
        isDeleted: false,
        id: "8muOduDfH24FjVqQgd1sX",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 13363.009368927584,
        y: 3160.3328543125294,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 140.46979968270716,
        height: 0,
        seed: 2039075086,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [140.46979968270716, 0],
        ],
      },
      {
        type: "line",
        version: 140,
        versionNonce: 2047723790,
        isDeleted: false,
        id: "uRKK2wc3eyWY93VasVwyt",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 13180.947180349805,
        y: 3299.7040969332884,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 182.7024072628883,
        height: 0,
        seed: 154028050,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [182.7024072628883, 0],
        ],
      },
      {
        type: "line",
        version: 249,
        versionNonce: 1297716946,
        isDeleted: false,
        id: "fH8TdUrUssLRrMV7H0_6J",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 100,
        angle: 0,
        x: 13243.113869716322,
        y: 3144.8402896541324,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 0,
        height: 32.89487255362337,
        seed: 1103447822,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 32.89487255362337],
        ],
      },
      {
        type: "line",
        version: 337,
        versionNonce: 2144171854,
        isDeleted: false,
        id: "HbGuPbyU2zNFb16dxnJKI",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 100,
        angle: 0,
        x: 13337.01305353808,
        y: 3143.567018286165,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 0,
        height: 32.89487255362337,
        seed: 1248181394,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 32.89487255362337],
        ],
      },
      {
        type: "line",
        version: 419,
        versionNonce: 1197665426,
        isDeleted: false,
        id: "juDZqa7ZwM2euGvLAyv92",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 100,
        angle: 0,
        x: 13381.728356176447,
        y: 3111.066026152959,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 0,
        height: 32.89487255362337,
        seed: 2033818898,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 32.89487255362337],
        ],
      },
      {
        type: "line",
        version: 514,
        versionNonce: 100490638,
        isDeleted: false,
        id: "z2FFTRLuB3XgiHbjxyH_H",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 100,
        angle: 0,
        x: 13382.46899175265,
        y: 3183.596270749829,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 0,
        height: 32.89487255362337,
        seed: 316294354,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 32.89487255362337],
        ],
      },
      {
        type: "line",
        version: 292,
        versionNonce: 1765509714,
        isDeleted: false,
        id: "XjbueNdN0U8mre9UnB7h0",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 100,
        angle: 0,
        x: 13208.478801396668,
        y: 3297.372333352552,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 31.353953084058958,
        height: 21.689515908868998,
        seed: 180252306,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-8.545294045179617, -21.689515908868998],
          [22.80865903887934, -21.242511218456457],
          [17.074274050561144, -0.5204178694943948],
        ],
      },
      {
        type: "line",
        version: 176,
        versionNonce: 30833614,
        isDeleted: false,
        id: "VHToZ-JQC9q1Ch7Yin23a",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 100,
        angle: 0,
        x: 13205.524328789601,
        y: 3273.159035487232,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 32.49593586978153,
        height: 18.34513775067535,
        seed: 218460818,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-6.470148183119818, -17.635477019546215],
          [26.025787686661715, -16.568538816883574],
          [20.15436476405921, 0.7096607311291336],
        ],
      },
      {
        type: "line",
        version: 418,
        versionNonce: 163213330,
        isDeleted: false,
        id: "MkU4u4kmeGxZwrJx7Kd_x",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 100,
        angle: 0,
        x: 13274.63875838563,
        y: 3257.5464994023973,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 50.283133459432065,
        height: 40.77531106628521,
        seed: 351872658,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [13.074071492566873, 39.7230554994394],
          [49.71866768248947, 40.57954258873269],
          [50.283133459432065, -0.19576847755251947],
          [0, 0],
        ],
      },
      {
        type: "line",
        version: 317,
        versionNonce: 1500631566,
        isDeleted: false,
        id: "Q5qP0_2D__Vw9QBcgGdaE",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 2,
        opacity: 100,
        angle: 0,
        x: 13326.643023043547,
        y: 3262.1862123203987,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 14.594540001560745,
        height: 23.583575929192193,
        seed: 461766286,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [14.594540001560745, -1.6379295955252928],
          [10.178329428767029, 17.159107057501387],
          [1.2480240444001538, 21.9456463336669],
        ],
      },
      {
        type: "line",
        version: 549,
        versionNonce: 215391698,
        isDeleted: false,
        id: "WZkq9_YPZhZ7IOU25xUhM",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 13225.458454016412,
        y: 3369.7691477555504,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 71.74914702309434,
        height: 16.88503118892777,
        seed: 341919058,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-6.908995853633314, 0.9690539638863811],
          [-23.07131507959457, -15.22915614962676],
          [48.67783194349977, -14.868615870133453],
          [41.01023323934896, -0.7569714465371362],
          [-4.455364268305857, 1.6558750393010087],
        ],
      },
      {
        type: "line",
        version: 401,
        versionNonce: 1204314190,
        isDeleted: false,
        id: "KHQT5a951cVLW-PzCug6v",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 13337.762672568546,
        y: 3369.302566217383,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 40.1162238585257,
        height: 35.784846292669044,
        seed: 1844699598,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.20066268949085497, -28.012837733823744],
          [5.439100868008609, -34.416098353779034],
          [-34.67712299051709, -34.416098353779034],
          [-33.59713355601525, 1.3687479388900101],
        ],
      },
      {
        type: "line",
        version: 73,
        versionNonce: 1067914130,
        isDeleted: false,
        id: "yrdKlWoeFc9W8_LnjrH8v",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 13301.824474301793,
        y: 3336.3465744253517,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 10.930406663363101,
        height: 11.101704081222579,
        seed: 966988754,
        groupIds: ["1o5gzd3wF3kHGpAiM0wOk"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700484543087,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-10.930406663363101, 0.6574558037814313],
          [-10.07881378600905, 11.101704081222579],
        ],
      },
    ],
    id: "4cUJ2UG-pqmdJYC7aNa8e",
    created: 1700484544747,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 175,
        versionNonce: 1010709266,
        isDeleted: false,
        id: "yHblp-H38PFnKWtQmuGBD",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12686.320044404702,
        y: 3151.222747053049,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 329.8872279269035,
        height: 16.80210287793716,
        seed: 328515794,
        groupIds: ["jU4tXCInlrCcIFAz-QWdR"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484199757,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 122,
        versionNonce: 1413463822,
        isDeleted: false,
        id: "-I-z72WIzS6HcX0E_3XWI",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12697.145560352903,
        y: 3167.0063202046767,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 17.413220713722694,
        height: 149.1309399625011,
        seed: 772625614,
        groupIds: ["jU4tXCInlrCcIFAz-QWdR"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484199757,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 238,
        versionNonce: 1609171154,
        isDeleted: false,
        id: "I4duQiUxesipQwWbRO28I",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12990.160193384327,
        y: 3168.168535374519,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 17.413220713722694,
        height: 149.1309399625011,
        seed: 1719191374,
        groupIds: ["jU4tXCInlrCcIFAz-QWdR"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484199757,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 148,
        versionNonce: 1817226574,
        isDeleted: false,
        id: "uL4lAsEiBMC-8UlCqNNAU",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12714.118630720612,
        y: 3205.4994686468353,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 277.95312470363024,
        height: 0.6838699590930446,
        seed: 599487118,
        groupIds: ["jU4tXCInlrCcIFAz-QWdR"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700484199757,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [277.95312470363024, -0.6838699590930446],
        ],
      },
      {
        type: "line",
        version: 39,
        versionNonce: 1079383698,
        isDeleted: false,
        id: "4gvQm4HrOqMOeBhTneHff",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12846.982195911489,
        y: 3167.4319201260273,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 0.12004100345802726,
        height: 39.00241330531344,
        seed: 664480402,
        groupIds: ["jU4tXCInlrCcIFAz-QWdR"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700484199757,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.12004100345802726, 39.00241330531344],
        ],
      },
      {
        type: "ellipse",
        version: 54,
        versionNonce: 682218382,
        isDeleted: false,
        id: "T7i1gsXQ08h6htPpyLyeX",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12758.490150726044,
        y: 3179.3887315916645,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 14.597713541710618,
        height: 14.597713541710618,
        seed: 1879885070,
        groupIds: ["jU4tXCInlrCcIFAz-QWdR"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700484199757,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 152,
        versionNonce: 1849259090,
        isDeleted: false,
        id: "YJIsSWeiDgDKHPNxKBIFS",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12914.912672247068,
        y: 3179.441476881063,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 14.597713541710618,
        height: 14.597713541710618,
        seed: 54430926,
        groupIds: ["jU4tXCInlrCcIFAz-QWdR"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700484199757,
        link: null,
        locked: false,
      },
    ],
    id: "N1wP3wpYjFvrhwJSMzzKF",
    created: 1700484201497,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 220,
        versionNonce: 1993848974,
        isDeleted: false,
        id: "5o-uwEu_KbK9e8UDtCsPk",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12218.58766282731,
        y: 3136.5020162856636,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 13.579962878569859,
        height: 322.0450367143362,
        seed: 1062601806,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 788,
        versionNonce: 1309171538,
        isDeleted: false,
        id: "FjAB0XRTgpGVOwaBPLoQE",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12421.044004917438,
        y: 3131.5341854284957,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 13.115808466805902,
        height: 326.4108455378654,
        seed: 2136806926,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 1021,
        versionNonce: 1018001102,
        isDeleted: false,
        id: "1g-_3oqne17xv_fs7CCB8",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 4.71238898038469,
        x: 12318.870291682146,
        y: 3121.4468692520277,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 13.62591876092299,
        height: 186.20404406727556,
        seed: 125386318,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 1164,
        versionNonce: 1210361106,
        isDeleted: false,
        id: "l-6atJV0_WDuXcTl0gwuW",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 4.71238898038469,
        x: 12319.113857858612,
        y: 3222.903670722616,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 13.690256996216684,
        height: 187.2610293613923,
        seed: 289923726,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 1178,
        versionNonce: 900820238,
        isDeleted: false,
        id: "iJoTQGBthW6WKk8nAHp9C",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 4.71238898038469,
        x: 12319.692901976265,
        y: 3329.4202148402624,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 13.690256996216684,
        height: 190.62499994962835,
        seed: 840357842,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 111,
        versionNonce: 1244803794,
        isDeleted: false,
        id: "V2iKDY3Mg4ZcLxbvCTS9K",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12254.112883050657,
        y: 3137.05440603458,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 12.899999353467138,
        height: 69.44602447090028,
        seed: 870442190,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 208,
        versionNonce: 1331469134,
        isDeleted: false,
        id: "dBJ2WylWJPXhDK79eJhah",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12270.324467379747,
        y: 3136.2253236929027,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 12.899999353467138,
        height: 69.44602447090028,
        seed: 315679374,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 299,
        versionNonce: 1455949970,
        isDeleted: false,
        id: "nv6rgNG9M8NKoGOTvHEu_",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12288.018673922137,
        y: 3136.79465062281,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 12.899999353467138,
        height: 69.44602447090028,
        seed: 902680786,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 389,
        versionNonce: 1611137422,
        isDeleted: false,
        id: "xiQNwNvmysDEazZRfUg2R",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12237.597657692282,
        y: 3136.201601737491,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 12.899999353467138,
        height: 69.44602447090028,
        seed: 426593358,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 533,
        versionNonce: 94185042,
        isDeleted: false,
        id: "ZfEZ9FjOH7kdEYDuoPgoY",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 5.9735318284068,
        x: 12316.722239971594,
        y: 3135.822050450886,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 12.899999353467138,
        height: 69.44602447090028,
        seed: 765498066,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 235,
        versionNonce: 183279566,
        isDeleted: false,
        id: "OXc4UKTlyJNQbNRaBkMax",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12255.597792215138,
        y: 3344.1249688954535,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 12.899999353467138,
        height: 69.44602447090028,
        seed: 2114697490,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 582,
        versionNonce: 1602784274,
        isDeleted: false,
        id: "UF8KkrUwgiUcLK98creL9",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0.41053648452244307,
        x: 12369.439088417137,
        y: 3236.284788380328,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 12.899999353467138,
        height: 69.44602447090028,
        seed: 1511442130,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 561,
        versionNonce: 606303758,
        isDeleted: false,
        id: "trz5_Li7fD2pWqAEJfsUC",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12400.13409939463,
        y: 3237.850819399552,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 12.899999353467138,
        height: 69.44602447090028,
        seed: 620371090,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 513,
        versionNonce: 981547474,
        isDeleted: false,
        id: "lEj4q5aC9JB4BPKqa0Kel",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12239.082566856765,
        y: 3343.2721645983643,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 12.899999353467138,
        height: 69.44602447090028,
        seed: 1819064914,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 851,
        versionNonce: 346391630,
        isDeleted: false,
        id: "jVkdjBbo6wlkGBCvW_hgo",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 4.720507597603854,
        x: 12374.291760994063,
        y: 3371.9097976930766,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 12.899999353467138,
        height: 69.44602447090028,
        seed: 1866833938,
        groupIds: ["hxSba0R3OSjk3ZRXCrGNy"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700484142511,
        link: null,
        locked: false,
      },
    ],
    id: "Wm_xa1drEH8wGpkM1sS2g",
    created: 1700484145080,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 164,
        versionNonce: 2146973838,
        isDeleted: false,
        id: "M1Rshi97umBvJCBOJwi4a",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11761.496670180244,
        y: 2912.4533030503744,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 158.92463235293872,
        height: 134.16360294117658,
        seed: 372347790,
        groupIds: ["yiVwXhFv2Nxh4LqruHoos"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700483936425,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 402,
        versionNonce: 1160765266,
        isDeleted: false,
        id: "NAbiFGUkRDT74RW1C2Ao4",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11760.255861356716,
        y: 2876.9431927562573,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 159.35661764705765,
        height: 35.67555147058829,
        seed: 1737156366,
        groupIds: ["yiVwXhFv2Nxh4LqruHoos"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483936425,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 462,
        versionNonce: 1304295118,
        isDeleted: false,
        id: "dH4WUw5s64mBblsshvJ2X",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11441.89189076848,
        y: 3047.542917020962,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 158.92463235293872,
        height: 137.20588235294144,
        seed: 845357010,
        groupIds: ["yiVwXhFv2Nxh4LqruHoos"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700483936425,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 573,
        versionNonce: 1851532562,
        isDeleted: false,
        id: "bJCYD2XDwtwnbqLxrNXiW",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11441.740236356716,
        y: 2876.6237993739037,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 155.90533088235134,
        height: 35.67555147058829,
        seed: 1146312082,
        groupIds: ["yiVwXhFv2Nxh4LqruHoos"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483936425,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 303,
        versionNonce: 1353837838,
        isDeleted: false,
        id: "q-_WcIGbEAde-k8SQEC3D",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11600.471854003776,
        y: 2912.4969611386095,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 160.47794117646663,
        height: 134.16360294117658,
        seed: 273917650,
        groupIds: ["yiVwXhFv2Nxh4LqruHoos"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700483936425,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 507,
        versionNonce: 1031385810,
        isDeleted: false,
        id: "tclGUkm3PmOVXHtQWLaXl",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11600.784354003776,
        y: 2876.9868508444924,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 159.35661764705765,
        height: 35.67555147058829,
        seed: 1083899026,
        groupIds: ["yiVwXhFv2Nxh4LqruHoos"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483936425,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 770,
        versionNonce: 911022926,
        isDeleted: false,
        id: "dtvh3PnHD5DkPZ2Q04tKR",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 4.71238898038469,
        x: 11352.729206944952,
        y: 3098.696409668023,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 138.45588235294053,
        height: 35.67555147058738,
        seed: 1066894862,
        groupIds: ["yiVwXhFv2Nxh4LqruHoos"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483936425,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 584,
        versionNonce: 1965000850,
        isDeleted: false,
        id: "KBZhZ3dF_EhVgbQY-1jrP",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11438.73311319495,
        y: 3185.093468491549,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 161.5854779411748,
        height: 134.16360294117658,
        seed: 438587598,
        groupIds: ["yiVwXhFv2Nxh4LqruHoos"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700483936425,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 877,
        versionNonce: 350781838,
        isDeleted: false,
        id: "AiWENz1gEEA34cArcVSzo",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 4.71238898038469,
        x: 11349.570429371423,
        y: 3233.2046817268456,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 138.45588235294053,
        height: 35.67555147058738,
        seed: 859637518,
        groupIds: ["yiVwXhFv2Nxh4LqruHoos"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483936425,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 771,
        versionNonce: 1829021266,
        isDeleted: false,
        id: "pPD82UMZhTedrZrRcJlbZ",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 4.71238898038469,
        x: 11339.954850327302,
        y: 2942.9174574621393,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 169.0487132352955,
        height: 35.67555147058738,
        seed: 118060750,
        groupIds: ["yiVwXhFv2Nxh4LqruHoos"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483936425,
        link: null,
        locked: false,
      },
    ],
    id: "Agc_wsqYJe8gsqlVJDVJI",
    created: 1700483937736,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 379,
        versionNonce: 1084743245,
        isDeleted: false,
        id: "xNAwtss4H5VLzAtVz2xVx",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12115.786565422033,
        y: 1676.740904807301,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 80.97655788520203,
        height: 76.66219491764969,
        seed: 612953389,
        groupIds: ["2K5VMv4rQfUaw3iIWYCGX"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483655467,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 305,
        versionNonce: 1694873859,
        isDeleted: false,
        id: "HfDwP7uFGKn9hK4h_P85r",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12091.205560010869,
        y: 1678.9648192434527,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 32.89158587563725,
        height: 60.39797768745874,
        seed: 828851693,
        groupIds: ["2K5VMv4rQfUaw3iIWYCGX"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483655467,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 508,
        versionNonce: 571509933,
        isDeleted: false,
        id: "AZJi5vL55BSMQfcjn1V4t",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12189.84151940965,
        y: 1673.8232780522671,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 32.89158587563725,
        height: 68.69769464805586,
        seed: 29491277,
        groupIds: ["2K5VMv4rQfUaw3iIWYCGX"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483655467,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 721,
        versionNonce: 781033635,
        isDeleted: false,
        id: "WkyPREYPxCRJ3JvHmQPIP",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12090.840856666497,
        y: 1663.0187474838904,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 131.81775602079702,
        height: 23.32549474902362,
        seed: 1066823341,
        groupIds: ["2K5VMv4rQfUaw3iIWYCGX"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483655467,
        link: null,
        locked: false,
      },
    ],
    id: "4AxSgkyQ5bMga3sO0rBiU",
    created: 1700483670539,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 568,
        versionNonce: 1594410637,
        isDeleted: false,
        id: "bB8N571pJBeOkBgopCjXX",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11711.297190029221,
        y: 1692.8095789677395,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 302.9148381958059,
        height: 85.94893880175893,
        seed: 871434787,
        groupIds: ["K0Qj3UVgavXeRXT9mkDd8"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483667048,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 348,
        versionNonce: 129397955,
        isDeleted: false,
        id: "-rliYTrlTlRnL9qLmjP7I",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11723.099611024985,
        y: 1707.4566860496611,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 279.0834145520257,
        height: 99.91009321042296,
        seed: 1824614659,
        groupIds: ["K0Qj3UVgavXeRXT9mkDd8"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483667049,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 290,
        versionNonce: 1286237421,
        isDeleted: false,
        id: "BzfS_pRlV_sOxENfpk6-M",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11725.058145580759,
        y: 1731.797142446447,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 277.49113526975816,
        height: 0,
        seed: 1401919917,
        groupIds: ["K0Qj3UVgavXeRXT9mkDd8"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700483667049,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [277.49113526975816, 0],
        ],
      },
      {
        type: "line",
        version: 319,
        versionNonce: 2066801763,
        isDeleted: false,
        id: "wrvJQcP1_47fPJqcCg2iL",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11808.539517073737,
        y: 1733.4390834607161,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 0.4748903122563206,
        height: 75.53859823113658,
        seed: 640699203,
        groupIds: ["K0Qj3UVgavXeRXT9mkDd8"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700483667049,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.4748903122563206, 75.53859823113658],
        ],
      },
      {
        type: "line",
        version: 177,
        versionNonce: 940066637,
        isDeleted: false,
        id: "ZRiHux7pkleC_66obY_bI",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11904.04833928561,
        y: 1733.3863178704657,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 0,
        height: 74.45224784362381,
        seed: 944126221,
        groupIds: ["K0Qj3UVgavXeRXT9mkDd8"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700483667049,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 74.45224784362381],
        ],
      },
    ],
    id: "cwEX8i7rhh-Yi8-4cjaGB",
    created: 1700483668839,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 274,
        versionNonce: 1804773453,
        isDeleted: false,
        id: "Du3tA5a6WYh-OfXetPROT",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11922.471289928702,
        y: 1537.472337057291,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 80.97655788520203,
        height: 76.66219491764969,
        seed: 1907517837,
        groupIds: ["8K-pCYNU6P4C3h5z02Hcc"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483428843,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 372,
        versionNonce: 1250201347,
        isDeleted: false,
        id: "qDu4IyAaLi9D4Ul5G3QvG",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12002.96674978515,
        y: 1537.8044498900447,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 88.04093926228224,
        height: 76.66219491764969,
        seed: 923440515,
        groupIds: ["8K-pCYNU6P4C3h5z02Hcc"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483428843,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 115,
        versionNonce: 2031228589,
        isDeleted: false,
        id: "VoSyTqE-Mzp_1S_sKoq-8",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11897.890284517536,
        y: 1523.4320342632514,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 32.89158587563725,
        height: 76.66219491764969,
        seed: 2067080269,
        groupIds: ["8K-pCYNU6P4C3h5z02Hcc"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483428843,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 209,
        versionNonce: 1774302883,
        isDeleted: false,
        id: "fIIRDnIsMUjtSeeacTMPi",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12084.25990121185,
        y: 1523.7222450096297,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 32.89158587563725,
        height: 76.66219491764969,
        seed: 909765677,
        groupIds: ["8K-pCYNU6P4C3h5z02Hcc"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483428843,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 399,
        versionNonce: 1431615757,
        isDeleted: false,
        id: "WGjXb6vKVxYU2Q7xd_Udu",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11927.527475018012,
        y: 1523.2442508391243,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 155.34810541432498,
        height: 25.935839537304208,
        seed: 1392382403,
        groupIds: ["8K-pCYNU6P4C3h5z02Hcc"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483428843,
        link: null,
        locked: false,
      },
    ],
    id: "avpDQXgXKM1Y4YTH0Uj4z",
    created: 1700483661889,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 953,
        versionNonce: 1816897315,
        isDeleted: false,
        id: "Es3z-ipshqTMCYIHtwLF5",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11999.104156489462,
        y: 1452.8254451018238,
        strokeColor: "#495057",
        backgroundColor: "#6d7379",
        width: 4.23836978770305,
        height: 6.078605000046764,
        seed: 191274211,
        groupIds: ["xIZnjkDlItRXrefj7d_Nj"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700483431588,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 978,
        versionNonce: 218108045,
        isDeleted: false,
        id: "HgJKo32lJH6nI5teWa4X3",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12010.750813031236,
        y: 1452.0300114122567,
        strokeColor: "#495057",
        backgroundColor: "#6d7379",
        width: 4.23836978770305,
        height: 6.078605000046764,
        seed: 489984109,
        groupIds: ["xIZnjkDlItRXrefj7d_Nj"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700483431588,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 1095,
        versionNonce: 1328597699,
        isDeleted: false,
        id: "o2_uElunAsQcu7rD2WGjq",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12081.357298267263,
        y: 1451.5527551044563,
        strokeColor: "#495057",
        backgroundColor: "#6d7379",
        width: 4.23836978770305,
        height: 6.078605000046764,
        seed: 648103011,
        groupIds: ["xIZnjkDlItRXrefj7d_Nj"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700483431588,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 809,
        versionNonce: 287864557,
        isDeleted: false,
        id: "VPtgi5obVcS30qA3eQDV9",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11927.438986736766,
        y: 1451.664306659966,
        strokeColor: "#495057",
        backgroundColor: "#6d7379",
        width: 4.23836978770305,
        height: 6.078605000046764,
        seed: 1073436579,
        groupIds: ["xIZnjkDlItRXrefj7d_Nj"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700483431588,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 189,
        versionNonce: 1290828387,
        isDeleted: false,
        id: "d4-K2OD_vY62B0nlz6G-Z",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11921.976669484173,
        y: 1415.9584860635584,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 23.04958371974135,
        height: 35.38767370841947,
        seed: 795807459,
        groupIds: ["xIZnjkDlItRXrefj7d_Nj"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483431588,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 286,
        versionNonce: 339022157,
        isDeleted: false,
        id: "2YMUpEGReIsYFsQqyypGk",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12067.658832175903,
        y: 1415.4446245960235,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 23.04958371974135,
        height: 35.38767370841947,
        seed: 1935006541,
        groupIds: ["xIZnjkDlItRXrefj7d_Nj"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483431588,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 777,
        versionNonce: 1113142787,
        isDeleted: false,
        id: "dSkm2CNSOlZA7T-Yv2pxf",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11934.137840057434,
        y: 1401.160583823601,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 71.98917141238417,
        height: 50.17501260942635,
        seed: 1605981987,
        groupIds: ["xIZnjkDlItRXrefj7d_Nj"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700483431588,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 432,
        versionNonce: 327114669,
        isDeleted: false,
        id: "VMJGyr97-XTD6VWcfqp5B",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 12006.16748862359,
        y: 1401.1034135738194,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 71.98917141238417,
        height: 50.17501260942635,
        seed: 1872420781,
        groupIds: ["xIZnjkDlItRXrefj7d_Nj"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700483431588,
        link: null,
        locked: false,
      },
    ],
    id: "ZxZNlOUrlqdxlRAiZo1_o",
    created: 1700483660190,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 665,
        versionNonce: 1738672909,
        isDeleted: false,
        id: "x3DuXD1QQFbHTstLB70-0",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11869.342578416252,
        y: 1437.2595690687908,
        strokeColor: "#6d7379",
        backgroundColor: "transparent",
        width: 8.877452652192007,
        height: 20.37149098692407,
        seed: 542816387,
        groupIds: ["VIiCpcLNiTnbixy7Ir50u"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483434200,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 466,
        versionNonce: 1671542851,
        isDeleted: false,
        id: "U_v0q9Cm0J6rvB4E5zFDE",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11769.314256094982,
        y: 1394.7696338338203,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 24.38030251300006,
        height: 21.669667941716177,
        seed: 1239070701,
        groupIds: ["VIiCpcLNiTnbixy7Ir50u"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483434200,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 108,
        versionNonce: 1789337965,
        isDeleted: false,
        id: "rmUwabcdSqff7zCSyOZew",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11769.427252704301,
        y: 1407.2506229540786,
        strokeColor: "#6d7379",
        backgroundColor: "#ffffff",
        width: 104.45894505371871,
        height: 51.41987750213502,
        seed: 367274243,
        groupIds: ["VIiCpcLNiTnbixy7Ir50u"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700483434200,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 290,
        versionNonce: 1340585955,
        isDeleted: false,
        id: "cQqVSlTssLE9xj8z5ZlYW",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11785.802724750736,
        y: 1459.728635669169,
        strokeColor: "#495057",
        backgroundColor: "#6d7379",
        width: 4.23836978770305,
        height: 6.078605000046764,
        seed: 970662915,
        groupIds: ["VIiCpcLNiTnbixy7Ir50u"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700483434200,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 396,
        versionNonce: 1299868621,
        isDeleted: false,
        id: "IdjD_KSv5jneLzLUtJ1dL",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11871.495398293206,
        y: 1458.397823629418,
        strokeColor: "#495057",
        backgroundColor: "#6d7379",
        width: 4.23836978770305,
        height: 7.66946378994999,
        seed: 1177811501,
        groupIds: ["VIiCpcLNiTnbixy7Ir50u"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700483434200,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 602,
        versionNonce: 780977027,
        isDeleted: false,
        id: "z4sZC9xTUuUQmvu84nWNF",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11855.18953262465,
        y: 1460.1604659457657,
        strokeColor: "#495057",
        backgroundColor: "#6d7379",
        width: 4.23836978770305,
        height: 6.078605000046764,
        seed: 1812927587,
        groupIds: ["VIiCpcLNiTnbixy7Ir50u"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700483434200,
        link: null,
        locked: false,
      },
    ],
    id: "hMFPYp7jvP9VBhsMyUKu8",
    created: 1700483658573,
  },
  {
    status: "published",
    elements: [
      {
        type: "line",
        version: 1468,
        versionNonce: 2147386829,
        isDeleted: false,
        id: "FhE5bAY47Fw5Q8VGxFpue",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11616.66890449111,
        y: 1428.1525733029482,
        strokeColor: "#6d7379",
        backgroundColor: "transparent",
        width: 69.55292338408981,
        height: 73.93470441550994,
        seed: 760692941,
        groupIds: ["R_gOD0pExB8UXaQ_GslbC"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700482791502,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.39131671835093584, -65.50062136737893],
          [69.16160666573705, -65.50062136737893],
          [69.16160666573887, 0.27247238166728494],
          [61.523684051981036, 0.27247238166728494],
          [62.18554072376173, 8.371279130370795],
          [7.60120647722033, 8.434083048131015],
          [7.601206477222149, -0.34687086886015095],
          [0, 0],
        ],
      },
      {
        type: "line",
        version: 249,
        versionNonce: 1768900995,
        isDeleted: false,
        id: "nuJNECtksEyUFnTMlRdmm",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11636.79127974147,
        y: 1428.581572371956,
        strokeColor: "#6d7379",
        backgroundColor: "transparent",
        width: 41.40227501574191,
        height: 0.10048626841626174,
        seed: 1658850339,
        groupIds: ["R_gOD0pExB8UXaQ_GslbC"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700482791502,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [41.40227501574191, -0.10048626841626174],
        ],
      },
      {
        type: "rectangle",
        version: 196,
        versionNonce: 522601517,
        isDeleted: false,
        id: "0KaVVNDWVK0bcY7mGvbTj",
        fillStyle: "solid",
        strokeWidth: 2,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11624.056577647947,
        y: 1375.5605725705825,
        strokeColor: "#6d7379",
        backgroundColor: "transparent",
        width: 54.95536046838606,
        height: 47.156247788349894,
        seed: 1601069507,
        groupIds: ["R_gOD0pExB8UXaQ_GslbC"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700482791502,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 132,
        versionNonce: 7414051,
        isDeleted: false,
        id: "qOjyCsE7sSfQFBXEzxk0E",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 11624.741623458593,
        y: 1368.0687224334106,
        strokeColor: "#6d7379",
        backgroundColor: "#6d7379",
        width: 4.310674258523077,
        height: 3.8040743697852126,
        seed: 356223597,
        groupIds: ["R_gOD0pExB8UXaQ_GslbC"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700482791502,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 195,
        versionNonce: 1401370253,
        isDeleted: false,
        id: "JuiS0vUwNp-rtXf-n_-9K",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 11631.730937033122,
        y: 1367.5921634671618,
        strokeColor: "#6d7379",
        backgroundColor: "#6d7379",
        width: 4.310674258523077,
        height: 3.8040743697852126,
        seed: 1668825933,
        groupIds: ["R_gOD0pExB8UXaQ_GslbC"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700482791502,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 304,
        versionNonce: 1956054211,
        isDeleted: false,
        id: "u9eQjD7V71zzNfq-AfW7v",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 11638.693954016724,
        y: 1367.487994084713,
        strokeColor: "#6d7379",
        backgroundColor: "#6d7379",
        width: 4.310674258523077,
        height: 3.8040743697852126,
        seed: 751325229,
        groupIds: ["R_gOD0pExB8UXaQ_GslbC"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700482791502,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 375,
        versionNonce: 131783917,
        isDeleted: false,
        id: "WCH9ez8f0bBxoegM4uPqH",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 11673.62689158352,
        y: 1367.8517041319071,
        strokeColor: "#6d7379",
        backgroundColor: "#6d7379",
        width: 4.310674258523077,
        height: 3.8040743697852126,
        seed: 1332252013,
        groupIds: ["R_gOD0pExB8UXaQ_GslbC"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700482791502,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 426,
        versionNonce: 494892131,
        isDeleted: false,
        id: "U_kmvudPSo52Ez5DxRpOP",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11630.693916478918,
        y: 1395.8912450380005,
        strokeColor: "#6d7379",
        backgroundColor: "transparent",
        width: 16.48259779408198,
        height: 17.1579750957765,
        seed: 72032867,
        groupIds: ["R_gOD0pExB8UXaQ_GslbC"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700482791502,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 494,
        versionNonce: 329955149,
        isDeleted: false,
        id: "weHlh0wUgAvdyGpevWyqw",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11643.493804021831,
        y: 1396.064096794908,
        strokeColor: "#6d7379",
        backgroundColor: "transparent",
        width: 16.48259779408198,
        height: 17.1579750957765,
        seed: 1768900195,
        groupIds: ["R_gOD0pExB8UXaQ_GslbC"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700482791502,
        link: null,
        locked: false,
      },
      {
        type: "ellipse",
        version: 493,
        versionNonce: 1526263811,
        isDeleted: false,
        id: "HUD86NtpxxfKx2WXO96_R",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11653.104510305844,
        y: 1395.7442540484005,
        strokeColor: "#6d7379",
        backgroundColor: "transparent",
        width: 16.48259779408198,
        height: 17.1579750957765,
        seed: 610784781,
        groupIds: ["R_gOD0pExB8UXaQ_GslbC"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700482791502,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 139,
        versionNonce: 558908845,
        isDeleted: false,
        id: "AAF4IzHyIDl2tqAiR813B",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11642.119341447358,
        y: 1379.0035053429062,
        strokeColor: "#6d7379",
        backgroundColor: "transparent",
        width: 4.934648143702361,
        height: 8.556199360884875,
        seed: 823968483,
        groupIds: ["R_gOD0pExB8UXaQ_GslbC"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700482791502,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-4.934648143702361, 8.556199360884875],
        ],
      },
      {
        type: "line",
        version: 62,
        versionNonce: 251787171,
        isDeleted: false,
        id: "2Iz_WMe2Yt0meWggXFpcc",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11655.595134034727,
        y: 1378.7835119817298,
        strokeColor: "#6d7379",
        backgroundColor: "transparent",
        width: 6.4504326491078245,
        height: 8.499374507444372,
        seed: 101153485,
        groupIds: ["R_gOD0pExB8UXaQ_GslbC"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700482791502,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [6.4504326491078245, 8.499374507444372],
        ],
      },
      {
        type: "line",
        version: 183,
        versionNonce: 1408567309,
        isDeleted: false,
        id: "_lK_NzUy86Rb26P1_akUI",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 11648.704026337164,
        y: 1379.0505707422192,
        strokeColor: "#6d7379",
        backgroundColor: "transparent",
        width: 0.39243984444874513,
        height: 8.126978960826818,
        seed: 639331725,
        groupIds: ["R_gOD0pExB8UXaQ_GslbC"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700482791502,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.39243984444874513, 8.126978960826818],
        ],
      },
    ],
    id: "bq3Y72DnzVjpTxtohQp7U",
    created: 1700482792712,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 285,
        versionNonce: 791976060,
        isDeleted: false,
        id: "RgiUzWKklZj37nkYYZkIl",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 1256.2090076856884,
        y: -214.93858998987872,
        strokeColor: "transparent",
        backgroundColor: "#b2f2bb",
        width: 199.74895397489533,
        height: 64.43514644351465,
        seed: 10693628,
        groupIds: ["HJPAHstmWaEYwrfH1EGVV"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488736717,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 309,
        versionNonce: 1384392956,
        isDeleted: false,
        id: "Sa939E5KNUia-YUXCCDu-",
        fillStyle: "solid",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 1449.5144470162331,
        y: -131.17289961331045,
        strokeColor: "transparent",
        backgroundColor: "#b2f2bb",
        width: 57.99163179916319,
        height: 103.09623430962344,
        seed: 802442364,
        groupIds: ["HJPAHstmWaEYwrfH1EGVV"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488736717,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [57.99163179916319, -51.54811715481172],
          [0, -103.09623430962344],
          [0, 0],
        ],
      },
    ],
    id: "pSs8n6_AWmdv0J2-LO4Ss",
    created: 1700488740606,
  },
  {
    status: "published",
    elements: [
      {
        type: "line",
        version: 2922,
        versionNonce: 2138311548,
        isDeleted: false,
        id: "HJMsxQKpUQp0JEArIzxed",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: -87.1210909898723,
        y: 354.7772474401762,
        strokeColor: "#e03131",
        backgroundColor: "transparent",
        width: 617.9959930631092,
        height: 363.1493063056664,
        seed: 2053802748,
        groupIds: [],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488685558,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-22.9010653522364, 16.56177722820621],
          [-53.23985060250834, 25.3917965564533],
          [-87.77644408025935, 24.438669679625946],
          [-116.24257626968779, 12.92987876806228],
          [-142.07405641667788, -10.437806091318635],
          [-154.79217416445385, -37.60415786844851],
          [-160.08560346159652, -31.39296972411057],
          [-178.8353436740647, -22.616204725852185],
          [-199.7958944901784, -22.242551394129805],
          [-222.29206936471314, -30.643980896702942],
          [-234.1568615330052, -40.578756083723874],
          [-243.28913804405374, -55.3734307164018],
          [-245.22281745825467, -63.94803793420806],
          [-243.81050028004438, -72.34727386096574],
          [-250.55459274078453, -63.08153972090031],
          [-274.0844579627144, -63.79463195591188],
          [-300.4640174895676, -77.34215495175795],
          [-312.94461990729474, -96.875834895759],
          [-316.3112090192301, -117.18524530376317],
          [-313.4715230263771, -133.73388146387242],
          [-303.0411147084028, -147.32452216624552],
          [-293.23560037532025, -157.4509495747211],
          [-277.3525169678883, -164.69170452195203],
          [-259.9573587915886, -165.9389257048033],
          [-250.4319046393707, -161.18568926945045],
          [-256.2635151306638, -171.1685707364868],
          [-252.8476609653478, -194.51204193092553],
          [-242.41097588269167, -217.9723882093149],
          [-226.12046358151724, -239.94660799345002],
          [-205.59785887070416, -252.72643521547843],
          [-184.30711620849297, -258.4058179860035],
          [-156.26335125537548, -258.8706005474078],
          [-135.67931621530306, -253.93741940635118],
          [-117.43856351725049, -243.86027862089395],
          [-109.78500883639458, -233.31775132416624],
          [-109.76624325126272, -243.62768242863012],
          [-101.21215618045767, -261.58632583020017],
          [-84.04397530570077, -281.8252250913133],
          [-67.01526371078671, -297.803818856175],
          [-45.6104176631186, -311.86356683238773],
          [-22.282897216041647, -321.21955912300973],
          [12.536171464121708, -327.43893778293295],
          [46.2283344026589, -323.10651420175327],
          [79.02509852511025, -312.46340768264645],
          [107.78743440131234, -293.63803151400225],
          [121.968824351492, -280.1732712194794],
          [134.59448258532484, -265.2156978428966],
          [137.76081917730107, -251.4771649170102],
          [144.6054261515062, -257.8926655112],
          [164.8883059046691, -262.71954069096637],
          [189.35349534277543, -260.7716729542776],
          [210.49937631562375, -250.36738546802144],
          [230.24064245653176, -232.98948300218763],
          [239.4196882211429, -210.09784180148472],
          [242.46745651257947, -195.10903558895552],
          [239.6582268486991, -172.94065670761984],
          [244.78127472897813, -179.1780999394373],
          [258.27964051963386, -178.1118264504612],
          [278.6087655768456, -169.2959486822474],
          [293.8967210999399, -153.57157467182742],
          [301.68478404387906, -131.0562805163895],
          [299.748175658269, -109.7154315849125],
          [291.2316197577277, -95.56661178823742],
          [283.75277914534104, -87.50429089607815],
          [275.52115816387095, -87.3428637246675],
          [287.49644867023653, -81.46021946967085],
          [295.6259158457474, -72.75268599354604],
          [296.0736152537902, -56.895097898328004],
          [288.37385840816137, -43.352902603088296],
          [278.33862742949873, -37.47513308629823],
          [266.6479698672715, -36.48696325681931],
          [256.604154172652, -38.9231675996989],
          [243.96318149578065, -52.03336618341973],
          [247.54667488827477, -40.924679026299145],
          [242.03444602807076, -25.526286956222066],
          [238.42690248911458, -16.46520554228573],
          [225.85107011925265, 2.745576919618088],
          [213.8196554145959, 14.847028239129031],
          [185.57675956269964, 31.562699676549983],
          [158.8690611316954, 35.71036852273346],
          [129.57158253466355, 31.826194375367322],
          [101.23425480724154, 21.09033841250539],
          [85.75972191473261, 3.207037756757927],
          [84.96557097980667, 11.793328297220341],
          [67.56832054861297, 22.18266802428525],
          [46.03323702620196, 25.830417368627423],
          [25.70784351637826, 22.122984835710668],
          [5.913149381939547, 9.968838890363713],
          [0, 0],
        ],
      },
    ],
    id: "R5yx2Pzze_kMGzWfRZMeX",
    created: 1700488701494,
  },
  {
    status: "published",
    elements: [
      {
        type: "line",
        version: 199,
        versionNonce: 1334162244,
        isDeleted: false,
        id: "Gtuqif6KholK1bDZ3d7tA",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1083.7377950202736,
        y: 606.3968178451021,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 88.800048828125,
        height: 49.5999755859375,
        seed: 1155796732,
        groupIds: ["O-ODndjY-ZRWGsdyX6x5c"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459731,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [88.800048828125, -49.5999755859375],
        ],
      },
      {
        type: "line",
        version: 211,
        versionNonce: 458560252,
        isDeleted: false,
        id: "wh5iatAPsjiKIK8gjun7i",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1171.7377950202736,
        y: 556.7968422591646,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 82.4000244140625,
        height: 49.5999755859375,
        seed: 536924028,
        groupIds: ["O-ODndjY-ZRWGsdyX6x5c"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459731,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [82.4000244140625, 49.5999755859375],
        ],
      },
      {
        type: "line",
        version: 196,
        versionNonce: 1047964356,
        isDeleted: false,
        id: "nw-tBTPtoJO6cfXq-heJk",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1082.9378072273048,
        y: 606.3968178451021,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 171.20001220703125,
        height: 1.600006103515625,
        seed: 1790017532,
        groupIds: ["O-ODndjY-ZRWGsdyX6x5c"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459731,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [171.20001220703125, 1.600006103515625],
        ],
      },
      {
        type: "rectangle",
        version: 236,
        versionNonce: 162616188,
        isDeleted: false,
        id: "PACLhgcOsHWxv65q9cUU4",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1068.5379048835548,
        y: 626.3968178451021,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 204.79998779296875,
        height: 144.80001831054688,
        seed: 1828200572,
        groupIds: ["O-ODndjY-ZRWGsdyX6x5c"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459731,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 287,
        versionNonce: 593277508,
        isDeleted: false,
        id: "hQ7zzMGLd_pAD3sugHIdX",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1053.337770606211,
        y: 611.9968239486177,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 235.2000732421875,
        height: 171.20001220703125,
        seed: 513192188,
        groupIds: ["O-ODndjY-ZRWGsdyX6x5c"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700488459731,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 205,
        versionNonce: 1404142588,
        isDeleted: false,
        id: "DCs_i9GHLgqKd0aH9UDyl",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1112.5377828132423,
        y: 692.7968422591646,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 43.20001220703125,
        height: 64.800048828125,
        seed: 789524860,
        groupIds: ["O-ODndjY-ZRWGsdyX6x5c"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459731,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-43.20001220703125, 64.800048828125],
        ],
      },
      {
        type: "line",
        version: 216,
        versionNonce: 487967172,
        isDeleted: false,
        id: "gh-g4o4CVebtosF6QT1fr",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1113.337770606211,
        y: 692.7968422591646,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 56.800048828125,
        height: 72.800048828125,
        seed: 228827644,
        groupIds: ["O-ODndjY-ZRWGsdyX6x5c"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459731,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [56.800048828125, 72.800048828125],
        ],
      },
      {
        type: "line",
        version: 209,
        versionNonce: 1062708348,
        isDeleted: false,
        id: "55-jy7SYN_0HJ10bqEnmm",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1154.9377461921486,
        y: 741.5968910872896,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 72,
        height: 97.60006713867188,
        seed: 1901462140,
        groupIds: ["O-ODndjY-ZRWGsdyX6x5c"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459731,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [72, -97.60006713867188],
        ],
      },
      {
        type: "line",
        version: 196,
        versionNonce: 1052766532,
        isDeleted: false,
        id: "msF6bAxSNJxkYmQsOezVs",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1227.7377950202736,
        y: 644.7968422591646,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 42.4000244140625,
        height: 57.5999755859375,
        seed: 1625838332,
        groupIds: ["O-ODndjY-ZRWGsdyX6x5c"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459731,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [42.4000244140625, 57.5999755859375],
        ],
      },
      {
        type: "ellipse",
        version: 329,
        versionNonce: 40219900,
        isDeleted: false,
        id: "83HjKlVpUQjx7KdJslPa_",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1098.9376851569923,
        y: 639.1967751204927,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 35.20001220703125,
        height: 35.20001220703125,
        seed: 987534204,
        groupIds: ["O-ODndjY-ZRWGsdyX6x5c"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459731,
        link: null,
        locked: false,
      },
    ],
    id: "CGLKNDIrd1RD6o0wybXNS",
    created: 1700488474270,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 140,
        versionNonce: 824638460,
        isDeleted: false,
        id: "iAUkOXlHPlcOXGVCx50_d",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1344.4422807487178,
        y: 317.21790451339587,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 572.1824068761986,
        height: 560.4573582882017,
        seed: 1505598076,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 85,
        versionNonce: 366203332,
        isDeleted: false,
        id: "l6teOTg1Ii_jIiysKS3k3",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1349.1323180749207,
        y: 317.21790451339587,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 283.7462295025083,
        height: 86.76537744217208,
        seed: 144501500,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 133,
        versionNonce: 1063132284,
        isDeleted: false,
        id: "tpg8KLbQlPUAtIrsWzbDd",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1349.1323180749207,
        y: 314.87284112278394,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 283.7462295025083,
        height: 562.8024216788136,
        seed: 809380732,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 122,
        versionNonce: 41744708,
        isDeleted: false,
        id: "2qpEg1c336RaIwt1KmF_S",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1625.8434468606142,
        y: 314.87284112278394,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 300.1613154167065,
        height: 558.1122948975898,
        seed: 1122196476,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 90,
        versionNonce: 719138044,
        isDeleted: false,
        id: "iC2cazyBWXd-RoAPQ1vqg",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1351.4772920105117,
        y: 781.5299896170632,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 272.0211809145123,
        height: 93.80029924894423,
        seed: 445545596,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 91,
        versionNonce: 1586835652,
        isDeleted: false,
        id: "2Sr6K-JbvscBmoc2lLINI",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1632.8785475774291,
        y: 774.4947994452274,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 281.4011661118964,
        height: 100.83548942078005,
        seed: 16878844,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 102,
        versionNonce: 1697000828,
        isDeleted: false,
        id: "ArkAlMRnogEnviVOOTGP-",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1351.7953416927398,
        y: 458.2220947546466,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 264.7685975801459,
        height: 3.255343636168972,
        seed: 935234940,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [264.7685975801459, 3.255343636168972],
        ],
      },
      {
        type: "line",
        version: 94,
        versionNonce: 163215428,
        isDeleted: false,
        id: "7QA5cLOHVq8EqjGKqB5s-",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1641.5216704027087,
        y: 483.1797844905297,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 269.1090419637249,
        height: 1.0851007474092227,
        seed: 491004,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [269.1090419637249, 1.0851007474092227],
        ],
      },
      {
        type: "line",
        version: 101,
        versionNonce: 1601904124,
        isDeleted: false,
        id: "uNYrL0B0d3yjaOys4zV3s",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1631.755598100261,
        y: 403.9662502023316,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 282.1304992962818,
        height: 3.2553022422292224,
        seed: 584998524,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [282.1304992962818, 3.2553022422292224],
        ],
      },
      {
        type: "line",
        version: 105,
        versionNonce: 1070256068,
        isDeleted: false,
        id: "LVFN7ArSCNg-Bqhz1jCLs",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1771.7357470009924,
        y: 408.30667388894074,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 1.0851007474099998,
        height: 313.5989176984426,
        seed: 2030382844,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1.0851007474099998, 313.5989176984426],
        ],
      },
      {
        type: "line",
        version: 98,
        versionNonce: 316538492,
        isDeleted: false,
        id: "0QEPpl_doOnC3buWyAyCG",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1629.5853966054428,
        y: 634.0110650471772,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 284.30070079110027,
        height: 5.425586524928721,
        seed: 1929244540,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [284.30070079110027, 5.425586524928721],
        ],
      },
      {
        type: "line",
        version: 95,
        versionNonce: 283391812,
        isDeleted: false,
        id: "wyqzJtd4iaGlbTWizQnzD",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1640.4365696552995,
        y: 563.4784402231069,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 269.1090419637249,
        height: 2.1702014948192225,
        seed: 554344444,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [269.1090419637249, -2.1702014948192225],
        ],
      },
      {
        type: "rectangle",
        version: 103,
        versionNonce: 135319292,
        isDeleted: false,
        id: "GTsMWFHlQ7rz2Vj2_4sdK",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1782.58692005085,
        y: 343.1996355897984,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 106.34144621587026,
        height: 58.59633032983373,
        seed: 992821372,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 81,
        versionNonce: 1499770564,
        isDeleted: false,
        id: "tcByj3aGEXoR9Vr2s642N",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1369.1572020149345,
        y: 347.5401006703472,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 108.51173049856976,
        height: 53.17076450187566,
        seed: 690475260,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 83,
        versionNonce: 1603637116,
        isDeleted: false,
        id: "i_Yjg818PDFRFnCWKGCHH",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1530.83969701538,
        y: 356.2210515284148,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 75.95816995505847,
        height: 44.48981364380809,
        seed: 175919484,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 193,
        versionNonce: 971650628,
        isDeleted: false,
        id: "1i8wzDEk4To641qJUqNSY",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1462.477273686129,
        y: 815.2256632585769,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 1.0851007474099998,
        height: 28.21303337205205,
        seed: 1047314940,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-1.0851007474099998, 28.21303337205205],
        ],
      },
      {
        type: "line",
        version: 88,
        versionNonce: 1504591868,
        isDeleted: false,
        id: "qw2Aa108DJY9IAgV-wA4j",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1515.6480381880037,
        y: 813.0554617637576,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 1.0851007474099998,
        height: 34.72372064438999,
        seed: 1811620476,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1.0851007474099998, 34.72372064438999],
        ],
      },
      {
        type: "line",
        version: 53,
        versionNonce: 257084868,
        isDeleted: false,
        id: "jZk2Yr-_lW6iANXkBRcY4",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1746.7780158711696,
        y: 813.0554617637576,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 2.1702014948192225,
        height: 35.808821391799995,
        seed: 166294268,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-2.1702014948192225, 35.808821391799995],
        ],
      },
      {
        type: "line",
        version: 118,
        versionNonce: 1583145084,
        isDeleted: false,
        id: "Qjsu0ct7mL4G6fZpx0Umw",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1801.0338811204545,
        y: 815.2256632585769,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 1.0851007474099998,
        height: 29.298216907342326,
        seed: 1723446140,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-1.0851007474099998, 29.298216907342326],
        ],
      },
      {
        type: "rectangle",
        version: 81,
        versionNonce: 1790735684,
        isDeleted: false,
        id: "zk9vIdzexJkSaPQ8j6Rb6",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1661.0537322197233,
        y: 690.4371731852218,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 87.89456793414593,
        height: 21.702346099714106,
        seed: 816702460,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 74,
        versionNonce: 90403068,
        isDeleted: false,
        id: "xItXOxV1DOpUOoOX24DNB",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1663.2240165024227,
        y: 452.7965082297179,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 86.80938439885566,
        height: 27.127932624642828,
        seed: 2110260348,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 81,
        versionNonce: 159722692,
        isDeleted: false,
        id: "X_Be8sc4PvYiJpDx2gwxn",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1803.204165403154,
        y: 527.6695774373671,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 82.46889862133693,
        height: 28.2130747659918,
        seed: 1405752572,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 242,
        versionNonce: 507769212,
        isDeleted: false,
        id: "zyhZ6ZwPPyUBzd1BPuJez",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1427.3152452200582,
        y: 465.7611049968368,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 92.04321851986806,
        height: 48.81080898468516,
        seed: 808850812,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.4648577541989487, 14.875678665778931],
          [-46.95134250151748, 44.62701826415163],
          [45.09187601835058, 48.81080898468516],
          [6.508114957899375, 19.989220361083017],
        ],
      },
      {
        type: "line",
        version: 209,
        versionNonce: 1296757828,
        isDeleted: false,
        id: "RzLZuHb7NiqM9C-xYNMNV",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1556.082793783584,
        y: 465.29622950945117,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 92.97296949463862,
        height: 48.810808984685934,
        seed: 273548796,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-1.394573262596069, 19.989202627897075],
          [-46.0215915267477, 43.69733822212873],
          [46.95137796789092, 48.810808984685934],
          [3.254075212136406, 21.848651377878035],
        ],
      },
      {
        type: "line",
        version: 112,
        versionNonce: 943743484,
        isDeleted: false,
        id: "AV8sxTtyi3eTJMTDM6xTE",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1628.5270924303018,
        y: 728.3882575847445,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 281.7878879738082,
        height: 4.5744782476201085,
        seed: 2031347324,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [281.7878879738082, -4.5744782476201085],
        ],
      },
      {
        type: "line",
        version: 89,
        versionNonce: 871091140,
        isDeleted: false,
        id: "nbd88lRCEDMsTNDj0oXkG",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1485.4188546518753,
        y: 784.4244247634787,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 3.100164049291533,
        height: 86.80432729197422,
        seed: 2135537404,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-3.100164049291533, 86.80432729197422],
        ],
      },
      {
        type: "line",
        version: 207,
        versionNonce: 710591100,
        isDeleted: false,
        id: "Q4ig86DVA3p-O2mTKYhuB",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1769.857797473578,
        y: 775.123962180958,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 0.7750853603555659,
        height: 95.32970451413851,
        seed: 1332836220,
        groupIds: ["yEiiz9ZbAJhTLe3bI5cly"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459731,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.7750853603555659, 95.32970451413851],
        ],
      },
    ],
    id: "UgT3hLUIxSrM6OsP4vSyH",
    created: 1700488470939,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 122,
        versionNonce: 490453572,
        isDeleted: false,
        id: "-fSyBTDhjvFFBRh_J0S10",
        fillStyle: "cross-hatch",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1144.0055617556209,
        y: 348.0560934339119,
        strokeColor: "#1e1e1e",
        backgroundColor: "#a5d8ff",
        width: 165.29306237193953,
        height: 109.70779216725214,
        seed: 1545267396,
        groupIds: [],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
      },
    ],
    id: "Ax3PNbr06l51hQ3KTKSu3",
    created: 1700488468442,
  },
  {
    status: "published",
    elements: [
      {
        type: "line",
        version: 98,
        versionNonce: 569234372,
        isDeleted: false,
        id: "cRd0FHXlUlJOLofO-M6CX",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1251.4553266743933,
        y: 18.28846095728366,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 65.5999755859375,
        height: 0,
        seed: 1569042684,
        groupIds: ["DB1T2MWAXEdQvpnY6opdf"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [65.5999755859375, 0],
        ],
      },
      {
        type: "line",
        version: 166,
        versionNonce: 459094652,
        isDeleted: false,
        id: "6iw0_0yQE6Htqyg01WZNs",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1314.6553388814245,
        y: 19.088479267830536,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 50.39996337890625,
        height: 99.19998168945312,
        seed: 941502844,
        groupIds: ["DB1T2MWAXEdQvpnY6opdf"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [50.39996337890625, 99.19998168945312],
        ],
      },
      {
        type: "line",
        version: 146,
        versionNonce: 1074182980,
        isDeleted: false,
        id: "ikurY8jNBJTW0BdhRYAvl",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1253.8553510884558,
        y: 16.688454853768036,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 52,
        height: 101.60000610351562,
        seed: 483566076,
        groupIds: ["DB1T2MWAXEdQvpnY6opdf"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-52, 101.60000610351562],
        ],
      },
      {
        type: "line",
        version: 192,
        versionNonce: 893946620,
        isDeleted: false,
        id: "F-aOuETpxQX8Cxctjptbg",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1205.8553510884558,
        y: 118.28846095728366,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 160.79998779296875,
        height: 0.800018310546875,
        seed: 1118110332,
        groupIds: ["DB1T2MWAXEdQvpnY6opdf"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [160.79998779296875, 0.800018310546875],
        ],
      },
      {
        type: "line",
        version: 1226,
        versionNonce: 160871108,
        isDeleted: false,
        id: "T1-ZNoCx4QJ-VgtkUZjL_",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1305.179634950303,
        y: 126.9936764667207,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 23.94183040901268,
        height: 116.6411391866859,
        seed: 811029244,
        groupIds: ["DB1T2MWAXEdQvpnY6opdf"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.19435179185782658, 4.27580614728231],
          [7.774190299042203, 11.27258186484778],
          [10.883863453040846, 21.379023322366038],
          [14.96531039441976, 39.06531070111441],
          [11.078215244898672, 61.61045960271872],
          [1.7491957829027456, 80.85158355846647],
          [-8.976520014592921, 104.45702168333105],
          [1.3169538682124653, 106.76780120748822],
          [1.9471737507813884, 116.6411391866859],
        ],
      },
      {
        type: "line",
        version: 518,
        versionNonce: 108383100,
        isDeleted: false,
        id: "YuYF25WCnyzqwasotGywY",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 80,
        angle: 0,
        x: 1306.0764529149078,
        y: 244.2650355359756,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 62.60121126699198,
        height: 117.6398480517862,
        seed: 1147900796,
        groupIds: ["DB1T2MWAXEdQvpnY6opdf"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700488459730,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-48.94658604669539, -1.4704916897844669],
          [-50.207009784675904, -10.503557861766694],
          [-39.28329999214418, -11.974049551551161],
          [-51.88756942626412, -43.69480711581218],
          [-60.08034776387356, -61.97099138442309],
          [-62.60121126699198, -87.38960621804563],
          [-53.148009191402025, -108.8168578451864],
          [-46.2156545917731, -113.64850087246214],
          [-1.4704916897843816, -117.6398480517862],
        ],
      },
    ],
    id: "dRfPoOWN6tNqNqd_OTFCo",
    created: 1700488465742,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 164,
        versionNonce: 1845410884,
        isDeleted: false,
        id: "QLIR7RNYxDX1YMpXPgSlx",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 378.55633893537197,
        y: 213.04423679320928,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 402.88732212925606,
        height: 583.0605706778167,
        seed: 531193980,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 150,
        versionNonce: 59578876,
        isDeleted: false,
        id: "YqUlJ5UJBomiiiiDgmmE9",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 583.7535516714877,
        y: 218.0489723551932,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 187.6804473507488,
        height: 535.5151055429112,
        seed: 1763700988,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 164,
        versionNonce: 451318724,
        isDeleted: false,
        id: "21n1PW2uG_UGYUQ02BM91",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 383.5610744973556,
        y: 213.04423679320928,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 200.19247717413205,
        height: 545.5244812076667,
        seed: 1450209660,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 159,
        versionNonce: 1532000892,
        isDeleted: false,
        id: "ScBlHhjJJJdDjXIZOKw7m",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 388.5658100593396,
        y: 758.5687180008758,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 387.87292452488083,
        height: 32.531353908166125,
        seed: 920445436,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 137,
        versionNonce: 468155204,
        isDeleted: false,
        id: "tkOjKExZDVjWb02OOXni0",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 396.0731043207389,
        y: 678.4918989578049,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 187.6804473507488,
        height: 85.0816500642668,
        seed: 560415356,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 144,
        versionNonce: 349424380,
        isDeleted: false,
        id: "YlAuxcP18qIJTdeBrOq3C",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 393.5707365397472,
        y: 595.912330296894,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 187.6804473507488,
        height: 80.07700996149532,
        seed: 2094109436,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 115,
        versionNonce: 727675588,
        isDeleted: false,
        id: "SegGbXVC_NWn-R7qQVvB2",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 383.5610744973556,
        y: 268.0970916487248,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 202.69484495512404,
        height: 2.5023677809906304,
        seed: 127783804,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [202.69484495512404, -2.5023677809906304],
        ],
      },
      {
        type: "line",
        version: 232,
        versionNonce: 1385761660,
        isDeleted: false,
        id: "vQd-PCCcxMgoZHLWG1mYf",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 461.1356212186471,
        y: 280.6091214721084,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 70.06744337831555,
        height: 65.06261235711973,
        seed: 1611531260,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [2.5023677809919884, 25.02405964676718],
          [-35.03372168915777, 65.06261235711973],
          [35.03372168915777, 65.06261235711973],
          [2.5023677809919884, 32.53135390816679],
        ],
      },
      {
        type: "line",
        version: 218,
        versionNonce: 132938308,
        isDeleted: false,
        id: "FcXvpHVC7dmXuWyBY_mFT",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 518.6910347735798,
        y: 290.6187835145001,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 27.526427427758495,
        height: 40.03845725114141,
        seed: 203695228,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-2.5023677809919884, 17.516860844578716],
          [-27.526427427758495, 40.03845725114141],
        ],
      },
      {
        type: "line",
        version: 202,
        versionNonce: 383925244,
        isDeleted: false,
        id: "E1xWAqJEogH9ZTD3lwYdv",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 519.9055036881965,
        y: 311.7279424305192,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 61.50901965108538,
        height: 33.550354963545466,
        seed: 27407612,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [39.76340999475904, 31.065156651894387],
          [-21.745609656326348, 33.550354963545466],
        ],
      },
      {
        type: "rectangle",
        version: 150,
        versionNonce: 1819054532,
        isDeleted: false,
        id: "A0ye1rB2Oi0f0EH-rB2zz",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 595.0831733054487,
        y: 456.4916150898497,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 34.17167231708431,
        height: 67.10068622625822,
        seed: 772420988,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 147,
        versionNonce: 1269914748,
        isDeleted: false,
        id: "RaHqoITAgJwXhskHGJElk",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 434.1656523682996,
        y: 522.9710076634028,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 114.94107961201122,
        height: 30.443839298356572,
        seed: 2124293628,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 142,
        versionNonce: 134068548,
        isDeleted: false,
        id: "Busnv-qyQzk0Nf8G-fVCT",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 398.75139274580647,
        y: 559.6278782921386,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 106.86411992185134,
        height: 33.55037866437955,
        seed: 1751123580,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 155,
        versionNonce: 1254649084,
        isDeleted: false,
        id: "eV_1iycf4ds2hZZFzPoV-",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 509.34336938721935,
        y: 560.8704892983808,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 63.99419426190306,
        height: 29.822545645652156,
        seed: 1880217340,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: { type: 3 },
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 122,
        versionNonce: 1979576516,
        isDeleted: false,
        id: "0sRVlwAgjtCynB_17q5sw",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 470.8225466978691,
        y: 634.8055716102241,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 36.035600676865045,
        height: 14.911260972409377,
        seed: 842372988,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 137,
        versionNonce: 1705602428,
        isDeleted: false,
        id: "YBNLS2ZHJqPpWe-sVfrh5",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 473.30776871035425,
        y: 713.7110505453703,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 32.92908501167547,
        height: 13.04740371512952,
        seed: 1796032508,
        groupIds: ["Y6ID-RxpNN6FyaioIgkpt"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487715430,
        link: null,
        locked: false,
      },
    ],
    id: "sW6n_vr6xYh3QorfIN6-o",
    created: 1700487727860,
  },
  {
    status: "published",
    elements: [
      {
        type: "rectangle",
        version: 100,
        versionNonce: 1275747708,
        isDeleted: false,
        id: "Z5R59vHJtr7rfAyCqJPiv",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 953.4727563913891,
        y: 233.42292460182256,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 315.0017197827909,
        height: 133.53324829275886,
        seed: 105484156,
        groupIds: ["lC69mm_jzuTJ--URU_pD_"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487712270,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 144,
        versionNonce: 376522820,
        isDeleted: false,
        id: "JtnUP81AtyBopc63oHKoe",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 931.217149702953,
        y: 366.9561728945814,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 356.08892333926264,
        height: 75.32651808476166,
        seed: 165822460,
        groupIds: ["lC69mm_jzuTJ--URU_pD_"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487712270,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 134,
        versionNonce: 978931196,
        isDeleted: false,
        id: "La1ju6wsGQSVvhKv2D3th",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 913.1999969482422,
        y: 443.09720357192543,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 400.6000061035157,
        height: 20.54353647192613,
        seed: 1745936508,
        groupIds: ["lC69mm_jzuTJ--URU_pD_"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487712270,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 91,
        versionNonce: 757571524,
        isDeleted: false,
        id: "MkGC37b9lOAt8y6Uf55q9",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 929.863897857231,
        y: 475.89063096467225,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 22.6606921823668,
        height: 38.424540843021106,
        seed: 949448956,
        groupIds: ["lC69mm_jzuTJ--URU_pD_"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487712270,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 119,
        versionNonce: 1383476860,
        isDeleted: false,
        id: "FJXTxnX6_1aPqg2t-b_4W",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 985.8521194735969,
        y: 316.58806930715286,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 105.42134866071234,
        height: 34.48361626201688,
        seed: 1257763196,
        groupIds: ["lC69mm_jzuTJ--URU_pD_"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487712270,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 136,
        versionNonce: 1512507204,
        isDeleted: false,
        id: "KTd49ilhRVEVj9E1hxpnZ",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 1128.240100700173,
        y: 317.74415613516874,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 102.46561764080006,
        height: 35.46884740726842,
        seed: 779379196,
        groupIds: ["lC69mm_jzuTJ--URU_pD_"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487712270,
        link: null,
        locked: false,
      },
      {
        type: "rectangle",
        version: 164,
        versionNonce: 86179580,
        isDeleted: false,
        id: "ABcGY18pPuA8-JYlxhFFj",
        fillStyle: "hachure",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 1258.6388670389047,
        y: 473.1219387858054,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 22.6606921823668,
        height: 38.424540843021106,
        seed: 974045820,
        groupIds: ["lC69mm_jzuTJ--URU_pD_"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700487712270,
        link: null,
        locked: false,
      },
    ],
    id: "5zQcYd3xug0a_FArZPbdi",
    created: 1700487723635,
  },
  {
    status: "published",
    elements: [
      {
        type: "ellipse",
        version: 459,
        versionNonce: 180156781,
        isDeleted: false,
        id: "ZrCT-iTu2j-PytqL3vvF7",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 749.3777785441727,
        y: 195.07842847570632,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 61.59999084472656,
        height: 61.59999084472656,
        seed: 1297674093,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 289,
        versionNonce: 487932877,
        isDeleted: false,
        id: "kG4pvf0OWrwk70AxFWya2",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 815.5776991984695,
        y: 301.0783979581282,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 24.800048828125,
        height: 70.4000244140625,
        seed: 1422031309,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [24.800048828125, 70.4000244140625],
        ],
      },
      {
        type: "line",
        version: 273,
        versionNonce: 649413165,
        isDeleted: false,
        id: "SzSYtmAg2OTrIFas7Y-Se",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 837.977723612532,
        y: 284.27841016515947,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 24,
        height: 89.60000610351562,
        seed: 525056045,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [24, 75.20001220703125],
          [21.5999755859375, 86.39999389648438],
          [12, 89.60000610351562],
          [0.79998779296875, 86.39999389648438],
        ],
      },
      {
        type: "line",
        version: 369,
        versionNonce: 1283880077,
        isDeleted: false,
        id: "Q2SXv9Le7J2fc-8LyqPCW",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 723.3943510985599,
        y: 284.52406859129417,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 22.31478569127671,
        height: 73.6656889389281,
        seed: 383658637,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-12.904935438431608, 44.091873858557676],
          [-22.31478569127671, 73.6656889389281],
        ],
      },
      {
        type: "line",
        version: 181,
        versionNonce: 1514298093,
        isDeleted: false,
        id: "o5hu1DV2wS4x9dxcguEtx",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 701.0795654072832,
        y: 358.1897575302223,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 0,
        height: 7.5278781510915,
        seed: 741560557,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0, 7.5278781510915],
        ],
      },
      {
        type: "line",
        version: 360,
        versionNonce: 1752331597,
        isDeleted: false,
        id: "1GKMYslNLU3s77hmzsZKR",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 700.8107166452853,
        y: 365.7176305533525,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 46.511543484307026,
        height: 71.51487320313805,
        seed: 2094312269,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [5.108198269419404, 6.452493359022924],
          [16.13117186202021, 7.796757680857922],
          [26.885286436700426, -11.291812098675905],
          [46.511543484307026, -63.71811552228013],
        ],
      },
      {
        type: "line",
        version: 511,
        versionNonce: 1114653613,
        isDeleted: false,
        id: "T2eIsY1B1YKQfl6M3Xv6a",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 747.3222601295923,
        y: 301.73065088519013,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 39.25252435113623,
        height: 115.87558556777083,
        seed: 958118317,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [3.763944203507208, 0.8065616698779081],
          [1.6131130838329, 11.022968464639462],
          [-35.48858014762902, 115.87558556777083],
        ],
      },
      {
        type: "line",
        version: 505,
        versionNonce: 207963661,
        isDeleted: false,
        id: "3ZfDldp8mGbeGhjPntgcN",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 810.5026832558382,
        y: 304.95689756470165,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 35.48856989170622,
        height: 121.25264285511082,
        seed: 1244789773,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [35.48856989170622, 102.7017911114184],
          [27.422983960696, 121.25264285511082],
        ],
      },
      {
        type: "line",
        version: 301,
        versionNonce: 692780141,
        isDeleted: false,
        id: "LS76X67PHoZEtZi9PK2-q",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 837.3879696925386,
        y: 425.94067114596885,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 27.154135198698214,
        height: 0.26884876199778773,
        seed: 940634733,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-27.154135198698214, -0.26884876199778773],
        ],
      },
      {
        type: "line",
        version: 231,
        versionNonce: 1626007245,
        isDeleted: false,
        id: "QcxclyVqaBieGaF8JNioV",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 809.9649857318424,
        y: 424.32755806213595,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 0.8065462859935906,
        height: 90.60342759878762,
        seed: 1333927117,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [0.8065462859935906, 90.60342759878762],
        ],
      },
      {
        type: "line",
        version: 335,
        versionNonce: 1172210989,
        isDeleted: false,
        id: "rUNK75SW694cAHd4rl4ai",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 750.5484862972581,
        y: 426.20953016388955,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 0.2688487619979014,
        height: 91.40998414070407,
        seed: 136248109,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.2688487619979014, 91.40998414070407],
        ],
      },
      {
        type: "line",
        version: 254,
        versionNonce: 1536120717,
        isDeleted: false,
        id: "ghUo_gMXHD4weIH6oqlg3",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 777.7026420078021,
        y: 425.40297362197316,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 6.7213216091751065,
        height: 90.334558324944,
        seed: 709954957,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [6.7213216091751065, 0.2688487619978446],
          [5.108188013496488, 90.334558324944],
        ],
      },
      {
        type: "line",
        version: 243,
        versionNonce: 2083970541,
        isDeleted: false,
        id: "A9Qs-McToRUpQOAciAfFr",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 777.1649239719605,
        y: 426.74473276998845,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 1.0754155598372108,
        height: 90.60341734286476,
        seed: 894555117,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [1.0754155598372108, 90.60341734286476],
        ],
      },
      {
        type: "line",
        version: 238,
        versionNonce: 2128517197,
        isDeleted: false,
        id: "QyxL6ceWlRniSvz455kmz",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 749.2042219754231,
        y: 426.20953016388955,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 27.422983960696115,
        height: 0.2688487619978446,
        seed: 86691405,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-27.422983960696115, -0.2688487619978446],
        ],
      },
      {
        type: "line",
        version: 316,
        versionNonce: 492832429,
        isDeleted: false,
        id: "LyNvM8oAGURIu-1_OZ11f",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 711.5648312199655,
        y: 417.87509547088155,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 11.291812098675791,
        height: 8.603283455005851,
        seed: 951418029,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [3.7639339475842917, 4.301641727502954],
          [11.291812098675791, 8.603283455005851],
        ],
      },
      {
        type: "line",
        version: 272,
        versionNonce: 442804493,
        isDeleted: false,
        id: "F9uo94Ifb4Lb8E3xuCWI4",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 750.0107887732624,
        y: 516.5440987447564,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 28.22955075853531,
        height: 12.0983788965151,
        seed: 1469800205,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [5.1082085253422065, 8.603283455005908],
          [16.93773865985952, 11.560660860673693],
          [24.734455317026004, 6.990170371173008],
          [28.22955075853531, -0.537718035841408],
        ],
      },
      {
        type: "line",
        version: 213,
        versionNonce: 660995949,
        isDeleted: false,
        id: "Xif0h_yG68QV5bFZ4WbBT",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 782.2731324973028,
        y: 515.7375319469171,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 23.121342233193218,
        height: 12.098378896515158,
        seed: 945157485,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [5.645906049338009, 9.40985025284516],
          [14.786907540185211, 12.098378896515158],
          [23.121342233193218, 8.60330396685157],
        ],
      },
      {
        type: "line",
        version: 191,
        versionNonce: 179145165,
        isDeleted: false,
        id: "SnMgtPy8778swzh1JlSDQ",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 810.771532017836,
        y: 514.393267625082,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 1.3442643218351122,
        height: 5.377057287340108,
        seed: 640597965,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-1.3442643218351122, 5.377057287340108],
        ],
      },
      {
        type: "line",
        version: 181,
        versionNonce: 89442349,
        isDeleted: false,
        id: "FpCX9Jt1h8WvwHK09gW5W",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 809.9649857318424,
        y: 518.694909352585,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 4.30166223934873,
        height: 5.1082085253422065,
        seed: 1195327021,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-4.30166223934873, 5.1082085253422065],
        ],
      },
      {
        type: "line",
        version: 420,
        versionNonce: 2034580109,
        isDeleted: false,
        id: "sr84fUhjxHhxJajbIXeIL",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 754.1981677565211,
        y: 263.60347213940804,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 85.18963054047151,
        height: 26.43815776744006,
        seed: 1114697869,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [55.16110997288126, -0.6527966321346241],
          [78.98810611390331, 14.687880644317943],
          [85.18963054047151, 25.785361135305436],
        ],
      },
      {
        type: "line",
        version: 291,
        versionNonce: 1329094893,
        isDeleted: false,
        id: "Ki0mF5BY2pKMgPDdgzdjM",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 753.8717756659838,
        y: 263.27708004887086,
        strokeColor: "#1e1e1e",
        backgroundColor: "#ffec99",
        width: 30.681329650784846,
        height: 22.19499833515539,
        seed: 628971245,
        groupIds: ["d7Jl5pHqDnLKkJhXbKB9w"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550064447,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-14.035084012183233, 4.243159432284699],
          [-24.806172412633487, 13.708679470585992],
          [-30.681329650784846, 22.19499833515539],
        ],
      },
    ],
    id: "QbmNYsq0jBaSpa30Hy0sI",
    created: 1700550099250,
  },
  {
    status: "published",
    elements: [
      {
        type: "ellipse",
        version: 419,
        versionNonce: 334242979,
        isDeleted: false,
        id: "V0DnYLeAWcncCz9OOlL2v",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 989.5789724813699,
        y: 204.9603754717435,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 61.5999755859375,
        height: 61.5999755859375,
        seed: 602532867,
        groupIds: ["ghJxxjJsqLMNQKf7ePZS3"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550069346,
        link: null,
        locked: false,
      },
      {
        type: "line",
        version: 996,
        versionNonce: 2028312643,
        isDeleted: false,
        id: "cTaxQKLfry366eySptSY7",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 961.5789724813699,
        y: 381.7603174883451,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 124.79998779296875,
        height: 121.60000610351562,
        seed: 834769005,
        groupIds: ["ghJxxjJsqLMNQKf7ePZS3"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550069346,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-0.79998779296875, -36.79998779296875],
          [-0.79998779296875, -78.39999389648438],
          [14.4000244140625, -98.39999389648438],
          [28, -107.19996643066406],
          [42.4000244140625, -107.19996643066406],
          [87.20001220703125, -108],
          [103.20001220703125, -101.60000610351562],
          [116, -92],
          [122.4000244140625, -69.60000610351562],
          [124, -32],
          [123.20001220703125, 8.800018310546875],
          [112, 13.600006103515625],
          [99.20001220703125, 12.800018310546875],
          [97.5999755859375, 0.800018310546875],
          [98.4000244140625, -23.199981689453125],
          [97.5999755859375, -65.60000610351562],
        ],
      },
      {
        type: "line",
        version: 1135,
        versionNonce: 1370462179,
        isDeleted: false,
        id: "G4AiosQheWvT1CJ_bpJFu",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 1058.3789602743386,
        y: 315.3603235918607,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 83.20001220703125,
        height: 230.39999389648438,
        seed: 157655011,
        groupIds: ["ghJxxjJsqLMNQKf7ePZS3"],
        frameId: null,
        roundness: { type: 2 },
        boundElements: [],
        updated: 1700550069346,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-4.79998779296875, 0],
          [-6.39996337890625, 14.399993896484375],
          [-8, 76.79998779296875],
          [-7.20001220703125, 185.60000610351562],
          [-8, 222.39999389648438],
          [-25.5999755859375, 223.19998168945312],
          [-32.79998779296875, 219.20001220703125],
          [-33.5999755859375, 180.00006103515625],
          [-33.5999755859375, 84.79998779296875],
          [-43.20001220703125, 81.60000610351562],
          [-41.5999755859375, 134.39999389648438],
          [-41.5999755859375, 202.39999389648438],
          [-45.5999755859375, 226.39999389648438],
          [-59.20001220703125, 224.80001831054688],
          [-68.79998779296875, 216.00006103515625],
          [-67.20001220703125, 120],
          [-65.5999755859375, 56],
          [-67.20001220703125, 2.399993896484375],
          [-72.79998779296875, -4],
          [-72.79998779296875, 36.79998779296875],
          [-72, 72],
          [-83.20001220703125, 82.39999389648438],
          [-71.20001220703125, 72],
        ],
      },
      {
        type: "line",
        version: 366,
        versionNonce: 1817748355,
        isDeleted: false,
        id: "8_pbBHlLXhbqYi0UKWKZe",
        fillStyle: "solid",
        strokeWidth: 4,
        strokeStyle: "solid",
        roughness: 0,
        opacity: 100,
        angle: 0,
        x: 987.1789480673074,
        y: 384.96032969537634,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        width: 26.39996337890625,
        height: 15.199981689453125,
        seed: 1159343469,
        groupIds: ["ghJxxjJsqLMNQKf7ePZS3"],
        frameId: null,
        roundness: null,
        boundElements: [],
        updated: 1700550069346,
        link: null,
        locked: false,
        startBinding: null,
        endBinding: null,
        lastCommittedPoint: null,
        startArrowhead: null,
        endArrowhead: null,
        points: [
          [0, 0],
          [-9.5999755859375, 12.79998779296875],
          [-22.39996337890625, 10.399993896484375],
          [-26.39996337890625, -2.399993896484375],
        ],
      },
    ],
    id: "KLdly1_JXQlaVaO_Ac_su",
    created: 1700550082995,
  },
];
