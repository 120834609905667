import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {
  Typography,
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Button,
} from "@mui/material";
// import Masonry from "@mui/lab/Masonry";
import { useState } from "react";
import { getRequest } from "../utils/fetch";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";

const ImageSearchDialog = ({
  openImageSearch,
  setOpenImageSearch,
  handleImageClick = () => {},
}) => {
  const [search, setSearch] = useState("");
  const [images, setImages] = useState(IMAGE_LINK);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (search === "") {
      toast.warning("Please enter a search query.");
      return;
    }
    try {
      setLoading(true);
      const response = await getRequest(
        "/dc/aitools/image-search/?query=" + encodeURI(search),
      );
      setImages(response?.data?.photos);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setImages([]);
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={openImageSearch}
      onClose={() => setOpenImageSearch(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">
        <form
          style={{ display: "flex", gap: 6 }}
          onSubmit={(event) => handleSubmit(event)}
        >
          <TextField
            autoFocus
            fullWidth={true}
            id="outlined-basic"
            variant="outlined"
            placeholder="Search Image"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button variant="outlined" type="submit">
            Submit
          </Button>
        </form>
      </DialogTitle>
      <DialogContent sx={{ maxHeight: "420px", overflow: "auto" }}>
        {images.length !== 0 && !loading ? (
          <ImageList
            sx={{ position: "relative" }}
            variant="masonry"
            cols={4}
            gap={4}
          >
            {images.map((value, index) => {
              return (
                <ImageListItem key={index}>
                  <ImageListItemBar
                    // title={value?.alt}
                    subtitle={`by ${value?.photographer}`}
                    actionIcon={
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${value?.title}`}
                        onClick={() => window.open(value?.url)}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                  <img
                    onClick={() => handleImageClick(value)}
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                    src={value?.src?.medium}
                    alt={index}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        ) : null}
        {(loading || images.length === 0) && (
          <Box
            sx={{
              display: "grid",
              justifyContent: "center",
              textAlign: "center",
              minHeight: "400px",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              {images.length === 0 ? "Sorry no image found." : "Loading..."}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Typography variant="body2">
          Images are provided by <b>Pexels</b>
        </Typography>
        <Tooltip title="Click on the image to import" placement="left">
          <InfoIcon size="small" sx={{ color: "gray" }} />
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default ImageSearchDialog;

const IMAGE_LINK = [
  {
    id: 21701325,
    width: 2495,
    height: 3629,
    url: "https://www.pexels.com/photo/christmas-wreath-decorating-modern-interior-21701325/",
    photographer: "Taylor Thompson",
    photographer_url: "https://www.pexels.com/@taylor-thompson-872028288",
    photographer_id: 872028288,
    avg_color: "#C3BFB4",
    src: {
      original:
        "https://images.pexels.com/photos/21701325/pexels-photo-21701325.jpeg",
      large2x:
        "https://images.pexels.com/photos/21701325/pexels-photo-21701325.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/21701325/pexels-photo-21701325.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/21701325/pexels-photo-21701325.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/21701325/pexels-photo-21701325.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/21701325/pexels-photo-21701325.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/21701325/pexels-photo-21701325.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/21701325/pexels-photo-21701325.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Christmas Wreath Decorating Modern Interior",
  },
  {
    id: 19268316,
    width: 2267,
    height: 3388,
    url: "https://www.pexels.com/photo/ottoman-stool-a-sofa-and-a-potted-plant-on-a-tiled-floor-19268316/",
    photographer: "Vandana Vankhede",
    photographer_url: "https://www.pexels.com/@vandana-vankhede-322740014",
    photographer_id: 322740014,
    avg_color: "#A1988F",
    src: {
      original:
        "https://images.pexels.com/photos/19268316/pexels-photo-19268316.jpeg",
      large2x:
        "https://images.pexels.com/photos/19268316/pexels-photo-19268316.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/19268316/pexels-photo-19268316.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/19268316/pexels-photo-19268316.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/19268316/pexels-photo-19268316.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/19268316/pexels-photo-19268316.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/19268316/pexels-photo-19268316.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/19268316/pexels-photo-19268316.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Minimalist ",
  },
  {
    id: 19227224,
    width: 2874,
    height: 3831,
    url: "https://www.pexels.com/photo/modern-interior-design-19227224/",
    photographer: "Sofía Morales",
    photographer_url: "https://www.pexels.com/@sofia-morales-95410047",
    photographer_id: 95410047,
    avg_color: "#8F7155",
    src: {
      original:
        "https://images.pexels.com/photos/19227224/pexels-photo-19227224.jpeg",
      large2x:
        "https://images.pexels.com/photos/19227224/pexels-photo-19227224.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/19227224/pexels-photo-19227224.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/19227224/pexels-photo-19227224.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/19227224/pexels-photo-19227224.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/19227224/pexels-photo-19227224.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/19227224/pexels-photo-19227224.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/19227224/pexels-photo-19227224.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Espacios para re(conectar) ",
  },
  {
    id: 19404868,
    width: 2835,
    height: 3780,
    url: "https://www.pexels.com/photo/glass-celing-in-shopping-mall-19404868/",
    photographer: "Star Zhang",
    photographer_url: "https://www.pexels.com/@star-zhang-3254790",
    photographer_id: 3254790,
    avg_color: "#585858",
    src: {
      original:
        "https://images.pexels.com/photos/19404868/pexels-photo-19404868.jpeg",
      large2x:
        "https://images.pexels.com/photos/19404868/pexels-photo-19404868.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/19404868/pexels-photo-19404868.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/19404868/pexels-photo-19404868.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/19404868/pexels-photo-19404868.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/19404868/pexels-photo-19404868.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/19404868/pexels-photo-19404868.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/19404868/pexels-photo-19404868.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Glass Celing in Shopping Mall",
  },
  {
    id: 19396040,
    width: 5159,
    height: 3869,
    url: "https://www.pexels.com/photo/elegant-modern-interior-with-fireplace-19396040/",
    photographer: "AFSHAHIN Corporation",
    photographer_url: "https://www.pexels.com/@afshahin-corporation-831742368",
    photographer_id: 831742368,
    avg_color: "#A59C97",
    src: {
      original:
        "https://images.pexels.com/photos/19396040/pexels-photo-19396040.jpeg",
      large2x:
        "https://images.pexels.com/photos/19396040/pexels-photo-19396040.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/19396040/pexels-photo-19396040.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/19396040/pexels-photo-19396040.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/19396040/pexels-photo-19396040.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/19396040/pexels-photo-19396040.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/19396040/pexels-photo-19396040.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/19396040/pexels-photo-19396040.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Vicolo Construction",
  },
  {
    id: 15945663,
    width: 5407,
    height: 8107,
    url: "https://www.pexels.com/photo/a-hall-with-stand-for-clothes-15945663/",
    photographer: "Kathrine Birch",
    photographer_url: "https://www.pexels.com/@kathrinepnw",
    photographer_id: 252235557,
    avg_color: "#4D3F32",
    src: {
      original:
        "https://images.pexels.com/photos/15945663/pexels-photo-15945663.jpeg",
      large2x:
        "https://images.pexels.com/photos/15945663/pexels-photo-15945663.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/15945663/pexels-photo-15945663.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/15945663/pexels-photo-15945663.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/15945663/pexels-photo-15945663.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/15945663/pexels-photo-15945663.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/15945663/pexels-photo-15945663.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/15945663/pexels-photo-15945663.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "A Hall with Stand for Clothes",
  },
  {
    id: 7034717,
    width: 4000,
    height: 6000,
    url: "https://www.pexels.com/photo/anonymous-woman-working-on-laptop-in-room-7034717/",
    photographer: "George Milton",
    photographer_url: "https://www.pexels.com/@george-milton",
    photographer_id: 19162611,
    avg_color: "#BEB8B5",
    src: {
      original:
        "https://images.pexels.com/photos/7034717/pexels-photo-7034717.jpeg",
      large2x:
        "https://images.pexels.com/photos/7034717/pexels-photo-7034717.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/7034717/pexels-photo-7034717.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/7034717/pexels-photo-7034717.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/7034717/pexels-photo-7034717.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/7034717/pexels-photo-7034717.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/7034717/pexels-photo-7034717.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/7034717/pexels-photo-7034717.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Anonymous woman working on laptop in room",
  },
  {
    id: 6177607,
    width: 4480,
    height: 6720,
    url: "https://www.pexels.com/photo/light-workplace-with-laptop-in-office-6177607/",
    photographer: "Cup of  Couple",
    photographer_url: "https://www.pexels.com/@cup-of-couple",
    photographer_id: 3716105,
    avg_color: "#BABAB8",
    src: {
      original:
        "https://images.pexels.com/photos/6177607/pexels-photo-6177607.jpeg",
      large2x:
        "https://images.pexels.com/photos/6177607/pexels-photo-6177607.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/6177607/pexels-photo-6177607.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/6177607/pexels-photo-6177607.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/6177607/pexels-photo-6177607.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/6177607/pexels-photo-6177607.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/6177607/pexels-photo-6177607.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/6177607/pexels-photo-6177607.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Light workplace with laptop in office",
  },
  {
    id: 3049121,
    width: 2448,
    height: 3264,
    url: "https://www.pexels.com/photo/wooden-bench-and-stools-in-a-cozy-room-3049121/",
    photographer: "Catherine Augustin",
    photographer_url: "https://www.pexels.com/@kathekth",
    photographer_id: 1469879,
    avg_color: "#928980",
    src: {
      original:
        "https://images.pexels.com/photos/3049121/pexels-photo-3049121.jpeg",
      large2x:
        "https://images.pexels.com/photos/3049121/pexels-photo-3049121.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/3049121/pexels-photo-3049121.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/3049121/pexels-photo-3049121.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/3049121/pexels-photo-3049121.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/3049121/pexels-photo-3049121.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/3049121/pexels-photo-3049121.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/3049121/pexels-photo-3049121.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Wooden Bench And Stools In A Cozy Room",
  },
  {
    id: 6446708,
    width: 5304,
    height: 7952,
    url: "https://www.pexels.com/photo/laptop-on-shelf-near-decorative-ceramic-vases-with-flowers-6446708/",
    photographer: "cocarinne",
    photographer_url: "https://www.pexels.com/@cocarinne",
    photographer_id: 16616019,
    avg_color: "#B5A895",
    src: {
      original:
        "https://images.pexels.com/photos/6446708/pexels-photo-6446708.jpeg",
      large2x:
        "https://images.pexels.com/photos/6446708/pexels-photo-6446708.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/6446708/pexels-photo-6446708.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/6446708/pexels-photo-6446708.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/6446708/pexels-photo-6446708.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/6446708/pexels-photo-6446708.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/6446708/pexels-photo-6446708.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/6446708/pexels-photo-6446708.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Netbook with white screen placed on shelf near uneven curved ceramic vases with flowers with green leaves near pipes in light room with white wall",
  },
  {
    id: 3356416,
    width: 3333,
    height: 4188,
    url: "https://www.pexels.com/photo/gray-dining-table-under-pendant-lamps-3356416/",
    photographer: "Houzlook .com",
    photographer_url: "https://www.pexels.com/@houzlook",
    photographer_id: 1528797,
    avg_color: "#605249",
    src: {
      original:
        "https://images.pexels.com/photos/3356416/pexels-photo-3356416.jpeg",
      large2x:
        "https://images.pexels.com/photos/3356416/pexels-photo-3356416.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/3356416/pexels-photo-3356416.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/3356416/pexels-photo-3356416.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/3356416/pexels-photo-3356416.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/3356416/pexels-photo-3356416.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/3356416/pexels-photo-3356416.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/3356416/pexels-photo-3356416.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Gray Dining Table Under Pendant Lamps",
  },
  {
    id: 4207785,
    width: 2873,
    height: 4309,
    url: "https://www.pexels.com/photo/scandinavian-room-interior-with-mockup-photo-frame-and-vase-4207785/",
    photographer: "Karolina Grabowska",
    photographer_url: "https://www.pexels.com/@karolina-grabowska",
    photographer_id: 2332540,
    avg_color: "#C0BAB5",
    src: {
      original:
        "https://images.pexels.com/photos/4207785/pexels-photo-4207785.jpeg",
      large2x:
        "https://images.pexels.com/photos/4207785/pexels-photo-4207785.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/4207785/pexels-photo-4207785.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/4207785/pexels-photo-4207785.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/4207785/pexels-photo-4207785.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/4207785/pexels-photo-4207785.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/4207785/pexels-photo-4207785.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/4207785/pexels-photo-4207785.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Scandinavian room interior with mockup photo frame and vase",
  },
  {
    id: 462235,
    width: 1333,
    height: 2000,
    url: "https://www.pexels.com/photo/five-posters-mounted-on-wall-462235/",
    photographer: "Pixabay",
    photographer_url: "https://www.pexels.com/@pixabay",
    photographer_id: 2659,
    avg_color: "#B9B8B9",
    src: {
      original:
        "https://images.pexels.com/photos/462235/pexels-photo-462235.jpeg",
      large2x:
        "https://images.pexels.com/photos/462235/pexels-photo-462235.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/462235/pexels-photo-462235.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/462235/pexels-photo-462235.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/462235/pexels-photo-462235.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/462235/pexels-photo-462235.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/462235/pexels-photo-462235.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/462235/pexels-photo-462235.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Five Posters Mounted on Wall",
  },
  {
    id: 6373305,
    width: 3699,
    height: 5548,
    url: "https://www.pexels.com/photo/book-with-white-hard-cover-on-table-6373305/",
    photographer: "Monstera Production",
    photographer_url: "https://www.pexels.com/@gabby-k",
    photographer_id: 3372733,
    avg_color: "#AB8864",
    src: {
      original:
        "https://images.pexels.com/photos/6373305/pexels-photo-6373305.jpeg",
      large2x:
        "https://images.pexels.com/photos/6373305/pexels-photo-6373305.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/6373305/pexels-photo-6373305.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/6373305/pexels-photo-6373305.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/6373305/pexels-photo-6373305.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/6373305/pexels-photo-6373305.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/6373305/pexels-photo-6373305.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/6373305/pexels-photo-6373305.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Book with white hard cover on table",
  },
  {
    id: 3932930,
    width: 3721,
    height: 5581,
    url: "https://www.pexels.com/photo/interior-of-children-bedroom-with-wooden-furniture-and-toys-and-globe-placed-on-shelves-in-room-3932930/",
    photographer: "Tatiana Syrikova",
    photographer_url: "https://www.pexels.com/@tatianasyrikova",
    photographer_id: 1056622,
    avg_color: "#C0AFA1",
    src: {
      original:
        "https://images.pexels.com/photos/3932930/pexels-photo-3932930.jpeg",
      large2x:
        "https://images.pexels.com/photos/3932930/pexels-photo-3932930.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      large:
        "https://images.pexels.com/photos/3932930/pexels-photo-3932930.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
      medium:
        "https://images.pexels.com/photos/3932930/pexels-photo-3932930.jpeg?auto=compress&cs=tinysrgb&h=350",
      small:
        "https://images.pexels.com/photos/3932930/pexels-photo-3932930.jpeg?auto=compress&cs=tinysrgb&h=130",
      portrait:
        "https://images.pexels.com/photos/3932930/pexels-photo-3932930.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=1200&w=800",
      landscape:
        "https://images.pexels.com/photos/3932930/pexels-photo-3932930.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200",
      tiny: "https://images.pexels.com/photos/3932930/pexels-photo-3932930.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    },
    liked: false,
    alt: "Comfortable bed with toys globe and paintings placed on wooden cabinet with wicker boxes and soft white rug with toy house on floor",
  },
];
